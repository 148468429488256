import React from "react";
import style from "./SwipeableCard.module.css";
import MinifiedCart from "./MinifiedCart";
import FullCart from "./FullCart";

function SwipeableCart({ isOnSalesPage, isOpen, setIsOpen }) {
  return (
    <div className={`${isOpen ? style.fullViewBackdrop : ""}`}>
      <div className={`${style.cartPage} ${style.transitionTransform} ${isOnSalesPage ? style.cartPageOnSalesPage : style.cartPageOnOtherPage} ${isOpen ? style.cartOnFullCart : ""}`}>
        <div className={`${style.cartContainer}`}>{isOpen ? <FullCart isOpen={isOpen} setIsOpen={setIsOpen} /> : <MinifiedCart setIsOpen={setIsOpen} />}</div>
      </div>
    </div>
  );
}

export default SwipeableCart;
