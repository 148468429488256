import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ModalRedirections from "../Components/ForcedRedirections/ModalRedirections";
import FormRedirections from "../Components/ForcedRedirections/FormRedirections";

const ForcedRedirections = () => {
  const { user } = useSelector((state) => state.auth);
  const user_id = user.user_id;
  const redirections = user?.redirections;
  const [modalRedirections, setModalRedirections] = useState(false);
  const [formRedirections, setFormRedirections] = useState(false);
  const [selectedRedirectionIndex, setSelectedRedirectionIndex] = useState(0);

  useEffect(() => {
    if (
      redirections?.length > 0 &&
      selectedRedirectionIndex < redirections.length
    ) {
      const currentObject = redirections[selectedRedirectionIndex];
      if (JSON.parse(currentObject.json_object)?.hasOwnProperty("textbody")) {
        setModalRedirections(true);
        setFormRedirections(false);
      } else {
        setFormRedirections(true);
        setModalRedirections(false);
      }
    }
  }, [selectedRedirectionIndex, redirections]);
 
  return (
    <>
      {selectedRedirectionIndex < redirections.length && modalRedirections && (
        <ModalRedirections
          show={modalRedirections}
          data={redirections[selectedRedirectionIndex]}
        />
      )}
      {selectedRedirectionIndex < redirections.length && formRedirections && (
        <FormRedirections
          show={formRedirections}
          data={redirections[selectedRedirectionIndex]}
        />
      )}
    </>
  );
};
export default ForcedRedirections;
