import Select from "react-select";
const SearchableSelect = ({
  label,
  value,
  id,
  disabled,
  onClick,
  handleChange,
  isClearable,
  forceSelection,
  placeholder,
  isValidate,
  options,
  important,
  multiselect,
  searchable,
  defaultValue,
  onInputChange,
  filterOption,
  getOptionLabel,
  selectClassName,
  closeMenuOnSelect = true,
  noOptionsMessage,
  ...props
}) => {
  if (isValidate === true) {
    var validate = {
      border: "1px solid red",
    };
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#5932EA" : "#80858C",
      background: state.isSelected ? "#F5F6FA" : "#fff",
      padding: 10,
      zIndex: 10,
      background: state.isDisabled ? "#E8E8E8" : "",
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: "100%",
      maxWidth: "100%",
      fontSize: "15px",
      ...validate,
    }),
    singleValue: (provided, state) => ({
      color: "#000",
      marginTop: "-30px",
    }),
  };

  return (
    <>
      {label ? (
        <label>
          {label}
          {important ? <span className="text-danger">*</span> : <span></span>}
        </label>
      ) : (
        ""
      )}
      <Select
        className={`text-secondary bg-white text-capitalize ${selectClassName}`}
        isValidate={isValidate}
        isDisabled={disabled}
        required
        forceSelection={forceSelection}
        classNamePrefix="react-select-container"
        defaultValue={defaultValue}
        isMulti={multiselect}
        isSearchable={searchable}
        closeMenuOnScroll={false}
        closeMenuOnSelect={closeMenuOnSelect}
        options={options}
        isClearable={isClearable}
        value={value}
        placeholder={placeholder}
        id={id}
        filterOption={filterOption}
        getOptionLabel={getOptionLabel}
        onClick={onClick}
        onChange={handleChange}
        styles={customStyles}
        onInputChange={onInputChange}
        noOptionsMessage={noOptionsMessage}
        {...props}
      />
    </>
  );
};

export default SearchableSelect;
