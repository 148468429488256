import React from "react";
import styles from "../../styles/PlansCard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import rocket from "../../assets/images/rocket.png";
import running from "../../assets/images/running-man.png";
import crown from "../../assets/images/crown.png";
import trophy from "../../assets/images/trophy.png";
import RequestModal from "./RequestModal";
import { useState } from "react";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Accordion, Col, Row } from "react-bootstrap";

function MobilePansCard({ plansData, setModalShow, setChangePlan, setPlanId, setCustomModal }) {
  const [frequency, setFrequency] = useState("monthly");
  const [check, setCheck] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const loc = useLocation();
  const shop_id = user.shop_id;
  const sectionsRef = useRef(null);
  const moduleNames = Array.from(new Set(plansData.flatMap((plan) => plan.modules.map((module) => module.name))));
  const handleFrequency = (e) => {
    setCheck(false);
    setFrequency(e.target.value);
    setTimeout(() => {
      setCheck(true);
    }, 200);
  };

  return (
    <div>
      <div className=" w-100 p-3 ">
        <div className="d-flex flex-column bg-white p-2">
          <div className="mb-2">
            <p className={`text-uppercase  text-center m-0   text-black-50  ${styles.freqP} `}>Choose Payment Frequency</p>
          </div>
          <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="btn-group btn-group-sm border border-primary rounded-pill border-1 p-2" role="group" aria-label="Basic radio toggle button group">
              <button name="plans" onClick={handleFrequency} value="monthly" className="btn btn-outline-primary  fs-14 rounded-pill">
                3 Months
              </button>

              <button name="plans" onClick={handleFrequency} className={`btn btn-outline-primary fs-14 rounded-pill mx-1  `} aria-label="radio Quarterly" value="quarterly" checked={frequency === "quarterly"}>
                6 Months
              </button>

              <button name="plans" onClick={handleFrequency} className="btn btn-outline-primary fs-14 rounded-pill" aria-label="radio yearly" value="yearly" checked={frequency === "yearly"}>
                12 Months
              </button>
            </div>
          </div>
          <div className="col-12 text-muted fw-normal form-text fs-14 ">
            <p className={`text-center m-0 `}>Save 10% on 6 Months</p>
            <p className={`text-center m-0 `}>Save 20% on 12 Months</p>
          </div>
        </div>
        {/* card */}
        <Row>
          {plansData?.map((i, index) => (
            <Col xs={12} sm={6} md={6} lg={4} className="mt-4">
              <div className="bg-white p-3 text-center">
                {i.plan_name === "Basic" ? (
                  <img src={rocket} height="40" width="40" alt="rocket" />
                ) : i.plan_name === "Standard" ? (
                  <img src={running} height="40" width="40" alt="rocket" />
                ) : i.plan_name === "Pro" ? (
                  <img src={crown} height="40" width="40" alt="rocket" />
                ) : i.plan_name === "Business" ? (
                  <img src={trophy} height="40" width="40" alt="rocket" />
                ) : (
                  ""
                )}
                <h6 className={` mt-1   ${i.id === user.plan_id && frequency === user.current_plan_billing_frequency ? "inactiveColor" : "inactiveColor"} ${styles.subsh2}     `}>{i.plan_name.toUpperCase()}</h6>
                {
                  <div className={`${styles.subsdiv} ${i.id === user.plan_id && frequency === user.current_plan_billing_frequency ? "activeColor" : ""} `}>
                    <div className="d-flex flex-column text-center">
                      {frequency === "monthly" ? (
                        ""
                      ) : frequency === "quarterly" ? (
                        <span
                          style={{
                            textDecoration: "line-through",
                            fontSize: "16px",
                          }}
                          className=" "
                        >
                          {i.monthly_total * 3}
                        </span>
                      ) : (
                        <span
                          style={{
                            textDecoration: "line-through",
                            fontSize: "16px",
                          }}
                          className=" "
                        >
                          {i.monthly_total * 12}
                        </span>
                      )}
                      <span className="d-grid ">
                        <div className="mb-2">
                          <span className={`${styles.subsspan1}`}>Rs.</span>
                          <span className={`${styles.subsspan2}`}> {frequency === "monthly" ? i.monthly_total : frequency === "quarterly" ? i.quarterly_total : frequency === "yearly" ? i.yearly_total : ""}</span>
                          {/* <span className={`${styles.subsspan3}`}>/{frequency}</span> */}
                        </div>
                        <span className={`text-center fw-light form-text  mb-2`}>
                          {i.plan_name === "Basic"
                            ? "New to the platform? Let's get you started"
                            : i.plan_name === "Standard"
                            ? "Explore more with amazing features"
                            : i.plan_name === "Pro"
                            ? "Power usage with all the features at your disposal"
                            : i.plan_name === "Business"
                            ? "Unlimited Resources with Dedicated Support"
                            : ""}
                        </span>
                        <div>
                          {user.on_payment === 1 ? (
                            i.id > user.plan_id ? (
                              <button
                                // className={`${styles.susbsbtn} w-100`}
                                className={`btn   btn-outline-primary  ${styles.downgradeBtn} w-100`}
                                variant="outline-primary"
                                onClick={() => {
                                  setModalShow(true);
                                  setPlanId(i.id);
                                  setChangePlan("Upgrade");
                                }}
                              >
                                {user.plan_name.includes("customPlan") ? "Subscribe" : "Upgrade"}
                              </button>
                            ) : i.id < user.plan_id ? (
                              <button
                                className={`btn   btn-outline-primary  ${styles.downgradeBtn} w-100`}
                                variant="outline-primary"
                                onClick={() => {
                                  setModalShow(true);
                                  setPlanId(i.id);
                                  setChangePlan("Downgrade");
                                }}
                              >
                                {user.plan_name.includes("customPlan") ? "Subscribe" : "Downgrade"}
                              </button>
                            ) : i.id === user.plan_id && frequency === user.current_plan_billing_frequency ? (
                              <>
                                <button style={{ pointerEvents: "none" }} className={`btn   btn-outline-success w-100 `} variant="outline-primary">
                                  Current Plan
                                </button>
                              </>
                            ) : (
                              <button
                                className={`${styles.susbsbtn} ${styles.changeFreqBtn} w-100`}
                                variant="outline-primary"
                                onClick={() => {
                                  setModalShow(true);
                                  setPlanId(i.id);
                                  setChangePlan("Change_Frequency");
                                }}
                              >
                                {user.plan_name.includes("customPlan") ? "Subscribe" : "Change"}
                              </button>
                            )
                          ) : (
                            user.on_trial === 1 && (
                              <button
                                className={`${styles.susbsbtn} w-100`}
                                variant="outline-primary"
                                onClick={() => {
                                  setModalShow(true);
                                  setPlanId(i.id);
                                  setChangePlan("");
                                }}
                              >
                                Buy Plan
                              </button>
                            )
                          )}
                        </div>
                      </span>
                    </div>
                    <Accordion className="mt-3" defaultActiveKey={index === 0 || index === 1 ? [0, 1] : ""}>
                      <Accordion.Item className={styles.accordianBody} eventKey="0">
                        <Accordion.Header className={`${styles.accordianHeader}`}> View Details</Accordion.Header>
                        <Accordion.Body className={`p-0 `}>
                          <table ref={sectionsRef} className={`table mb-3 mt-3 ${styles.termsTableMobile}`}>
                            <thead></thead>
                            <tbody>
                              <tr className=" ">
                                <td className={`   ${styles.subsh2}  font700  align-middle text-start strippedColumn `}>Consumable Items</td>
                                <td className={`text-center strippedColumn ${styles.subsh2} align-middle ${i.id === user.plan_id && frequency === user.current_plan_billing_frequency ? "" : ""} `}>Quantity</td>
                              </tr>
                              {plansData.length > 0
                                ? Object.keys(plansData && plansData[0]).map((property, rowIndex) => (
                                    <tr key={rowIndex}>
                                      {property !== "id" &&
                                        property !== "plan_name" &&
                                        property !== "plan_type" &&
                                        property !== "plan_amount" &&
                                        property !== "monthly_total" &&
                                        property !== "quarterly_total" &&
                                        property !== "yearly_total" &&
                                        property !== "modules" &&
                                        property !== "themes" &&
                                        property !== "is_active" && (
                                          <td className={`  ${rowIndex % 2 === 0 ? "" : "bg-white"} h-100 d-flex  text-start  `} style={{ color: "#2f1c6a" }}>
                                            {property?.replaceAll("_", " ").charAt(0).toUpperCase() + property?.replaceAll("_", " ").slice(1)}
                                          </td>
                                        )}

                                      <>
                                        {property !== "id" &&
                                          property !== "plan_name" &&
                                          property !== "plan_type" &&
                                          property !== "plan_amount" &&
                                          property !== "monthly_total" &&
                                          property !== "quarterly_total" &&
                                          property !== "yearly_total" &&
                                          property !== "modules" &&
                                          property !== "themes" &&
                                          property !== "is_active" && (
                                            <td style={{ color: "#2f1c6a" }} className={`  h-100  ${i.id === user.plan_id && frequency === user.current_plan_billing_frequency ? "" : ""} border-top-0 `}>
                                              {i[property]}
                                            </td>
                                          )}
                                      </>
                                    </tr>
                                  ))
                                : ""}

                              <tr className="bg-secondary ">
                                <td className={`   ${styles.subsh2} align-middle text-start  font700 strippedColumn  `}>Features</td>
                                <td className={`text-center strippedColumn border-top-0 ${styles.subsh2} ${i.id === user.plan_id && frequency === user.current_plan_billing_frequency ? "" : ""}`}>Availability</td>
                              </tr>
                              {i.modules.map((mod, rowIndex) => (
                                <tr key={mod}>
                                  <td className={`  ${rowIndex % 2 === 0 ? "" : "bg-white"} h-100 d-flex text-start  align-items-center  `} style={{ color: "#2972DD" }}>
                                    {mod.name.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")}
                                  </td>

                                  {/* <td className={`${styles.subsdiv} h-100 d-flex ${rowIndex % 2 === 0 ? "" : "bg-white"} font700  justify-content-center align-items-center`}>--</td> */}
                                  <td className={`${rowIndex % 2 === 0 ? "" : "bg-white"} text-center ${i.id === user.plan_id && frequency === user.current_plan_billing_frequency ? "" : ""} border-top-0 `} key={i.id + mod.name}>
                                    {mod ? mod.isIncluded ? <FontAwesomeIcon style={{ color: "#36AF8D", fontSize: "18px" }} icon={faCheck} /> : <FontAwesomeIcon style={{ color: "#F35185", fontSize: "18px" }} icon={faXmark} /> : "No"}
                                  </td>
                                </tr>
                              ))}

                              <tr>
                                <td style={{ color: "#C5D852" }} className={`   ${styles.subsh2} align-middle text-start  font700   `}>
                                  Themes
                                </td>
                                {/* 
                              <td className={`   h-100 d-flex  align-items-center  `} style={{ color: "#C5D852", }}>
                                Themes
                              </td> */}

                                <td style={{ color: "#2f1c6a", maxWidth: "170px" }} className={`  text-center ${i.id === user.plan_id && frequency === user.current_plan_billing_frequency ? "" : ""} border-top-0 `}>
                                  {i.themes.length > 0 ? i.themes.map((theme) => theme.theme_name).join(", ") : "No Themes"}
                                </td>

                                {/* <td className={`${styles.subsdiv} h-100 d-flex   font700  justify-content-center align-items-center`}>--</td> */}
                              </tr>

                              {/* ================================== */}
                            </tbody>
                          </table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                }
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default MobilePansCard;
