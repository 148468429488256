import { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import { Form, Row, Col } from "react-bootstrap";
import Markdown from "../../MarkdownEditor/Markdown";
import ImageUploader from "../../ImageUploader/ImageUploader";
import ReactSelect from "../../ReactSelect";
import axios from "axios";
import { getcomponent } from "../../InventoryForms/utils";
import { convert } from "html-to-text";
import VideoUploader from "../../VideoUploader/VideoUploader";
import style from "./validationModal.module.css";

const AccessoryForm = forwardRef(({ state, setState }, ref) => {
  const { imageRef, videoRef } = ref;

  const [titlePlaceholder, setTitlePlaceholder] = useState("e.g. Mi Power Bank");
  const [isTitleCustomize, setIsTitleCustomize] = useState(true);
  const [customizeTitle, setCustomizeTitle] = useState({
    fixedPart: "",
    dynamicPart: "",
  });
  const [isTitleCharacterLimitExceeded, setIsTtitleCharacterLimitExceeded] = useState(false);
  const [isCustomizeTitleFocused, setIsCustomizeTitleFocused] = useState(false);

  const handleChangeAdditionalNotes = (e) => {
    setState((prev) => {
      return {
        ...prev,
        listing_optional: {
          ...prev.listing_optional,
          notes: e.target.value,
        },
      };
    });
  };
  const handleChangeType = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        accessory_type: e.label,
        fk_accessory_type_id: e.value,
      },
    }));
  };
  const handleChangeBrand = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        brand: e.label,
        fk_brand_id: e.value,
      },
    }));
  };
  const handleChangeColor = (e) => {
    setState((prev) => ({
      ...prev,
      listing_color: {
        color: e.label,
        fk_color_id: e.value,
      },
    }));
  };
  const handleChangeModel = (e) => {
    const cleanedValue = e.target.value
      .replace(/[^a-zA-Z0-9 \-\/'+]/g, "")
      .replace(/\s+/g, " ")
      .replace(/\s+/g, " ");
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        model: cleanedValue,
        fk_model_id: -1,
      },
    }));
  };
  const handleChangeImages = (event) => {
    if (typeof event === "function") {
      setState((prev) => ({
        ...prev,
        images: event(prev.images),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        images: event,
      }));
    }
  };
  const handleChangeVideo = (event) => {
    if (typeof event === "function") {
      setState((prev) => ({
        ...prev,
        video: event(prev.video),
      }));
    } else {
      if (event === null) {
        setState((prev) => {
          if (prev?.video) {
            delete prev?.video;
          }
          return prev;
        });
      } else {
        setState((prev) => ({
          ...prev,
          video: event,
        }));
      }
    }
  };

  const validateInput = (event) => {
    const regex = /^[a-zA-Z0-9 \-\/']*$/;

    const input = event.target.value + event.key;
    if (!regex.test(input)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (isTitleCustomize) {
      if (state?.listing?.brand && state?.listing?.model) {
        const autoFillPart = `${state?.listing?.brand ? (state?.listing?.brand === "Other" ? "" : state?.listing?.brand + " ") : ""}${state?.listing?.model?.trim()}`;
        setCustomizeTitle((prev) => ({
          ...prev,
          fixedPart: autoFillPart,
        }));
        setState((prev) => ({
          ...prev,
          title: autoFillPart + `${customizeTitle.dynamicPart?.length > 0 ? " " + customizeTitle.dynamicPart : ""}`,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          title: "",
        }));
        setCustomizeTitle({ fixedPart: "", dynamicPart: "" });
        setTitlePlaceholder("Fill all the fields to Add the title....");
      }
    } else {
      if (state?.listing?.accessory_type && state?.listing?.brand && state?.listing_color?.color && state?.listing?.model) {
        setState((prev) => ({
          ...prev,
          title: `${state?.listing?.accessory_type && state?.listing?.accessory_type !== "Other" ? state?.listing?.accessory_type + " " : ""}${state?.listing?.brand && state?.listing?.brand !== "Other" ? state?.listing?.brand + " " : ""}${
            state?.listing_color?.color && state?.listing_color?.color !== "Other" ? state?.listing_color?.color + " " : ""
          }${state?.listing?.model?.trim()}`,
        }));
      } else {
        if (state?.listing?.accessory_type || state?.listing?.brand || state?.listing_color?.color || state?.listing?.model) {
          setTitlePlaceholder("Fill all the fields to generate the title....");
        }
        setState((prev) => {
          return {
            ...prev,
            title: "",
          };
        });
      }
    }
  }, [state?.listing?.accessory_type, state?.listing?.brand, state?.listing_color?.color, state?.listing?.model, isTitleCustomize, customizeTitle.dynamicPart]);
  return (
    <>
      <Form noValidate validated={state?.validated} className="m-3">
        <Row className="gx-0 gx-sm-3 gx-lg-5">
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                label={"Type"}
                important
                placeholder="Select Type"
                searchable={true}
                cache={false}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    axios
                      .get(process.env.REACT_APP_API_URL + `/component/accessory_type`, {
                        withCredentials: true,
                        headers: { dropdown: true },
                      })
                      .then((res) => {
                        const value = res.data.success.data.map((loc) => ({
                          value: loc.id,
                          label: loc.label,
                        }));

                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                onInputChange={(e) => {
                  return e;
                }}
                handleChange={handleChangeType}
                value={
                  state?.listing?.accessory_type
                    ? {
                        label: state?.listing?.accessory_type,
                        value: state?.listing?.fk_accessory_type_id,
                      }
                    : null
                }
                isInvalid={state?.validated && !state?.listing?.accessory_type}
                name="Accessory Type"
                required
              />
              <Form.Control.Feedback type="invalid"> * Please Select Type </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                label={"Brand"}
                important
                placeholder="Select brand"
                searchable={true}
                cache={false}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("brand", { is_accessory: true }, true)
                      .then((res) => {
                        const value = res.data.success.data.map((loc) => ({
                          value: loc.id,
                          label: loc.label,
                        }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                isInvalid={state?.validated && !state?.listing?.brand}
                handleChange={handleChangeBrand}
                value={
                  state?.listing?.brand
                    ? {
                        label: state?.listing?.brand,
                        value: state?.listing?.fk_brand_id,
                      }
                    : null
                }
                name="Brand"
                required
              />
              <Form.Control.Feedback type="invalid">* Please Select Brand </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group className="" controlId="model">
              <Form.Label className="mb-0">
                Model<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control className="py-2" onKeyDown={validateInput} type="text" placeholder="e.g. dell" onChange={handleChangeModel} value={state?.listing?.model ? state?.listing?.model : ""} name="model" required />
              <Form.Control.Feedback type="invalid">* Please Select Model </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-2" md={12}>
            <Form.Group>
              <Form.Label>
                Title<span style={{ color: "red" }}>*</span>{" "}
                <Form.Check
                  type="checkbox"
                  style={{ display: "inline", paddingLeft: "5px" }}
                  checked={!isTitleCustomize}
                  onChange={(e) => {
                    setIsTitleCustomize(!e.target.checked);
                  }}
                />
                <span className={`ps-1 text-muted`}>Auto-Generate Title</span>
              </Form.Label>
              {!isTitleCustomize ? (
                <Form.Control className="py-3 pe-5" type="text" placeholder={titlePlaceholder} value={state?.title} disabled={true} onChange={() => {}} name="model" required />
              ) : (
                <>
                  <div className={`p-3 form-control ${style.customizeTitleField} ${state.title ? "" : style.disabledDiv}`}>
                    {state?.title ? (
                      <>
                        <span className={style.customizeTitleFieldSpan}>{customizeTitle.fixedPart}&nbsp;</span>
                        <input
                          value={customizeTitle.dynamicPart}
                          className={`${style.customizeTitleFieldInput}`}
                          onKeyDown={validateInput}
                          onChange={(e) => {
                            const value = e.target.value;

                            const cleanedValue = value.replace(/[^a-zA-Z0-9 \-\/'+]/g, "").replace(/\s+/g, " ");
                            if (customizeTitle.fixedPart.length + cleanedValue.length <= 250) {
                              setCustomizeTitle((prev) => ({
                                ...prev,
                                dynamicPart: cleanedValue,
                              }));
                            }

                            if (customizeTitle.fixedPart.length + cleanedValue.length <= 70) {
                              setIsTtitleCharacterLimitExceeded(false);
                            } else {
                              setIsTtitleCharacterLimitExceeded(true);
                            }
                          }}
                          type="text"
                          disabled={state?.listing?.brand && state?.listing?.model ? false : true}
                          name="model"
                          required
                        />
                      </>
                    ) : (
                      <span className="text-muted">{titlePlaceholder}</span>
                    )}
                  </div>
                  {isTitleCharacterLimitExceeded ? <div className="text-danger py-2">*7-100 characters are recommended above 100 is not SEO friendly. The maximum allowed length is 250 characters.</div> : <></>}
                  <div className="alert alert-warning" role="alert">
                    Please enter "Brand and Model" before entering the title
                  </div>
                </>
              )}
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                label={"Color"}
                important
                placeholder="Select Color"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("color", { is_accessory: true }, true)
                      .then((res) => {
                        const value = res.data.success.data.map((loc) => ({
                          value: loc.id,
                          label: loc.label,
                        }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeColor}
                value={
                  state?.listing_color?.color
                    ? {
                        label: state?.listing_color?.color,
                        value: state?.listing_color?.fk_color_id,
                      }
                    : null
                }
                isInvalid={state?.validated && !state?.listing_color?.color}
                name="Color"
                required
              />

              <Form.Control.Feedback type="invalid">Please Select Color</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-2" md={12}>
            <Form.Group>
              <Form.Label>
                Additional Notes <span className="text-muted">(Optional)</span>
              </Form.Label>
              <Form.Control className="py-3 pe-5" type="text" as="textarea" rows={3} placeholder="Enter Additional Information" value={state?.listing_optional?.notes} onChange={handleChangeAdditionalNotes} name="notes" isInvalid={state?.listing_optional?.notes?.length > 500} />
              <Form.Control.Feedback type="invalid">Character Limit Exceeded: Please limit your input to a maximum of 500 characters.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="pb-3" sm={12} md={12}>
            <Form.Group>
              <Markdown
                important
                value={state?.listing?.description ? state?.listing?.description : ""}
                onEditorChange={(content, editor) => {
                  setState((prev) => ({
                    ...prev,
                    listing: {
                      ...prev.listing,
                      description: content,
                    },
                  }));
                }}
                isInvalid={state?.validated && !state?.listing?.description}
              />
              <Form.Control value={state?.listing?.description} name="Description" onChange={() => {}} hidden required isInvalid={state.validated && convert(state?.listing?.description)?.length < 50} />

              <Form.Control.Feedback type="invalid">{convert(state?.listing?.description)?.length < 50 ? "Description Must be more than 50 characters." : "Please Enter Description"}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={12}>
            <Form.Group>
              <Form.Label className="mb-0">
                Upload Image<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <ImageUploader ref={imageRef} selectedFiles={state?.images} setSelectedFiles={handleChangeImages} isInvalid={state?.validated && !state?.images?.length > 0} />
              <Form.Control value={state?.images?.length > 0 ? state?.images : ""} name="Image" onChange={() => {}} hidden required />
              <Form.Control.Feedback type="invalid">Please Enter Atleast One Image</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={12}>
            <Form.Group>
              <Form.Label className="mb-0 mt-3">Upload Video</Form.Label>
              <VideoUploader ref={videoRef} selectedFile={state?.video} setSelectedFile={handleChangeVideo} isInvalid={state?.validated && !state?.images?.length > 0} disabled={!state?.listing?.model} />
              <Form.Control value={state?.images?.length > 0 ? state?.images : ""} name="Image" onChange={() => {}} hidden />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
});

AccessoryForm.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
};

export default AccessoryForm;
