import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import style from './../../styles/Chart.module.css'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    type:"line",
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            labels: {
                usePointStyle: true,
                pointStyle: 'square'
            },
            position: 'top',
            align: 'end'
        }
    },
    scales: {
      
     
    }
};

const LineChart = ({data}) => {
    return (<>
        {
            data?.labels?.length > 0 ? (
                <Line options={options} data={data} className={`${style.canvas}`} />
        ) : (
                <div className={`${style.barCanvas} fs-3 fw-bold d-flex justify-content-center align-items-center`}>No data exists.</div>
          )
        }
    </>);
}

export default LineChart;