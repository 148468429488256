import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCircleCheck, faXmarkCircle, faGlassCheers } from "@fortawesome/free-solid-svg-icons";
import triangle from "../../assets/svg/triangle.svg";
import dangerline from "../../assets/svg/dangerline.svg";
import styleModal from "../../styles/Modal.module.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";

import { useSearchParams, useNavigate } from "react-router-dom";
import PaginationBar from "../../Components/PaginationBar";
import { useEffect, useState, useRef } from "react";

import moment from "moment";
import PopUpModal from "../../Components/Modal";
import customloader from "../../assets/images/RollingLoader.gif";
import { useReactToPrint } from "react-to-print";
import { useCallback } from "react";
import Csv from "../../Components/csv/Csv";
import axios from "axios";
import NoResult from "../../Components/NoResult";
import styles from "../../styles/Refund.module.css";

import { requestDateTime, TIMEOUT } from "../../Features/dateTime";
import ViewImei from "../../Components/IMEI/ViewImei";
import ReceivePayments from "../../Pages/ReceivePayments";
import InvoicePaymentSales from "../../Pages/SalesHistory/InvoicePaymentSales/InvoicePaymentSales";
import ThermalInvoicePaymentSales from "../../Pages/SalesHistory/InvoicePaymentSales/ThermalInvoicePaymentSales";
import AddRefund from "../../Pages/SalesHistory/Refund/AddRefund";
import LoaderPage from "../loaders/loaderPage";

const Dummy = ({ payment, search, submitted, setSettingsModal, columnNotToShow, toDate, fromDate, locationFields, sizeOfPages, onlyWalkInCustomer }) => {
  const { user } = useSelector((state) => state.auth);
  const user_type = user.user_type;
  const shopName = user.shop_name;
  const [orderBy, setOrderBy] = useState({ "sales_order.created_at": "DESC" });

  const componentRef = useRef(null);
  const thermalcomponentRef = useRef(null);
  const shop_id = user.shop_id;
  const userId = user.user_id;
  const logo = user.logo_path;
  const { printerType } = useSelector((state) => state.printerType);
  const [sales, setSales] = useState([]);
  const [totalSales, setTotalSales] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [dummyModal, setDummyModal] = useState(false);
  const [searchParams] = useSearchParams();
  const [modalPaymentShow, setPaymentModalShow] = useState(false);
  let [is_remaining, setRemaining] = useState([]);
  const [emailSuccess, setEmailSuccess] = useState();
  const [emailError, setEmailError] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [printId, setPrintId] = useState();
  let [received_amount, setReceivedAmount] = useState();
  const [updatePaymentId, setupdatePaymentId] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const [toggle, refresh] = useState(false);
  const [locationId, setLocationId] = useState(-1);
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [emailPaymentModalShow, setEmailPaymentModalShow] = useState(false);
  const [isEmailClicked, setIsEmailClicked] = useState(false);
  const [emailStatus, setEmailStatus] = useState("");
  const [refundModal, setRefundModal] = useState(false);
  const [salesOrderId, setSalesOrderId] = useState();
  const [showImeiModal, setShowImeiModal] = useState(false);
  const [ImeiData, setImeiData] = useState();

  // useEffect(() => {
  //   if (searchParams.get("page")) {
  //     setCurrentPage(Number(searchParams.get("page")));
  //   }
  // }, [currentPage, searchParams]);

  const changePage = (page) => {
    setCurrentPage(page);
    navigate("/sales/sales-history?page=" + page);
  };

  // My Sales Order
  const getSalesHistory = async () => {
    if (user?.tour && !user?.tour?.SaleHistory) {
      setIsLoading(false);
      setSales([
        {
          id: 3400,
          customer_name: "testtt",
          customer_email: null,
          customer_number: "+92-374-5674564",
          sales_number: "QUOT970-NU1M859ES",
          created_at: "2024-10-25T17:36:09.000Z",
          base_grand_total: "6867",
          received_amount: 6867,
          remaining: 0,
          imei: false,
        },
      ]);
      setTotalSales(1);
      setNoOfPages(1);
    } else {
      try {
        let quantityObj = {};
        if (payment) {
          quantityObj["quantity"] = payment.value;
        }
        let searchobj = {};

        if (search.length > 0) {
          searchobj = { ...searchobj, search: search };
        } else {
          searchobj = { ...searchobj, search: "" };
        }
        if (Object.keys(locationFields).length > 0) {
          searchobj = { ...searchobj, location: locationFields.value };
        }
        let dates = {
          from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
          to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
        };
        searchobj = { ...searchobj, ...quantityObj, dates, orderBy, onlyWalkInCustomer };
        const res = await axios.post(process.env.REACT_APP_API_URL + `/sales/dummy-sales/get/${userId}?page=${currentPage}&size=${sizeOfPages}`, searchobj, { withCredentials: true });
        const data = res.data;
        if (data) {
          setSales(
            data.data.map((item) => {
              if (item.customer_name === "nil") {
                return {
                  ...item,
                  customer_name: "Walk-in Customer",
                  customer_number: "",
                  customer_email: "",
                };
              } else {
                return item;
              }
            })
          );
          setTotalSales(data.count.no_of_items);
          setNoOfPages(data.count.no_of_pages);

          if (data.count.no_of_pages !== 0) {
            if (currentPage === 0) {
              navigate("/sales/sales-history?page=1");
              setCurrentPage(1);
            }
          }
          if (currentPage > data.count.no_of_pages) {
            if (data.count.no_of_pages === 0) {
              setCurrentPage(1);
              navigate("/sales/sales-history?page=1");
            } else {
              setCurrentPage(data.count.no_of_pages);
              navigate("/sales/sales-history?page=" + data.count.no_of_pages);
            }
          }
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    let timeOut;
    if (userId) {
      if (search?.length > 0) {
        timeOut = setTimeout(() => {
          setIsLoading(true);
          getSalesHistory();
        }, TIMEOUT);
      } else {
        setIsLoading(true);
        getSalesHistory();
      }
    }
    return () => clearTimeout(timeOut);
  }, [shop_id, search, currentPage, payment, locationFields, sizeOfPages, toDate, toggle, userId, orderBy, onlyWalkInCustomer, user?.tour?.SaleHistory]);
  useEffect(() => {
    if (currentPage === 1) {
      // refresh((prev) => !prev);
    } else {
      // changePage(1);
    }
  }, [orderBy, payment, fromDate, toDate]);
  // My Sales Order For CSV
  function requestAllHistory() {
    let quantityObj = {};
    if (payment) {
      quantityObj["quantity"] = payment.value;
    }
    let searchobj = {};

    if (search.length > 0) {
      searchobj = { ...searchobj, search: search };
    } else {
      searchobj = { ...searchobj, search: "" };
    }
    if (Object.keys(locationFields).length > 0) {
      searchobj = { ...searchobj, location: locationFields.value };
    }
    let dates = {
      from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
      to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
    };
    searchobj = { ...searchobj, ...quantityObj, dates, orderBy, onlyWalkInCustomer };
    return axios.post(process.env.REACT_APP_API_URL + `/sales/get-dummy-sales/${userId}`, searchobj, { withCredentials: true });
  }

  const handleReceivePayment = (id) => {
    const result = sales.filter((v) => v.id === id);
    setRemaining(result[0].remaining);
    setReceivedAmount(result[0].received_amount);
    setupdatePaymentId(id);
  };

  const InvoicePayments = async (id) => {
    window.open(`/invoice-payments-sales/` + id, "_blank", "noopener,noreferrer");
  };

  // Send Email functionality
  const handleSendEmail = async (id) => {
    try {
      setIsEmailClicked(true);
      let invoiceData;
      const res = await axios.post(process.env.REACT_APP_API_URL + `/sales/invoice-payments-sales/${id}`, { id: shop_id }, { withCredentials: true });
      if (res) {
        let arr = [];
        const products = res.data.items.map((i) => ({
          title: i.title,
          cartQuantity: i.qty_ordered,
          price: i.sale_price,
          discount: i.discount,
        }));

        const miscProducts = res.data.miscItems.map((i) => ({
          title: i.name,
          cartQuantity: i.mis_qty,
          price: i.price,
          discount: i.discount,
          description: i.description,
        }));

        arr.push(...products);
        arr.push(...miscProducts);
        const data = {
          ...invoiceData,
          invoice: res.data.data[0],
          items: arr,
          payments: res.data.payments,
          logo: logo,
        };
        const res2 = await axios.post(process.env.REACT_APP_API_URL + "/sales/send-sales-history-email", data, { withCredentials: true });
        if (res2.data.status === 404) {
          setEmailSuccess("");
          setEmailError(res2.data.message);
          setModalShow(true);
          setTimeout(() => setModalShow(false), 2500);
          setIsEmailClicked(false);
        } else if (res2.data.status === 200) {
          setEmailError("");
          setEmailSuccess(res2.data.success.message);
          setModalShow(true);
          setTimeout(() => setModalShow(false), 2500);
          setIsEmailClicked(false);
        }
      }
    } catch (error) {
      setEmailSuccess("");
      setIsEmailClicked(true);
      setEmailError(error.response.data.error.message);
      setModalShow(true);
      setTimeout(() => setModalShow(false), 1500);
      setIsEmailClicked(false);
    }
  };
  const viewImei = (data) => {
    setImeiData(data?.imei);
    setShowImeiModal(true);
  };
  const settingsModal = () => {
    setSettingsModal(true);
  };

  return (
    <>
      <div className="d-none">
        <InvoicePaymentSales printId={printId} ref={componentRef} />
        <ThermalInvoicePaymentSales printId={printId} ref={thermalcomponentRef} />
      </div>

      <Container fluid className="p-0">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
            <LoaderPage />
          </div>
        ) : sales.length > 0 ? (
          <div id="sales-archive-history-table">
            <CustomizeTableview
              settingsModal={settingsModal}
              className=""
              data={sales}
              columnNotShow={["id", "imei"].concat(columnNotToShow)}
              dateColumn={columnNotToShow.includes("created_at") ? "" : ["created_at"]}
              sortColumn={["customer_name", "created_at", "base_grand_total", "received_amount", "remaining"]}
              view={{
                action: InvoicePayments,
                isShow: (value) => {
                  return !value["sales_number"].startsWith("D4U");
                },
              }}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              status={
                !columnNotToShow.includes("status")
                  ? {
                      column: "remaining",
                      style: (value) => {
                        if (value > 0) {
                          return "text-danger";
                        } else {
                          return "text-success";
                        }
                      },
                      format: (value) => {
                        if (value > 0) {
                          return "Pending";
                        } else {
                          return "Paid";
                        }
                      },
                    }
                  : ""
              }
            />
            <Row className="mt-5 align-items-center">
              <Col className="d-none d-md-block" md="3" lg="3">
                <span className="text-secondary fs-6"> {totalSales} result(s)</span>
              </Col>
              <Col xs="12" md="6" lg="6">
                <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
              </Col>
              <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                <span className="text-secondary fs-6"> {totalSales} result(s)</span>
              </Col>
              <Col xs="6" md="3" lg="3" className="text-end">
                <Csv
                  filename={`${
                    "Sale History " +
                    shopName +
                    " " +
                    new Date().toLocaleDateString("en-US", {
                      timeZone: "Asia/Karachi",
                    })
                  }`}
                  api={requestAllHistory}
                  format={(data) => {
                    const { id, created_at, customer_number, ...temp } = data;
                    const newObj = {
                      ...temp,
                      customer_number: customer_number.replaceAll("+", "").replaceAll("-", " "),
                      status: data.remaining > 0 ? "Pending" : "Paid",
                      created_at: created_at.split("T")[0],
                    };

                    return newObj;
                  }}
                  error={(err) => {
                    const tempErr = err.response?.data.error;
                    if (tempErr?.status === 404 && tempErr.message === "No Record Found") {
                      return true;
                    } else {
                      return false;
                    }
                  }}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <NoResult path={"/sales"} name="Sales" />
        )}
      </Container>
    </>
  );
};
export default Dummy;
