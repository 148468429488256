import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import style from './../../styles/ResetPassword.module.css'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

const ResetUserPassword = ({close, id}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showcPassword, setShowcPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [validated, setValidated] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const handleSubmit = async(e)=> {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        try {
            setIsClicked(true)
            setValidated(true);

            if(newPassword && confirmPassword){
                setIsClicked(true)

                const res = await axios.post(process.env.REACT_APP_API_URL +`/user/reset-secondary-user-password/${id}`, {password:newPassword, cpassword:confirmPassword},{withCredentials:true});
                if(res){
                    setSuccess(res.data.success)
                    setError('')
                    setTimeout(()=> close(), 1500)
                }
            }
        } catch (error) {
            setIsClicked(true)
            setError(error.response.data.error.message)
            setSuccess('')
            setIsClicked(false)
        }
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit} className='mb-4'>
            {error && <p className="text-danger text-center p-2 text-center my-1 rounded-1">*{error}</p>}
            {success && <p className="text-success text-center p-2 text-center my-1 rounded-1">*{success}</p>}
         
            <Form.Group className={`mb-4 ${style.passwordInput}`}>
                <Form.Label>New Password <span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Control className={`p-2 ${style.passwordValidate}`} name="password" type={showPassword ? "text" : "password"} placeholder="******" required value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                <div className={`${style.passwordField}`}>
                    <FontAwesomeIcon className="text-secondary" icon={showPassword ? faEye : faEyeSlash} onClick={() => setShowPassword((a) => !a)} />
                </div>
                <Form.Control.Feedback type="invalid">
                    * Please Enter New Password
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className={`mb-4 ${style.passwordInput}`}>
                <Form.Label>Confirm Password <span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Control className={`p-2 ${style.passwordValidate}`} name="cpassword" type={showcPassword ? "text" : "password"} placeholder="******" required value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                <div className={`${style.passwordField}`}>
                    <FontAwesomeIcon className="text-secondary" icon={showcPassword ? faEye : faEyeSlash} onClick={() => setShowcPassword((a) => !a)} />
                </div>
                <Form.Control.Feedback type="invalid">
                    * Please Confirm Password
                </Form.Control.Feedback>
            </Form.Group>
            <Button disabled={isClicked} type="submit" className='w-100 mt-3'>Save</Button>
        </Form>
    );
}

export default ResetUserPassword;