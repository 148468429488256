import { Button } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import Invoice from "./Invoice";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import PopUpModal from "../../../Components/Modal";
import styleModal from "./../../../styles/Modal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useCallback } from "react";
import Loader from "../../../Components/Loader";
import { useSelector } from "react-redux";
import "./../../../styles/printLayout.css";
import ThermalInvoice from "./ThermalInvoice";
import Loading from "./../../../Components/Loading/Loading";

const InvoiceLayout = () => {
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [isloading, setisLoading] = useState(true);
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { printerType } = useSelector((state) => state.printerType);
  const shop_id = user.shop_id;
  const logo = user.logo_path;

  useEffect(() => {
    const pageTimeout = setTimeout(() => {
      if (id) {
        axios
          .post(process.env.REACT_APP_API_URL + `/sales/invoice-payments-sales/${id}`, { id: shop_id }, { withCredentials: true })
          .then((res) => {
            let arr = [];
            const products = res.data.items.map((i) => ({ title: i.title, cartQuantity: i.qty_ordered, price: i.sale_price, discount: i.discount }));

            const miscProducts = res.data.miscItems.map((i) => ({ title: i.name, cartQuantity: i.mis_qty, price: i.price, description: i.description, discount: i.discount }));

            arr.push(...products);
            arr.push(...miscProducts);
            setData(res.data.data[0]);
            setItems(arr);
            setisLoading(false);
          })
          .catch((err) => {
            setisLoading(false);
            throw new Error(err);
          });
      }
    }, 1000);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [id, shop_id]);

  const componentRef = useRef(null);
  const thermalcomponentRef = useRef(null);
  // const componentRef = createRef();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmail = async () => {
    try {
      setLoading(true);
      const res = await axios.post(process.env.REACT_APP_API_URL + "/sales/send-email", { data: data, items: items, shopLogo: logo }, { withCredentials: true });
      if (res.data.status === 404) {
        setError("No Email Register for this customer. Please add Customer email to send Email.");
        setModalShow(true);
        setTimeout(() => setModalShow(false), 2500);
        setLoading(false);
      } else if (res.data.status === 200) {
        setLoading(true);
        setSuccess(res.data.success.message);
        setModalShow(true);
        setTimeout(() => setModalShow(false), 2500);
        setLoading(false);
      }
    } catch (error) {
      setLoading(true);
      setError(error?.response?.data?.error?.message);
      setLoading(false);
    }
  };

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
  });
  const reactToPrintThermalContent = useCallback(() => {
    return thermalcomponentRef.current;
  }, []);

  const handleThermalPrint = useReactToPrint({
    content: reactToPrintThermalContent,
  });

  return (
    <>
      {
        <PopUpModal
          title={success ? "Email Sent" : "Email Not sent."}
          icon={<FontAwesomeIcon icon={faCircleCheck} />}
          text={success ? success : error}
          type={success ? styleModal.successBg : styleModal.errorBg}
          iconModal={success ? styleModal.successIcon : styleModal.errorIcon}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      }
      {isloading ? (
        <Loading />
      ) : items.length !== 0 ? (
        <>
          <div className={`my-4 d-flex justify-content-center gap-3`}>
            {printerType?.value === "thermal" ? (
              <Button variant="outline-primary" className="px-4" onClick={handleThermalPrint}>
                Print{" "}
              </Button>
            ) : (
              <Button variant="outline-primary" className="px-4" onClick={handlePrint}>
                Print{" "}
              </Button>
            )}
            {data?.customer_email && data?.customer_email !== "nil" && data?.customer_email !== "" ? (
              <Button type="submit" disabled={loading} onClick={() => handleEmail()}>
                {loading ? <Loader /> : "Send Email"}
              </Button>
            ) : (
              <></>
            )}
          </div>
          <div className="printComponent mb-5">{printerType?.value === "thermal" ? <ThermalInvoice ref={thermalcomponentRef} items={items} data={data} /> : <Invoice ref={componentRef} items={items} data={data} />}</div>
        </>
      ) : (
        <div style={{ height: "80vh" }} className="d-flex justify-content-center align-items-center">
          <h3>No data exists for this invoice</h3>
        </div>
      )}
    </>
  );
};

export default InvoiceLayout;
