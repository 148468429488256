import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import style from "./../../styles/Chart.module.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  barThickness: 20,
  hoverBackgroundColor: "rgba(149, 55, 255, 1)",
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },

  scales: {
    y: {
      display: false,
      grid: {
        display: false,
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
};

// export const labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

// const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: [65, 59, 80, 81, 56, 65, 59, 80, 81, 56],
//       backgroundColor: 'rgba(89, 50, 234, 0.5)',
//     },
//   ],
// };

const VerticalBarChart = ({ data }) => {
  return (
    <>
      {data.labels.length > 0 ? (
        <Bar options={options} data={data} className={`${style.barCanvas}`} />
      ) : (
        <div
          className={`${style.barCanvas} fs-3 fw-bold d-flex justify-content-center align-items-center`}
        >
          No data exists.
        </div>
      )}
    </>
  );
};
export default VerticalBarChart;
