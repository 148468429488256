import React from "react";
import { useState } from "react";
import chevronDown from "../assets/svg/chevron-down.svg";
import chevronUp from "../assets/svg/chevronUp.svg";
function VendorCaret({ caret, rowId }) {
  return (
    <div>
      <img
        src={caret.id === rowId && caret.caret ? chevronUp : chevronDown}
        alt="chevron"
      />
    </div>
  );
}

export default VendorCaret;
