export function dateTime(date) {
  if (date) {
    if (process.env.REACT_APP_ENV === "development") {
      return new Date(date);
    } else {
      let a = new Date(date);
      let b = a.getTime() + a.getTimezoneOffset() * 60000;
      return new Date(b);
    }
  } else {
    if (process.env.REACT_APP_ENV === "development") {
      return new Date();
    } else {
      let a = new Date();
      let b = a.getTime() + a.getTimezoneOffset() * 60000;
      return new Date(b);
    }
  }
}
export function dateTimeForInvoice(date) {
  if (date) {
    return new Date(
      new Date(date).toLocaleString("en-US", { timeZone: "Asia/Karachi" }),
    );
  } else {
    return new Date(
      new Date().toLocaleString("en-US", { timeZone: "Asia/Karachi" }),
    );
  }
}

//add five hours

export function requestDateTime(date) {
  if (process.env.REACT_APP_ENV === "development") {
    return new Date(date);
  } else {
    let a = new Date(date);
    let c = a.getTimezoneOffset() * 60000;
    let b = a.getTime() - c;
    return new Date(b);
  }
}

const TIMEOUT = 1000;

export { TIMEOUT };
