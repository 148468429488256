import React from "react";
import style from "./MinifiedCart.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { emptyCart } from "../../../Features/cart/cartSlice";

function MinifiedCart({ setIsOpen }) {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  return (
    <div className={` d-none d-lg-block`}>
      <div className={`${style.miniCartContainer}`}>
        <div
          className={`${style.actionToOpen}`}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <FontAwesomeIcon icon={faCaretLeft} />
        </div>
        <div className={`p-2 w-100 d-flex flex-column justify-content-between ${style.miniCartBody}`}>
          <div>
            <div className={`d-flex justify-content-between align-items-center`}>
              <div className={`${style.itemsincart}`}>Items in cart</div>
              <Button
                className="p-2"
                variant="outline-primary"
                style={{ fontSize: "10px ", fontWeight: "300" }}
                onClick={() => {
                  dispatch(emptyCart());
                }}
              >
                Empty Cart
              </Button>
            </div>
            <hr />
            <div style={{ background: "#F5F6FA" }} className={`mt-4 mb-2 border-0 rounded-3 p-1`}>
              <div style={{ padding: "0.5rem" }} className={`d-flex justify-content-between align-items-center`}>
                <div style={{ fontSize: "13px", fontWeight: "300" }} className="text-secondary">
                  {`Items (${cart?.cartItems?.length || 0})`}
                </div>
                <div style={{ fontSize: "12px", fontWeight: "300" }} className={` me-2`}>
                  {`Qty(${cart?.totalCartQuantity})`}
                </div>
              </div>
              <hr className={`m-0`} />
              <div>
                {cart?.cartItems?.length > 0 ? (
                  <div className={`my-2 w-100 border ${style.cartItemTable}`} style={{ fontSize: "13px" }}>
                    <table className={`bg-white w-100`}>
                      <tr className={``}>
                        <th className={`border-end`}>Products</th>
                        <th>Qty</th>
                      </tr>
                      {cart?.cartItems?.map((item, index) => {
                        return (
                          <tr key={index} className={`border-top`}>
                            <td className={`border-end`}>
                              <strong>{item.title}</strong>
                            </td>
                            <td>{item.cartQuantity}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                ) : (
                  <div className="px-1 d-flex align-items-center justify-content-center ">
                    <div className="text-center text-secondary p-3">Your cart is empty</div>
                  </div>
                )}
              </div>
            </div>
            <hr />
            <div style={{ background: "#F5F6FA" }} className={`p-1 rounded p-1`}>
              {cart?.customer?.id ? (
                <div className="d-flex  flex-column py-2">
                  <div className={`d-flex justify-content-start align-items-center gap-1`}>
                    <FontAwesomeIcon className={`mb-2 rounded-circle text-white`} icon={faCircleUser} style={{ width: "42px", height: "42px" }} />

                    <h5 className="ms-1" style={{ fontSize: "16px" }}>
                      {cart?.customer?.customer_name}
                    </h5>
                  </div>
                  {cart?.customer?.customer_number || cart?.customer?.customer_email ? (
                    <>
                      <hr className={`m-0`} />
                      <div className=" pt-1 px-2">
                        <table style={{ fontSize: "12px" }}>
                          {cart?.customer?.customer_number ? (
                            <tr>
                              <td>
                                <strong>Phone</strong>
                              </td>
                              <td>
                                <strong>:</strong>
                              </td>
                              <td> {cart?.customer?.customer_number}</td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          {cart?.customer?.customer_email ? (
                            <tr>
                              <td>
                                <strong>Email</strong>
                              </td>
                              <td>
                                <strong>:</strong>
                              </td>
                              <td> {cart?.customer?.customer_email}</td>
                            </tr>
                          ) : (
                            <></>
                          )}
                        </table>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div className={`p-3 text-center text-secondary`} style={{ fontSize: "16px", fontWeight: "700" }}>
                  {cart?.isCustomerWalkIn ? "Walk-in Customer" : "Customer not selected"}
                </div>
              )}
            </div>
            <hr />
          </div>
          <div className={`d-flex flex-column`}>
            <div style={{ fontSize: "13px" }}>
              <div className="d-flex justify-content-between align-items-center pb-3">
                <strong>Discount</strong>
                <div>{cart?.totalCartDiscount ? Number(cart?.totalCartDiscount).toLocaleString("en-IN") : 0}</div>
              </div>
              <div className="d-flex justify-content-between align-items-center pb-3">
                <strong>Shipping Cost</strong>
                <div>{cart?.shippingCost ? Number(cart?.shippingCost).toLocaleString("en-IN") : 0}</div>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-4 mt-3 border-top pt-3">
                <strong>Total </strong>
                <div>{Number(cart?.totalCartAmount + cart?.shippingCost - cart?.totalCartDiscount).toLocaleString("en-IN")}</div>
              </div>
            </div>
            <Button
              className={`w-100`}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              Checkout
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MinifiedCart;
