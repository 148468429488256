import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Button, Form } from "react-bootstrap";
import inputStyle from "./imageUploader.module.css";
import { faClose, faCloudArrowUp, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone } from "react-dropzone";

import arrayMove from "array-move";

import { Draggable } from "react-drag-reorder";
import axios from "axios";
import icon from "./../../assets/svg/empty-state-icon.svg";
import { centerCrop, convertToPercentCrop, convertToPixelCrop, makeAspectCrop, ReactCrop } from "react-image-crop";
import PopUpModal from "../Modal";
import "react-image-crop/dist/ReactCrop.css";
import { useRef } from "react";
import { canvasPreview } from "./CanvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import { toast } from "react-toastify";

const Image = ({ src, onDelete, primary, customize,setSelectedFiles,source,setPreviewImage,selectedFiles }) => {
  const [crop, setCrop] = useState();
  const [imgUrl, setImgUrl] = useState(false);
  const [completedCrop, setCompletedCrop] = useState()
const [finalizeFiles, setFinalizeFiles] = useState([])
  const imgRef = useRef();
  const canvasRef = useRef();
  function onImageLoad(e) {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: "%",
          width: 90,
        },
        16 / 4,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
  }
   
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        canvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          canvasRef.current,
          completedCrop,
        
        )
      }
    },
    100,
    [completedCrop, ],
  )
  async function onDownloadCropClick() {
    const image = imgRef.current
    const previewCanvas = canvasRef.current
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist')
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    )
    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    )
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    })
     
    // console.log(src,"file")
    const filee = new File([blob], src.data.name, { type: blob.type });

    const newFiles=[...selectedFiles,{data:filee,preview:URL.createObjectURL(filee),type:"file"}]
    // newFiles[selectedFiles.length-1]={data:filee,preview:URL.createObjectURL(filee),type:"file"}
    setSelectedFiles(newFiles)
    setPreviewImage(false)
    // setSelectedFiles([...source, {data:filee,preview:URL.createObjectURL(filee),type:"file"}])
// console.log(newFiles,"new")
}

  return (
    <>
      <div className={`  d-grid justify-content-center align-items-center gap-2`}>
        <div className="img">
          <ReactCrop
            aspect={16 / 4}
            crop={crop}
            onChange={(c) => {
              setCrop(c);
            }}
            onComplete={(c) => setCompletedCrop(c)}
          >
            <img ref={imgRef} src={src.preview} style={{ height: "100" }} alt="" width="100%" height="auto" onLoad={onImageLoad} />
          </ReactCrop>
          <div className="text-center">
          
          </div>
        </div>
        <strong>Preview</strong>
        {crop && <canvas ref={canvasRef} style={{ border: "1px solid", height: "200px", width: "700px" }} />}
        <div className="text-center">
            <Button
              className="btn btn-primary rounded-pill  "
              onClick={onDownloadCropClick}
            >
             Save Changes
            </Button>
          </div>
      </div>
    </>
  );
};

const RenderPhotos = ({ source = [],selectedFiles,setSelectedFiles, setSource, onDelete, customize, prevImage, setPreviewImage, }) => {
  const [dragKey, setDragKey] = useState(1);
  useEffect(() => {
    setDragKey((prev) => prev + 1);
  }, [source]);
  const handlePosChange = (currentPos, newPos) => {
    setSource(arrayMove(source, currentPos, newPos));
  };
  return (
    <div style={{ padding: "0 10%" }}>
      <PopUpModal
        //  title={"Crop"}
        customize={true}
        onHide={() => {
          setPreviewImage(false);
        }}
        show={prevImage}
        size="lg"
      >
        <Image  selectedFiles={selectedFiles} setPreviewImage={setPreviewImage} source={source}  setSelectedFiles={setSelectedFiles} src={source[source.length - 1]} onDelete={onDelete} />
      </PopUpModal>
    </div>
  );
};

const ImageUploaderForThemes = forwardRef(({ selectedFiles = [], setSelectedFiles, numberOfImages = 10, cdnDirectory = "images", isInvalid, disabled = false, customize }, ref) => {
  const [noOfImageError, setNoOfImageError] = useState(false);
  const [sizeOfImageError, setSizeOfImageError] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [deletedImages, setDeletedImages] = useState([]);
  const [fileRejectionsState, setFileRejectionsState] = useState([]);

const [selectedImages, setSelectedImages] = useState([])
const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    open,
  } = useDropzone({    
        onDrop: (acceptedFiles, fileRejections) => {
            setPreviewImage(true)
            setFileRejectionsState(fileRejections); // Store rejections in state

            

        },
      accept:{
        "image/png": [".png"],
        "image/jpeg": [".jpg", ".jpeg"],
      },
    maxFiles:1
  });
  useEffect(() => {
    if (fileRejectionsState.length > 0) {
      const errors = fileRejectionsState.map(fileRejection => fileRejection.errors[0]);
      toast.error(errors[0].message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [fileRejectionsState]);

  const filesArray = acceptedFiles?.map((file) => ({
      preview: URL.createObjectURL(file),
      data: file,
      type: "file",
    }));
//   const { getRootProps, getInputProps, open } = useDropzone({
//     accept: {
//       "image/png": [".png"],
//       "image/jpeg": [".jpg", ".jpeg"],
//       "image/heic": [".heic", ".heif"],
//     },
//     onDrop: (acceptedFiles, fileRejections) => {
//       setSizeOfImageError(false);
//       fileRejections.forEach((file) => {
//         file.errors.forEach((err) => {
//           if (err.code === "file-too-large") {
//             setSizeOfImageError(true);
//           }
//         });
//       });
//       const filesArray = acceptedFiles?.map((file) => ({
//         preview: URL.createObjectURL(file),
//         data: file,
//         type: "file",
//       }));
//       if (selectedFiles.length + filesArray.length < numberOfImages + 1) {
//         setSelectedImages((prevImage) => [...prevImage, ...filesArray]);
//         setPreviewImage(true);
//         setNoOfImageError(false);
//       } else {
//         setNoOfImageError(true);
//       }
//       acceptedFiles?.map((file) => URL.revokeObjectURL(file));
//     },
//     // maxFiles:10,
//     // maxSize: 2621440, //2.4mb
//     maxSize: 10485760, //10mb
//   });

  const onDelete = (photo) => {
    setSelectedImages((prevImage) => prevImage.filter((img) => img !== photo));

    setNoOfImageError(false);
    setSizeOfImageError(false);
  };

  useImperativeHandle(ref, () => ({
    uploadingImages: () => {
      return new Promise((resolve, reject) => {
        if (selectedFiles.length > 0) {
          if (selectedFiles.filter((img) => img.type === "file").length > 0) {
            const formData = new FormData();
            selectedFiles?.map((file) => {
              if (file.type === "file") {
                formData.append("files", file.data, file.data.name);
              }
            });

            axios
              .post(
                `${process.env.REACT_APP_URL_IMAGE_CDN}/images`,
                formData,
                { withCredentials: true },
                {
                  headers: {
                    Accept: "application/json",
                    "content-type": "multipart/form-data",
                  },
                }
              )
              .then(({ data }) => {
                var filesArray = [];
                var counter = 0;
                selectedFiles?.map((file) => {
                  if (file.type === "url") {
                    filesArray.push(file.data);
                  } else {
                    filesArray.push(data.files[counter]);
                    counter = counter + 1;
                  }
                });
                resolve(filesArray);
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            resolve(selectedFiles);
          }
        } else {
          resolve([]);
        }
      });
    },
    uploadImagesOnUpdate: () => {
      return new Promise((resolve, reject) => {
        if (selectedFiles.length > 0) {
          if (selectedFiles.filter((img) => img.type === "file").length > 0) {
            const formData = new FormData();
            selectedFiles?.map((file) => {
              if (file.type === "file") {
                formData.append("files", file.data, file.data.name);
              }
            });

            axios
              .post(
                `${process.env.REACT_APP_URL_IMAGE_CDN}/${cdnDirectory}`,
                formData,
                { withCredentials: true },
                {
                  headers: {
                    Accept: "application/json",
                    "content-type": "multipart/form-data",
                  },
                }
              )
              .then(({ data }) => {
                var filesArray = [];
                var counter = 0;
                selectedFiles?.map((file) => {
                  if (file.type === "url") {
                    filesArray.push(file);
                  } else {
                    filesArray.push({
                      preview: data.files[counter],
                      data: data.files[counter],
                      type: "file",
                    });
                    counter = counter + 1;
                  }
                });

                resolve(filesArray);
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            resolve(selectedFiles);
          }
        } else {
          resolve([]);
        }
      });
    },
    deleteImages: () => {
      return new Promise((resolve, reject) => {
        if (deletedImages.length > 0) {
          deletedImages.forEach((photo) => {
            axios
              .delete(photo.preview)
              .then((res) => {})
              .catch((err) => {
                reject(err);
              });
          });
        }
        resolve(true);
      });
    },
  }));
// console.log("selec",selectedFiles)
  return (
    <>
      <Form.Group
        className={`d-grid justify-content-center pt-0   mt-0`}
        style={{
          ...(isInvalid ? { border: "1px solid red", borderRadius: "8px" } : {}),
          ...(disabled ? { pointerEvents: "none", background: "#e9ecef", opacity: "0.7" } : {}),
        }}
      >
       
        {sizeOfImageError && <span style={{ color: "red" }}>You Try to Add Image with size more then 2.5 MBs.</span>}
        {/* {noOfImageError ? <span style={{ color: "red" }}>You Try to Add more then {numberOfImages} Images</span> : <span>{selectedFiles?.length} Images Added</span>} */}

        {selectedFiles?.length < numberOfImages && (
          <div className="d-flex justify-content-center align-items-center">
            {" "}
            <Button onClick={open}>
              {" "}
              <FontAwesomeIcon className="" style={{ fontSize: "20px" }} icon={faCloudArrowUp} /> Add Images +
            </Button>
          </div>
        )}
        {
       filesArray.length>0&&
        <RenderPhotos prevImage={previewImage} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles}  setPreviewImage={setPreviewImage} customize={customize} source={filesArray} setSource={setSelectedFiles} onDelete={onDelete} getInputProps={getInputProps} />
        }
      </Form.Group>
    </>
  );
});

export default ImageUploaderForThemes;
