import search from "../assets/svg/search.svg";
import { Form, FormControl } from "react-bootstrap";
import style from "./../styles/SearchInput.module.css";

const SearchInput = ({
  styles,
  searchlogo,
  bordered,
  value,
  openSearchBar,
  handleChange,
}) => {
  //{value, onChange ,...props}
  const handle = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <>
      <Form className="position-relative d-none d-lg-inline" onSubmit={handle}>
        <FormControl
          type="search"
          placeholder="Search"
          className={` ${style.searchInput} `}
          aria-label="Search"
          style={styles}
          onChange={handleChange}
          value={value}
        />
        <div className={` ${style.margin_bottom}  ${style.searchIcon} d-flex`}>
          {searchlogo ? <img src={search} alt="search" /> : ""}
        </div>
      </Form>
      <Form
        className="d-flex  position-relative d-lg-none justify-content-end h-100"
        onSubmit={handle}
      >
        <FormControl
          type="search"
          placeholder="Search"
          className={` d-none ${style.searchInput} `}
          aria-label="Search"
          style={styles}
        />
        <div
          className={`pe-2 ${style.searchIcon_Mobile}  ${
            bordered ? style.iconBorder : ``
          } d-flex  justify-content-center`}
          onClick={openSearchBar}
        >
          {searchlogo ? <img src={search} alt="search" /> : ""}
          <span className="d-none d-sm-inline"> Search</span>
        </div>
      </Form>
    </>
  );
};

export default SearchInput;
export const SearchMobleInput = ({
  styles,
  searchlogo,
  bordered,
  value,
  openSearchBar,
  handleChange,
}) => {
  const handle = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <Form className="position-relative d-lg-inline" onSubmit={handle}>
      <FormControl
        type="search"
        placeholder="Search"
        className={`text-secondary ${style.searchInput}`}
        aria-label="Search"
        style={styles}
        onChange={handleChange}
        value={value}
      />
      <div className={`${style.searchIcon} d-flex`}>
        {searchlogo ? (
          <img style={{ width: "1.47rem" }} src={search} alt="search" />
        ) : (
          ""
        )}
      </div>
    </Form>
  );
};
