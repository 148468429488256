import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import SearchInput from "../../Components/SearchInput";
import { useState } from "react";

import customloader from "../../assets/images/RollingLoader.gif";
import SearchableSelect from "../../Components/SearchableSelect";
import PopUpModal from "../../Components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCircleCheck, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import filterImg from "../../assets/svg/filter.svg";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import NoResult from "../../Components/NoResult";
import axios from "axios";
import PaginationBar from "../../Components/PaginationBar";
import { useRef } from "react";
import DatePicker from "../../Components/DatePicker/DatePicker";
import moment from "moment";
import Csv from "../../Components/csv/Csv";
import styleModal from "./../../styles/Modal.module.css";
import SearchMobile from "../../Components/SearchMobile";
import MobileSearchAcoordian from "../../Components/MobileSearchAcoordian";
import { requestDateTime } from "../../Features/dateTime";
import VendorPaymentInvoice from "./Invoices/VendorPaymentInvoice";

const DELAY_TIME = 100;

const Payments = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const user_id = user.user_id;
  const logo = user.logo_path;
  const shopName = user.shop_name;

  const { id, purchase_id } = useParams();

  //navigate states
  const [navigateState, setNavigateState] = useState({});
  useEffect(() => {
    setNavigateState({
      shop_id: shop_id,
      user_id: user_id,
    });
  }, [shop_id, user_id]);

  //pagination
  const [noOfPages, setNoOfPages] = useState(1);
  const [noOfItems, setNoOfItems] = useState(0);

  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const [paymentData, setPaymentData] = useState({});
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const caret = async (pId) => {
    try {
      setPaymentData(null);

      const res = await axios.get(process.env.REACT_APP_API_URL + `/purchase-order/payment/get/${pId}`, { withCredentials: true });
      const paymentDate = new Date(res?.data.payment_date).toDateString();
      const { created_at, payment_id, vendor_purchase_id, invoice_number, payment_amount, payment_date, ...paymentObj } = res.data;
      const paymentDetails = {
        paid_amount: payment_amount,
        ...paymentObj,
        payment_date: paymentDate,
      };
      setPaymentData(paymentDetails);
      setInvoiceNumber(res.data.invoice_number);
    } catch (error) {
      throw new Error(error);
    }
  };

  // //searchParams
  // useEffect(() => {
  //   if (searchParams.get("page")) {
  //     setCurrentPage(Number(searchParams.get("page")));
  //   }
  // }, [currentPage, searchParams]);

  //filters
  const [search, setSearch] = useState("");
  const currentSearch = useRef();
  currentSearch.current = search;

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);

  //orderBy
  const [orderBy, setOrderBy] = useState({ payment_date: "DESC" });

  //Payments
  const [purchase, setPurchase] = useState(null);
  const [payments, setPayments] = useState([]);
  const [totalPayment, setTotalPaymentData] = useState({});
  const [paymentError, setPaymentError] = useState("");

  //first time
  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + "/purchase-order/get-payments/" + purchase_id, { shopId: shop_id, vendorId: id }, { withCredentials: true })
      .then((res) => {
        if (res?.data?.code === 200) {
          const { id, vendor_email, vendor_number, purchase_detail, last_payment_date, purchase_date, purchase_order, ...purchaseData } = res?.data?.success?.data;
          const purchaseDate = new Date(purchase_date).toDateString();
          const lastPaymentDate = new Date(last_payment_date).toDateString();
          const data = {
            ...purchaseData,
            purchase_date: purchaseDate,
            last_payment_date: lastPaymentDate,
          };

          setTotalPaymentData({
            paid_amount: res?.data?.success?.data.purchase.paid_amount,
            due_amount: `Due Amount: ${res?.data?.success?.data.purchase.due_amount}`,
            total: res?.data?.success?.data.purchase.total_amount,
          });
          setPurchase(data);
        } else {
          setPaymentError(res.data.error.message);
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [purchase_id]);

  //no of pages
  useEffect(() => {
    const pageTimeout = setTimeout(() => {
      axios
        .post(
          process.env.REACT_APP_API_URL + "/purchase-order/payment/" + purchase_id + "/no-of-pages?size=" + sizeOfPages,
          {
            search: search,
            shopId: shop_id,
            dates: {
              from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
              to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
            },
          },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.no_of_pages === 0) {
            // if (searchParams.get("page")) {
            //   setNoOfPages(0);
            //   navigate(
            //     "/purchase-order/" + id + "/payment/" + purchase_id + "?page=0"
            //   );
            // }
          } else {
            setNoOfPages(res.data.no_of_pages);
            setNoOfItems(res.data.no_of_items);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
    }, DELAY_TIME);

    return () => {
      clearTimeout(pageTimeout);
    };
  }, [id, purchase_id, sizeOfPages, currentPage, navigate, search, fromDate, toDate]);

  //loading
  const [isLoading, setIsLoading] = useState(true);

  //fetch data
  useEffect(() => {
    const dataTimeout = setTimeout(() => {
      axios
        .post(
          process.env.REACT_APP_API_URL + "/purchase-order/payment/get-by-purchase/" + purchase_id + "?page=" + currentPage + "&size=" + sizeOfPages,
          {
            search: search,
            shopId: shop_id,
            dates: {
              from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
              to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
            },
            orderBy: orderBy,
          },
          { withCredentials: true }
        )
        .then((data) => {
          setIsLoading(false);
          setPayments(data.data);
        })
        .catch((err) => {
          setIsLoading(false);
          const tempErr = err.response.data.error;
          if (tempErr.status === 404 && tempErr.message === "Record not Exists against provided ID.") {
            setPayments([]);
          }
        });
    }, DELAY_TIME);
    return () => {
      clearTimeout(dataTimeout);
      // if (currentSearch.current !== search) {
      //   setCurrentPage(1);
      //   navigate(
      //     "/vendor/purchase/" + id + "/payment/" + purchase_id + "?page=1"
      //   );
      // }
    };
  }, [id, purchase_id, search, navigate, fromDate, toDate, orderBy, currentPage, sizeOfPages]);

  //invoice
  const [paymentId, setPaymentId] = useState(-1);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const closeInviceModal = () => {
    setShowInvoiceModal(false);
    setPaymentId(-1);
  };
  const handlePrintAction = (id) => {
    setPaymentId(id);
    setShowInvoiceModal(true);
  };

  const [emailSuccess, setEmailSuccess] = useState();
  const [emailError, setEmailError] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [isEmailClicked, setIsEmailClicked] = useState(false);

  function requestAllHistory() {
    return axios.post(
      process.env.REACT_APP_API_URL + "/vendors/payment/get-by-purchase/" + purchase_id,
      {
        search: search,
        shopId: shop_id,
        dates: {
          from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
          to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
        },
        orderBy: orderBy,
      },
      { withCredentials: true }
    );
  }

  const handleEmailAction = async (id) => {
    try {
      setIsEmailClicked(true);
      const res = await axios.post(process.env.REACT_APP_API_URL + `/purchase-order/payment/email/${id}`, { logo: logo, shopId: shop_id }, { withCredentials: true });
      if (res.data.status === 404) {
        setEmailError(res.data.message);
        setModalShow(true);
        setTimeout(() => setModalShow(false), 1500);
        setIsEmailClicked(false);
      } else if (res.data.status === 200) {
        setEmailSuccess(res.data.success.message);
        setModalShow(true);
        setTimeout(() => setModalShow(false), 2500);
        setIsEmailClicked(false);
      }
    } catch (err) {
      setIsEmailClicked(true);
      setEmailError(err.response.data.error.message);
      setModalShow(true);
      setTimeout(() => setModalShow(false), 1500);
      setIsEmailClicked(false);
      throw new Error(err);
    }
  };

  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);

  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setSearch("");
  };
  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };

  const Filter = () => {
    setToogleMobileSearch(false);
  };
  const searchResult = () => {
    setToogleMobileSearch(false);
    setToogleMobileSearch(false);
  };

  const SearchData = [];

  return (
    <>
      {toogleMobileSearch === "search" ? (
        <SearchMobile data={payments} toggleSearch={handleAcoordianCLose} value={search} handleChange={handleSearchChange} searchResult={searchResult} searchTitle={"invoice_number"} />
      ) : toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian SearchData={SearchData} toggleSearchclose={handleAcoordianCLose} dateName={"Select Date"} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} filter={Filter} />
      ) : (
        <Container fluid>
          <Row className="align-items-center">
            <Col xs="2" lg="3" className="d-none d-lg-inline">
              <SearchInput searchlogo value={search} handleChange={handleSearchChange} />
            </Col>
            <Col xs lg="2" className="d-none d-lg-inline"></Col>
            <Col xs lg="3" className="d-none d-lg-inline"></Col>
            <Col xs lg="2" className="d-none d-lg-inline">
              <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
            </Col>
            <Col xs="6" lg="2">
              <SearchableSelect
                selectClassName={"bg-white"}
                placeholder={`Showing ${sizeOfPages}`}
                defaultValue={`Showing ${sizeOfPages}`}
                value={`Showing ${sizeOfPages}`}
                searchable={false}
                options={[
                  { value: "10", label: "10" },
                  { value: "20", label: "20" },
                  { value: "30", label: "30" },
                ]}
                handleChange={handlePageSize}
              />
            </Col>
            <Col onClick={() => handleAcoordianOpen("search")} xs="2" lg="3" className="d-lg-none d-inline">
              <SearchInput searchlogo bordered />
            </Col>
            {purchase?.due_amount > 0 && (
              <>
                <Col className="d-lg-none d-flex" xs="2" lg="2">
                  <Button onClick={() => handleAcoordianOpen("select")} variant="outline-primary">
                    <img src={filterImg} alt="filter" />
                  </Button>
                </Col>
                <Col xs="2" lg="2">
                  <Link to={"/vendor/purchase/" + id + "/payment/" + purchase_id + "/add"} state={navigateState}>
                    <Button size="sm" type="submit" style={{ fontSize: "0.75rem", height: "100%" }} className="w-100">
                      <span className="d-none d-lg-inline">Add Payment</span> <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </Link>
                </Col>
              </>
            )}
          </Row>
          {
            <PopUpModal
              title={emailSuccess ? "Email Sent" : "Email Not sent."}
              icon={<FontAwesomeIcon icon={emailSuccess ? faCircleCheck : faXmarkCircle} />}
              text={emailSuccess ? emailSuccess : emailError}
              type={emailSuccess ? styleModal.successBg : styleModal.errorBg}
              iconModal={emailSuccess ? styleModal.successIcon : styleModal.errorIcon}
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          }
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <img src={customloader} alt="customloader" width="100" height="100" />
            </div>
          ) : !purchase ? (
            <div className="d-flex justify-content-center vh-100 align-items-center">
              <h2 className="d-inline">{paymentError}</h2>
            </div>
          ) : (
            <>
              {!payments[0] ? (
                <NoResult name="Vendor Payments" />
              ) : (
                <>
                  <CustomizeTableview
                    className={"mt-5"}
                    data={payments}
                    columnNotShow={["id", "comments", "created_at"]}
                    dateColumn={["payment_date"]}
                    sortColumn={["amount", "payment_date"]}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    print={handlePrintAction}
                    email={purchase?.vendor_email !== "nil" ? (isEmailClicked === false ? handleEmailAction : true) : null}
                    caret={caret}
                    caretTitle={`Invoice Number: ${invoiceNumber}`}
                    caretData={paymentData}
                    total={totalPayment}
                    // caretTitle={`Purchase Order Number: ${purchaseOrder}`}
                    // caretData={purchase}
                  />
                  <Row className="mt-5 align-items-center">
                    <Col className="d-none d-md-block" md="3" lg="3">
                      <span className="text-secondary fs-6">{noOfItems} result(s)</span>
                    </Col>
                    <Col xs="12" md="6" lg="6">
                      <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
                    </Col>
                    <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                      <span className="text-secondary fs-6">{noOfItems} result(s)</span>
                    </Col>
                    <Col xs="6" md="3" lg="3" className="text-end mt-3">
                      {/* <Csv
                        filename={`${
                          "Payments " +
                          shopName +
                          " " +
                          new Date().toLocaleDateString("en-US", {
                            timeZone: "Asia/Karachi",
                          })
                        }`}
                        api={requestAllHistory}
                        format={(data) => {
                          const { id, payment_date, ...temp } = data;
                          const newObj = {
                            ...temp,
                            payment_date: payment_date.split("T")[0],
                          };
                          return newObj;
                        }}
                        error={(err) => {
                          const tempErr = err.response.data.error;
                          if (
                            tempErr.status === 404 &&
                            tempErr.message === "No Record Found"
                          ) {
                            return true;
                          } else {
                            return false;
                          }
                        }}
                      /> */}
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Container>
      )}

      <PopUpModal title="Payment Invoice" size="xl" show={showInvoiceModal} onHide={closeInviceModal}>
        <VendorPaymentInvoice close={closeInviceModal} id={paymentId} />
      </PopUpModal>
    </>
  );
};

export default Payments;
