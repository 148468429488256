import React from "react";
import style from "./listingPagination.module.css";
import PaginationBar from "../../PaginationBar";

function ListingPagination({
  noOfPages = 10,
  currentPage = 1,
  setCurrentPage = () => {},
  totalItems = 40,
  showing = 10,
}) {
  const changePage = (page) => {
    setCurrentPage(page);
  };
  return (
    <div
      className={`d-flex flex-column flex-xl-row align-items-center align-items-sm-start align-items-xl-center  p-3`}
    >
      <PaginationBar
        noOfPages={noOfPages}
        currentPage={currentPage}
        changePage={changePage}
      />
      <div className={style.showing}>
        Showing {showing} of {totalItems}
      </div>
    </div>
  );
}

export default ListingPagination;
