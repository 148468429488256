import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";

const DepositBankAmount = ({ close, bankId }) => {
  const { user } = useSelector((state) => state.auth);
  const shopId = user.shop_id;
  const [amount, setAmount] = useState();
  const [success, setSucces] = useState("");
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const handleAmountSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);
    try {
      if (amount > 0) {
        setIsClicked(true);
        const res = await axios.post(
          process.env.REACT_APP_API_URL + `/shops/deposit/${bankId}`,
          {
            openingBalance: amount,
            shopId: shopId,
          },
          { withCredentials: true }
        );
        if (res) {
          setAmount();
          close();
        }
      }
    } catch (error) {
      setIsClicked(true);
      setError(error.response?.data.error.message);
      setIsClicked(false);
    }
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleAmountSubmit}>
        {error ? <p className="text-danger p-2 text-center my-2 rounded-1">*{error}</p> : success ? <p className="text-success p-2 text-center my-2 rounded-1">*{success}</p> : ""}
        <Form.Group className="mb-4" controlId="price">
          <Form.Label className="mb-0">
            Enter Amount<span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            maxLength="9"
            type="number"
            min={1}
            placeholder="i.e 500"
            onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
            value={amount}
            onChange={(e) => {
              if (e.target.value.length < 10) {
                setAmount(e.target.value.replace(/\D/g, ""));
              }
            }}
            name="opening_balance"
            required
          />

          <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
        </Form.Group>

        <Button disabled={isClicked} type="submit" className="w-100">
          Save
        </Button>
      </Form>
    </>
  );
};

export default DepositBankAmount;
