import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import style from "./validationModal.module.css";
import { toast } from "react-toastify";
import LoaderPage from "../../loaders/loaderPage";
import AccessoryForm from "./AccessoryForm";
import TvMonitorForm from "./TvMonitorForm";
import MobileForm from "./MobileForm";
import TabletForm from "./TabletForm";
import LaptopForm from "./LaptopForm";
import DesktopForm from "./DesktopForm";
import axios from "axios";
import SearchForm from "./SearchForm";
import { convert } from "html-to-text";

const ValidationForm = forwardRef(({ value = null, setValue, setLoading, setIsSubmitting, sendChangeSignal }, ref) => {
  const [isSearchMode, setIsSearchMode] = useState(true);
  const [inputState, setInputState] = useState({
    validated: false,
    images: [],
    imageId: null,
  });

  const setValues = () => {
    if (value?.listing?.category === "Accessories") {
      setInputState((prev) => ({
        ...prev,
        listing: {
          ...prev.listing,
          model: value?.oldValue?.model,
          fk_model_id: value?.oldValue?.fk_model_id ? value?.oldValue?.fk_model_id : -1,
          accessory_type: value?.oldValue?.accessory_type,
          fk_accessory_type_id: value?.oldValue?.fk_accessory_type_id,
          brand: value?.oldValue?.brand,
          fk_brand_id: value?.oldValue?.fk_brand_id,
          description: value?.oldValue?.description,
        },
        listing_optional: {
          notes: value?.oldValue?.notes,
        },
        listing_color: {
          color: value?.oldValue?.color,
          fk_color_id: value?.oldValue?.fk_color_id,
        },
        title: value?.oldValue?.title,
        video: value?.oldValue?.video,
        isValueFilled: true,
      }));
    } else if (value?.listing?.category === "TV / Monitor") {
      setInputState((prev) => ({
        ...prev,
        listing: {
          ...prev.listing,
          model: value?.oldValue?.model,
          fk_model_id: value?.oldValue?.fk_model_id ? value?.oldValue?.fk_model_id : -1,
          brand: value?.oldValue?.brand,
          fk_brand_id: value?.oldValue?.fk_brand_id,
          description: value?.oldValue?.description,
          display_type: value?.oldValue?.display_type,
          fk_display_type_id: value?.oldValue?.fk_display_type_id,
          operating_system: value?.oldValue?.operating_system,
          fk_operating_system_id: value?.oldValue?.fk_operating_system_id,
          refresh_rate: value?.oldValue?.refresh_rate,
          fk_refresh_rate_id: value?.oldValue?.fk_refresh_rate_id,
          resolution: value?.oldValue?.resolution,
          fk_resolution_id: value?.oldValue?.fk_resolution_id,
          screen_size: value?.oldValue?.screen_size,
          fk_screen_size_id: value?.oldValue?.fk_screen_size_id,
          screen_type: value?.oldValue?.screen_type,
          fk_screen_type_id: value?.oldValue?.fk_screen_type_id,
          speaker: value?.oldValue?.speaker,
          fk_speaker_id: value?.oldValue?.fk_speaker_id,
          is_smart_tv: value?.oldValue?.is_smart_tv,
          is_tv_certificated: value?.oldValue?.is_tv_certificated,
          is_webcam: value?.oldValue?.is_webcam,
          type: value?.oldValue?.type,
        },
        listing_optional: {
          notes: value?.oldValue?.notes,
        },
        listing_color: {
          color: value?.oldValue?.color,
          fk_color_id: value?.oldValue?.fk_color_id,
        },
        ...(value?.oldValue?.ports && value?.oldValue?.ports?.length > 0
          ? {
              listing_port: value?.oldValue?.ports?.map((port) => ({
                port: port.port,
                fk_port_id: port.fk_port_id,
              })),
            }
          : {}),
        title: value?.oldValue?.title,
        video: value?.oldValue?.video,
        isValueFilled: true,
      }));
    } else if (value?.listing?.category === "Mobile") {
      setInputState((prev) => ({
        ...prev,
        listing: {
          ...prev.listing,
          model: value?.oldValue?.model,
          fk_model_id: value?.oldValue?.fk_model_id ? value?.oldValue?.fk_model_id : -1,
          brand: value?.oldValue?.brand,
          fk_brand_id: value?.oldValue?.fk_brand_id,
          description: value?.oldValue?.description,
          ram: value?.oldValue?.ram,
          fk_ram_id: value?.oldValue?.fk_ram_id,
          storage_mobile: value?.oldValue?.storage_mobile,
          fk_storage_mobile_id: value?.oldValue?.fk_storage_mobile_id,
          is_pta_approved: value?.oldValue?.is_pta_approved,
        },
        listing_optional: {
          battery_capacity: value?.oldValue?.battery_capacity,
          fk_battery_capacity_id: value?.oldValue?.fk_battery_capacity_id,
          body_type: value?.oldValue?.body_type,
          fk_body_type_id: value?.oldValue?.body_type_id,
          camera_specs: value?.oldValue?.camera_specs,
          fk_camera_specs_id: value?.oldValue?.fk_camera_specs_id,
          finger_print: value?.oldValue?.finger_print,
          fk_finger_print_id: value?.oldValue?.fk_finger_print_id,
          network_band: value?.oldValue?.network_band,
          fk_network_band_id: value?.oldValue?.fk_network_band_id,
          port: value?.oldValue?.port,
          fk_port_id: value?.oldValue?.fk_port_id,
          processor: value?.oldValue?.processor,
          fk_processor_id: value?.oldValue?.fk_processor_id,
          screen_protection: value?.oldValue?.screen_protection,
          fk_screen_protection_id: value?.oldValue?.fk_screen_protection_id,
          screen_size: value?.oldValue?.screen_size,
          fk_screen_size_id: value?.oldValue?.fk_screen_size_id,
          screen_type: value?.oldValue?.screen_type,
          fk_screen_type_id: value?.oldValue?.fk_screen_type_id,
          speaker: value?.oldValue?.speaker,
          fk_speaker_id: value?.oldValue?.fk_speaker_id,
          body_type: value?.oldValue?.body_type,
          fk_body_type_id: value?.oldValue?.fk_body_type_id,
          is_e_sim: value?.oldValue?.is_e_sim,
          notes: value?.oldValue?.notes,
        },
        listing_color: {
          color: value?.oldValue?.color,
          fk_color_id: value?.oldValue?.fk_color_id,
        },
        title: value?.oldValue?.title,
        video: value?.oldValue?.video,
        isValueFilled: true,
      }));
    } else if (value?.listing?.category === "Tablet") {
      setInputState((prev) => ({
        ...prev,
        listing: {
          ...prev.listing,
          model: value?.oldValue?.model,
          fk_model_id: value?.oldValue?.fk_model_id ? value?.oldValue?.fk_model_id : -1,
          brand: value?.oldValue?.brand,
          fk_brand_id: value?.oldValue?.fk_brand_id,
          description: value?.oldValue?.description,
          ram: value?.oldValue?.ram,
          fk_ram_id: value?.oldValue?.fk_ram_id,
          storage_mobile: value?.oldValue?.storage_mobile,
          fk_storage_mobile_id: value?.oldValue?.fk_storage_mobile_id,
        },
        listing_optional: {
          battery_capacity: value?.oldValue?.battery_capacity,
          fk_battery_capacity_id: value?.oldValue?.fk_battery_capacity_id,
          body_type: value?.oldValue?.body_type,
          fk_body_type_id: value?.oldValue?.fk_body_type_id,
          camera_specs: value?.oldValue?.camera_specs,
          fk_camera_specs_id: value?.oldValue?.fk_camera_specs_id,
          finger_print: value?.oldValue?.finger_print,
          fk_finger_print_id: value?.oldValue?.fk_finger_print_id,
          network_band: value?.oldValue?.network_band,
          fk_network_band_id: value?.oldValue?.fk_network_band_id,
          port: value?.oldValue?.port,
          fk_port_id: value?.oldValue?.fk_port_id,
          processor: value?.oldValue?.processor,
          fk_processor_id: value?.oldValue?.fk_processor_id,
          screen_protection: value?.oldValue?.screen_protection,
          fk_screen_protection_id: value?.oldValue?.fk_screen_protection_id,
          screen_size: value?.oldValue?.screen_size,
          fk_screen_size_id: value?.oldValue?.fk_screen_size_id,
          screen_type: value?.oldValue?.screen_type,
          fk_screen_type_id: value?.oldValue?.fk_screen_type_id,
          speaker: value?.oldValue?.speaker,
          fk_speaker_id: value?.oldValue?.fk_speaker_id,
          sim_type: value?.oldValue?.sim_type,
          fk_sim_type_id: value?.oldValue?.fk_sim_type_id,
          is_e_sim: value?.oldValue?.is_e_sim,
          is_pta_approved: value?.oldValue?.is_pta_approved,
          is_sim_support: value?.oldValue?.is_sim_support,
          notes: value?.oldValue?.notes,
        },
        listing_color: {
          color: value?.oldValue?.color,
          fk_color_id: value?.oldValue?.fk_color_id,
        },
        title: value?.oldValue?.title,
        video: value?.oldValue?.video,
        isValueFilled: true,
      }));
    } else if (value?.listing?.category === "Desktop Computer") {
      setInputState((prev) => ({
        ...prev,
        listing: {
          ...prev.listing,
          model: value?.oldValue?.model,
          fk_model_id: value?.oldValue?.fk_model_id ? value?.oldValue?.fk_model_id : -1,
          brand: value?.oldValue?.brand,
          fk_brand_id: value?.oldValue?.fk_brand_id,
          description: value?.oldValue?.description,
          desktop_type: value?.oldValue?.desktop_type,
          fk_desktop_type_id: value?.oldValue?.fk_desktop_type_id,
          generation: value?.oldValue?.generation,
          fk_generation_id: value?.oldValue?.fk_generation_id,
          processor: value?.oldValue?.processor,
          fk_processor_id: value?.oldValue?.fk_processor_id,
          ram: value?.oldValue?.ram,
          fk_ram_id: value?.oldValue?.fk_ram_id,
          ram_type: value?.oldValue?.ram_type,
          fk_ram_type_id: value?.oldValue?.fk_ram_type_id,
          storage_hdd: value?.oldValue?.storage_hdd,
          fk_storage_hdd_id: value?.oldValue?.fk_storage_hdd_id,
          storage_ssd: value?.oldValue?.storage_ssd,
          fk_storage_ssd_id: value?.oldValue?.fk_storage_ssd_id,
        },
        ...(value?.oldValue?.ports && value?.oldValue?.ports?.length > 0
          ? {
              listing_port: value?.oldValue?.ports?.map((port) => ({
                port: port.port,
                fk_port_id: port.fk_port_id,
              })),
            }
          : {}),

        listing_optional: {
          graphic_card_memory: value?.oldValue?.graphic_card_memory,
          fk_graphic_card_memory_id: value?.oldValue?.fk_graphic_card_memory_id,
          graphic_card_name: value?.oldValue?.graphic_card_name,
          fk_graphic_card_name_id: value?.oldValue?.fk_graphic_card_name_id,
          graphic_card_type: value?.oldValue?.graphic_card_type,
          fk_graphic_card_type_id: value?.oldValue?.fk_graphic_card_type_id,
          resolution: value?.oldValue?.resolution,
          fk_resolution_id: value?.oldValue?.fk_resolution_id,
          screen_size: value?.oldValue?.screen_size,
          fk_screen_size_id: value?.oldValue?.fk_screen_size_id,
          speaker: value?.oldValue?.speaker,
          fk_speaker_id: value?.oldValue?.fk_speaker_id,
          is_touch_screen: value?.oldValue?.is_touch_screen,
          notes: value?.oldValue?.notes,
        },
        listing_color: {
          color: value?.oldValue?.color,
          fk_color_id: value?.oldValue?.fk_color_id,
        },
        title: value?.oldValue?.title,
        video: value?.oldValue?.video,
        isValueFilled: true,
      }));
    } else if (value?.listing?.category === "Laptop") {
      setInputState((prev) => ({
        ...prev,
        listing: {
          ...prev.listing,
          model: value?.oldValue?.model,
          fk_model_id: value?.oldValue?.fk_model_id ? value?.oldValue?.fk_model_id : -1,
          brand: value?.oldValue?.brand,
          fk_brand_id: value?.oldValue?.fk_brand_id,
          description: value?.oldValue?.description,
          laptop_type: value?.oldValue?.laptop_type,
          fk_laptop_type_id: value?.oldValue?.fk_laptop_type_id,
          generation: value?.oldValue?.generation,
          fk_generation_id: value?.oldValue?.fk_generation_id,
          processor: value?.oldValue?.processor,
          fk_processor_id: value?.oldValue?.fk_processor_id,
          ram: value?.oldValue?.ram,
          fk_ram_id: value?.oldValue?.fk_ram_id,
          ram_type: value?.oldValue?.ram_type,
          fk_ram_type_id: value?.oldValue?.fk_ram_type_id,
          storage_hdd: value?.oldValue?.storage_hdd,
          fk_storage_hdd_id: value?.oldValue?.fk_storage_hdd_id,
          storage_ssd: value?.oldValue?.storage_ssd,
          fk_storage_ssd_id: value?.oldValue?.fk_storage_ssd_id,
          screen_size: value?.oldValue?.screen_size,
          fk_screen_size_id: value?.oldValue?.fk_screen_size_id,
        },
        ...(value?.oldValue?.ports && value?.oldValue?.ports?.length > 0
          ? {
              listing_port: value?.oldValue?.ports?.map((port) => ({
                port: port.port,
                fk_port_id: port.fk_port_id,
              })),
            }
          : {}),

        listing_optional: {
          battery: value?.oldValue?.battery,
          fk_battery_id: value?.oldValue?.fk_battery_id,
          camera_type: value?.oldValue?.camera_type,
          fk_camera_type_id: value?.oldValue?.fk_camera_type_id,
          finger_print: value?.oldValue?.finger_print,
          fk_finger_print_id: value?.oldValue?.fk_finger_print_id,
          graphic_card_memory: value?.oldValue?.graphic_card_memory,
          fk_graphic_card_memory_id: value?.oldValue?.fk_graphic_card_memory_id,
          graphic_card_name: value?.oldValue?.graphic_card_name,
          fk_graphic_card_name_id: value?.oldValue?.fk_graphic_card_name_id,
          graphic_card_type: value?.oldValue?.graphic_card_type,
          fk_graphic_card_type_id: value?.oldValue?.fk_graphic_card_type_id,
          keyboard: value?.oldValue?.keyboard,
          fk_keyboard_id: value?.oldValue?.fk_keyboard_id,
          resolution: value?.oldValue?.resolution,
          fk_resolution_id: value?.oldValue?.fk_resolution_id,
          screen_type: value?.oldValue?.screen_type,
          fk_screen_type_id: value?.oldValue?.fk_screen_type_id,
          speaker: value?.oldValue?.speaker,
          fk_speaker_id: value?.oldValue?.fk_speaker_id,
          is_backlit: value?.oldValue?.is_backlit,
          is_touch_screen: value?.oldValue?.is_touch_screen,
          notes: value?.oldValue?.notes,
        },
        listing_color: {
          color: value?.oldValue?.color,
          fk_color_id: value?.oldValue?.fk_color_id,
        },
        title: value?.oldValue?.title,
        video: value?.oldValue?.video,
        isValueFilled: true,
      }));
    }
  };

  useEffect(() => {
    if (value?.oldValue) {
      setIsSearchMode(value?.oldValue ? (value?.oldValue?.hasOwnProperty("fk_model_id") ? (value?.oldValue?.fk_model_id === -1 ? false : true) : true) : true);
      setValues();
    }
  }, [value?.oldValue]);

  const imageComponentRef = useRef();
  const videoComponentRef = useRef();

  const [progressBar, setProgressBar] = useState(0);

  const onHideHandle = (actionToCall) => {
    setInputState((prev) => ({
      ...prev,
      validated: false,
      // images: [],
      // imageId: null,
    }));
    setIsSearchMode(true);
    if (actionToCall) {
      actionToCall();
    }
    setProgressBar(0);
  };
  useEffect(() => {
    if (value?.listing?.item_id) {
      getItem(value?.listing?.item_id);
    }
    async function getItem(id) {
      try {
        setLoading(true);
        const getItemResponse = await axios.get(process.env.REACT_APP_API_URL + `/inventory/` + id, {
          withCredentials: true,
        });
        if (getItemResponse) {
          // Images
          const imagesArray = getItemResponse.data.success.data.images;
          const imagesResolvedArray = imagesArray.map((image) => {
            if (image !== "nil") {
              const obj = {
                preview: process.env.REACT_APP_URL_S3 + "/" + image,
                data: image,
                type: "url",
              };
              return obj;
            } else {
              return "nil";
            }
          });
          setInputState((prev) => ({
            ...prev,
            oldImages: imagesResolvedArray.filter((img) => img !== "nil"),
            images: imagesResolvedArray.filter((img) => img !== "nil"),
            imageId: getItemResponse.data.success.data.item.images,
          }));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }, [value?.listing?.item_id]);

  useEffect(() => {
    let NumberOfFields =
      (value?.listing?.category === "Accessories"
        ? 7
        : value?.listing?.category === "TV / Monitor"
        ? inputState?.listing?.is_smart_tv === false && !inputState?.listing?.hasOwnProperty("is_tv_certificated") && !inputState?.listing?.hasOwnProperty("operating_system") && !inputState?.listing?.hasOwnProperty("fk_operating_system_id")
          ? 20
          : 23
        : value?.listing?.category === "Mobile"
        ? 10
        : value?.listing?.category === "Tablet"
        ? 9
        : value?.listing?.category === "Desktop Computer"
        ? inputState?.listing?.hasOwnProperty("storage_ssd") && inputState?.listing?.hasOwnProperty("storage_hdd")
          ? 19
          : 17
        : value?.listing?.category === "Laptop"
        ? inputState?.listing?.hasOwnProperty("storage_ssd") && inputState?.listing?.hasOwnProperty("storage_hdd")
          ? 21
          : 19
        : Number.MAX_VALUE) + 4;
    const valueOfProgress =
      (inputState?.listing ? Object.keys(inputState?.listing).length : 0) +
      (inputState?.listing_color ? Object.keys(inputState?.listing_color).length : 0) +
      (convert(inputState?.listing?.description)?.trim().length > 0 ? (convert(inputState?.listing?.description)?.trim().length > 49 ? 1 : 0) : -1) +
      (inputState?.listing?.model?.length === 0 ? -1 : 0) +
      (inputState?.images?.length > 0 ? 1 : 0);
    if (valueOfProgress > 0) {
      setProgressBar(Math.floor((valueOfProgress / NumberOfFields) * 100));
    } else {
      setProgressBar(0);
    }
  }, [inputState]);

  useImperativeHandle(ref, () => ({
    handleSave: async (actionToCall) => {
      try {
        if (value) {
          if (!inputState.validated) {
            setInputState((prev) => ({ ...prev, validated: true }));
          }
          let NumberOfFields =
            value?.listing?.category === "Accessories"
              ? 7
              : value?.listing?.category === "TV / Monitor"
              ? inputState?.listing?.is_smart_tv === false && !inputState?.listing?.hasOwnProperty("is_tv_certificated") && !inputState?.listing?.hasOwnProperty("operating_system") && !inputState?.listing?.hasOwnProperty("fk_operating_system_id")
                ? 20
                : 23
              : value?.listing?.category === "Mobile"
              ? 10
              : value?.listing?.category === "Tablet"
              ? 9
              : value?.listing?.category === "Desktop Computer"
              ? inputState?.listing?.hasOwnProperty("storage_ssd") && inputState?.listing?.hasOwnProperty("storage_hdd")
                ? 19
                : 17
              : value?.listing?.category === "Laptop"
              ? inputState?.listing?.hasOwnProperty("storage_ssd") && inputState?.listing?.hasOwnProperty("storage_hdd")
                ? 21
                : 19
              : Number.MAX_VALUE;
          if (
            inputState?.images?.length > 0 &&
            inputState?.listing &&
            Object.keys(inputState?.listing).length === NumberOfFields &&
            inputState?.listing_color &&
            Object.keys(inputState?.listing_color).length === 2 &&
            inputState.title &&
            (value?.listing?.category === "TV / Monitor" ? inputState?.listing_port && inputState?.listing_port.length > 0 : true) &&
            (value?.listing?.category === "Laptop" ? (inputState?.listing_port ? inputState?.listing_port.length > 0 : true) : true) &&
            (inputState?.listing_optional?.notes ? inputState?.listing_optional?.notes?.length <= 500 : true) &&
            convert(inputState?.listing?.description)?.trim().length > 50
          ) {
            setIsSubmitting(true);
            const videoUploaded = await videoComponentRef.current.uploadVideoHandle();
            const imageURLs = await imageComponentRef.current.uploadImagesOnUpdate();
            const imageArrayForRequest = imageURLs.map((img) => img.data);
            await axios.patch(
              process.env.REACT_APP_API_URL + `/items-images/update/` + inputState?.imageId,
              {
                images: imageArrayForRequest,
                is_image_uploaded: true,
              },
              { withCredentials: true },
            );
            const { imageId, validated, images, ...listings } = inputState;
            const validateCallReponse = value?.oldValue
              ? await axios.patch(process.env.REACT_APP_API_URL + `/listings/validate/${value?.listing?.id}`, { ...listings, category: value?.listing?.category, video: videoUploaded }, { withCredentials: true })
              : await axios.post(process.env.REACT_APP_API_URL + `/listings/validate/${value?.listing?.id}`, { ...listings, category: value?.listing?.category, video: videoUploaded }, { withCredentials: true });

            if (validateCallReponse) {
              setValue((prev) => {
                return {
                  ...prev,
                  listing: {
                    ...prev.listing,
                    brand: inputState.listing.brand,
                    title: inputState.title,
                  },
                  oldValue: {
                    ...prev.listing,
                    notes: listings?.listing_optional?.notes,
                    video: listings?.video,
                  },
                };
              });
              setIsSubmitting(false);
              sendChangeSignal();
              onHideHandle(actionToCall);
              videoComponentRef.current.deleteFromCDN();
              toast.success(`${inputState?.title} is ${value?.oldValue ? "Updated" : "Validated"} successfully`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1500,
                pauseOnHover: false,
                className: "toastMsg",
              });
            }
          }
        } else {
          toast.error(`Erron on loading Listing!`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
            pauseOnHover: false,
            className: "toastMsg",
          });
          if (actionToCall) {
            actionToCall();
          }
        }
      } catch (error) {
        if (error?.response?.data?.error?.message === "This Listings is already validated!") {
          toast.error("This Listings is already validated!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
            pauseOnHover: false,
            className: "toastMsg",
          });
        } else {
          toast.error(`Erron While ${value?.oldValue ? "updating" : "validating"} this listing!`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
            pauseOnHover: false,
            className: "toastMsg",
          });
        }
        setIsSubmitting(false);
      }
    },
    reset: () => {
      setInputState((prev) => {
        return {
          imageId: prev?.imageId,
          validated: false,
          images: prev?.oldImages?.length > 0 ? prev?.oldImages : [],
          listing: undefined,
          listing_port: undefined,
          listing_optional: undefined,
          listing_color: undefined,
          title: undefined,
          video: undefined,
        };
      });
    },
    resetEdit: () => {
      if (isSearchMode) {
        setInputState((prev) => {
          return {
            ...prev,
            imageId: prev?.imageId,
            validated: false,
            images: prev?.oldImages?.length > 0 ? prev?.oldImages : [],
            listing: undefined,
            listing_port: undefined,
            listing_optional: undefined,
            listing_color: undefined,
            title: undefined,
            video: undefined,
          };
        });
      } else {
        setInputState((prev) => ({
          ...prev,
          imageId: prev?.imageId,
          validated: false,
          images: prev?.oldImages?.length > 0 ? prev?.oldImages : [],
          video: prev?.video,
        }));
        setValues();
      }
    },
  }));

  const handleChangeSearchMode = (value) => {
    setIsSearchMode(value);
    setInputState((prev) => ({
      imageId: prev?.imageId,
      validated: false,
      images: [],
    }));
    setProgressBar(0);
  };

  let DisplayForm = isSearchMode
    ? SearchForm
    : value?.listing?.category === "Accessories"
    ? AccessoryForm
    : value?.listing?.category === "TV / Monitor"
    ? TvMonitorForm
    : value?.listing?.category === "Mobile"
    ? MobileForm
    : value?.listing?.category === "Tablet"
    ? TabletForm
    : value?.listing?.category === "Desktop Computer"
    ? DesktopForm
    : value?.listing?.category === "Laptop"
    ? LaptopForm
    : () => <div style={{ minHeight: "400px", height: "100%" }}></div>;
  return (
    <>
      <div className={`${style.tableContainer} overflow-auto`}>
        <div className={` px-3 py-1 border-bottom`}>
          <div className={`${style.toggleButtonContainer}`}>
            <button className={isSearchMode ? "" : style.active} onClick={() => handleChangeSearchMode(false)}>
              Add Manually
            </button>
            <button className={isSearchMode ? style.active : ""} onClick={() => handleChangeSearchMode(true)}>
              Search Product
            </button>
          </div>
        </div>
        <div className="px-0 px-sm-3 px-lg-5">
          <DisplayForm ref={value?.listing?.category ? { imageRef: imageComponentRef, videoRef: videoComponentRef } : null} state={inputState} setState={setInputState} category={value?.listing?.category} condition={value?.listing?.condition_id} />
        </div>
      </div>
      {isSearchMode ? (
        <></>
      ) : (
        <div className="px-3 py-1 border-top">
          <div className={`${style.progressBarContainer}`}>
            <div
              className={`${style.progressBar} ${progressBar === 100 ? style.progress100 : ""}`}
              style={{
                width: progressBar + "%",
                // background:
                //   progressBar === 100
                //     ? "linear-gradient(180deg, #18a538 0%, #0ab737 100%)"
                //     : "linear-gradient(180deg, #a93bff 0%, #8834ff 100%)",
              }}
            ></div>
          </div>
        </div>
      )}
    </>
  );
});

export default ValidationForm;
