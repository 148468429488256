import React from "react";
import style from "./../../styles/Invoice.module.css";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import style1 from "../../styles/InvoiceCard.module.css";
import "./../../styles/thermalPrintlayout.css";
import { forwardRef } from "react";
import { dateTime } from "../../Features/dateTime";

const ThermalRefundInvoice = forwardRef((props, ref) => {
  const { user } = useSelector((state) => state.auth);
  const logo = user.logo_path;
  const { isThermalLogo } = useSelector((state) => state.printerType);
  var invoiceDate = dateTime(props?.data?.refunded_on).toLocaleString().split(",")[0];
  var invoicetime = dateTime(props?.data?.refunded_on).toLocaleString().split(",")[1];
  return (
    <div className={"mx-auto w-50 text-center bg-white py-3"}>
      <div ref={ref} className={"thermalInvoice text-center mx-auto"}>
        <div>
          <div>
            <h2 className="shopName m-0 dark-text">{user?.shop_name}</h2>
            {isThermalLogo.value ? logo !== null && <img src={process.env.REACT_APP_URL_S3 + "/" + logo} alt="" className={`${style1.logoimg} ${style1.greyscale} ${style.logo}`} /> : ""}
            <p className="shopDetails dark-text">
              <strong>Email: </strong> {props?.data?.owner_email}
            </p>
            <p className="shopDetails dark-text">{props?.data?.shop_address}</p>
            <p className="shopDetails dark-text">
              {/* <strong>Mobile No: </strong> {props?.data?.owner_whatsapp_number} */}
              Phone:{" "}
              {props?.data?.is_contact_no_on_invoice === 1 && props?.data?.is_alternative_no_on_invoice === 1 ? (
                <>
                  {props?.data?.contact_number}
                  <br />
                  {props?.data?.alternative_number}
                </>
              ) : props?.data?.is_contact_no_on_invoice === 1 ? (
                props?.data?.contact_number
              ) : props?.data?.is_alternative_no_on_invoice === 1 ? (
                props?.data?.alternative_number
              ) : (
                props?.data?.owner_whatsapp_number
              )}
            </p>
            <table className={`mx-auto mt-1 text-start thermalPrinterTable ${style?.itemsTable}`}>
              <thead
                style={{
                  borderTop: "1px solid #000",
                  borderBottom: "1px solid #000",
                }}
              >
                <th colSpan="3" style={{ border: "1px solid #000", padding: "5px" }}>
                  {invoiceDate}
                </th>
                <th
                  colSpan="3"
                  style={{
                    border: "1px solid #000",
                    padding: "5px",
                    textAlign: "end",
                  }}
                >
                  {invoicetime}
                </th>
              </thead>
              <thead
                style={{
                  borderTop: "1px solid #000",
                  borderBottom: "1px solid #000",
                }}
              >
                <th className="border-0 extraTh"></th>
              </thead>
              <thead
                style={{
                  borderTop: "1px solid #000",
                  borderBottom: "1px solid #000",
                }}
              >
                <th style={{ border: "1px solid #000", padding: "5px" }}>Products</th>
                <th style={{ border: "1px solid #000", padding: "5px" }}>Unit Price</th>
                <th style={{ border: "1px solid #000", padding: "5px" }}>Total Qty</th>
                <th style={{ border: "1px solid #000", padding: "5px" }}>Refunded Qty</th>
                <th style={{ border: "1px solid #000", padding: "5px" }}>Refunded Amount</th>
              </thead>
              {props?.items?.map((item, index) => (
                <tr key={index}>
                  <td style={{ borderTop: "1px solid #000", padding: "5px" }} className="d-flex flex-column">
                    {item?.title}
                  </td>
                  <td style={{ border: "1px solid #000", padding: "5px" }}>{Number(item?.price).toLocaleString("en-IN")}</td>
                  <td style={{ border: "1px solid #000", padding: "5px" }}>{item?.qty_ordered}</td>
                  <td style={{ border: "1px solid #000", padding: "5px" }}>{Number(item?.refunded_qty).toLocaleString("en-IN")}</td>
                  <td style={{ border: "1px solid #000", padding: "5px" }}>{Number(item?.refunded_amount).toLocaleString("en-IN")}</td>
                </tr>
              ))}
            </table>
            <div className="w-75 mx-auto mt-2">
              <Row className="mt-1">
                <Col xs="1" sm="2" md="2" lg="6" className="d-none d-md-block"></Col>
                <Col xs="6" sm="5" md="6" lg="3" className="text-start">
                  <p className="totals m-0"> Base Grand Total</p>
                </Col>
                <Col xs="6" sm="5" md="4" lg="3" className="text-end">
                  <p className="totals m-0"> {Number(props?.data?.base_grand_total).toLocaleString("en-IN")} </p>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col xs="1" sm="2" md="2" lg="6" className="d-none d-md-block"></Col>
                <Col xs="6" sm="5" md="6" lg="3" className="text-start">
                  <p className="totals m-0"> Total Refunded Amount</p>
                </Col>
                <Col xs="6" sm="5" md="4" lg="3" className="text-end my-0">
                  <p className="totals m-0"> {Number(props?.data?.total_refunded_amount).toLocaleString("en-IN")} </p>
                </Col>
              </Row>
            </div>
            <p className={`invoiceTerms m-0 mt-1`}>Invoice terms</p>
            {props?.data?.invoice_terms !== "nil" && <p className={`mt-0 mb-3 text-center invoiceTerms`}>* {props?.data?.invoice_terms}</p>}
            <p className={`mt-0 mb-3 text-center invoiceTerms fst-italic`}>Powered by TechBazaar POS</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ThermalRefundInvoice;
