import axios from "axios";
const API_URL = "/auth/";

// Login User

// const getUserPermissions = async (id) => {
//   try {
//     const res = await axios.get(
//       process.env.REACT_APP_API_URL + `/user/get-user-modules/${id}`,
//       { withCredentials: true },
//     );
//     return res.data;
//   } catch (err) {
//     throw new Error(err.response.data);
//   }
// };

const login = async (userData) => {
  try {
    const res = await axios.post(process.env.REACT_APP_API_URL + API_URL + "login-seller", userData, { withCredentials: true });

    localStorage.setItem("refreshToken", res.data.refreshToken);
    // const permission = await getUserPermissions(res.data.user.user_id);
    return true;
  } catch (err) {
    throw new Error(err.response?.data.error.message);
  }
};

const loginByOtp = async (payload) => {
  try {
    const res = await axios.post(process.env.REACT_APP_API_URL + "/otp/verify", { email: payload.user_email, otp: payload.otp }, { withCredentials: true });
    localStorage.setItem("refreshToken", res.data.refreshToken);
    return true;
  } catch (err) {
    throw new Error(err);
  }
};
const getUser = async (refreshToken) => {
  try {
    const res = await axios.get(process.env.REACT_APP_API_URL + "/user/get-seller-user-by-accesstoken", {
      withCredentials: true,
      headers: {
        Authorization: refreshToken,
      },
    });

    // const permission = await getUserPermissions(res.data.user_id);
    return res.data;
  } catch (err) {
    throw new Error(err.response.data.error.message);
  }
};
const getUserForAdmin = async (refreshToken) => {
  try {
    const res = await axios.get(process.env.REACT_APP_API_URL + "/user/direct-login", {
      withCredentials: true,
      headers: {
        Authorization: refreshToken,
      },
    });
    localStorage.setItem("refreshToken", res.data.refreshToken);
    // const permission = await getUserPermissions(res.data.user_id);
    return res.data;
  } catch (err) {
    throw new Error(err.response?.data.error.message);
  }
};
const getRegisterId = async (shop_id, location_id) => {
  try {
    const headers = {
      details: JSON.stringify({ shop_id, location_id }),
    };
    const res = await axios.get(process.env.REACT_APP_API_URL + "/cash-registers/get-id", {
      withCredentials: true,
      headers: headers,
    });
    return res.data.id;
  } catch (err) {
    throw new Error(err.response?.data.error.message);
  }
};
const updateTourGuide = async ({ tour_guide, user_id }) => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/settings/tour-guide/${user_id}`, tour_guide, { withCredentials: true });
    return tour_guide;
  } catch (err) {
    throw new Error(err.response.data.error.message);
  }
};

const authService = {
  login,
  getUser,
  // getUserPermissions,
  getUserForAdmin,
  getRegisterId,
  loginByOtp,
  updateTourGuide,
};
export default authService;
