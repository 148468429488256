import React, { useCallback, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, Card } from "react-bootstrap";
import Invoice from "./Invoice";
import { useParams } from "react-router-dom";
import axios from "axios";
import LoaderPage from "../../Components/loaders/loaderPage";
import style from "./OrderInvoice.module.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const DeliveryForYouOrderInvoicePage = () => {
  const { invoiceId } = useParams();
  const [invoiceData, setInvoiceData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const componentRef = useRef(null);
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
  });
  useEffect(() => {
    if (invoiceId) {
      setIsLoading(true);
      axios
        .get(process.env.REACT_APP_API_URL + `/d4u-orders/${invoiceId}/for-sales`, { withCredentials: true })
        .then((res) => {
          setInvoiceData(res?.data?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, [invoiceId]);

  const handleSaveAsPDF = (id) => {
    const input = componentRef.current;
    if (input) {
      html2canvas(input)
        .then((canvas) => {
          const pdf = new jsPDF("p", "mm", "a4");
          const imgData = canvas.toDataURL("image/png");
          const imgWidth = 210; // A4 width in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width; // maintain aspect ratio
          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight, undefined, "FAST");
          pdf.save(`order_${invoiceId}_invoice.pdf`);
        })
        .catch((error) => {
          console.error("Error generating PDF: ", error);
        });
    }
  };
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center mb-3 gap-3">
        <Button variant="outline-primary" className="px-4" onClick={handlePrint}>
          Print{" "}
        </Button>
        <Button variant="primary" className="px-4" onClick={handleSaveAsPDF}>
          Download
        </Button>
      </div>
      <>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
            <LoaderPage />
          </div>
        ) : invoiceData ? (
          <>
            <Card className={`border-0 ${style.invoiceCard} `}>
              <div ref={componentRef} className="laser-invoice p-3">
                <Invoice invoiceData={invoiceData} />
              </div>
            </Card>
          </>
        ) : (
          <div style={{ height: "80vh" }} className="d-flex justify-content-center align-items-center">
            <h3>No data exists for this order</h3>
          </div>
        )}
      </>
    </div>
  );
};

export default DeliveryForYouOrderInvoicePage;
