import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import SearchableSelect from "../../Components/SearchableSelect";
import { dateTime, requestDateTime } from "../../Features/dateTime";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import OpeningRegister from "../../Components/Register/OpeningRegister";
// import { getRegisterId } from "../../Features/authSlice";

import ReactSelect from "../../Components/ReactSelect";
import { setLocation } from "../../Features/locationSlice";

const AddExpenses = ({ user, location, openingBalances, close, refresh }) => {
  const dispatch = useDispatch();
  const register = useSelector((state) => state.location?.register);
  const registerID = register?.registerId;
  const [validated, setValidated] = useState(false);
  const [expenseType, setExpensesType] = useState("");
  const [expenseAmount, setExpensesAmount] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [expenseDate, setExpenseDate] = useState(new Date().toISOString().split("T")[0]);
  const [comments, setComments] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [error, setError] = useState("");
  const [bank, setBank] = useState(null);
  const [cheque_number, setCheque_number] = useState(null);
  const [registerClosedAlert, setRegisterClosedAlert] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [openingBalance, setOpeningBalance] = useState();
  const [isValidate, setIsValidated] = useState(false);
  const [cashInHand, setCashInHand] = useState();

  useEffect(() => {
    // setLoading(true);
    const cashInHand = () => {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/cash-in-hand/${user?.user_id}`,
          {
            dates: {
              from: register?.openingBalance?.opening_balance_time.split(".")[0].replace("T", " "),
              to: register?.openingBalance?.closing_balance_time ? register?.openingBalance?.closing_balance_time.split(".")[0].replace("T", " ") : null,
            },
            locationFields: location,
            openingBalance: register?.openingBalance?.opening_balance_id,
          },
          { withCredentials: true }
        )
        .then((res) => {
          let n = res?.data?.length;
          setCashInHand(res.data[n - 1]);
          // setIsLoading(false);
          // setLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(location).length > 0 && register?.openingBalance) cashInHand();
  }, [location, register?.openingBalance]);
  const loadBanksOptions = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + `/shops/banks/${user?.shop_id}`, {
          withCredentials: true,
        })
        .then((banksRes) => {
          const banks = banksRes.data.map((bank) => ({
            value: bank,
            label: (
              <>
                <span style={{ fontSize: "12px" }}>
                  Bank Name: <strong className="fs-12">{bank?.bank_name}</strong>
                </span>
                <br />
                <span style={{ fontSize: "12px" }}>Account Title: {bank?.account_title}</span>
                <br />
                <span className="d-block" style={{ fontSize: "12px" }}>
                  Account Number:{bank?.account_number}
                </span>
              </>
            ),
            name: bank.bank_name,
          }));
          const defaultBank = banks.filter((bank) => bank.value.default_business_account === 1);
          if (defaultBank[0]) {
            setBank({ value: defaultBank[0].value, label: defaultBank[0].name });
          }
          resolve(banks);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const handleChangeBank = (e) => {
    setBank({ value: e.value, label: e.name });
  };
  const handleExpenseSubmit = async (e) => {
    try {
      e.preventDefault();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropogation();
      }
      setValidated(true);
      if (paymentType === "Cash" ? register.isCashRegisterOpen : true) {
        setError("");
        if (paymentType === "Cash" ? cashInHand.cash_in_hand >= Number(expenseAmount) : true) {
          setError("");

          if (expenseType.length > 0 && expenseAmount.length > 0 && Number(expenseAmount) > 0 && paymentType.length > 0 && expenseDate.length > 0 && (paymentType === "Transfer" || paymentType === "Cheque" || paymentType === "Card" ? bank?.value : true)) {
            setIsClicked(true);
            // const expense_dt = requestDateTime(expenseDate + "T" + new Date().toISOString().split("T")[1]);
            /*  I'M COMMENTING IT FOR FUTURE REFERENCES
            const expense_time = new Date().toISOString().split("T")[1];
            const expense_dt = requestDateTime(`${expenseDate}T${expense_time}`);
            const payload = {
              shop_id: user?.shop_id,
              location_id: location.value,
              expense_type: expenseType,
              expense_amount: expenseAmount,
              payment_type: paymentType,
              bank_id: paymentType === "Cash" ? -1 : bank?.value?.bank_id,
              cheque_number: cheque_number,
              expense_date: expense_dt,
              comments: comments,
            };

            */
            const payload = {
              shop_id: user?.shop_id,
              location_id: location.value,
              expense_type: expenseType,
              expense_amount: expenseAmount,
              payment_type: paymentType,
              bank_id: paymentType === "Cash" ? -1 : bank?.value?.bank_id,
              cheque_number: cheque_number,
              expense_date: expenseDate,
              comments: comments,
            };
            const res = await axios.post(process.env.REACT_APP_API_URL + "/expenses/add", payload, { withCredentials: true });
            if (res) {
              dispatch(setLocation({ shop_id: user?.shop_id, location: location }));
              close();
            }
          }
        } else {
          setRegisterClosedAlert(false);
          setError("Amount is more then the Cash in Hand!");
        }
      } else {
        setRegisterClosedAlert(true);
        setError("Register is not opened!");
      }
    } catch (err) {
      setIsClicked(false);

      throw new Error(err.response.data);
    }
  };

  const handleExpenseTypeChange = (e) => {
    setExpensesType(e.target.value);
  };
  const handleExpenseAmountChange = (e) => {
    // const ob = openingBalances.filter(
    //   (ob) => ob.location_id === location.value
    // )[0];
    const format = /^[0-9]*$/;
    if (format.test(e.target.value)) {
      if (Number(e.target.value) > 0 && e.target.value.length < 10) {
        if (paymentType === "Cash" ? register.isCashRegisterOpen : true) {
          if (paymentType === "Cash" ? cashInHand?.cash_in_hand < Number(e.target.value) : false) {
            setRegisterClosedAlert(false);
            setError("Amount is more then the Cash in Hand!");
          } else {
            setError("");
          }
        } else {
          setRegisterClosedAlert(true);
          setError("Register is not Open yet!");
        }
        setExpensesAmount(e.target.value);
      } else {
        setExpensesAmount("");
      }
    } else {
      setExpensesAmount("");
    }
  };
  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.target.value);
  };

  const handleExpenseDateChange = (e) => {
    setExpenseDate(e.target.value);
  };
  const handleCommentsChange = (e) => {
    setComments(e.target.value);
  };
  const handleChequeNumberChange = (e) => {
    if (e.target.value.length < 20) {
      setCheque_number(e.target.value);
    }
  };
  useEffect(() => {
    if (user && location?.value) {
      // dispatch(
      //   getRegisterId({
      //     shop: user,
      //     location: location.value,
      //   })
      // );
    }
  }, [location, user]);
  const handleCashOpeningBalance = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setIsValidated(true);
    try {
      if (registerID === -1) {
        setError("Register is not Set!");
      } else {
        if (openingBalance >= 0) {
          const res = await axios.post(process.env.REACT_APP_API_URL + `/cash-registers/opening-balance`, { register_id: registerID, opening_balance: openingBalance }, { withCredentials: true });
          if (res) {
            dispatch(setLocation({ shop_id: user?.shop_id, location: location }));
            // dispatch(getRegisterId({ shop: user, location: location.value }));
            setRegisterClosedAlert(false);
            setError("");
            refresh();
            setIsValidated(false);
            setOpeningBalance();
            setTimeout(() => setOpenRegisterModal(false), 1000);
          }
        }
      }
    } catch (error) {
      setError(error);
    }
  };
  const currentMonth = new Date().toDateString().split(" ")[1];
  const currentDate = new Date().getDate() + " " + currentMonth + ", " + new Date().getFullYear();
  const today_sh = new Date();
  const currentTime = today_sh.getHours();
  let showtime;
  if (currentTime < 12) {
    showtime = "Good Morning";
  } else if (currentTime < 18) {
    showtime = "Good Afternoon";
  } else {
    showtime = "Good Evening";
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleExpenseSubmit} className="px-5 py-3">
        {registerClosedAlert ? (
          <div className="alert alert-warning border-dotted" style={{ border: "2px dotted #8834ff" }}>
            <div className="d-flex justify-content-between align-items-center">
              *{error}
              <button
                onClick={() => {
                  setOpenRegisterModal(true);
                }}
                className="btn btn-primary"
              >
                Open Register
              </button>
            </div>
          </div>
        ) : error ? (
          <div className="alert alert-warning border-dotted" style={{ border: "2px dotted #8834ff" }}>
            *{error}
          </div>
        ) : (
          ""
        )}
        <Form.Group className="mb-3" controlId="Location" id="expense-add-location">
          <SearchableSelect placeholder="Select location" label="Location" important searchable={false} value={Object.keys(location).length > 0 ? location : null} disabled={true} />
        </Form.Group>

        <Form.Group className="mb-3" id="expense-add-expense-type">
          <Form.Label className="mb-1">
            Expense Type<span className="text-danger">*</span>{" "}
          </Form.Label>
          <Form.Select onChange={handleExpenseTypeChange} required>
            <option value="" disabled selected>
              <span style={{ opacity: "0.3" }}>Select</span>
            </option>

            <option value="Rent">Rent</option>
            <option value="Purchase">Purchase</option>
            <option value="Food">Food</option>
            <option value="Electric Bills">Electric Bills</option>
            <option value="Parts">Parts</option>
            <option value="Repairing">Repairing</option>
            <option value="Delivery">Delivery</option>
            <option value="PTA Approval">PTA Approval</option>
            <option value="Tax">Tax</option>
            <option value="Others">Others</option>
          </Form.Select>
          <Form.Control hidden value={expenseType} onChange={() => {}} />
          <Form.Control.Feedback type="invalid">Please Select Expense Type.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" id="expense-add-expense-amount">
          <Form.Label className="mb-1">
            Expense Amount<span className="text-danger">*</span>{" "}
          </Form.Label>
          <Form.Control
            type="text"
            // min={1}
            step={1}
            // onKeyDown={(e) =>
            //   e.target.value.replace(/[^a-z0-9]/gi, '')
            // }
            value={expenseAmount}
            onChange={handleExpenseAmountChange}
            placeholder="e.g. 100000"
            required
          />
          <Form.Control.Feedback type="invalid">Please Select Expense Amount.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" id="expense-add-payment-type">
          <Form.Label className="mb-1">
            Payment Type<span className="text-danger">*</span>{" "}
          </Form.Label>
          <Form.Select onChange={handlePaymentTypeChange} required>
            <option value="" disabled selected hidden>
              <span style={{ opacity: "0.3" }}>Select</span>
            </option>
            <option value="Transfer">Transfer</option>
            <option value="Cash">Cash</option>
            <option value="Card">Card</option>
            <option value="Cheque">Cheque</option>
          </Form.Select>
          <Form.Control hidden value={paymentType} onChange={() => {}} />
          <Form.Control.Feedback type="invalid">Please Select Payment Type.</Form.Control.Feedback>
        </Form.Group>
        {paymentType === "Cash" || paymentType === "" ? (
          ""
        ) : (
          <>
            {paymentType === "Cheque" ? (
              <>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1">
                    Select Bank <span className="text-danger">*</span>
                  </Form.Label>
                  <ReactSelect placeholder="Select" value={bank} options={loadBanksOptions} handleChange={handleChangeBank} searchable={false} />
                  <Form.Control value={bank} onChange={() => {}} hidden required={paymentType === "Card" || paymentType === "Transfer" || paymentType === "Cheque"} />
                  <Form.Control.Feedback type="invalid">Please Select Bank.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1">Cheque Number</Form.Label>
                  <Form.Control type="text" step={1} value={cheque_number} onChange={handleChequeNumberChange} placeholder="Cheque Number" />
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1">
                    Select Bank <span className="text-danger">*</span>
                  </Form.Label>
                  <ReactSelect placeholder="Select" value={bank} options={loadBanksOptions} handleChange={handleChangeBank} searchable={false} />
                  <Form.Control value={bank} onChange={() => {}} hidden required={paymentType === "Card" || paymentType === "Transfer"} />
                  <Form.Control.Feedback type="invalid">Please Select Bank.</Form.Control.Feedback>
                </Form.Group>
              </>
            )}
          </>
        )}
        <Form.Group className="mb-3" id="expense-add-expense-date">
          <Form.Label className="mb-1">
            Expense date<span className="text-danger">*</span>{" "}
          </Form.Label>
          <Form.Control type="date" value={expenseDate} onChange={handleExpenseDateChange} placeholder="Select date" required />
          <Form.Control.Feedback type="invalid">Please Select Expense Date.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" id="expense-add-comments">
          <Form.Label className="mb-1">Comments: </Form.Label>
          <Form.Control as="textarea" value={comments} onChange={handleCommentsChange} placeholder="Comments" maxLength={300} />
        </Form.Group>

        <Form.Group className="mb-3" id="expense-add-save-button">
          <Form.Control type="button" value="Save" className="btn btn-primary" onClick={handleExpenseSubmit} disabled={isClicked} />
        </Form.Group>
      </Form>
      <OpeningRegister
        cashRegisterModal={openRegisterModal}
        setCashRegisterModal={setOpenRegisterModal}
        validated={isValidate}
        setIsValidate={setIsValidated}
        handleCashOpeningBalance={handleCashOpeningBalance}
        showtime={showtime}
        error={error}
        openingBalance={openingBalance}
        setOpeningBalance={setOpeningBalance}
      />
    </>
  );
};

export default AddExpenses;
