import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import search from "./../assets/svg/search.svg";

const NoResult = ({ path, onClick, name, height="75vh" }) => {
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
      style={{ height: height }}
    >
      <div className="text-center">
        <img
          height="45px"
          width="45px"
          src={search}
          alt="search"
          className="mb-3"
        />
        <h5 className="text-dark">No result found!</h5>
        <p className="text-secondary">0 result. Add {name} now</p>
        {onClick ? (
          <Button variant="outline-primary" onClick={onClick}>
            Add {name} +
          </Button>
        ) : path ? (
          <Link to={path}>
            <Button variant="outline-primary">Add {name} +</Button>
          </Link>
        ) : (
          <h5>Thanks for visiting!</h5>
        )}
      </div>
    </Container>
  );
};

export default NoResult;
