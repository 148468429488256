import React, { useEffect } from "react";
import lottie from "lottie-web";
import { useRef } from "react";

const animationData = {
  v: "5.7.1",
  ip: 0,
  op: 180,
  nm: "Animation",
  mn: "{10f831c4-dd0d-4f04-91f1-4c2994c4d726}",
  fr: 60,
  w: 512,
  h: 512,
  assets: [],
  layers: [
    {
      ddd: 0,
      ty: 4,
      ind: 0,
      st: 0,
      ip: 0,
      op: 240,
      nm: "Layer 1",
      mn: "{f1600663-9296-417c-aeae-f470c56a49ee}",
      ks: {
        a: { a: 0, k: [120.28, 125.401] },
        p: { a: 0, k: [241.867, 258.117] },
        s: { a: 0, k: [503.367, 503.367] },
        r: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      shapes: [
        {
          ty: "gr",
          nm: "bottom L",
          mn: "{bcda3e42-2195-4273-88d7-08653fecf337}",
          it: [
            {
              ty: "sh",
              nm: "Path 7",
              mn: "{778fff01-cff9-4a90-b2ba-96aa85049933}",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  v: [
                    [150.779, 125.112],
                    [141.51, 146.791],
                    [120.602, 155.29],
                    [100.022, 147.104],
                    [90.424, 125.112],
                    [98.923, 104.204],
                    [120.602, 94.935],
                    [142.655, 104.599],
                    [150.779, 125.112],
                  ],
                  i: [
                    [0, 0],
                    [5.702, -5.503],
                    [8.084, -0],
                    [5.408, 5.067],
                    [0, 8.633],
                    [-5.251, 5.441],
                    [-8.472, 0],
                    [-5.526, -5.932],
                    [0, -7.89],
                  ],
                  o: [
                    [-0, 8.472],
                    [-5.441, 5.251],
                    [-7.922, 0],
                    [-5.893, -5.522],
                    [-0, -8.084],
                    [5.503, -5.702],
                    [8.666, 0],
                    [5.031, 5.401],
                    [-0, 16.555],
                  ],
                },
              },
            },
            {
              ty: "gs",
              nm: "linearGradient3999",
              mn: "{14312caf-57d0-4824-b0b5-eae54c657b27}",
              o: { a: 0, k: 100 },
              lc: 2,
              lj: 2,
              ml: 4,
              w: { a: 0, k: 4.904 },
              s: { a: 0, k: [87.525, 157.154] },
              e: { a: 0, k: [150.648, 95.385] },
              t: 1,
              h: { a: 0, k: 0 },
              a: { a: 0, k: 0 },
              g: {
                p: 2,
                k: {
                  a: 0,
                  k: [0, 0.443, 0.129, 0.847, 1, 0.882, 0.529, 0.976, 0, 1, 1, 1],
                },
              },
            },
            {
              ty: "tm",
              nm: "Trim Path",
              mn: "{e468fb3a-22e8-44d4-937f-1ff448349117}",
              s: { a: 0, k: 14 },
              e: {
                a: 1,
                k: [
                  {
                    t: 94,
                    s: [14],
                    h: 0,
                    o: { x: [0], y: [0] },
                    i: { x: [1], y: [1] },
                  },
                  { t: 111, s: [46] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
            },
            {
              ty: "tr",
              a: { a: 0, k: [120.602, 125.112] },
              p: { a: 0, k: [120.602, 125.112] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
            },
          ],
        },
        {
          ty: "gr",
          nm: "Up R",
          mn: "{761d6ee0-be31-40c3-828e-aa3ff54ed025}",
          it: [
            {
              ty: "sh",
              nm: "Path 6",
              mn: "{db237b39-5d0b-4ebb-8fd6-09663378a834}",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  v: [
                    [150.779, 125.112],
                    [120.602, 155.29],
                    [90.424, 125.112],
                    [120.602, 94.935],
                    [150.779, 125.112],
                  ],
                  i: [
                    [0, 0],
                    [16.555, -0],
                    [0, 16.555],
                    [-16.555, 0],
                    [0, -16.555],
                  ],
                  o: [
                    [-0, 16.555],
                    [-16.555, 0],
                    [-0, -16.555],
                    [16.555, 0],
                    [-0, 16.555],
                  ],
                },
              },
            },
            {
              ty: "gs",
              nm: "0x000000000df2c420",
              mn: "{6d6f09ef-1944-4c57-a335-bdf0a42c9001}",
              o: { a: 0, k: 100 },
              lc: 2,
              lj: 2,
              ml: 4,
              w: { a: 0, k: 4.904 },
              s: { a: 0, k: [-65.912, 425.928] },
              e: { a: 0, k: [405.305, -15.423] },
              t: 1,
              h: { a: 0, k: 0 },
              a: { a: 0, k: 0 },
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [0, 0.925, 0.659, 1, 0.5, 0.063, 0, 0.271, 1, 0.063, 0, 0.271, 0, 1, 0.5, 1, 1, 1],
                },
              },
            },
            {
              ty: "tm",
              nm: "Trim Path 1",
              mn: "{6016d8dd-9ee7-4588-b221-7047de535e53}",
              s: {
                a: 1,
                k: [
                  {
                    t: 0,
                    s: [90],
                    h: 0,
                    o: { x: [0], y: [0] },
                    i: { x: [1], y: [1] },
                  },
                  {
                    t: 90,
                    s: [45],
                    h: 0,
                    o: { x: [0], y: [0] },
                    i: { x: [1], y: [1] },
                  },
                  { t: 134, s: [54] },
                ],
              },
              e: {
                a: 1,
                k: [
                  {
                    t: 0,
                    s: [90],
                    h: 0,
                    o: { x: [0], y: [0] },
                    i: { x: [1], y: [1] },
                  },
                  {
                    t: 46,
                    s: [0],
                    h: 0,
                    o: { x: [0], y: [0] },
                    i: { x: [1], y: [1] },
                  },
                  {
                    t: 90,
                    s: [0],
                    h: 0,
                    o: { x: [0], y: [0] },
                    i: { x: [1], y: [1] },
                  },
                  { t: 134, s: [17] },
                ],
              },
              o: {
                a: 1,
                k: [
                  {
                    t: 0,
                    s: [0],
                    h: 0,
                    o: { x: [0], y: [0] },
                    i: { x: [1], y: [1] },
                  },
                  {
                    t: 46,
                    s: [14.4],
                    h: 0,
                    o: { x: [0], y: [0] },
                    i: { x: [1], y: [1] },
                  },
                  { t: 90, s: [100.8] },
                ],
              },
              m: 1,
            },
            {
              ty: "tr",
              a: { a: 0, k: [120.602, 125.112] },
              p: { a: 0, k: [120.602, 125.112] },
              s: { a: 0, k: [100, 100] },
              r: {
                a: 1,
                k: [
                  {
                    t: 0,
                    s: [-1168],
                    h: 0,
                    o: { x: [0], y: [0] },
                    i: { x: [1], y: [1] },
                  },
                  {
                    t: 46,
                    s: [-490.716],
                    h: 0,
                    o: { x: [0], y: [0] },
                    i: { x: [1], y: [1] },
                  },
                  {
                    t: 78,
                    s: [-183.91],
                    h: 0,
                    o: { x: [0], y: [0] },
                    i: { x: [1], y: [1] },
                  },
                  { t: 120, s: [78] },
                ],
              },
              o: { a: 0, k: 100 },
            },
          ],
        },
        {
          ty: "gr",
          nm: "center",
          mn: "{6d61fb6e-2336-4360-9a7a-492c8812f046}",
          it: [
            {
              ty: "sh",
              nm: "Path 5",
              mn: "{c8fd6960-6efd-4e9d-a274-adfb0bc11b8b}",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  v: [
                    [140.591, 125.112],
                    [120.602, 145.102],
                    [100.612, 125.112],
                    [120.602, 105.123],
                    [140.591, 125.112],
                  ],
                  i: [
                    [0, 0],
                    [10.966, -0],
                    [0, 10.966],
                    [-10.966, 0],
                    [0, -10.966],
                  ],
                  o: [
                    [-0, 10.966],
                    [-10.966, 0],
                    [-0, -10.966],
                    [10.966, 0],
                    [-0, 10.966],
                  ],
                },
              },
            },
            {
              ty: "gs",
              nm: "linearGradient6974",
              mn: "{1824f92b-325f-412e-b876-f336613c6d96}",
              o: { a: 0, k: 100 },
              lc: 2,
              lj: 2,
              ml: 4,
              w: { a: 0, k: 4.904 },
              s: { a: 0, k: [93.814, 151.473] },
              e: { a: 0, k: [144.993, 100.618] },
              t: 1,
              h: { a: 0, k: 0 },
              a: { a: 0, k: 0 },
              g: {
                p: 2,
                k: {
                  a: 0,
                  k: [0, 0.443, 0.129, 0.847, 1, 0.882, 0.529, 0.976, 0, 1, 1, 1],
                },
              },
            },
            {
              ty: "tm",
              nm: "Trim Path 2",
              mn: "{e3b6cc60-c078-40c0-809f-a119a6160967}",
              s: { a: 0, k: 0 },
              e: {
                a: 1,
                k: [
                  {
                    t: 0,
                    s: [0],
                    h: 0,
                    o: { x: [0.03], y: [0] },
                    i: { x: [0.667], y: [1] },
                  },
                  { t: 131, s: [76] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
            },
            {
              ty: "tr",
              a: { a: 0, k: [120.602, 125.112] },
              p: { a: 0, k: [120.602, 125.112] },
              s: { a: 0, k: [100, 100] },
              r: {
                a: 1,
                k: [
                  {
                    t: 0,
                    s: [-845],
                    h: 0,
                    o: { x: [0.043], y: [0.011] },
                    i: { x: [0.517], y: [0.529] },
                  },
                  {
                    t: 51,
                    s: [-281.432],
                    h: 0,
                    o: { x: [0.348], y: [0.518] },
                    i: { x: [0.732], y: [1] },
                  },
                  { t: 146, s: [-12] },
                ],
              },
              o: { a: 0, k: 100 },
            },
          ],
        },
        {
          ty: "gr",
          nm: "diagonal",
          mn: "{a4d1c79c-15af-48d3-a2d5-0b807ec5a8c2}",
          it: [
            {
              ty: "sh",
              nm: "Path 4",
              mn: "{a204b788-fbcb-4388-b7b0-54408006cf13}",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  v: [
                    [114.22, 131.467],
                    [141.742, 103.946],
                  ],
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                },
              },
            },
            {
              ty: "st",
              nm: "Stroke 5",
              mn: "{9410f1ac-50b8-4d6c-ae7e-eb1bec003bda}",
              o: { a: 0, k: 100 },
              lc: 2,
              lj: 2,
              ml: 4,
              w: { a: 0, k: 4.904 },
              c: { a: 0, k: [0.063, 0, 0.271] },
            },
            {
              ty: "tm",
              nm: "Trim Path 3",
              mn: "{aedf52ae-0b1b-49ce-b9a6-7264e16555a7}",
              s: {
                a: 1,
                k: [
                  {
                    t: 125,
                    s: [100],
                    h: 0,
                    o: { x: [0], y: [0] },
                    i: { x: [1], y: [1] },
                  },
                  { t: 167, s: [0] },
                ],
              },
              e: { a: 0, k: 100 },
              o: { a: 0, k: 0 },
              m: 1,
            },
            {
              ty: "tr",
              a: { a: 0, k: [128.098, 117.59] },
              p: { a: 0, k: [128.098, 117.59] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
            },
          ],
        },
        {
          ty: "gr",
          nm: "Bb",
          mn: "{3a0461be-a8f9-4277-b138-d277c421f870}",
          it: [
            {
              ty: "sh",
              nm: "Path 3",
              mn: "{a8115820-528a-4fc6-90aa-a58ec7764eba}",
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  v: [
                    [90.502, 126.921],
                    [90.502, 89.778],
                    [100.677, 89.778],
                    [100.677, 123.654],
                  ],
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                },
              },
            },
            {
              ty: "gs",
              nm: "linearGradient6982",
              mn: "{f1cbb436-f94e-4efa-af8d-c4a0ffa7f69d}",
              o: { a: 0, k: 100 },
              lc: 2,
              lj: 2,
              ml: 4,
              w: { a: 0, k: 4.904 },
              s: { a: 0, k: [91.072, 154.872] },
              e: { a: 0, k: [146.502, 97.874] },
              t: 1,
              h: { a: 0, k: 0 },
              a: { a: 0, k: 0 },
              g: {
                p: 2,
                k: {
                  a: 0,
                  k: [0, 0.443, 0.129, 0.847, 1, 0.882, 0.529, 0.976, 0, 1, 1, 1],
                },
              },
            },
            {
              ty: "tm",
              nm: "Trim Path 4",
              mn: "{0a040e91-a601-4df8-96b3-c9da0e7e660c}",
              s: { a: 0, k: 0 },
              e: {
                a: 1,
                k: [
                  {
                    t: 106,
                    s: [0],
                    h: 0,
                    o: { x: [0], y: [0] },
                    i: { x: [1], y: [1] },
                  },
                  { t: 157, s: [100] },
                ],
              },
              o: { a: 0, k: 0 },
              m: 1,
            },
            {
              ty: "tr",
              a: { a: 0, k: [95.59, 108.35] },
              p: { a: 0, k: [95.59, 108.35] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
            },
          ],
        },
      ],
    },
  ],
  meta: { g: "Glaxnimate 0.5.3+deb-4c10136b" },
};

const LoaderIcon = () => {
  const animationDivRef = useRef();
  useEffect(() => {
    // Create the Lottie animation instance
    const animationContainer = animationDivRef.current;
    const animation = lottie.loadAnimation({
      container: animationContainer,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData,
    });

    // Optional: Adjust animation speed
    animation.setSpeed(1.5);

    // Optional: Listen to animation events
    animation.addEventListener("complete", () => {});

    // Cleanup: Destroy the animation on component unmount
    return () => animation.destroy();
  }, []);

  return <div ref={animationDivRef} style={{ width: "112px", height: "112px" }} />;
};

export default LoaderIcon;
