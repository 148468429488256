import { Button, Col, Form, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SearchableSelect from "../SearchableSelect";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const Batch = ({ close, itemId, batchId, refreshHandle }) => {
  const [vendorPurchaseId, setVendorPurchaseId] = useState(-1);
  const [quantity, setQuantity] = useState("");
  const [costPrice, setCostPrice] = useState("");
  const [isCostPriceEditable, setIsCostPriceEditable] = useState(false);
  const [entryDate, setEntryDate] = useState(new Date().toISOString().split("T")[0]);
  const [validated, setValidated] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const shopId = user.shop_id;

  useEffect(() => {
    const handleFetchBatchSubmit = async (id) => {
      try {
        const batchData = await axios.get(process.env.REACT_APP_API_URL + `/batch/${id}`, {
          withCredentials: true,
        });
        const data = batchData?.data;
        setIsCostPriceEditable(data?.in_stock_quantity === data?.purchase_quantity);
        setQuantity(data?.purchase_quantity);
        setCostPrice(data?.cost_price);
        setEntryDate(new Date(data?.entry_date).toISOString().split("T")[0]);
      } catch (error) {
        throw new Error(error);
      }
    };
    if (batchId && batchId !== -1) {
      handleFetchBatchSubmit(batchId);
    } else {
      setQuantity("");
      setCostPrice("");
      setEntryDate(new Date().toISOString().split("T")[0]);
    }
  }, [batchId]);

  const handleBatchSubmit = async (e) => {
    try {
      e.preventDefault();

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
      }
      setValidated(true);
      if (itemId && quantity > 0 && costPrice > 0 && entryDate.length > 0) {
        setIsClicked(true);
        if (batchId && batchId !== -1) {
          // update call
          const res = await axios.patch(
            process.env.REACT_APP_API_URL + `/batch/update-batch/${batchId}`,
            {
              vendor_purchase_id: vendorPurchaseId,
              item_id: itemId,
              purchase_quantity: quantity,
              cost_price: costPrice,
            },
            { withCredentials: true },
          );
          if (res) {
            submitResponseActions(res);
          }
          // add call
        } else {
          const res = await axios.post(
            process.env.REACT_APP_API_URL + `/batch/add-batch`,
            {
              vendor_purchase_id: vendorPurchaseId,
              item_id: itemId,
              purchase_quantity: quantity,
              cost_price: costPrice,
            },
            { withCredentials: true },
          );
          if (res) {
            submitResponseActions(res);
          }
        }
      }
    } catch (error) {
      setIsClicked(true);
      close(true);
      toast.error(`${error.response.data.error.message}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500,
        pauseOnHover: false,
        className: "toastMsg",
      });
      setIsClicked(false);
    }
  };

  const submitResponseActions = (response) => {
    refreshHandle();
    setVendorPurchaseId(-1);
    setCostPrice("");
    setQuantity("");
    setValidated(false);
    toast.success(`${response.data.message}`, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 1500,
      pauseOnHover: false,
      className: "toastMsg",
    });
    close(true);
  };

  const handleEntryDateChange = (e) => {
    setEntryDate(e.target.value);
  };

  const handleClearSubmit = async (e) => {
    if (batchId && batchId !== -1) {
      close(true);
    }
    e.preventDefault();
    setVendorPurchaseId("");
    setCostPrice("");
    setQuantity("");
    setValidated(false);
  };

  return (
    <Form noValidate validated={validated}>
      <div className="d-flex justify-content-between align-items-center px-5">
        <h5>
          <span className="text-capitalize">{batchId && batchId !== -1 ? "Update" : "Add"}</span> Batch
        </h5>
      </div>
      <hr />
      <Form.Group className="mb-3" controlId="Location">
        <SearchableSelect placeholder="No PO" label="Purchase Order" important disabled={true} searchable={false} value={vendorPurchaseId} handleChange={(e) => setVendorPurchaseId(e)} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="mb-1">
          Purchase Quantity<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          className="p-2"
          type="number"
          min={1}
          name="quantity"
          value={quantity}
          placeholder="e.g. 12 Items"
          onChange={(e) => {
            if (e.target.value.length < 11) {
              setQuantity(e.target.value);
            }
          }}
          onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
          required
        />
        <Form.Control.Feedback type="invalid">Please Enter Purchase Quantity.</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="mb-1">
          Cost Price<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          className="p-2"
          type="number"
          min={1}
          name="costPrice"
          value={costPrice}
          disabled={batchId !== -1 && !isCostPriceEditable ? true : false}
          placeholder="e.g. 500"
          onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
          onChange={(e) => {
            if (e.target.value.length < 11) {
              setCostPrice(e.target.value);
            }
          }}
          required
        />
        <Form.Control.Feedback type="invalid">Please Enter Cost Price.</Form.Control.Feedback>
      </Form.Group>
      <Row className="py-3">
        <Col>
          <Button type="clear" className="w-100 " variant="secondary" onClick={handleClearSubmit}>
            Clear
          </Button>
        </Col>
        <Col>
          <Button type="submit" className="w-100" onClick={handleBatchSubmit} disabled={isClicked}>
            {batchId && batchId !== -1 ? "Update" : "Save"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Batch;
