import axios from "axios";
import { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddForm from "../../Components/AddForm";
import SearchableSelect from "../../Components/SearchableSelect";
import Loading from "../../Components/Loading/Loading";
import customloader from "./../../assets/images/RollingLoader.gif";

import { requestDateTime } from "../../Features/dateTime";

import ReactSelect from "../../Components/ReactSelect";
import OpeningRegister from "../../Components/Register/OpeningRegister";
import { useDispatch } from "react-redux";
import { setLocation } from "../../Features/locationSlice";
const PaymentForm = ({ id, refresh, close, openingBalances, purchaseId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  // const registerID = useSelector((state) => state.auth?.registerId);
  const register = useSelector((state) => state.location?.register);
  const registerID = register?.registerId;
  const location = useSelector((state) => state.location?.location);
  const shop_id = user.shop_id;
  const [error, setError] = useState(false);
  const [isloading, setisLoading] = useState(true);
  const [paymentError, setPaymentError] = useState("");

  // const [location, setLocation] = useState(-1);
  const [bank, setBank] = useState(null);
  const [cheque_number, setCheque_number] = useState(null);
  const [registerClosedAlert, setRegisterClosedAlert] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [openingBalance, setOpeningBalance] = useState();
  const [isValidate, setIsValidated] = useState(false);
  const loadBanksOptions = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + `/shops/banks/${shop_id}`, {
          withCredentials: true,
        })
        .then((banksRes) => {
          const banks = banksRes.data.map((bank) => ({
            value: bank,
            label: (
              <>
                <span style={{ fontSize: "12px" }}>
                  Bank Name:{" "}
                  <strong className="fs-12">{bank?.bank_name}</strong>
                </span>
                <br />
                <span style={{ fontSize: "12px" }}>
                  Account Title: {bank?.account_title}
                </span>
                <br />
                <span className="d-block" style={{ fontSize: "12px" }}>
                  Account Number:{bank?.account_number}
                </span>
              </>
            ),
            name: bank.bank_name,
          }));
          resolve(banks);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const handleChangeBank = (e) => {
    setBank({ value: e.value, label: e.name });
  };

  useEffect(() => {
    const pageTimeout = setTimeout(() => {
      if (purchaseId && purchaseId !== -1) {
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "/purchase-order/get-payments/" +
              purchaseId,
            { shopId: shop_id, vendorId: id },
            { withCredentials: true }
          )
          .then((res) => {
            if (res?.data?.code === 200) {
              setError(false);
              setDueAmount(res?.data?.success?.data?.purchase?.due_amount);
              // setLocation(res?.data?.success?.data?.purchase?.location_id);
              setisLoading(false);
            } else {
              setPaymentError(res.data.error.message);
              setisLoading(false);
              setError(true);
            }
          })
          .catch((err) => {
            setisLoading(false);
            setError(true);
            throw new Error(err);
          });
      }
    }, 1000);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [id, purchaseId, shop_id]);
  //validation
  const [validated, setValidated] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [modalError, setModalError] = useState("");

  //states
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [dueAmount, setDueAmount] = useState("");
  const [paymentDate, setPaymentDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [note, setNote] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [cashInHand, setCashInHand] = useState();

  useEffect(() => {
    // setLoading(true);
    const cashInHand = () => {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            `/reports/cash-in-hand/${user?.user_id}`,
          {
            dates: {
              from: register?.openingBalance?.opening_balance_time
                .split(".")[0]
                .replace("T", " "),
              to: register?.openingBalance?.closing_balance_time
                ? register?.openingBalance?.closing_balance_time
                    .split(".")[0]
                    .replace("T", " ")
                : null,
            },
            locationFields: location,
            openingBalance: register?.openingBalance?.opening_balance_id,
          },
          { withCredentials: true }
        )
        .then((res) => {
          let n = res?.data?.length;
          setCashInHand(res.data[n - 1]);
          // setIsLoading(false);
          // setLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(location).length > 0 && register?.openingBalance)
      cashInHand();
  }, [location, register?.openingBalance]);
  //onchange handles
  const handlePaymentAmoundChange = (e) => {
    let num = parseInt(e.target.value, 10);
    setPaymentAmount(num);
    // const ob = openingBalances.filter((ob) => ob.location_id === location)[0];
    if (paymentType === "Cash" ? register.isCashRegisterOpen : true) {
      if (
        paymentType === "Cash" ? cashInHand.cash_in_hand < Number(num) : false
      ) {
        setRegisterClosedAlert(false);
        setErrorMessage("Amount is more then the Cash in Hand!");
      } else {
        if (num > 0 && num <= dueAmount) {
          setErrorMessage("");
        } else {
          setErrorMessage(
            "Payment Amount must be positive and less then or equal to remaining amount."
          );
        }
      }
    } else {
      setRegisterClosedAlert(true);
      setErrorMessage("Register is not Open yet!");
    }
  };
  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.value);
  };
  const handlePaymentDateChange = (e) => {
    setPaymentDate(e.target.value);
  };
  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  //submit method
  const handlePaymentSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);
    // const ob = openingBalances.filter((ob) => ob.location_id === location)[0];
    if (paymentType === "Cash" ? register.isCashRegisterOpen : true) {
      setModalError("");
      if (
        paymentType === "Cash"
          ? cashInHand.cash_in_hand >= Number(paymentAmount)
          : true
      ) {
        if (
          paymentAmount > 0 &&
          paymentAmount <= dueAmount &&
          paymentType?.length > 0
        ) {
          const payment = {
            payment_amount: paymentAmount,
            payment_type: paymentType,
            bank_id: paymentType === "Cash" ? -1 : bank.value.bank_id,
            cheque_number: cheque_number,
            note: note,
            payment_date:
              paymentDate +
              " " +
              new Date().getHours() +
              ":" +
              new Date().getMinutes(),
            vendor_purchase_id: purchaseId,
          };
          if (purchaseId && purchaseId !== -1) {
            setIsClicked(true);

            axios
              .post(
                process.env.REACT_APP_API_URL + "/purchase-order/payment/add",
                payment,
                { withCredentials: true }
              )
              .then(() => {
                refresh((prev) => !prev);
                navigate("/purchase-order");
                setValidated(false);
                close();
                setPaymentAmount("");
                setPaymentType("");
                setDueAmount("");
                setPaymentDate(null);
                setNote("");
              })
              .catch((err) => {
                setIsClicked(false);

                throw new Error(err);
              });
          }
        }
      } else {
        setRegisterClosedAlert(false);
        setModalError("Amount is more than the Cash in Hand!");
      }
    } else {
      setRegisterClosedAlert(true);
      setModalError("Register is not set!");
    }
  };
  const handleChequeNumberChange = (e) => {
    if (e.target.value.length < 20) {
      setCheque_number(e.target.value);
    }
  };
  const handleCashOpeningBalance = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setIsValidated(true);
    try {
      if (registerID === -1) {
        setError("Register is not Set!");
      } else {
        if (openingBalance >= 0) {
          const res = await axios.post(
            process.env.REACT_APP_API_URL + `/cash-registers/opening-balance`,
            { register_id: registerID, opening_balance: openingBalance },
            { withCredentials: true }
          );
          if (res) {
            dispatch(setLocation({ shop_id, location: location }));
            setRegisterClosedAlert(false);
            setModalError("");
            refresh();
            setIsValidated(false);
            setOpeningBalance();
            setTimeout(() => setOpenRegisterModal(false), 1000);
          }
        }
      }
    } catch (error) {
      setError(error);
    }
  };
  const currentMonth = new Date().toDateString().split(" ")[1];
  const currentDate =
    new Date().getDate() + " " + currentMonth + ", " + new Date().getFullYear();
  const today_sh = new Date();
  const currentTime = today_sh.getHours();
  let showtime;
  if (currentTime < 12) {
    showtime = "Good Morning";
  } else if (currentTime < 18) {
    showtime = "Good Afternoon";
  } else {
    showtime = "Good Evening";
  }

  return (
    <>
      {isloading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "40vh" }}
        >
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : error === true ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <h1>{paymentError}</h1>
        </div>
      ) : (
        <Form
          noValidate
          validated={validated}
          onSubmit={handlePaymentSubmit}
          className="px-5"
        >
          {registerClosedAlert ? (
            <div
              className="alert alert-warning border-dotted"
              style={{ border: "2px dotted #8834ff" }}
            >
              <div className="d-flex justify-content-between align-items-center">
                *{modalError}
                <button
                  onClick={() => {
                    setOpenRegisterModal(true);
                  }}
                  className="btn btn-primary"
                >
                  Open Register
                </button>
              </div>
            </div>
          ) : modalError ? (
            <div
              className="alert alert-warning border-dotted"
              style={{ border: "2px dotted #8834ff" }}
            >
              *{modalError}
            </div>
          ) : (
            ""
          )}
          {/* <div className="text-center">
            {modalError && (
              <span className="text-danger p-2 text-center my-2 rounded-1">
                *{modalError}
              </span>
            )}
          </div> */}
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">
              Due Amount<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              value={dueAmount}
              disabled
              onChange={() => {}}
              className="p-2"
              type="number"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">
              Payment Amount<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              value={paymentAmount}
              onChange={handlePaymentAmoundChange}
              className="p-2"
              type="number"
              placeholder="Rs. 1000"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Payment Amount.
            </Form.Control.Feedback>
          </Form.Group>
          {errorMessage.length > 0 ? (
            <div className="alert alert-warning" role="alert">
              {errorMessage}
            </div>
          ) : (
            ""
          )}
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">
              Payment Date<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              value={paymentDate}
              onChange={handlePaymentDateChange}
              className="p-2"
              type="date"
              placeholder=""
              required
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Payment Date.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <SearchableSelect
              placeholder="Select"
              label="Payment Type"
              important
              searchable={false}
              options={[
                { value: "Transfer", label: "Transfer" },
                { value: "Cash", label: "Cash" },
                { value: "Cheque", label: "Cheque" },
              ]}
              handleChange={handlePaymentTypeChange}
            />
            <Form.Control
              hidden
              value={paymentType}
              onChange={() => {}}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please Select Payment Type.
            </Form.Control.Feedback>
          </Form.Group>
          {paymentType === "Cash" || paymentType === "" ? (
            ""
          ) : (
            <>
              {paymentType === "Cheque" ? (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1">
                      Select Bank <span className="text-danger">*</span>
                    </Form.Label>
                    <ReactSelect
                      placeholder="Select"
                      value={bank}
                      options={loadBanksOptions}
                      handleChange={handleChangeBank}
                      searchable={false}
                    />
                    <Form.Control
                      value={bank}
                      onChange={() => {}}
                      hidden
                      required={
                        paymentType === "Transfer" || paymentType === "Cheque"
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Select Bank.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="mb-0">Cheque Number</Form.Label>
                    <Form.Control
                      type="text"
                      step={1}
                      value={cheque_number}
                      onChange={handleChequeNumberChange}
                      placeholder="Cheque Number"
                      // required
                    />
                  </Form.Group>
                </>
              ) : (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1">
                      Select Bank <span className="text-danger">*</span>
                    </Form.Label>
                    <ReactSelect
                      placeholder="Select"
                      value={bank}
                      options={loadBanksOptions}
                      handleChange={handleChangeBank}
                      searchable={false}
                    />
                    <Form.Control
                      value={bank}
                      onChange={() => {}}
                      hidden
                      required={
                        paymentType === "Card" || paymentType === "Transfer"
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Select Bank.
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
            </>
          )}
          <Form.Group className="mb-3" controlId="purchaseDetail_PurchaseForm">
            <Form.Label className="mb-1">Note</Form.Label>
            <Form.Control
              value={note}
              onChange={handleNoteChange}
              className="p-2"
              as="textarea"
              maxLength={"200"}
              placeholder="Comments"
              rows={3}
            />
          </Form.Group>
          <Button
            className="w-100"
            onClick={handlePaymentSubmit}
            disabled={isClicked}
          >
            Submit
          </Button>
        </Form>
      )}
      <OpeningRegister
        cashRegisterModal={openRegisterModal}
        setCashRegisterModal={setOpenRegisterModal}
        validated={isValidate}
        setIsValidate={setIsValidated}
        handleCashOpeningBalance={handleCashOpeningBalance}
        showtime={showtime}
        error={error}
        openingBalance={openingBalance}
        setOpeningBalance={setOpeningBalance}
      />
    </>
  );
};

export default PaymentForm;
