import axios from "axios";
import { useEffect, useState } from "react";
import { FormControl, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import NoResult from "../../Components/NoResult";
import PaginationBar from "../../Components/PaginationBar";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import search from "../../assets/svg/search.svg";
import eyeIcon from "../../assets/svg/union.svg";
import customloader from "../../assets/images/RollingLoader.gif";
import LoaderPage from "../../Components/loaders/loaderPage";
import { data } from "jquery";

const CustomerTradeIn = ({ show, close, id }) => {
  const [tradesData, setTradesData] = useState([]);
  const [totalTrades, setTotalTrades] = useState(0);
  const [isTradesLoading, setIsTradesLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });
  const handleCloseModal = () => {
    close();
  };
  const changePage = (page) => {
    setCurrentPage(page);
  };
  const filters = { orderBy };
  const headers = {
    filters: JSON.stringify(filters),
  };
  useEffect(() => {
    const getCustomerTrades = async () => {
      try {
        setIsTradesLoading(true);
        setTradesData([]);
        const responseData = await axios.get(process.env.REACT_APP_API_URL + `/trade-in/customer/${id}?page=` + currentPage + `&size=` + sizeOfPages, { headers, withCredentials: true });
        if (responseData) {
          setIsTradesLoading(false);
          setTradesData(responseData.data.data);
          setNoOfPages(responseData.data.pagination.no_of_pages);
          setTotalTrades(responseData.data.pagination.no_of_items);
        }
      } catch (error) {
        setIsTradesLoading(true);
        setTradesData([]);
        setIsTradesLoading(false);
      }
    };
    if (id !== -1) {
      getCustomerTrades();
    }
  }, [id, user, currentPage, search, sizeOfPages, noOfPages, orderBy]);
  const InvoicePayments = async (id) => {
    window.open(`/trade-in/` + id, "_blank", "noopener,noreferrer");
  };
  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" centered size="xl" backdrop="static" className="customerSalesModal" show={show} onHide={handleCloseModal}>
      <Modal.Header className="border-0 pt-4" closeButton>
        <div className="d-flex align-items-center gap-4">
          <h4>Customer Trade-in</h4>
        </div>
      </Modal.Header>
      <hr className="m-0 mb-3 d-sm-none d-block" />
      {isTradesLoading ? (
        <div style={{ height: "30vh", display: "flex", justifyContent: "center" }}>
          <LoaderPage />
        </div>
      ) : tradesData.length > 0 ? (
        <div
          style={{
            maxHeight: "70vh",
            overflow: "auto",
            display: "block",
          }}
          className="mb-4"
        >
          <CustomizeTableview className={"customerSalesModal mb-2 mt-3"} data={tradesData} columnNotShow={["id", "title"]} list={tradesData} dateColumn={["created_at"]} sortColumn={["created_at"]} orderBy={orderBy} setOrderBy={setOrderBy} view={InvoicePayments} />
          <div>
            <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
          </div>
        </div>
      ) : (
        <div className="mb-4">
          <NoResult height="auto" onClick={() => navigate("/trade-in/products")} name="Trades-in Products" />
        </div>
      )}
    </Modal>
  );
};

export default CustomerTradeIn;
