import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const ResetPasswordTour = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const resetPasswordTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "ResetPassword", value: true } }));
      resetPasswordTour.destroy();
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Reset-Password",
        popover: {
          title: "Reset Password",
          description: "Allows users to reset their password.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#reset-password",
        popover: {
          title: `Manage Shop Password`,
          description: "Allows sellers to reset their passwords in the shops",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "ResetPassword", value: true } }));
            resetPasswordTour.destroy();
          },
        },
      },
    ],
  });
  useEffect(() => {
    resetPasswordTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default ResetPasswordTour;
