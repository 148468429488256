import React, { useEffect } from "react";
import customloader from "../assets/images/RollingLoader.gif";
import { useSearchParams, Navigate } from "react-router-dom";
import { currentUserForAdmin, authActions } from "../Features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import RedirectComponent from "../RedirectComponent";

function AdminLogin({ token }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(authActions.logout());
      localStorage.removeItem("refreshToken");
    }
    dispatch(currentUserForAdmin("bearer " + token));
  }, [token]);

  const { user } = useSelector((state) => state.auth);

  return (
    <>
      {!user ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
}

export default AdminLogin;
