import style from "./../../styles/Sidebar.module.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useSelector } from "react-redux";
import crown from "../../assets/images/crown.png";
import comingSoon from "../../assets/images/comingsoon.png";
const NavOption = ({
  setIsSandwichOpen,
  isSandwichOpen,
  title,
  icon,
  activeIcon,
  path,
  navPath,
  identifier,
  isSubNav,
  active,
  setActive,
  activeParent,
  parentIdentifier,
  setActiveParent,
  activeCaret,
  setActiveCaret,
  subnavigation,
  isOpen,
  setIsOpen,
  Included,
  navigation,
  alwaysGenerateRoute,
  isComing,
}) => {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  return (
    // <div className={`${style.Sidebartooltip} `}>
    <>
      {navigation === false ? (
        ""
      ) : (
        <div
          style={
            location.pathname === navPath
              ? {
                  background: " #f8f6ff ",
                  boxShadow: " 0 0 15px rgb(0 0 0 / 0%)",
                  color: "rgba(89, 50, 234, 1) !important",
                  textDecoration: "none",
                }
              : !subnavigation && activeParent === identifier
              ? {
                  background: " #f8f6ff ",
                  boxShadow: " 0 0 15px rgb(0 0 0 / 0%)",
                  textDecoration: "none",
                }
              : { color: "#545e6f", color: "#39414A", textDecoration: "none" }
            // : { opacity: "0.6", animationFillMode: "none" }
          }
          id={`tooltip-anchor-${title.replaceAll(" ", "-")}`}
          className={` mt-1 ${subnavigation ? (!isSandwichOpen ? style.sublink : style.sublinkClose) : style.sideLink} ${subnavigation ? style.subanimate : ""} d-flex  justify-content-between align-items-center`}
        >
          {user?.gracePeriodEnd === 1 ? (
            <div className={`${style.subSideLink}  my-1 d-block mt-2 `}>
              <div className={`d-flex   ${subnavigation && " "} ${isSandwichOpen ? "justify-content-center" : "gap-2 justify-content-between"} align-items-center`}>
                <div className="d-flex  align-items-center gap-2">
                  {active === navPath || activeParent === identifier ? activeIcon : icon}
                  <span className={`${active === navPath || activeParent === identifier ? style.activeColor : ""}`}> {!isSandwichOpen && title}</span>
                </div>
              </div>
              {/* {isSandwichOpen && <span className={style.tooltipClose}>{title}</span>} */}
              {isSandwichOpen && <Tooltip anchorId={`tooltip-anchor-${title.replaceAll(" ", "-")}`} content={`${title}`} place="right" className={`${style.reactTooltip}`} />}
            </div>
          ) : (
            <NavLink
              style={(Included === "true" && !isComing) || alwaysGenerateRoute ? {} : { opacity: "0.6" }}
              to={(Included === "true" && !isComing) || alwaysGenerateRoute ? path : !isComing ? "/settings/plans" : "#"}
              className={`${style.subSideLink}  my-1 d-block mt-2 `}
              onClick={() => {
                if ((Included === "true" && !isComing) || alwaysGenerateRoute) {
                  if (window.innerWidth < 820) {
                    setIsSandwichOpen((prev) => !prev);
                  }

                  setIsOpen(true);
                  setActive(navPath);
                  if (subnavigation) {
                    setActiveCaret(parentIdentifier);
                    setActiveParent(parentIdentifier);
                  } else {
                    setActiveParent(identifier);
                    setActiveCaret(identifier);
                  }
                } else {
                  setActive("/settings/plans");
                }
              }}
            >
              <div
                className={`d-flex  
          
          ${subnavigation && " "} ${isSandwichOpen ? "justify-content-center" : "gap-2 justify-content-between"} align-items-center`}
              >
                <div className={`d-flex  align-items-center gap-2  ${!isSandwichOpen ? "w-100" : ""} `}>
                  {active === navPath || activeParent === identifier ? activeIcon : icon}
                  <span className={`${active === navPath || activeParent === identifier ? style.activeColor : ""} d-flex justify-content-between w-100  `}>
                    {" "}
                    <span> {!isSandwichOpen && title}</span> {isComing && !isSandwichOpen ? <img src={comingSoon} height="25" width="25" /> : Included !== "true" && !alwaysGenerateRoute && !isSandwichOpen && <img src={crown} height="20" width="20" />}{" "}
                  </span>
                </div>
              </div>
              {/* {isSandwichOpen && <span className={style.tooltipClose}>{title}</span>} */}
            </NavLink>
          )}
          {(isSandwichOpen ? true : (Included === "false" && !alwaysGenerateRoute) || isComing) && (
            <Tooltip
              anchorId={`tooltip-anchor-${title}`}
              content={`${(Included === "true" && !isComing) || alwaysGenerateRoute ? title : isComing ? "Coming Soon" : "Upgrade for " + title}`}
              place="right"
              className={`${(Included === "true" && !isComing) || alwaysGenerateRoute ? style.reactTooltip : isComing ? style.reactTooltipInComing : style.reactTooltipNoIncluded}  `}
            />
          )}
          <div
            style={(Included === "true" && !isComing) || alwaysGenerateRoute ? {} : { opacity: "0.6" }}
            className={`p-2 ${isSandwichOpen && "d-none"}`}
            onClick={() => {
              if ((Included === "true" && !isComing) || alwaysGenerateRoute) {
                setActiveCaret(identifier);
                if (isOpen) {
                  setIsOpen(false);
                } else {
                  setIsOpen(true);
                }
                if (activeCaret !== identifier) {
                  setIsOpen(true);
                }
              }
            }}
          >
            {!isSandwichOpen && isSubNav && <FontAwesomeIcon icon={activeCaret === identifier && isOpen ? faChevronUp : faChevronDown} />}
          </div>
        </div>
      )}
    </>
  );
};

export default NavOption;
