import React, { useState } from "react";
import style from "../ListingAddItem/listingAddItem.module.css";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import CustomizeTableview from "../../TableViews/CustomizeTableview";
import ListingHeader from "../ListingHeader/ListingHeader";
import NoResult from "../../NoResult";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import LoaderPage from "../../loaders/loaderPage";
import LoaderOverlay from "../../loaders/loaderOverlay";
import { toast } from "react-toastify";
import PaginationBar from "../../PaginationBar";

function FeatureditemsModal({ matchedField, show = false, setDetectChange, onHide, SectionConfigs, sections, secId, setSections, size, customize, userSelectedTheme }) {
  const { user } = useSelector((state) => state.auth);
  const userId = user.user_id;
  const [items, setItems] = useState([]);
  const [noOfItems, setNoOfItems] = useState(0);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // console.log(sections,'items');
  const changePage = (page) => {
    setCurrentPage(page);
  };
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchField, setSearchField] = useState(null);
  const [category, setCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });

  useEffect(() => {
    // setSelectedItems(
    //   sections?.[secId]?.sectionItems.map((item) => {
    //     const { listing_id, ...rest } = item;
    //     return { id: listing_id, ...rest };
    //   }) || []
    // );

    return () => {
      setSelectedItems([]);
    };
  }, [sections?.[secId]?.sectionItems]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const paramsObj = {
          page: currentPage,
          size: sizeOfPages,
          search: searchField || "",
          ...(category !== null && { category: category.value }),
          orderBy: orderBy,
          active: true, // Set your orderBy parameters here
        };

        paramsObj.orderBy = new URLSearchParams(orderBy).toString();
        const searchParams = new URLSearchParams(paramsObj).toString();
        const response = await axios.get(process.env.REACT_APP_API_URL + `/listings?${searchParams}`, {
          withCredentials: true,
        });
        setItems(response?.data?.success?.data);
        setNoOfItems(response?.data?.success?.count?.no_of_items);
        setNoOfPages(response?.data?.success?.count?.no_of_pages);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    if (userId && show) {
      setIsLoading(true);
      fetchItems();
    }
  }, [userId, show, searchField, currentPage, sizeOfPages, category, orderBy]);

  const onClose = () => {
    setSelectedItems([]);
    onHide();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedItems.length > matchedField?.MaxpProducsPerSections) {
      toast.error(`Please select maximum of ${matchedField?.MaxpProducsPerSections} items`);
    } else {
      let values = [...sections];
      // Get the existing sectionItems array or create an empty array if it doesn't exist
      let existingItems = values[secId].sectionItems || [];
      // Combine the existing items with the selected items
      let newItems = [
        ...existingItems,
        ...selectedItems.map((item) => ({
          listing_id: item.id,
          title: item.title,
          price: item.online_price,
          discount: item.online_discount,
          image: item.image,
          effective_price: item.effective_price,
        })),
      ];
      // Update the sectionItems array in the values array
      values[secId].sectionItems = newItems;
      setDetectChange(false);
      // setSections(values);
      setSelectedItems([]);
      onHide();
    }
  };

  return (
    <>
      <LoaderOverlay loading={isSubmitting} />

      <Modal show={show} onHide={onHide} size={"lg"} centered dialogClassName={`${customize ? style.customizeModal : style.modalDialog}`} fullscreen="lg-down">
        <Modal.Header closeButton>
          <Modal.Title>Add Listings</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`p-0 overflow-hidden `}>
          <div className={`pt-2 p-sm-4`} style={{ borderBottom: "1px solid #DEDFE4" }}>
            <ListingHeader hideButton={true} hideTitle={true} hideShowing={true} availableItems={noOfItems} setSearchText={setSearchField} handleCategoryFilter={setCategory} />
          </div>
          <div className={`${style.tableContainer} overflow-auto`}>
            {isLoading ? (
              <LoaderPage />
            ) : noOfItems === 0 || items.length === 0 ? (
              <NoResult name="Inventory" />
            ) : (
              <CustomizeTableview
                data={items}
                columnNotShow={["id", "category_id", "listed_quantity", "created_at", "updated_at", "brand_id", "archived_on", "activation_date", "no_of_lead", "no_of_views", "product_url", "sale_price", "status", "stock_quantity", "item_id", "is_deleted", "image", "online_discount_unit"]}
                thumbnail={{
                  title: "title",
                  img: "image",
                  description: "title",
                }}
                checkbox={{ data: selectedItems, setter: setSelectedItems }}
                actions={false}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className={`d-flex flex-column gap-1 p-4`}>
          <div className={`d-flex gap-1 align-item-end justify-content-between w-100`}>
            <span
              className=""
              style={{
                color: "#39414A",
                textAlign: "center",
                paddingTop: "14px",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
            >
              <FontAwesomeIcon icon={faCheck} className={`me-2`} />
              {selectedItems?.length} Items Selected
            </span>
            {noOfItems > 0 ? <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} /> : ""}
          </div>

          <Button className={`w-100`} onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FeatureditemsModal;
