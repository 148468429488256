import { Form } from "react-bootstrap";
import InputMask from "react-input-mask";

const PhoneInput = ({
  placeholder,
  value,
  onChange,
  required,
  className,
  isInvalid,
}) => {
  return (
    <InputMask mask="+\92-399-9999999" value={value} onChange={onChange}>
      {(inputProps) => (
        <Form.Control
          {...inputProps}
          className={className}
          placeholder={placeholder}
          type="tel"
          required={required}
          isInvalid={isInvalid}
        />
      )}
    </InputMask>
  );
};

export default PhoneInput;
