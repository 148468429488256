import React from "react";
import { Card } from "react-bootstrap";
import { SearchMobleInput } from "./SearchInput";
import arrow from "../assets/images/rigthArrow.png";
function SearchMobile({
  toggleSearch,
  data,
  searchResult,
  styles,
  searchlogo,
  bordered,
  value,
  openSearchBar,
  handleChange,
  handleResultClick,
  saleNumber = null,
  searchTitle = null,
  date = null,
  showArrow = true,
}) {
  return (
    <>
      <div className="d-lg-none d-md-inline d-inline">
        <Card className="border-0">
          <Card.Header>
            <div className="d-flex  mb-2 justify-content-center align-items-center">
              <div className="">
                {" "}
                <SearchMobleInput
                  searchlogo
                  value={value}
                  handleChange={handleChange}
                />
              </div>
              <span className="ms-4" onClick={toggleSearch}>
                X
              </span>
            </div>
          </Card.Header>
          <Card.Body>
            {data.map((data, index) => (
              <>
                {" "}
                <div
                  role="button"
                  onClick={searchResult}
                  key={index}
                  className="d-flex pb-3 pt-1 border-bottom justify-content-between align-items-center corsur"
                >
                  <div className="d-flex flex-column">
                    <strong>{data[searchTitle]}</strong>
                    {saleNumber && <strong>{data[saleNumber]}</strong>}
                    {date && (
                      <strong>{new Date(data[date]).toDateString()}</strong>
                    )}
                    {/* <span>Stock number: {data.stock_no}</span> */}
                  </div>
                  {showArrow && (
                    <span>
                      <img alt="arrow" src={arrow} />
                    </span>
                  )}
                </div>
              </>
            ))}
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default SearchMobile;
