import { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { handleImeiNumber } from "../../Features/cart/cartSlice";
import styles from "../../styles/ImeiForm.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const IMEIForm = ({ show, close, id, setId }) => {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [isClicked, setIsClicked] = useState(false);
  const [IMEICheck, setIMEICheck] = useState(false);

  const [imeis, setImeis] = useState([]);
  const [input, setInput] = useState("");

  useEffect(() => {
    const filteredItem = cart.cartItems.filter((item) => item.id === id);
    if (filteredItem.length > 0) {
      if (filteredItem[0]?.imeis?.length > 0) {
        setImeis(filteredItem[0]?.imeis);
      } else {
        setImeis([]);
      }
    }
  }, [cart, id, show]);

  const handleChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  function getRandomColor() {
    const tagColors = ["rgba(149, 55, 255, 0.15)", "rgba(217, 87, 249, 0.15)", "rgba(255, 209, 102, 0.15)", "rgba(6, 214, 160, 0.15)", "rgba(17, 138, 178, 0.15)", "rgba(7, 59, 76, 0.15)"];

    const randomIndex = Math.floor(Math.random() * tagColors.length);
    return tagColors[randomIndex];
  }

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();
    if (key === "Enter" && trimmedInput.length && !imeis.includes(trimmedInput)) {
      setIsClicked(false);
      e.preventDefault();
      if (input.length > 14) {
        const randomColor = getRandomColor();
        setImeis((prevState) => [...prevState, { imeiNumber: trimmedInput, color: randomColor }]);
        setInput("");
        setIMEICheck(false);
      } else {
        setIMEICheck(true);
      }
    }
  };
  const deleteImei = (index) => {
    setImeis((prevState) => prevState.filter((tag, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsClicked(true);
    let data = { id, imeis: imeis };
    dispatch(handleImeiNumber(data));
    close();
    setId(-1);
    setImeis([]);
    setIsClicked(false);
  };

  return (
    <Modal aria-labelledby="contained-modal-title-vcenter" centered size="md" backdrop="static" show={show} onHide={close}>
      <Modal.Header className="border-0 pt-4" closeButton>
        <h4>Add IMEI</h4>
      </Modal.Header>
      <hr className="m-0 mb-2" />
      <Modal.Body className="mx-4 mb-4">
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Add IMEI</Form.Label>
            <div className={styles.tagArea}>
              <ul>
                {imeis?.map((imei, index) => (
                  <li style={{ background: imei.color, borderColor: imei.color }} className={styles.tag} key={index}>
                    {imei.imeiNumber}
                    <button type="button" onClick={() => deleteImei(index)}>
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </li>
                ))}
                <input
                  placeholder="Add Imei"
                  type="text"
                  value={input}
                  maxLength="15"
                  pattern="[0-9\-]*"
                  title="Please enter 15 digits"
                  className={styles.tagInput}
                  onInput={(event) => {
                    // Remove any non-numeric characters
                    event.target.value = event.target.value.replace(/[^0-9\-]/g, "").slice(0, 15);
                  }}
                  id="tag-input"
                  onKeyDown={onKeyDown}
                  onChange={handleChange}
                />
              </ul>
            </div>
            {IMEICheck && <span className="text-danger  "> IMEI should contain 15 digits</span>}
          </Form.Group>
          <Button type="submit" disabled={isClicked} className="w-100 mt-2">
            Save
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default IMEIForm;
