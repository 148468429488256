import { useRef, useCallback } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'react-bootstrap'
import InvoicePaymentSales from './InvoicePaymentSales';
import './../../../styles/printLayout.css'
import ThermalInvoicePaymentSales from './ThermalInvoicePaymentSales';
import { useSelector } from 'react-redux';

const InvoicePaymentSalesInvoice = () => {
    const componentRef = useRef(null);
    const thermalcomponentRef = useRef(null);
    const { printerType } = useSelector((state) => state.printerType);
    const reactToPrintContent = useCallback(() => {
        return componentRef.current;
    }, []);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent
    });

    const reactToPrintThermalContent = useCallback(() => {
        return thermalcomponentRef.current;
    }, []);

    const handleThermalPrint = useReactToPrint({
        content: reactToPrintThermalContent
    });

    return (<>
        {
            printerType?.value === "thermal" ?
                <div className={`my-4 d-flex justify-content-center gap-3`} >
                    <Button variant="outline-primary" className='px-4' onClick={handleThermalPrint}>Print </Button>
                </div>
                :
                <div className={`my-4 d-flex justify-content-center gap-3`} >
                    <Button variant="outline-primary" className='px-4' onClick={handlePrint}>Print </Button>
                </div>
        }
        <div className='printComponent'>
            {
                printerType?.value === "thermal" ? (
                    <ThermalInvoicePaymentSales ref={thermalcomponentRef} />
                ) : (
                    <InvoicePaymentSales ref={componentRef} />
                )
            }
        </div>
    </>);
}

export default InvoicePaymentSalesInvoice;