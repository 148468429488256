import React, { useEffect } from "react";
import { driver } from "driver.js";
import style from "../styles/Sidebar.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateTourGuide } from "../Features/authSlice";

const DashboardTour = ({ setSeletedCategory }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const dashboardTour = driver({
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Dashboard", value: true } }));
      setTimeout(() => {
        dashboardTour.destroy();
      });
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        popover: {
          title: `Hello, ${user.user_full_name}`, // Title for the popover box
          description: "A friendly greeting personalised for you.", // Description for the popover box
          side: "right",
          align: "start",
        },
      },

      {
        element: "#topbar_tour",
        popover: {
          title: "Topbar",
          description: "Topbar is a navigation bar that appears at the top of the screen.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#topbar_hamburger",
        popover: {
          title: `Hamburger Menu`,
          description: "Opens or closes the navigation menu.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#topbar_location",
        popover: {
          title: "Location",
          description: " Shows the shop's location.",
          side: "left",
          align: "center",
        },
      },
      {
        element: "#topbar_register_button",
        popover: {
          title: "Register Button",
          description: "Allows you to open or close the cash register for managing in-store transactions.",
          side: "bottom",
          align: "end",
        },
      },
      {
        element: ".topbar_cart_icon",
        popover: {
          title: "Cart",
          description: "Open cart while on every page except sales page.",
          side: "bottom",
          align: "end",
        },
      },
      {
        element: "#topbar_notification_bell",
        popover: {
          title: "Notification Bell",
          description: "Shows the total number of notifications received across all projects.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#topbar_user_thumbnail",
        popover: {
          title: "Profile Icon",
          description: "Allows you to access your shop details and log out.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#sidebar_intro",
        popover: {
          title: "Sidebar",
          description: "The sidebar is your main navigation tool. From here, you can access different sections of the website with ease.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#sidebar_logo",
        popover: {
          title: "Shop LOGO",
          description: "You can change your shop LOGO from here.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sidebar_navigation_menu",
        popover: {
          title: "Navigation Menu",
          description: "Easily navigate through the key sections of TechBazaar.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#tooltip-anchor-Dashboard",
        popover: {
          title: "Dashboard",
          description: "Overview of your shop.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#dashboard_overview_intro",
        popover: {
          title: `Dashboard Overview`, // Title for the popover box
          description: "A complete overview of your shop.", // Description for the popover box
          side: "right",
          align: "start",
        },
      },

      {
        element: "#dashboard_overview_Products",
        popover: {
          title: "Products:",
          description: "Shows the number of products currently in stock.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#dashboard_overview_Customers",
        popover: {
          title: "Customers",
          description: "Shows the total number of customers.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#dashboard_overview_Sales",
        popover: {
          title: "Sales",
          description: "Shows the total sales made so far.",
          side: "left",
          align: "center",
        },
      },
      {
        element: "#dashboard_overview_Expenses",
        popover: {
          title: "Expenses",
          description: "Shows the total expenses incurred.",
          side: "left",
          align: "center",
        },
      },
      {
        element: "#dashboard_average_sales",
        popover: {
          title: "Weekly Average Sales",
          description: `A graph that shows your weekly sales trends.`,
          side: "left",
          align: "start",
          onNextClick: () => {
            setSeletedCategory("monthly");
            dashboardTour.moveNext(); // Manually advance the tour
          },
        },
      },
      {
        element: "#dashboard_average_sales",
        popover: {
          title: "Monthly Average Sales",
          description: `A graph that shows your monthly sales trends.`,
          side: "left",
          align: "center",
          onNextClick: () => {
            setSeletedCategory("yearly");
            dashboardTour.moveNext(); // Manually advance the tour
          },
          onPrevClick: () => {
            setSeletedCategory("weekly");
            dashboardTour.movePrevious();
          },
        },
      },
      {
        element: "#dashboard_average_sales",
        popover: {
          title: "Yearly Average Sales",
          description: `A graph that shows your yearly sales trends.`,
          side: "left",
          align: "end",
          onPrevClick: () => {
            setSeletedCategory("monthly");
            dashboardTour.movePrevious(); // Manually advance the tour
          },
        },
      },
      {
        element: "#dashboard_best_selling_products",
        popover: {
          title: "Top 10 Best-Selling Products",
          description: `A list of your Top 10 Best-Selling Products.<br/>Use this section to identify your store’s most popular items.`,
          side: "left",
          align: "start",
          onPrevClick: () => {
            setSeletedCategory("yearly");
            dashboardTour.movePrevious(); // Manually advance the tour
          },
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Dashboard", value: true } }));
            dashboardTour.destroy();
          },
        },
      },
    ],
  });

  useEffect(() => {
    dashboardTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return (
    <div className="position-fixed vh-100 vw-100 start-0 top-0">
      <div id="dashboard_overview_intro" className={`${style.content}`}></div>
    </div>
  );
};

export default DashboardTour;
