import { Button, Form } from "react-bootstrap";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.css";

const Markdown = ({ value, onInit, onEditorChange, important = false, isInvalid, autoGenerated }) => {
  const Size = Quill.import("attributors/style/size");
  Size.whitelist = ["8px", "10px", "12px", "14px", "18px", "24px", "36px"];
  Quill.register(Size, true);
  return (
    <>
      {autoGenerated ? (
        <Form.Label className="mb-1 d-flex justify-content-between w-100 align-items-end">
          <span>Description{important ? <span style={{ color: "red" }}>*</span> : ""}</span>
          <Button variant="success" onClick={autoGenerated}>
            Generate with AI ✨
          </Button>
        </Form.Label>
      ) : (
        <Form.Label>Description{important ? <span style={{ color: "red" }}>*</span> : ""}</Form.Label>
      )}

      <div style={isInvalid ? { border: "1px solid red", borderRadius: "8px", marginBottom: "10px" } : { marginBottom: "10px" }}>
        <ReactQuill
          placeholder="Write product details"
          theme="snow"
          modules={{
            toolbar: {
              container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ["bold", "italic", "underline", "strike", "blockquote", "code-block", { script: "sub" }, { script: "super" }, "link"],
                [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                [{ align: "center" }, { align: "" }, { align: "right" }, { align: "justify" }],
                [{ color: [] }, { background: [] }],
                [{ font: [] }],
                [{ size: ["8px", "10px", "12px", "14px", "18px", "24px", "36px"] }, "line-height"],
                ["clean"],
              ],
            },
          }}
          value={value}
          onChange={onEditorChange}
        />
        {/* <Editor
          apiKey="v1oaw3yn9un79c63uodd74j2ecckl6kpejp444c7divbpm6m"
          onEditorChange={onEditorChange}
          value={value}
          init={{
            placeholder: "Write product details",
            height: 300,

            plugins: "table",
            table_use_colgroups: true,
            table_default_attributes: {
              border: "1px",
            },
          }}
        /> */}
      </div>
    </>
  );
};

export default Markdown;
