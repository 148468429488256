import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const BanksTour = ({ setAddBankModalShow }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const banksTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Banks", value: true } }));
      setTimeout(() => {
        banksTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Banks",
        popover: {
          title: "Banks",
          description: "Overall shop banks.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#banks",
        popover: {
          title: `Manage Bank Details`,
          description: "Allows sellers to manage their bank account details in the POS system.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#banks-search",
        popover: {
          title: `Search bank`,
          description: "Helps users find specific bank accounts efficiently by Bank name, Account Title & Number.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#banks-page-size",
        popover: {
          title: `Page size`,
          description: " Allows users to control how many bank records are displayed on a single page.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#banks-add-bank",
        popover: {
          title: `Add Bank`,
          description: "Opens a modal form for adding a new bank account.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setAddBankModalShow(true);
            setTimeout(() => {
              banksTour.moveNext();
            });
          },
        },
      },
      {
        element: "#bank-add-bank-modal > .modal-content",
        popover: {
          title: `Add Bank`,
          description: "A form for entering new bank account details.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setAddBankModalShow(false);
            setTimeout(() => {
              banksTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#add-bank-name",
        popover: {
          title: `Bank Name`,
          description: "Dropdown menu to select the bank's name.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#add-bank-opening-balance",
        popover: {
          title: `Opening Balance`,
          description: "Field to specify the initial balance for the account.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#add-bank-account-type",
        popover: {
          title: `Account Type`,
          description: "Dropdown to choose the type of account (e.g., Business, Personal).",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#add-bank-default-account",
        popover: {
          title: `Default Account`,
          description: "Checkbox to set the account as the default.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#add-bank-account-title",
        popover: {
          title: `Account Title`,
          description: "Field to enter the account holder's title.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#add-bank-account-number",
        popover: {
          title: `Account Number`,
          description: "Input field for the bank account number.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#add-bank-add-btn",
        popover: {
          title: `Save`,
          description: "The button to submit the form to save the new bank account information.",
          side: "left",
          align: "start",
          onNextClick: () => {
            setAddBankModalShow(false);
            setTimeout(() => {
              banksTour.moveNext();
            });
          },
        },
      },
      {
        element: "#bank-table > div:nth-child(1)",
        popover: {
          title: `Bank List`,
          description: "It displays all registered banks and their details, including account title, account number, opening balance, account type, and default status.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setAddBankModalShow(true);
            setTimeout(() => {
              banksTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#edit",
        popover: {
          title: `Edit`,
          description: "Modify the details of an existing bank account.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#remove",
        popover: {
          title: `Delete`,
          description: "Remove a bank account from the system.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#deposit",
        popover: {
          title: `Deposit`,
          description: "Add funds to the selected bank account.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#csv-download",
        popover: {
          title: `Download CSV`,
          description: "Enables users to export bank account details in CSV format.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Banks", value: true } }));
            setTimeout(() => {
              banksTour.destroy();
            });
          },
        },
      },
    ],
  });
  useEffect(() => {
    banksTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default BanksTour;
