import React from "react";
import { Offcanvas } from "react-bootstrap";
import { reportsNameMapping } from "../../utils/reportsNameMapping";

function MobileReportsTabs({ show, handleClose, activeTab, handleTabChange }) {
  return (
    <div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header className="border-bottom bg-light " closeButton>
          <Offcanvas.Title>
            <div>{reportsNameMapping[activeTab]}</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="mt-3">
          <div
            onClick={() => {
              handleTabChange(undefined);
              handleClose();
            }}
            className={`w-100 p-2  mt-2 ${activeTab === undefined ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">General Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("daily-cash");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTab === "daily-cash" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Daily Cash Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("daily-transactional");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTab === "daily-transactional" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Daily Transaction Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("profit-loss");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTab === "profit-loss" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Profit/Loss Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("inventory");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTab === "inventory" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Inventory Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("sales");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTab === "sales" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Sales Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("sales-summary");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTab === "sales-summary" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Sales Summary Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("journal");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTab === "journal" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Journal</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("imei");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTab === "imei" ? "bg-light primary-color  " : ""}`}
          >
            <span className=" ">IMEI Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("bank-transactions");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTab === "bank-transactions" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Bank Transactions Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("misc-items-history");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTab === "misc-items-history" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Misc Items History Report</span>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default MobileReportsTabs;
