import { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import { Form, Row, Col } from "react-bootstrap";
import SearchableSelect from "../../SearchableSelect";
import Markdown from "../../MarkdownEditor/Markdown";
import ImageUploader from "../../ImageUploader/ImageUploader";
import ReactSelect from "../../ReactSelect";
import { getcomponent } from "../../InventoryForms/utils";
import { convert } from "html-to-text";
import VideoUploader from "../../VideoUploader/VideoUploader";
import { generateShortDescription } from "./generateDescription";
import style from "./validationModal.module.css";

const TabletForm = forwardRef(({ state, setState }, ref) => {
  const { imageRef, videoRef } = ref;

  const [titlePlaceholder, setTitlePlaceholder] = useState("e.g. iPhone 12 Pro Max");
  const [isTitleCustomize, setIsTitleCustomize] = useState(true);
  const [customizeTitle, setCustomizeTitle] = useState({
    fixedPart: "",
    dynamicPart: "",
  });
  const [isTitleCharacterLimitExceeded, setIsTtitleCharacterLimitExceeded] = useState(false);
  const [isCustomizeTitleFocused, setIsCustomizeTitleFocused] = useState(false);
  const [isErrorInGeneratingDescription, setIsErrorInGeneratingDescription] = useState(false);

  const handleChangeScreenSize = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        screen_size: e.label,
        fk_screen_size_id: e.value,
      },
    }));
  };
  const handleChangeRam = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        ram: e.label,
        fk_ram_id: e.value,
      },
    }));
  };
  const handleChangeStorage = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        storage_mobile: e.label,
        fk_storage_mobile_id: e.value,
      },
    }));
  };
  const handleChangeBrand = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        brand: e.label,
        fk_brand_id: e.value,
      },
    }));
  };
  const handleChangeSpeaker = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        speaker: e.label,
        fk_speaker_id: e.value,
      },
    }));
  };
  const handleChangeScreenType = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        screen_type: e.label,
        fk_screen_type_id: e.value,
      },
    }));
  };
  const handleChangeFingerPrint = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        finger_print: e.label,
        fk_finger_print_id: e.value,
      },
    }));
  };
  const handleChangeBatteryCapacity = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        battery_capacity: e.label,
        fk_battery_capacity_id: e.value,
      },
    }));
  };
  const handleChangeScreenProtection = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        screen_protection: e.label,
        fk_screen_protection_id: e.value,
      },
    }));
  };
  const handleChangeBodyType = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        body_type: e.label,
        fk_body_type_id: e.value,
      },
    }));
  };
  const handleChangeProcessor = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        processor: e.label,
        fk_processor_id: e.value,
      },
    }));
  };
  const handleChangeNetworkBand = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        network_band: e.label,
        fk_network_band_id: e.value,
      },
    }));
  };
  const handleChangeSimType = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        sim_type: e.label,
        fk_sim_type_id: e.value,
      },
    }));
  };
  const handleChangeResolution = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        resolution: e.label,
        fk_resolution_id: e.value,
      },
    }));
  };
  const handleChangeRefreshRate = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        refresh_rate: e.label,
        fk_refresh_rate_id: e.value,
      },
    }));
  };
  const handleChangeCameraSpecs = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        camera_specs: e.label,
        fk_camera_specs_id: e.value,
      },
    }));
  };

  const handleChangePTA = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        is_pta_approved: e.value,
      },
    }));
  };
  const handleChangeESim = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        is_e_sim: e.value,
      },
    }));
  };
  const handleChangeSimSupport = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        is_sim_support: e.value,
      },
    }));
  };

  const handleChangeColor = (e) => {
    setState((prev) => ({
      ...prev,
      listing_color: {
        color: e.label,
        fk_color_id: e.value,
      },
    }));
  };
  const handleChangePort = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        port: e.label,
        fk_port_id: e.value,
      },
    }));
  };
  const handleChangeModel = (e) => {
    const cleanedValue = e.target.value
      .replace(/[^a-zA-Z0-9 \-\/'+]/g, "")
      .replace(/\s+/g, " ")
      .replace(/\s+/g, " ");
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        model: cleanedValue,
        fk_model_id: -1,
      },
    }));
  };
  const handleChangeAdditionalNotes = (e) => {
    setState((prev) => {
      return {
        ...prev,
        listing_optional: {
          ...prev.listing_optional,
          notes: e.target.value,
        },
      };
    });
  };
  const handleChangeImages = (event) => {
    if (typeof event === "function") {
      setState((prev) => ({
        ...prev,
        images: event(prev.images),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        images: event,
      }));
    }
  };
  const handleChangeVideo = (event) => {
    if (typeof event === "function") {
      setState((prev) => ({
        ...prev,
        video: event(prev.video),
      }));
    } else {
      if (event === null) {
        setState((prev) => {
          if (prev?.video) {
            delete prev?.video;
          }
          return prev;
        });
      } else {
        setState((prev) => ({
          ...prev,
          video: event,
        }));
      }
    }
  };
  const validateInput = (event) => {
    const regex = /^[a-zA-Z0-9 \-\/']*$/;

    const input = event.target.value + event.key;
    if (!regex.test(input)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (isTitleCustomize) {
      if (state?.listing?.brand && state?.listing?.model) {
        const autoFillPart = `${state?.listing?.brand ? (state?.listing?.brand === "Other" ? "" : state?.listing?.brand) : ""} ${state?.listing?.model?.trim()}`;
        setCustomizeTitle((prev) => ({
          ...prev,
          fixedPart: autoFillPart,
        }));
        setState((prev) => ({
          ...prev,
          title: autoFillPart + `${customizeTitle?.dynamicPart?.length > 0 ? " " + customizeTitle.dynamicPart : ""}`,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          title: "",
        }));
        setCustomizeTitle({ fixedPart: "", dynamicPart: "" });
        setTitlePlaceholder("Fill all the fields to Add the title....");
      }
    } else {
      if (state?.listing?.brand && state?.listing?.model && state?.listing?.ram && state?.listing?.storage_mobile && state?.listing_color?.color) {
        setState((prev) => ({
          ...prev,
          title: `${state?.listing?.brand ? (state?.listing?.brand === "Other" ? "" : state?.listing?.brand + " ") : ""}${state?.listing?.model?.trim()}${
            state?.listing?.ram && state?.listing?.ram !== 0
              ? state?.listing?.storage_mobile && (state?.listing?.storage_mobile !== 0 ? ` ${state?.listing?.ram}/${state?.listing?.storage_mobile}` : ` ${state?.listing?.ram}`)
              : state?.listing?.storage_mobile && state?.listing?.storage_mobile !== 0
              ? ` ${state?.listing?.storage_mobile}`
              : ""
          }${state?.listing_color?.color && state?.listing_color?.color !== "Other" ? " " + state?.listing_color?.color : ""}`,
        }));
      } else {
        if (state?.listing?.brand || state?.listing?.model || state?.listing?.ram || state?.listing?.storage_mobile || state?.listing_color?.color) {
          setTitlePlaceholder("Fill all the fields to generate the title....");
        }
        setState((prev) => {
          return {
            ...prev,
            title: "",
          };
        });
      }
    }
  }, [state?.listing?.brand, state?.listing?.model, state?.listing?.ram, state?.listing?.storage_mobile, state?.listing_color?.color, isTitleCustomize, customizeTitle.dynamicPart]);
  return (
    <>
      <Form noValidate validated={state?.validated} className="m-3">
        <Row className="gx-0 gx-sm-3 gx-lg-5">
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.brand
                    ? {
                        label: state?.listing?.brand,
                        value: state?.listing?.fk_brand_id,
                      }
                    : null
                }
                label={"Brand"}
                important
                placeholder="Select brand"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("brand", { is_tab: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeBrand}
                name="Brand"
                required
                isInvalid={state?.validated && !state?.listing?.brand}
              />
              <Form.Control.Feedback type="invalid">* Please Select Brand </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group className="" controlId="model">
              <Form.Label className="mb-0">
                Model<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control onKeyDown={validateInput} className="py-2" type="text" placeholder="e.g. dell" onChange={handleChangeModel} value={state?.listing?.model ? state?.listing?.model : ""} name="model" required />

              <Form.Control.Feedback type="invalid">* Please Select Model </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-2" md={12}>
            <Form.Group>
              <Form.Label>
                Title<span style={{ color: "red" }}>*</span>{" "}
                <Form.Check
                  type="checkbox"
                  style={{ display: "inline", paddingLeft: "5px" }}
                  checked={!isTitleCustomize}
                  onChange={(e) => {
                    setIsTitleCustomize(!e.target.checked);
                  }}
                />
                <span className={`ps-1 text-muted`}>Auto-Generate Title</span>
              </Form.Label>
              {!isTitleCustomize ? (
                <Form.Control className="py-3 pe-5" type="text" placeholder={titlePlaceholder} value={state?.title} disabled={true} onChange={() => {}} name="model" required />
              ) : (
                <>
                  <div className={`p-3 form-control ${style.customizeTitleField} ${state.title ? "" : style.disabledDiv}`}>
                    {state?.title ? (
                      <>
                        <span className={style.customizeTitleFieldSpan}>{customizeTitle.fixedPart}&nbsp;</span>
                        <input
                          value={customizeTitle.dynamicPart}
                          className={`${style.customizeTitleFieldInput}`}
                          onKeyDown={validateInput}
                          onChange={(e) => {
                            const value = e.target.value;

                            const cleanedValue = value.replace(/[^a-zA-Z0-9 \-\/'+]/g, "").replace(/\s+/g, " ");
                            if (customizeTitle.fixedPart.length + cleanedValue.length <= 250) {
                              setCustomizeTitle((prev) => ({
                                ...prev,
                                dynamicPart: cleanedValue,
                              }));
                            }

                            if (customizeTitle.fixedPart.length + cleanedValue.length <= 70) {
                              setIsTtitleCharacterLimitExceeded(false);
                            } else {
                              setIsTtitleCharacterLimitExceeded(true);
                            }
                          }}
                          type="text"
                          disabled={state?.listing?.brand && state?.listing?.model ? false : true}
                          name="model"
                          required
                        />
                      </>
                    ) : (
                      <span className="text-muted">{titlePlaceholder}</span>
                    )}
                  </div>
                  {isTitleCharacterLimitExceeded ? <div className="text-danger py-2">*7-100 characters are recommended above 100 is not SEO friendly. The maximum allowed length is 250 characters.</div> : <></>}

                  <div className="alert alert-warning" role="alert">
                    Please enter "Brand and Model" before entering the title
                    {/* Fill Brand and model before adding title! */}
                  </div>
                </>
              )}
            </Form.Group>
          </Col>

          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing_color?.color
                    ? {
                        label: state?.listing_color?.color,
                        value: state?.listing_color?.fk_color_id,
                      }
                    : null
                }
                name="Color"
                label={"Color"}
                important
                placeholder="Select Color"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("color", { is_tab: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeColor}
                required
                isInvalid={state?.validated && !state?.listing_color?.color}
              />

              <Form.Control.Feedback type="invalid">Please Select Color</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.ram
                    ? {
                        label: state?.listing?.ram,
                        value: state?.listing?.fk_ram_id,
                      }
                    : null
                }
                label={"RAM (GB)"}
                important
                placeholder="Select RAM"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("ram", { is_tab: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== 0)
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));

                        const filteredValue = value.filter((loc) => loc.label.toString().toLowerCase().includes(e.toLowerCase())).sort((a, b) => +a.label - +b.label);
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeRam}
                name="RAM"
                required
                isInvalid={state?.validated && !state?.listing?.ram}
              />
              <Form.Control.Feedback type="invalid">* Please Select RAM</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.storage_mobile
                    ? {
                        label: state?.listing?.storage_mobile,
                        value: state?.listing?.fk_storage_mobile_id,
                      }
                    : null
                }
                label={"Storage (GB)"}
                important
                placeholder="Select Storage"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("mobile_storage", { is_tab: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== 0)
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));

                        const filteredValue = value.filter((loc) => loc.label.toString().toLowerCase().includes(e.toLowerCase())).sort((a, b) => +a.label - +b.label);
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeStorage}
                name="Storage"
                required
                isInvalid={state?.validated && !state?.listing?.storage_mobile}
              />
              <Form.Control.Feedback type="invalid">* Please Select Storage</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Screen Size (Inches)"}
                placeholder="Select Screen Size"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("screen_size", { is_tab: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== 0)
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));

                        const filteredValue = value.filter((loc) => loc.label.toString().toLowerCase().includes(e.toLowerCase())).sort((a, b) => +a.label - +b.label);
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeScreenSize}
                value={
                  state?.listing_optional?.screen_size
                    ? {
                        label: state?.listing_optional?.screen_size,
                        value: state?.listing_optional?.fk_screen_size_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.screen_size} hidden onChange={() => {}} name="Screen Size" />
              <Form.Control.Feedback type="invalid">* Please Select Screen Size</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Port"}
                placeholder="Select Port"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("ports", { is_tab: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangePort}
                value={
                  state?.listing_optional?.port
                    ? {
                        label: state?.listing_optional?.port,
                        value: state?.listing_optional?.fk_port_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.port} hidden onChange={() => {}} name="Port" />
              <Form.Control.Feedback type="invalid">* Please Select Port</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Speaker"}
                placeholder="Select Speaker"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("speaker", undefined, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeSpeaker}
                value={
                  state?.listing_optional?.speaker
                    ? {
                        label: state?.listing_optional?.speaker,
                        value: state?.listing_optional?.fk_speaker_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.speaker} hidden onChange={() => {}} name="Speaker" />
              <Form.Control.Feedback type="invalid">* Please Select Speaker</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Screen Type"}
                placeholder="Select Screen Type"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("screen_type", undefined, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeScreenType}
                value={
                  state?.listing_optional?.screen_type
                    ? {
                        label: state?.listing_optional?.screen_type,
                        value: state?.listing_optional?.fk_screen_type_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.screen_type} hidden onChange={() => {}} name="Screen Type" />
              <Form.Control.Feedback type="invalid">* Please Select Screen Type</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Camera"}
                placeholder="Select Camera"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("camera_specs", undefined, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== 0)
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));

                        const filteredValue = value.filter((loc) => loc.label.toString().toLowerCase().includes(e.toLowerCase())).sort((a, b) => +a.label - +b.label);
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeCameraSpecs}
                value={
                  state?.listing_optional?.camera_specs
                    ? {
                        label: state?.listing_optional?.camera_specs,
                        value: state?.listing_optional?.fk_camera_specs_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.camera_specs} hidden onChange={() => {}} name="Camera" />
              <Form.Control.Feedback type="invalid">* Please Select Camera</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Finger Print"}
                placeholder="Select Finger Print"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("finger_print", { is_tab: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeFingerPrint}
                value={
                  state?.listing_optional?.finger_print
                    ? {
                        label: state?.listing_optional?.finger_print,
                        value: state?.listing_optional?.fk_finger_print_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.finger_print} hidden onChange={() => {}} name="Finger Print" />
              <Form.Control.Feedback type="invalid">* Please Select Finger Print</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Battery (mAh)"}
                placeholder="Select Battery"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("battery_capacity", undefined, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== 0)
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));

                        const filteredValue = value.filter((loc) => loc.label.toString().toLowerCase().includes(e.toLowerCase())).sort((a, b) => +a.label - +b.label);
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeBatteryCapacity}
                value={
                  state?.listing_optional?.battery_capacity
                    ? {
                        label: state?.listing_optional?.battery_capacity,
                        value: state?.listing_optional?.fk_battery_capacity_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.battery_capacity} hidden onChange={() => {}} name="Battery" />
              <Form.Control.Feedback type="invalid">* Please Select Battery</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Screen Protection"}
                placeholder="Select Screen Protection"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("screen_protection", { is_tab: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeScreenProtection}
                value={
                  state?.listing_optional?.screen_protection
                    ? {
                        label: state?.listing_optional?.screen_protection,
                        value: state?.listing_optional?.fk_screen_protection_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.screen_protection} hidden onChange={() => {}} name="Screen Protection" />
              <Form.Control.Feedback type="invalid">* Please Select Screen Protection</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Body Type"}
                placeholder="Select Body Type"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("body_type", { is_tab: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeBodyType}
                value={
                  state?.listing_optional?.body_type
                    ? {
                        label: state?.listing_optional?.body_type,
                        value: state?.listing_optional?.fk_body_type_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.body_type} hidden onChange={() => {}} name="Body Type" />
              <Form.Control.Feedback type="invalid">* Please Select Body Type</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Processor"}
                placeholder="Select Processor"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("processor", { is_tab: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeProcessor}
                value={
                  state?.listing_optional?.processor
                    ? {
                        label: state?.listing_optional?.processor,
                        value: state?.listing_optional?.fk_processor_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.processor} hidden onChange={() => {}} name="Processor" />
              <Form.Control.Feedback type="invalid">* Please Select Processor</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <SearchableSelect
                label={"Sim Support"}
                placeholder="Select Sim Support"
                searchable={false}
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                handleChange={handleChangeSimSupport}
                value={typeof state?.listing_optional?.is_sim_support === "boolean" ? (state?.listing_optional?.is_sim_support ? { value: true, label: "Yes" } : { value: false, label: "No" }) : null}
              />
              <Form.Control value={state?.listing_optional?.is_sim_support} hidden onChange={() => {}} name="Sim Support" />
              <Form.Control.Feedback type="invalid">* Please Select Sim Support</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <SearchableSelect
                label={"PTA"}
                placeholder="Select PTA"
                searchable={false}
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                handleChange={handleChangePTA}
                value={typeof state?.listing_optional?.is_pta_approved === "boolean" ? (state?.listing_optional?.is_pta_approved ? { value: true, label: "Yes" } : { value: false, label: "No" }) : null}
              />
              <Form.Control value={state?.listing_optional?.is_pta_approved} hidden onChange={() => {}} name="PTA" />
              <Form.Control.Feedback type="invalid">* Please Select PTA</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <SearchableSelect
                label={"E-Sim"}
                placeholder="Select E-Sim"
                searchable={false}
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                handleChange={handleChangeESim}
                value={typeof state?.listing_optional?.is_e_sim === "boolean" ? (state?.listing_optional?.is_e_sim ? { value: true, label: "Yes" } : { value: false, label: "No" }) : null}
              />
              <Form.Control value={state?.listing_optional?.is_e_sim} hidden onChange={() => {}} name="E-Sim" />
              <Form.Control.Feedback type="invalid">* Please Select E-Sim</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Network Band"}
                placeholder="Select Network Band"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("network_bands", undefined, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeNetworkBand}
                value={
                  state?.listing_optional?.network_band
                    ? {
                        label: state?.listing_optional?.network_band,
                        value: state?.listing_optional?.fk_network_band_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.network_band} hidden onChange={() => {}} name="Network Band" />
              <Form.Control.Feedback type="invalid">* Please Select Network Band</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Sim Type"}
                placeholder="Select Sim Type"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("sim_type", { is_tab: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeSimType}
                value={
                  state?.listing_optional?.sim_type
                    ? {
                        label: state?.listing_optional?.sim_type,
                        value: state?.listing_optional?.fk_sim_type_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.sim_type} hidden onChange={() => {}} name="Sim Type" />
              <Form.Control.Feedback type="invalid">* Please Select Sim Type</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Resolution"}
                placeholder="Select resolution"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("resolution", undefined, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeResolution}
                value={
                  state?.listing_optional?.resolution
                    ? {
                        label: state?.listing_optional?.resolution,
                        value: state?.listing_optional?.fk_resolution_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.resolution} hidden onChange={() => {}} name="resolution" />
              <Form.Control.Feedback type="invalid">* Please Select Resolution</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Refresh Rate"}
                placeholder="Select Refresh Rate"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("refresh_rate", undefined, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== 0)
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label.toString(),
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeRefreshRate}
                value={
                  state?.listing_optional?.refresh_rate
                    ? {
                        label: state?.listing_optional?.refresh_rate,
                        value: state?.listing_optional?.fk_refresh_rate_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.refresh_rate} hidden onChange={() => {}} name="Refresh Rate" />
              <Form.Control.Feedback type="invalid">* Please Select Refresh Rate</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="py-2" md={12}>
            <Form.Group>
              <Form.Label>
                Additional Notes <span className="text-muted">(Optional)</span>
              </Form.Label>
              <Form.Control className="py-3 pe-5" type="text" as="textarea" rows={3} placeholder="Enter Additional Information" value={state?.listing_optional?.notes} onChange={handleChangeAdditionalNotes} name="notes" isInvalid={state?.listing_optional?.notes?.length > 500} />
              <Form.Control.Feedback type="invalid">Character Limit Exceeded: Please limit your input to a maximum of 500 characters.</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={12} md={12}>
            <Form.Group>
              <Markdown
                important
                value={state?.listing?.description ? state?.listing?.description : ""}
                autoGenerated={
                  state?.listing
                    ? () => {
                        try {
                          const description = generateShortDescription("Tablet", {
                            ...state?.listing_color,
                            ...state?.listing,
                            ...(state?.listing_optional ? state?.listing_optional : {}),
                          });
                          setState((prev) => ({
                            ...prev,
                            listing: {
                              ...prev.listing,
                              description: description,
                            },
                          }));
                        } catch (error) {
                          setIsErrorInGeneratingDescription(true);
                        }
                      }
                    : undefined
                }
                onEditorChange={(content, editor) => {
                  setState((prev) => ({
                    ...prev,
                    listing: {
                      ...prev.listing,
                      description: content,
                    },
                  }));
                }}
                isInvalid={(state?.validated && !state?.listing?.description) || isErrorInGeneratingDescription}
              />
              <Form.Control value={state?.listing?.description} name="Description" onChange={() => {}} hidden required isInvalid={(state.validated && convert(state?.listing?.description)?.length < 50) || isErrorInGeneratingDescription} />

              <Form.Control.Feedback type="invalid">{isErrorInGeneratingDescription ? "Please Select All Required Fields" : convert(state?.listing?.description)?.length < 50 ? "Description Must be more than 50 characters." : "Please Enter Description"}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={12}>
            <Form.Group>
              <Form.Label className="mb-0">
                Upload Image<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <ImageUploader ref={imageRef} selectedFiles={state?.images} setSelectedFiles={handleChangeImages} isInvalid={state?.validated && !state?.images?.length > 0} />
              <Form.Control value={state?.images?.length > 0 ? state?.images : ""} name="Image" onChange={() => {}} hidden required />
              <Form.Control.Feedback type="invalid">Please Enter Atleast One Image</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={12}>
            <Form.Group>
              <Form.Label className="mb-0 mt-3">Upload Video</Form.Label>
              <VideoUploader ref={videoRef} selectedFile={state?.video} setSelectedFile={handleChangeVideo} isInvalid={state?.validated && !state?.images?.length > 0} disabled={!state?.listing?.model} />
              <Form.Control value={state?.images?.length > 0 ? state?.images : ""} name="Image" onChange={() => {}} hidden />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
});

TabletForm.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
};

export default TabletForm;
