import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NotFound from "../Components/404";
import PublicInvoice from "../Components/PublicInvoice";
import { decrypt } from "../Features/invoiceCrypto";
import Loading from "../Components/Loading/Loading";
const Receipt = () => {
  const { salesOrder } = useParams();

  const [salesOrderObject, setSalesOrderObject] = useState(null);
  const [invalidSalesOrder, setInvalidSalesOrder] = useState(false);
  useEffect(() => {
    setSalesOrderObject(decrypt(salesOrder));
  }, [salesOrder]);
  useEffect(() => {
    if (salesOrderObject) {
      setInvalidSalesOrder(false);
    } else {
      setInvalidSalesOrder(true);
    }
  }, [salesOrderObject]);

  return <>{invalidSalesOrder ? <NotFound /> : <PublicInvoice salesOrder={salesOrderObject} />}</>;
};
export default Receipt;
