import React, { useEffect } from "react";
import FormCard from "../Components/FormCard";
import style from "./../styles/ForgetPassword.module.css";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCircleCheck, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PopUpModal from "../Components/Modal";
import Loader from "../Components/Loader";
import PhoneInput from "../Components/PhoneInput/PhoneInput";
import styleModal from "./../styles/Modal.module.css";
import OTPInput from "otp-input-react";
import { useDispatch } from "react-redux";
import { currentUser, loginByOtp } from "../Features/authSlice";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import Loading from "../Components/Loading/Loading";

const ForgetPassword = () => {
  const [user_email, setEmail] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isClicked, setIsclicked] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [validated, setValidated] = useState(false);

  const [otp, setOtp] = useState("");
  const [otpResendTimer, setOtpResendTimer] = useState(60);

  const [otpError, setOtpError] = useState("");
  const [OTPsent, setOTPsent] = useState(false);
  const [OtpVerified, setOtpVerified] = useState(false);
  const [ResetPassword, setResetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowcPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  let { user, err, message } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    setEmail(location.state?.email);
  }, []);

  useEffect(() => {
    const token = Cookies.get("sel_taccess");
    if (token) {
      setToken(token);
      setTimeout(() => navigate("/forget-password"), 2000);
    }
  }, [token, navigate]);

  const tick = () => {
    setOtpResendTimer(otpResendTimer - 1);
  };
  useEffect(() => {
    let timer;
    if (OTPsent && otpResendTimer !== 0) {
      timer = setInterval(() => {
        tick();
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [otpResendTimer, OTPsent]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setIsloading(false);
      return;
    }

    setValidated(true);

    try {
      const res = await axios.post(process.env.REACT_APP_API_URL + `/auth/reset-seller-password`, { password, cpassword }, { withCredentials: true });
      setValidated(false);
      setSuccess(res.data.success.message);
      setIsloading(false);

      if (user) {
        navigate("/");
      }
    } catch (error) {
      setIsloading(false);
      setError(error.response.data.error?.message);
    }
  };

  const VerifyOTP = async (event) => {
    event.preventDefault();
    setIsloading(true);
    await axios
      .post(process.env.REACT_APP_API_URL + "/otp/verify", { email: user_email, otp }, { withCredentials: true })
      .then((res) => {
        setOtp("");
        localStorage.setItem("refreshToken", res.data.refreshToken);
        dispatch(currentUser(res.data.refreshToken));
        setOTPsent(false);
        setOtpVerified(true);
        setIsloading(false);
        setSuccess("");
      })
      .catch((e) => {
        setOtp("");
        setOtpError(e?.response?.data?.message);
        setIsloading(false);
      });
    // dispatch(loginByOtp({ otp, user_email }));
  };

  const onsubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }
    setIsloading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/otp/send`, { email: user_email })
      .then((res) => {
        setOTPsent(true);
        setIsloading(false);
        setSuccess(res.data.message);
        setOtpError("");
        setValidated(false);
      })
      .catch((error) => {
        setOTPsent(false);
        setOtpError(error?.response?.data?.message || error?.response?.data?.error?.message || "Something went wrong");
        setIsloading(false);
      });
  };

  const resetPassword = async (e) => {
    setValidated(false);
    setOtpVerified(false);
    setResetPassword(true);
  };

  const resendOTP = async (e) => {
    setOtp("");

    setIsloading(true);
    setSuccess("");
    setOtpError("");

    setOtpResendTimer(60);
    try {
      const res = await axios.post(process.env.REACT_APP_API_URL + "/otp/send", { email: user_email });
      if (res) {
        setIsloading(false);
        setSuccess(res.data.message);
      }
    } catch (e) {
      setIsloading(false);
      setOtpError(e.response.data.message);
    }
  };
  return (
    <div>
      <div className={`d-flex justify-content-center align-items-center ${style.wrapper}`}>
        {isLoading ? (
          <Loading />
        ) : OTPsent ? (
          <>
            <FormCard>
              <div style={{ width: "350px" }}>
                {success && <p className="my-3">{success.replace("$", "")}</p>}
                <Form onSubmit={VerifyOTP} className="mt-2">
                  {otpError !== "OTP limit exceeded" ? (
                    <>
                      <div className="d-flex justify-content-center">
                        <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={4} otpType="number" disabled={false} />
                      </div>
                      <div className="d-flex justify-content-center mt-2 mb-0 align-items-center">{message && <p className="text-danger">Invalid OTP</p>}</div>
                      <div className="d-flex justify-content-around me-2 mt-2">
                        <div className="" data-testid="otp-resend-root" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          {otpResendTimer ? <span>{otpResendTimer} seconds remaining</span> : <span>OTP not recieved yet? </span>}
                          <button type="button" disabled={otpResendTimer > 0} className={"py-0 btn btn-outline-secondary"} style={{ marginLeft: "20px", height: "2rem" }} onClick={resendOTP}>
                            Resend
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className={`d-flex justify-content-around my-1 ${otpError === "OTP limit exceeded" ? "py-5 fs-5" : ""}`}>
                    <p className="text-danger">{otpError === "OTP limit exceeded" ? ` ${otpError} try again in 24 hours ` : otpError ? otpError : ""}</p>
                  </div>

                  {otpError !== "OTP limit exceeded" ? (
                    <>
                      <div className="d-flex justify-content-center mt-1">
                        <Button variant="primary" type="submit" disabled={otp.length !== 4}>
                          Submit
                        </Button>
                      </div>
                    </>
                  ) : null}
                </Form>
                <div className={`link-main mt-2  ${style.backToLogin}`}>
                  <FontAwesomeIcon className="link mx-2" icon={faArrowLeft} />
                  <Link className="link" to="/login">
                    Back to login
                  </Link>
                </div>
              </div>
            </FormCard>
          </>
        ) : OtpVerified ? (
          <>
            <FormCard>
              <div className="d-flex justify-content-center mt-4">
                <Button className="w-100" onClick={resetPassword}>
                  Reset Password
                </Button>
              </div>

              <div className="d-flex justify-content-center me-2 my-1 ">or</div>
              <Button className="w-100" variant="outline-primary" onClick={() => navigate("/")}>
                Continue without password
              </Button>
            </FormCard>
          </>
        ) : ResetPassword ? (
          isLoading ? (
            <Loading />
          ) : (
            <>
              <FormCard>
                <h4 className="mt-5 mb-4 text-center fw-bold">Reset Password</h4>
                <div className="d-flex justify-content-around my-1">
                  <p className="text-success">{success ? success : ""}</p>
                </div>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group className={`mb-4 ${style.passwordInput}`} controlId="formBasicPassword">
                    <Form.Control
                      className={`p-2 ${style.passwordValidate}`}
                      name="password"
                      type={showPassword ? "text" : "password"}
                      isInvalid={validated && password.length < 6}
                      placeholder="Enter New Password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setError("");
                      }}
                      required
                    />

                    <div className={`${style.passwordField}`}>
                      <FontAwesomeIcon className="text-secondary" icon={showPassword ? faEye : faEyeSlash} onClick={() => setShowPassword((a) => !a)} />
                    </div>
                    <Form.Control.Feedback type="invalid">* Invalid New Password</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className={`mb-4 ${style.passwordInput}`} controlId="formBasicCPassword">
                    <Form.Control
                      className={`p-2 ${style.passwordValidate}`}
                      name="cpassword"
                      type={showcPassword ? "text" : "password"}
                      isInvalid={validated && (password !== cpassword || cpassword.length < 6)}
                      placeholder="Confirm Password"
                      value={cpassword}
                      onChange={(e) => {
                        setCPassword(e.target.value);
                        setError("");
                      }}
                      required
                    />
                    <div className={`${style.passwordField}`}>
                      <FontAwesomeIcon className="text-secondary" icon={showcPassword ? faEye : faEyeSlash} onClick={() => setShowcPassword((prev) => !prev)} />
                    </div>
                    <Form.Control.Feedback type="invalid">* {!cpassword && "Invalid Confirm Password"} </Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex justify-content-around ">
                    <p className="text-danger">{error ? error : ""}</p>
                  </div>
                  <div className="mb-4">
                    <Button className="w-100" type="submit" disabled={!password || !cpassword}>
                      Submit
                    </Button>
                  </div>
                </Form>
              </FormCard>
            </>
          )
        ) : (
          <FormCard>
            <div className={`mb-4 `}>
              <h4 className="mt-5 mb-4 text-center fw-bold">Forget Password</h4>
              {<PopUpModal icon={<FontAwesomeIcon icon={faCircleCheck} />} text={success} type={success ? styleModal.successBg : styleModal.errorBg} iconModal={success ? styleModal.successIcon : styleModal.errorIcon} show={modalShow} onHide={() => setModalShow(false)} />}
              {error && <span className="text-danger p-2 text-center my-2 rounded-1">*{error}</span>}
              <Form noValidate validated={validated} onSubmit={onsubmit}>
                <Form.Group className="mb-2" md="4" controlId="validationCustomEmail">
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="user_email"
                    className="p-2"
                    aria-describedby="inputGroupPrepend"
                    required
                    value={user_email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setOtpError("");
                    }}
                  />
                  <Form.Control.Feedback type="invalid">* Invalid email format</Form.Control.Feedback>
                </Form.Group>
                <div className="d-flex justify-content-around">
                  <p className="text-danger">{otpError === "OTP limit exceeded" ? ` ${otpError} try again in 24 hours ` : otpError ? otpError : ""}</p>
                </div>

                <div className="mb-4">
                  <Button className="w-100 " type="submit" disabled={!user_email || otpError}>
                    {isClicked ? <Loader /> : "Verify Email"}
                  </Button>
                </div>
                <div className={`link-main  ${style.backToLogin}`}>
                  <FontAwesomeIcon className="link mx-2" icon={faArrowLeft} />
                  <Link className="link" to="/login">
                    Back to login
                  </Link>
                </div>
              </Form>
            </div>
          </FormCard>
        )}
      </div>
    </div>
  );
};

const renderButton = (buttonProps) => {
  return (
    <Button className="py-0" variant="outline-secondary" style={{ marginLeft: "20px", height: "2rem" }} {...buttonProps}>
      Resend
    </Button>
  );
};
const renderTime = (remainingTime) => {
  return <span>{remainingTime} seconds remaining</span>;
};

export default ForgetPassword;
