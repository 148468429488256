import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";
const LocationsTour = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const locationTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "LocationList", value: true } }));
      setTimeout(() => {
        locationTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Settings",
        popover: {
          title: "Settings",
          description: "Allows customization of platform settings to your preferences.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#tooltip-anchor-Locations",
        popover: {
          title: "Locations",
          description: "Overall shop locations.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#locations",
        popover: {
          title: `Manage Locations`,
          description: "Allows sellers to manage their shop locations across the platform.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#location-search",
        popover: {
          title: `Location Search Filter`,
          description: "Assists users in filtering locations based on names.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#location-province-filter",
        popover: {
          title: `By Province Filter`,
          description: " Helps sellers filter locations based on the province they belong to.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#location-city-filter",
        popover: {
          title: `By City Filter`,
          description: "Enables users to filter locations based on city names.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#add-location",
        popover: {
          title: `Add New Location`,
          description: "Opens a modal that lets sellers add a new shop location in Tech Bazaar POS.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#location-reset-filter",
        popover: {
          title: `Reset Filter`,
          description: "Resets all the applied filters to default settings.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#location-table > div:nth-child(1)",
        popover: {
          title: `Location List`,
          description: " Displays the added locations in the system.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#edit",
        popover: {
          title: `Actions`,
          description: "Allows sellers to edit details of shop locations.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#csv-download",
        popover: {
          title: `Download CSV`,
          description: "Let sellers download their shop location data in CSV format.",
          side: "right",
          align: "start",
          onNextClick: () => {
            navigate("/settings/add-location");
            setTimeout(() => {
              locationTour.moveNext();
            });
          },
        },
      },
      {
        element: "#add-location-form",
        popover: {
          title: `Functionality`,
          description: "Allows sellers to enter details for a new shop location in the system.",
          side: "right",
          align: "start",
          disableButtons: ["previous"],
        },
      },
      {
        element: "#add-location-name",
        popover: {
          title: `Name of Location`,
          description: "Field to input the name or identifier of the new location.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#add-location-province",
        popover: {
          title: `Location Province`,
          description: "Dropdown menu to select the province where the shop is located.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#add-location-city",
        popover: {
          title: `City`,
          description: "Dropdown menu to choose the city for the new location.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#add-location-street-address",
        popover: {
          title: `Street Address`,
          description: "Field to enter the detailed street address of the shop.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#add-location-opening-days",
        popover: {
          title: `Shop Opening Days`,
          description: "Allows sellers to select the days when the shop is open.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#add-location-contact-number",
        popover: {
          title: `Contact Number (WhatsApp)`,
          description: "Input field for the shop's WhatsApp contact number.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#add-location-alternative-number",
        popover: {
          title: `Alternative Number`,
          description: "Option to provide an additional contact number.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#add-location-show-contact-number",
        popover: {
          title: `Show Contact Number on Invoice`,
          description: "Checkbox to display the contact number on the invoice.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#add-location-show-alternative-number",
        popover: {
          title: `Show Alternate Number on Invoice`,
          description: "Checkbox to display the alternative number on the invoice.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#add-location-map",
        popover: {
          title: `Choose Location`,
          description: "Interactive map to pinpoint the exact location of the shop.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#add-location-save-button",
        popover: {
          title: `Save`,
          description: "The button to save all the entered details and add the location to the system.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "LocationList", value: true } }));
            navigate("/settings/locations");
            setTimeout(() => {
              locationTour.destroy();
            }, 100);
          },
        },
      },
    ],
  });
  useEffect(() => {
    locationTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default LocationsTour;
