// import FormCard from "../Components/FormCard";
import style from "./../styles/Login.module.css";
import { Card, Alert } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "./../assets/images/logo.png";
import LoginInput from "../Components/Login/LoginInput";
import LoginPassword from "../Components/Login/LoginPassword";
import LoginOtp from "../Components/Login/LoginOtp";
import LoaderIcon from "../Components/loaders/loaderIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const [appError, setErrors] = useState("");
  const [alertError, setAlertErrors] = useState("");
  const [loading, setLoading] = useState(true);
  const [otpResendTimer, setOtpResendTimer] = useState(60);
  const [userInput, setUserInput] = useState("");
  const [inputType, setInputType] = useState("input");
  const [otpMessage, setOtpMessage] = useState("");
  const [otpError, setOtpError] = useState("");
  const [shopList, setShopList] = useState([]);
  const [username, setUsername] = useState("");
  let { user, error, message } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (inputType === "input" && loading && userInput.length === 0 && !user) {
      setLoading(false);
    } else if (error) {
      if (message.includes("Reasons")) {
        // if(message.includes("Your account is temporarily disabled because of")){
        //   message = message + `. For more information `;
        message = message.replace(`"Reasons " + `, "").split(",");
        setAlertErrors(message);
        setErrors("");
        setLoading(false);
      } else {
        setErrors(message);
        setAlertErrors("");
        setLoading(false);
      }
    } else if (user) {
      // setLoading(true);
      setErrors("");
      setAlertErrors("");
      setTimeout(() => navigate("/"), 2000);
    }
  }, [user, error, message, navigate]);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const showChat = () => {
    // document.tidioIdentify = {
    //   email: userEmail
    // };
    window.tidioChatApi?.show();
  };

  return (
    <>
      <div className={`d-flex justify-content-center align-items-center flex-column ${style.wrapper}`}>
        <div>
          {alertError && (
            <Alert variant="danger">
              Your account is temporarily disabled because of following {alertError.length > 1 ? "reasons" : "reason"}:
              <br />
              <ul>
                {alertError.map((d, i) => (
                  <li key={i}>{d}</li>
                ))}
              </ul>{" "}
              For more information
              <span style={{ cursor: "pointer", color: "#8834FF" }} onClick={() => showChat()} className="text-decoration-underline fw-bold ms-1">
                Contact Us
              </span>
            </Alert>
          )}
        </div>
        <Card className={`border-0 ${style.formCard}`}>
          <div className="text-center">
            <img src={logo} alt="" />
          </div>
          <h4 className="mt-5 mb-4 text-center fw-bold">Welcome Back</h4>
          {appError && <span className="text-danger my-2 text-center">{appError}</span>}
          <div style={{ minHeight: "150px" }}>
            {loading ? (
              <div className="d-flex justify-content-center">
                <LoaderIcon />
              </div>
            ) : inputType === "input" ? (
              <LoginInput userInput={userInput} setUserInput={setUserInput} setLoading={setLoading} setErrors={setErrors} setInputType={setInputType} setOtpMessage={setOtpMessage} setOtpError={setOtpError} shopList={shopList} setShopList={setShopList} setUsername={setUsername} username={username} />
            ) : inputType === "email" ? (
              <LoginPassword userInput={userInput} setLoading={setLoading} setInputType={setInputType} />
            ) : inputType === "number" ? (
              <LoginOtp userInput={userInput} setLoading={setLoading} setErrors={setErrors} otpMessage={otpMessage} otpResendTimer={otpResendTimer} setOtpResendTimer={setOtpResendTimer} setOtpError={setOtpError} otpError={otpError} setInputType={setInputType} username={username} />
            ) : (
              <div className="w-100 text-center">
                <div>Something went wrong, please try again</div>
                <div className={`link-main cursor-pointer mt-5 ${style.backToLogin}`} onClick={() => setInputType("input")}>
                  <FontAwesomeIcon className="link mx-2" icon={faArrowLeft} />
                  <span className="link">Back to login</span>
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};
export default Login;
