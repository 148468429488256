import React, { useEffect } from "react";
import style1 from "./../styles/InvoiceCard.module.css";
import style from "./../styles/Invoice.module.css";
import { Col, Row, Table, Card, Button } from "react-bootstrap";
import { useState } from "react";
import "./../styles/printLayout.css";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { useCallback } from "react";
import Loading from "./Loading/Loading";
import NotFound from "./404";

const PublicInvoice = ({ salesOrder }) => {
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [payments, setPayments] = useState(null);
  const [remainingAmount, setRemainingAmount] = useState();
  const [totalDiscount, setTotalDiscount] = useState();
  const componentRef = useRef(null);
  const [invalidSalesOrder, setInvalidSalesOrder] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  useEffect(() => {
    if (salesOrder?.shop && salesOrder?.shop !== -1) {
      axios
        .post(process.env.REACT_APP_API_URL + `/sales/public-invoice/${salesOrder?.sales}`, { id: salesOrder?.shop })
        .then((res) => {
          setInvalidSalesOrder(false);
          setIsloading(false);
          let arr = [];
          const products = res.data.items.map((i) => ({
            title: i.title,
            cartQuantity: i.qty_ordered,
            price: i.sale_price,
            discount: i.discount,
            imei1: i.imei1,
            imei2: i.imei2,
          }));

          const miscProducts = res.data.miscItems.map((i) => ({
            title: i.name,
            cartQuantity: i.mis_qty,
            price: i.price,
            description: i.description,
            discount: i.discount,
          }));

          arr.push(...products);
          arr.push(...miscProducts);
          setData(res.data.data[0]);
          setItems(arr);
          setPayments(res.data.payments);
        })
        .catch((err) => {
          setIsloading(false);
          setInvalidSalesOrder(true);
          throw new Error(err);
        });
    } else {
      setIsloading(false);
      setInvalidSalesOrder(true);
    }
  }, [salesOrder]);
  useEffect(() => {
    const amount = Number(data.base_grand_total) - Number(data.total_received);
    setRemainingAmount(amount);
  }, [data]);

  useEffect(() => {
    const sum = items?.reduce((accumulator, object) => {
      return Number(accumulator) + Number(object.discount);
    }, 0);
    setTotalDiscount(sum);
  }, [items]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
  });

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : invalidSalesOrder ? (
        <NotFound />
      ) : (
        <>
          <div className={`my-4 d-flex justify-content-center gap-3`}>
            <Button variant="outline-primary" className="px-4" onClick={handlePrint}>
              Print{" "}
            </Button>
          </div>
          <Card className={`border-0 ${style1.invoiceCard} ${style1.formCard}`}>
            <div ref={componentRef}>
              <div className="printComponent p-4">
                <div className={`d-flex ${data.logo_path !== null ? "justify-content-between" : "justify-content-end"} ${style.logoadd}`}>
                  {data.logo_path !== null && <img src={process.env.REACT_APP_URL_S3 + "/" + data.logo_path} alt="" className={`${style1.logoimg} ${style.logo}`} />}
                  <div className={`d-flex flex-column text-end mb-4 ${style.address}`}>
                    <h5 style={{ color: "#39414A", marginBottom: "0rem" }}>Address</h5>
                    <span style={{ color: "#39414A", marginBottom: "0rem" }} className={`${style.addresstxt}`}>
                      {data.shop_address}
                    </span>
                    <span style={{ color: "#39414A", marginBottom: "0rem" }} className={`${style.addresstxt}`}>
                      {/* Phone: {data.owner_whatsapp_number} */}
                      Phone:{" "}
                      {data?.is_contact_no_on_invoice === 1 && data?.is_alternative_no_on_invoice === 1
                        ? data?.contact_number + "   " + data?.alternative_number
                        : data?.is_contact_no_on_invoice === 1
                        ? data?.contact_number
                        : data?.is_alternative_no_on_invoice === 1
                        ? data?.alternative_number
                        : data?.owner_whatsapp_number}
                    </span>
                    <span style={{ color: "#39414A" }} className={`${style.addresstxt}`}>
                      {data.owner_email}
                    </span>
                  </div>
                </div>
                <h1 className={`mb-3 invoiceHeading ${style.invoice}`}>Invoice</h1>
                <Row>
                  <Col xs sm="5" md="5" lg="5" className="px-0 ps-3">
                    <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                      Customer details
                    </p>
                    <span
                      className="text-capitalize"
                      style={{
                        color: "#39414A",
                        marginBottom: "0rem",
                        fontSize: "18px",
                        fontWeight: "700",
                      }}
                    >
                      {data.customer_name}
                    </span>
                    <div className={`mb-2 ${style.phn} px-0`}>
                      <p className={`${style.emailphone}`}>
                        <strong>Phone:</strong> {data.customer_number}
                      </p>
                      {data?.customer_email && data?.customer_email !== "nil" && data?.customer_email !== "" ? (
                        <p className={`${style.emailphone}`}>
                          <strong>Email:</strong> {data.customer_email}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                  <Col xs sm="4" md="4" lg="4" className={`${style.invoicenum}`}>
                    <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                      Sales Order number
                    </p>
                    <h5 className={`${style.invoicetxt} fw-2`}>{data.sales_order_number}</h5>
                  </Col>
                  <Col xs sm="3" md="3" lg="3" className={`${style.invoicenum}`}>
                    <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                      Sales date
                    </p>
                    <h5 className={`${style.invoicetxt}`}>{new Date(data.created_at).toLocaleDateString()}</h5>
                  </Col>
                </Row>
                <div>
                  <Table
                    style={{
                      border: "1px solid #DEDFE4",
                      tableLayout: "fixed",
                    }}
                    className=" my-3"
                  >
                    <tbody>
                      <tr style={{ background: "#F5F6FA" }}>
                        <th className={`${style.tabledescription}`}>Products</th>
                        <th className={`${style.tabletxt}`}>Unit price</th>
                        <th className={`${style.tabletxt}`}>QTY</th>
                        <th className={`${style.tabletxt}`}>Discount</th>
                        <th className={`${style.tabletxt}`}>Total</th>
                      </tr>
                      {items?.map((cartItem, index) => (
                        <tr key={index}>
                          <td className={`${style.tabledescription} d-flex flex-column`}>
                            {cartItem.title}
                            {cartItem?.imei1 && <span className="text-secondary">IMEI 1 - {cartItem?.imei1}</span>}
                            {cartItem?.imei2 && <span className="text-secondary">IMEI 2 - {cartItem?.imei2}</span>}
                            <span style={{ color: "#686E76", fontSize: "13px" }}>{cartItem.description}</span>
                          </td>
                          <td className={`${style.tabletxt}`}>{Number(cartItem.price).toLocaleString("en-IN")}</td>
                          <td className={`${style.tabletxt}`}>{cartItem.cartQuantity}</td>
                          <td className={`${style.tabletxt}`}>{cartItem.discount}</td>
                          <td className={`${style.tabletxt}`}>{Number(Number(cartItem.cartQuantity * cartItem.price) - Number(cartItem.discount)).toLocaleString("en-IN")}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Table
                    style={{
                      border: "1px solid #DEDFE4",
                      tableLayout: "fixed",
                    }}
                    className={`mb-4 ${style.invoiceTablePayments}`}
                  >
                    {/* <tbody> */}
                    <thead style={{ background: "#F5F6FA" }}>
                      <th className={`${style.tabledescription}`}>Amount Received</th>
                      <th className={`${style.tabletxt}`}>Payment Type</th>
                      <th className={`${style.tabletxt}`}>Invoice Number</th>
                      <th className={`${style.tabletxt}`}>Comment</th>
                      <th className={`${style.tabletxt}`}>Payment Date</th>
                    </thead>
                    {payments?.map((payment, index) => (
                      <tr key={index}>
                        <td className={`${style.tabledescription}`}>{Number(payment?.customer_payments_amount_received).toLocaleString("en-IN")}</td>
                        <td className={`${style.tabletxt}`}>{payment?.bank_name === "" || payment?.bank_name === null ? "Cash" : `${payment?.payment_type} (${payment?.bank_name})`}</td>
                        <td className={`${style.tabletxt}`}>{payment?.invoice_number}</td>
                        <td className={`${style.tabletxt}`}>{payment?.customer_payments_comment}</td>
                        <td className={`${style.tabletxt}`}>{payment?.customer_payments_date ? new Date(payment?.customer_payments_date).toLocaleDateString() : null}</td>
                      </tr>
                    ))}
                    {/* </tbody> */}
                  </Table>
                </div>
                <Row>
                  <Col xs="4" sm="4" md="6" lg="6"></Col>
                  <Col xs="4" sm="4" md="3" lg="3" style={{ marginBottom: "0.5rem" }}>
                    <span className={`${style.tabletxt}`}> Subtotal </span>
                  </Col>
                  <Col xs="4" sm="4" md="3" lg="3" className="text-end">
                    <span className={`${style.tabletxt}`}> {Number(data?.base_subtotal).toLocaleString("en-IN")} </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs="4" sm="4" md="6" lg="6"></Col>
                  <Col xs="4" sm="4" md="3" lg="3" style={{ marginBottom: "0.5rem" }}>
                    <span className={`${style.tabletxt}`}> Shipping </span>
                  </Col>
                  <Col xs="4" sm="4" md="3" lg="3" className="text-end">
                    <span className={`  ${style.tabletxt}`}> {Number(data?.base_shipping_amount).toLocaleString("en-IN")} </span>
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col xs="4" sm="4" md="6" lg="6"></Col>
                  <Col xs="4" sm="4" md="3" lg="3" style={{ marginBottom: "0.5rem" }}>
                    <span className={`${style.tabletxt}`}> Total </span>
                  </Col>
                  <Col xs="4" sm="4" md="3" lg="3" className="text-end">
                    <span className={`${style.tabletxt}`}> {Number(data?.base_grand_total).toLocaleString("en-IN")} </span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs="4" sm="4" md="6" lg="6"></Col>
                  <Col xs="4" sm="4" md="3" lg="3">
                    <span className={`${style.tabletxt}`}> Total Paid</span>
                  </Col>

                  <Col xs="4" sm="4" md="3" lg="3" className="text-end">
                    <span className={` ${style.tabletxt}`}> {Number(data?.total_received).toLocaleString("en-IN")} </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs="4" sm="4" md="6" lg="6"></Col>
                  <Col xs="4" sm="4" md="3" lg="3" style={{ marginBottom: "0.5rem" }}>
                    <span className={`${style.tabletxt}`}>Total Discount </span>
                  </Col>
                  <Col xs="4" sm="4" md="3" lg="3" className="text-end">
                    <span className={` ${style.tabletxt}`}> {Number(totalDiscount).toLocaleString("en-IN")} </span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs="4" sm="4" md="6" lg="6"></Col>
                  <Col xs="4" sm="4" md="3" lg="3">
                    <span className={`${style.tabletxt}`}> Total Due</span>
                  </Col>

                  <Col xs="4" sm="4" md="3" lg="3" className="text-end">
                    <span className={` ${style.tabletxt}`}> {Number(remainingAmount).toLocaleString("en-IN")} </span>
                  </Col>
                </Row>
                <Row style={{ display: "contents" }}>
                  <div className="mb-2" style={{ borderTop: "1px solid #DEDFE4" }}></div>
                </Row>
                <p className={`${style.invoiceterms} m-0`}>Invoice terms</p>
                {remainingAmount === 0 ? (
                  <>
                    <p className="m-0" style={{ color: "#39414A", textAlign: "center" }}>
                      {" "}
                      The Buyer has <b className="text-success fs-5">PAID</b> the amount.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="m-0" style={{ color: "#39414A", textAlign: "center" }}>
                      {" "}
                      The Buyer still needs to pay <b className="text-danger fs-5">{Number(remainingAmount).toLocaleString("en-IN")}</b> to clear the invoice.
                    </p>
                  </>
                )}
                {data?.invoice_terms !== "nil" && <p className={`my-0 text-center terms ${style.terms}`}>* {data?.invoice_terms}</p>}
                <p className={`${style.invoiceterms} mt-2`}>Comments</p>
                <p className="text-capitalize text-center my-0">{data?.sales_order_comment === "" ? "-" : data?.sales_order_comment}</p>
              </div>
            </div>
          </Card>
        </>
      )}
    </>
  );
};
export default PublicInvoice;
