export const getDefaultImage = (type) => {
  let imageURLs = "";
  if (type === "Laptop") {
    return (imageURLs = [`images/dummyImages/laptops.jpg`]);
  } else if (type === "Mobile") {
    return (imageURLs = [`images/dummyImages/mobiles.jpg`]);
  } else if (type === "Tablet") {
    return (imageURLs = [`images/dummyImages/tablets.jpg`]);
  } else if (type === "TV / Monitor") {
    return (imageURLs = [`images/dummyImages/tvs.jpg`]);
  } else if (type === "Desktop Computer") {
    return (imageURLs = [`images/dummyImages/desktops.jpg`]);
  } else if (type === "Watch" || type === "Fitness Watch" || type === "Smart Watch") {
    return (imageURLs = [`images/dummyImages/watches.jpg`]);
  } else if (type === "Earbuds" || type === "Headphones" || type === "Wireless Earbuds" || type === "Handsfree" || type === "Neckband" || type === "Headset" || type === "Airpods") {
    return (imageURLs = [`images/dummyImages/headphones.jpg`]);
  } else if (type === "Wireless Charger" || type === "Charger" || type === "Car Charger" || type === "Adapter") {
    return (imageURLs = [`images/dummyImages/chargingCables.jpg`]);
  } else if (type === "Speaker" || type === "Bluetooth Speaker" || type === "Sound bar") {
    return (imageURLs = [`images/dummyImages/speakers.jpg`]);
  } else if (type === "Mobile Case/Cover" || type === "Tablet Case/Cover" || type === "Laptop Case/Cover" || type === "Laptop Screen Protector" || type === "Mobile Screen Protector" || type === "Tablet Screen Protector" || type === "Cover") {
    return (imageURLs = [`images/dummyImages/screenProtectors.jpg`]);
  } else if (type === "Power Bank" || type === "Magesafe charger" || type === "Magsafe charger" || type === "Battery") {
    return (imageURLs = [`images/dummyImages/powerBanks.jpg`]);
  } else if (type === "Cable" || type === "Connector") {
    return (imageURLs = [`images/dummyImages/connectorCables.jpg`]);
  } else if (type === "Keyboard" || type === "Mouse" || type === "Keyboard & Mouse" || type === "Controller") {
    return (imageURLs = [`images/dummyImages/keyboardMouse.jpg`]);
  } else if (type === "Printer" || type === "Scanner") {
    return (imageURLs = [`images/dummyImages/printers.jpg`]);
  } else if (type === "USB" || type === "RAM" || type === "Hard Disk" || type === "SSD" || type === "SD Card") {
    return (imageURLs = [`images/dummyImages/usbAndSdCards.jpg`]);
  } else if (type === "Camera" || type === "Webcam" || type === "Tripod" || type === "Selfie Stick" || type === "Drone Camera" || type === "Ringlight" || type === "Gimbal") {
    return (imageURLs = [`images/dummyImages/cameras.jpg`]);
  } else if (type === "Sensor" || type === "Remote") {
    return (imageURLs = [`images/dummyImages/sensors.jpg`]);
  } else if (type === "Microphone") {
    return (imageURLs = [`images/dummyImages/microphones.jpg`]);
  } else if (type === "Bag") {
    return (imageURLs = [`images/dummyImages/bags.jpg`]);
  } else if (type === "Docking Station" || type === "Convertor" || type === "USB Hub") {
    return (imageURLs = [`images/dummyImages/hubs.jpg`]);
  } else if (type === "Router" || type === "Range Extender" || type === "Wifi Adapter" || type === "Wifi Hotspot Device" || type === "Telephone") {
    return (imageURLs = [`images/dummyImages/routers.jpg`]);
  } else if (type === "Mobile Mount" || type === "Tablet Mount" || type === "Laptop Mount" || type === "LED Mount" || type === "Holder") {
    return (imageURLs = [`images/dummyImages/monitors.jpg`]);
  } else if (type === "Socket" || type === "Smart Plug" || type === "Switch") {
    return (imageURLs = [`images/dummyImages/plugs.jpg`]);
  } else if (type === "Stylus Pen") {
    return (imageURLs = [`images/dummyImages/pens.jpg`]);
  } else if (type === "Smart TV Box" || type === "Google Chromecast" || type === "Streaming Device") {
    return (imageURLs = [`images/dummyImages/tvDevices.jpg`]);
  } else {
    return (imageURLs = [`images/dummyImages/other.jpg`]);
  }
};
