import axios from "axios";
import React from "react";
import SideNavData from "../Components/Sidebar/SideNavData";
import DashboardLayout from "../Layout/DashboardLayout";
import ForgetPassword from "../Pages/ForgetPassword";
import Loading from "../Pages/Loading";
import Login from "../Pages/Login";
import Receipt from "../Pages/Receipt";
import SetResetPassword from "../Pages/SetResetPassword";
import { Component } from "react-image-crop";
import ImageUpload from "../Pages/ImagesUpload/ImageUpload";

export let publicRoutes = [
  {
    path: "/receipt/:salesOrder",
    Component: Receipt,
  },
  {
    path: "/login",
    Component: Login,
  },
  {
    path: "/forget-password",
    Component: ForgetPassword,
  },
  {
    path: "/reset-password",
    Component: SetResetPassword,
  },
  {
    path: "/set-password/:token",
    Component: Loading,
  },
  {
    path: "/upload",
    Component: ImageUpload,
  },
];

export let privateRoutes = SideNavData;
