import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";
const MiscItemsHistoryReportTour = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const miscItemsHistoryReportTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "MiscItemsHistoryReport", value: true } }));
      miscItemsHistoryReportTour.destroy();
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#misc-items-history-report",
        popover: {
          title: `Misc Items History Report`,
          description: "Tracks historical records of miscellaneous items not categorized under regular product categories. It also provides invoice.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#misc-items-history-report-filter",
        popover: {
          title: `Filters`,
          description: "Shows miscellaneous items history based on specific filters.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#misc-items-history-report-table",
        popover: {
          title: `Misc Items History Report Table`,
          description: "Shows miscellaneous items sales history report based on specific filters.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "MiscItemsHistoryReport", value: true } }));
            miscItemsHistoryReportTour.destroy();
          },
        },
      },
    ],
  });
  useEffect(() => {
    miscItemsHistoryReportTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default MiscItemsHistoryReportTour;
