import React from "react";
import CustomizeTableview from "../../TableViews/CustomizeTableview";
import NoResult from "../../NoResult";
import LoaderPage from "../../loaders/loaderPage";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../Features/cart/cartSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function TilesView({ data, isLoading, status }) {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <LoaderPage />
        </div>
      ) : (
        <>
          {data?.length > 0 ? (
            <CustomizeTableview
              // checkbox={status === "Available" ? { data: cart?.cartItems, setter: () => {}, isNotSelectAll: true } : undefined}
              highlight={(row) => {
                const cartItem = cart?.cartItems?.filter((item) => item.id === row.id);
                return cartItem.length;
              }}
              thumbnail={{
                title: "Title",
                img: "img0",
                description: "title",
              }}
              data={data}
              columnNotShow={["id", "is_on_marketplace", "created_at", "updated_at", "location_id", "label", "reserved_quantity"]}
              actionButton={{
                column: "quantity",
                label: "Add to Cart",
                onClick: (er, val) => {
                  if (cart?.cartItems?.some((item) => item.id === val.id) ? cart?.cartItems?.filter((item) => item.id === val.id)[0].cartQuantity === val.quantity : false) {
                    toast.error(`You already added availble quantity of product ${val.title} in cart.`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1500, pauseOnHover: false, className: "toastMsg" });
                  } else {
                    // Action Here
                    dispatch(addToCart(val));
                  }
                },
                variant: "outline-primary",
                style: { padding: "8px", fontSize: "12px" },
                isShow: (_, row) => {
                  const cartItem = cart?.cartItems?.filter((item) => item.id === row.id);
                  return cartItem.length === 0;
                },
              }}
              actions={status === "Available"}
            />
          ) : (
            <NoResult path={"/inventory/add"} name="Inventory" />
          )}
        </>
      )}
    </>
  );
}

export default TilesView;
