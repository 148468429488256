import axios from "axios";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PopUpModal from "../../Components/Modal";
import styles from "../../styles/Setting.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import styleModal from "./../../styles/Modal.module.css";
import SocialMediaSettings from "./SocialMediaSettings";
import PrinterSettings from "./PrinterSettings";
import SystemSettingsTour from "../../TourGuides/SystemSettingsTour";

const SystemSetting = () => {
  const { user } = useSelector((state) => state.auth);
  const [modalShow, setModalShow] = useState(false);
  const [activeTAb, setActiveTAb] = useState("sales-settings");
  const [validated, setValidated] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [termss, setTerms] = useState();
  const [vendorTerms, setVendorTerms] = useState("");

  const [success, setSuccess] = useState("");

  const handleTabChange = (tab) => {
    setValidated(false);
    setActiveTAb(tab);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `/shops/${user.shop_id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setTerms(res.data[0].invoice_terms !== "nil" ? res.data[0].invoice_terms : "");
        setVendorTerms(res.data[0].vendor_invoice_terms !== "nil" ? res.data[0].vendor_invoice_terms : "");
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [user.shop_id]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    setValidated(true);
    if (termss) {
      setSuccess("");
      const res = await axios
        .patch(process.env.REACT_APP_API_URL + `/shops/update-invoice-terms/${user.shop_id}`, { terms: termss }, { withCredentials: true })
        .then((res) => {
          setSuccess("Invoice terms updated.");
          setModalShow(true);
          setDisabled(false);
          setTimeout(() => {
            setModalShow(false);
          }, 1000);
        })
        .catch((err) => {
          setDisabled(false);

          throw new Error(err);
        });
    } else {
      setDisabled(false);
    }
  };

  const handleVendorSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    setValidated(true);
    if (termss) {
      setSuccess("");
      const res = await axios
        .patch(process.env.REACT_APP_API_URL + `/shops/vendor-invoice-terms/${user.shop_id}`, { vendorTerms: vendorTerms }, { withCredentials: true })
        .then((res) => {
          setSuccess("Invoice terms updated.");
          setModalShow(true);
          setDisabled(false);
          setTimeout(() => {
            setModalShow(false);
          }, 1000);
        })
        .catch((err) => {
          setDisabled(false);

          throw new Error(err);
        });
    } else {
      setDisabled(false);
    }
  };
  return (
    <>
      {user?.tour && !user?.tour?.SystemSettings && <SystemSettingsTour handleTabChange={handleTabChange} />}
      <Row className="justify-content-center w-100">
        <Col xl="11" lg="11" md="12">
          <Button onClick={() => handleTabChange("sales-settings")} className={`${styles.btn} ${activeTAb === "sales-settings" ? styles.activebtn : ""}  mb-2`} variant="outline-primary" id="system-settings-sales">
            Sales
          </Button>
          <Button disabled={true} onClick={() => handleTabChange("inventory-settings")} className={`${styles.btns} ${activeTAb === "inventory-settings" ? styles.activebtn : ""}  mb-2`} variant="outline-primary" id="system-settings-inventory">
            Inventory
          </Button>
          <Button onClick={() => handleTabChange("vendor-settings")} className={`${styles.btns} ${activeTAb === "vendor-settings" ? styles.activebtn : ""}  mb-2`} variant="outline-primary" id="system-settings-vendor">
            Vendor
          </Button>
          <Button onClick={() => handleTabChange("printer-settings")} className={`${styles.btns} ${activeTAb === "printer-settings" ? styles.activebtn : ""}  mb-2`} variant="outline-primary" id="system-settings-printer">
            Printer
          </Button>
          <Button onClick={() => handleTabChange("social-settings")} className={`${styles.btns} ${activeTAb === "social-settings" ? styles.activebtn : ""}  mb-2`} variant="outline-primary" id="system-settings-social">
            Social Media
          </Button>
          <Button disabled={true} onClick={() => handleTabChange("customer-settings")} className={`${styles.btne} ${activeTAb === "customer-settings" ? styles.activebtn : ""}  mb-2`} variant="outline-primary" id="system-settings-customer">
            Customer
          </Button>
        </Col>
      </Row>
      <div className="px-5 mt-3">
        {activeTAb === "sales-settings" || activeTAb === "vendor-settings" ? (
          <div className={`${styles.setcol}  ${styles.row} p-2`}>
            <strong className="fw-bold "> Invoices Terms and conditions</strong>
            <Form noValidate validated={validated} onSubmit={activeTAb === "sales-settings" ? handleSubmit : handleVendorSubmit}>
              <Form.Group id="system-settings-sales-invoice-terms">
                <Form.Control
                  className="mt-2 "
                  style={{ borderRadius: "0px" }}
                  as="textarea"
                  required
                  rows="8"
                  onChange={(e) => {
                    {
                      activeTAb === "sales-settings" ? setTerms(e.target.value) : setVendorTerms(e.target.value);
                    }
                  }}
                  maxLength={300}
                  value={activeTAb === "sales-settings" ? (termss ? termss : "") : vendorTerms ? vendorTerms : ""}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">* Please Fill Terms</Form.Control.Feedback>
              </Form.Group>
              <div className="text-end">
                <Button type="submit" disabled={disabled} className=" mt-2 me-1 px-5" id="system-settings-sales-save-btn">
                  Save
                </Button>
              </div>
            </Form>
          </div>
        ) : (
          ""
        )}
        {activeTAb === "printer-settings" ? <PrinterSettings setModalShow={setModalShow} setSuccess={setSuccess} /> : <></>}
        {activeTAb === "social-settings" ? <SocialMediaSettings /> : <></>}
      </div>
      <PopUpModal title={""} icon={<FontAwesomeIcon icon={faCircleCheck} />} text={success} type={styleModal.successBg} iconModal={styleModal.successIcon} show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default SystemSetting;
