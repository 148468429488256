import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";
const ProfitLossReportTour = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const profitLossReportTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "ProfitLossReport", value: true } }));
      profitLossReportTour.destroy();
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#profit-loss-report",
        popover: {
          title: `Profit/Loss Report`,
          description: "The Profit/Loss report provides a summary of total revenues versus expenses, helping assess the financial health of the business.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#profit-loss-report-day-filter",
        popover: {
          title: `Day Filter`,
          description: "Provides the filter option based on the days.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#profit-loss-report-location-filter",
        popover: {
          title: `Select Location`,
          description: "Provides the filtering option based on the location of shops.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#profit-loss-report-print",
        popover: {
          title: `Print`,
          description: "Provides the print option for printing the complete report.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#profit-loss-report-sales-revenue",
        popover: {
          title: `Sales Revenue`,
          description: "Shows the total revenue generated from sales in graph.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#profit-loss-report-goods-sold",
        popover: {
          title: `Cost of Goods Sold`,
          description: "Shows the total cost of the products sold.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#profit-loss-report-gross-profit",
        popover: {
          title: `Gross Profit`,
          description: "Shows the total profit before deducting operating expenses.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#profit-loss-report-operating-expenses",
        popover: {
          title: `Operating Expenses`,
          description: "Shows the total expenses incurred in running the business.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#profit-loss-report-net-profit",
        popover: {
          title: `Net Profit`,
          description: "Shows the final profit after deducting operating expenses from gross profit.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "ProfitLossReport", value: true } }));
            profitLossReportTour.destroy();
          },
        },
      },
    ],
  });
  useEffect(() => {
    profitLossReportTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default ProfitLossReportTour;
