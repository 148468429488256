import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  privateRoutes: [],
};

export const routesSlice = createSlice({
  name: "routes",
  initialState,
  reducers: {
    configureRoutes(state, action) {
      state.privateRoutes = action.payload;
    },
  },
});
export const routesAction = routesSlice.actions;
export default routesSlice.reducer;
