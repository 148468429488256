import React from "react";
import { Container, Row, Col, Button, Dropdown } from "react-bootstrap";
import SearchInput, { SearchMobleInput } from "../../Components/SearchInput";
import { useState } from "react";
import SearchableSelect from "../../Components/SearchableSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import filter from "../../assets/svg/filter.svg";
import { Link } from "react-router-dom";
import { useRef } from "react";
import DatePicker from "../../Components/DatePicker/DatePicker";
import moment from "moment";
import MobileSearchAcoordian from "../../Components/MobileSearchAcoordian";
import icon from "../../assets/svg/icon.svg";
import style from "./../../styles/Vendors.module.css";
import filterStyle from "./../../styles/filtersButtons.module.css";
import VendorWithoutPurchaseOrder from "./VendorWithoutPurchaseOrder";
import VendorWithPurchaseOrder from "./VendorWithPurchaseOrder";
import SupplierTour from "../../TourGuides/SupplierTour";
import { useSelector } from "react-redux";

const Vendor = () => {
  const { user } = useSelector((state) => state.auth);
  const shop_id = user?.shop_id;
  //data toggle
  const [toggle, refresh] = useState(true);

  //pagination
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);
  const [vendorType, setVendorType] = useState("primary");

  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setSearch("");
    setStatus(null);
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
  };
  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  //filters
  const [search, setSearch] = useState("");
  const currentSearch = useRef();
  currentSearch.current = search;

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const [status, setStatus] = useState(null);
  const handleStatusChange = (e) => {
    if (e.value) {
      setStatus(e);
    } else {
      setStatus(null);
    }
  };

  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);

  const resetFilterHandle = (e) => {
    setStatus(null);
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
  };
  const Filter = () => {
    setToogleMobileSearch(false);
  };

  const handleChangeStatusMobile = (e) => {
    if (e.target.value === "All") {
      setStatus({ value: null, label: e.target.value });
    } else {
      setStatus({ value: e.target.id, label: e.target.value });
    }
  };
  const SearchData = [
    {
      name: "Select Status",
      handleChange: handleChangeStatusMobile,
      value: status,
      filter: [
        { value: "none", label: "All" },

        { value: "paid", label: "Paid" },

        { value: "pending", label: "Pending" },
      ],
    },
  ];
  return (
    <>
      {user?.tour && !user?.tour?.Vendorlist && <SupplierTour setVendorType={setVendorType} />}
      {toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian SearchData={SearchData} toggleSearchclose={handleAcoordianCLose} dateName={"Select Date"} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} filter={Filter} />
      ) : (
        <Container fluid className="mb-5" id="supplier">
          {toogleMobileSearch === "search" ? (
            <div className="d-flex  mb-2 justify-content-center align-items-center">
              <div className="">
                {" "}
                <SearchMobleInput searchlogo value={search} handleChange={handleSearchChange} />
              </div>
              <span className="ms-4" onClick={handleAcoordianCLose}>
                X
              </span>
            </div>
          ) : (
            <Row className="d-flex align-items-center">
              <Col xs="2" lg="3" xl="2" className="d-none d-lg-inline" id="supplier-search">
                <SearchInput searchlogo value={search} handleChange={handleSearchChange} />
              </Col>
              <Col lg={2} className="d-none d-lg-inline"></Col>
              {vendorType === "other" && <Col lg={2} className="d-none d-lg-inline"></Col>}
              <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3" id="supplier-date-filter">
                <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
              </Col>
              {vendorType === "primary" && (
                <Col xs="3" lg="3" xl="2" className="d-none d-lg-inline mb-3" id="supplier-status-filter">
                  <SearchableSelect
                    selectClassName={"bg-white"}
                    placeholder="By Status"
                    value={status}
                    searchable={false}
                    options={[
                      { value: null, label: "All" },
                      { value: "paid", label: "Paid" },
                      { value: "pending", label: "Pending" },
                    ]}
                    handleChange={handleStatusChange}
                  />
                </Col>
              )}

              <Col className="d-none d-sm-inline d-lg-none d-md-inline px-0" sm={2}></Col>
              <Col xs="6" lg="3" xl="2" sm="3" className="mb-3" id="supplier-page-size">
                <SearchableSelect
                  selectClassName={"bg-white"}
                  placeholder={`Showing ${sizeOfPages}`}
                  defaultValue={`Showing ${sizeOfPages}`}
                  value={`Showing ${sizeOfPages}`}
                  searchable={false}
                  options={[
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "30", label: "30" },
                  ]}
                  handleChange={handlePageSize}
                />
              </Col>
              <Col onClick={() => handleAcoordianOpen("search")} xs="2" sm="2" lg="3" className="d-lg-none d-inline mb-3">
                <SearchInput searchlogo bordered />
              </Col>
              <Col className={`d-lg-none d-inline mb-3 ${filterStyle.filter}`} xs="2" lg="2">
                <Link to="" className={`${filterStyle.filterToggleButtonLink}`}>
                  <Button onClick={() => handleAcoordianOpen("select")} variant="outline-primary" className={`${filterStyle.filterToggleButton} d-flex align-items-center`}>
                    <img className="" src={filter} alt="filter" />
                    <span className=" ms-2 d-none d-sm-inline">Filter</span>
                  </Button>
                </Link>
              </Col>
              <Col xs="2" sm="3" lg="2" xl="2" xxl="2" className={`mb-3 p-0 ${filterStyle.addbtn}`}>
                <Link to={"/supplier/add"} className={` ${filterStyle.addButtonLink}`}>
                  <Button
                    id="supplier-add-supplier"
                    type="submit"
                    style={{
                      fontSize: "1rem",
                      height: "100%",
                    }}
                    className={` ${filterStyle.addButton}`}
                  >
                    <span className="d-none d-sm-inline  d-lg-inline">Add Supplier</span> <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </Link>
              </Col>
            </Row>
          )}
          <div className="pt-2">
            <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle} id="supplier-reset-filter">
              <span className="d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          <div className="d-flex justify-content-end">
            <Dropdown key={vendorType} className={`d-flex d-lg-inline d-md-inline justify-content-end ${style.toggle}`} onSelect={(e) => setVendorType(e)} id="supplier-type">
              <Dropdown.Toggle variant="light" className={`border-0 p-0 text-secondary `} id="dropdown-basic" style={{ background: "transparent" }}>
                <span style={{ color: "#9537FF" }}>({vendorType === "primary" ? "Suppliers with Purchase Orders" : "Suppliers without Purchase Orders"})</span> <img src={icon} alt="ellipsis" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="rounded-0 border-0 shadow">
                <Dropdown.Item as={Col} className="purple cursor-pointer" href="" eventKey="primary">
                  Suppliers with Purchase Orders
                </Dropdown.Item>
                <Dropdown.Item as={Col} className="purple cursor-pointer" href="" eventKey="other">
                  Suppliers without Purchase Orders
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {vendorType === "primary" ? <VendorWithPurchaseOrder search={search} fromDate={fromDate} toDate={toDate} status={status} sizeOfPages={sizeOfPages} /> : <VendorWithoutPurchaseOrder searchData={search} dateFrom={fromDate} dateTo={toDate} pageSize={sizeOfPages} />}
        </Container>
      )}
    </>
  );
};

export default Vendor;
