import { useState } from "react";
import { Table } from "react-bootstrap";
// import Loader from './Loader';
// import defaultImage from './../assets/svg/empty-state-icon.svg';
import noImage from "../assets/svg/no-image.svg";

const transform = (text) => {
  const temp = text.split("_");
  let ret = "";
  temp.forEach((txt) => {
    ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1);
  });
  return ret;
};
const ProductTableView = ({ data, thumbnail = null }) => {
  const [imgSrc, setImgSrc] = useState(process.env.REACT_APP_URL_S3 + "/" + data.img0);

  return (
    <>
      {!data[0] ? (
        <p className="text-center py-5">No Data exists!</p>
      ) : (
        <Table responsive className="rounded-3 bg-white mb-3">
          <thead className="text-secondary border-top">
            <tr>
              {thumbnail && (
                <th className="py-3">{transform(thumbnail?.title)}</th>
              )}
              {Object.keys(data[0])
                .slice(3)
                .map((s, index) => (
                  <th className="py-3" key={index}>
                    {transform(s)}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              
              <tr className="border-0" key={index}>
                {thumbnail && (
                  <td className="border-bottom-0 py-3 d-flex align-items-center">
                    <img
                      style={{ borderRadius: "8px", minWidth: "90px" }}
                      width="90"
                      height="56"
                      alt="thumbnail"
                      src={`${process.env.REACT_APP_URL_S3}/${row.img0}`}
                      className="img me-2"
                      onError={() => {
                      document.getElementsByClassName("img")[index].src = noImage
      }}
                    />
                    {row[thumbnail?.description]}
                  </td>
                )}
                {Object.values(row)
                  .slice(3)
                  .map((r, i) => (
                    <td className="border-bottom-0 py-3" key={i}>
                      {r.toLocaleString("en-IN")}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default ProductTableView;
