import { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import style from "./../../../styles/Refund.module.css";

const RefundItems = ({ item, handleItems, removeItem, setTotal }) => {
  const [newArray, setNewArray] = useState([]);
  // const [total,setTotal] = useState();

  useEffect(() => {
    setNewArray(
      item.map((element) => {
        return {
          id: element.id,
          discount: element.discount,
          is_misc: element.is_misc,
          price: element.price,
          quantity: element.quantity,
          returned_qty: element.returned_qty,
          title: element.title,
          refund_qty: element.quantity,
          refund_amount: element.quantity * element.price - element.discount,
          checkBox: false,
          // checkBox: allChecked === true ? true : false
        };
      })
    );
  }, [item]);

  const handleRefundData = (e, i, name) => {
    let values = [...newArray];
    if (name === "checkBox") {
      values[i][name] = e.target.checked;
    } else if (name === "refund_qty") {
      let { value, min, max } = e.target;
      const value1 = Math.max(Number(min), Math.min(Number(max), Number(value.replace(/\D/g, ""))));
      values[i][name] = value1;
      values[i]["refund_amount"] = value1 * (values[i]["price"] - values[i]["discount"] / values[i]["quantity"]);
    } else {
      let { value, min, max } = e.target;
      const value1 = Math.max(Number(min), Math.min(Number(max), Number(value.replace(/\D/g, ""))));
      values[i][name] = value1;
    }
    setNewArray(values);
  };

  useEffect(() => {
    let arr = [];
    arr = newArray.filter((item) => item.checkBox === true);
    const sum = arr.reduce((accumulator, object) => {
      return accumulator + object.refund_amount;
    }, 0);
    setTotal(sum);
  }, [newArray]);

  return newArray.map((item, index) => (
    <div className={`${style.refundItemElements} ${item.checkBox === true && style?.checkedItem}`}>
      <Row key={index} className={`my-1 align-items-center px-3 ${style.refundRow}`}>
        <Col className={`px-0 d-flex align-items-baseline gap-2 ${style.item} ${style.title}`} lg="5" md="5" sm="5" xs="5">
          <Form.Check
            className="py-1"
            name="items"
            type="checkbox"
            value={item.checkBox}
            onChange={(e) => {
              handleRefundData(e, index, "checkBox");
              {
                item.checkBox === true ? handleItems(item) : removeItem(item);
              }
            }}
          />
          <span className={`text-capitalize  ${item.checkBox === false && style?.isChecked}`}>{item.title}</span>
        </Col>
        <Col className={`px-0 d-flex justify-content-start mb-2 ${style.item} ${item.checkBox === false && style?.isChecked}`} lg="2" md="2" sm="2" xs="2">
          <span className="text-capitalize">{item.quantity}</span>
        </Col>
        <Col className={`ps-0 d-flex mb-2 ${style.item2} ${item.checkBox === false && style?.isChecked}`} lg="2" md="2" sm="2" xs="2">
          <Form.Control type="text" className={` ${item.checkBox === false && "text-secondary"}`} name={`${index} qty`} max={item.quantity} value={item.refund_qty} onChange={(e) => handleRefundData(e, index, "refund_qty")} />
        </Col>
        <Col className={`ps-0 d-flex mb-2 ${style.lastItem} ${item.checkBox === false && style?.isChecked}`} lg="3" md="3" sm="3" xs="3">
          <Form.Control type="text" className={` ${item.checkBox === false && "text-secondary"}`} name={`${index} amount`} value={item.refund_amount} max={item.refund_qty * (item.price - item.discount / item.quantity)} onChange={(e) => handleRefundData(e, index, "refund_amount")} />
        </Col>
      </Row>
    </div>
  ));
};

export default RefundItems;
