import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const PlansTour = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const plansTour = driver({
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Plans", value: true } }));
      plansTour.destroy();
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#plans",
        popover: {
          title: `Subscriptions Plans`,
          description: "This module allows users to manage their subscription plans and view detailed information.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#subscriptions-plan-featured",
        popover: {
          title: `Featured Plans`,
          description: `On this page, users can view a list of subscription plans that are marked as featured.`,
          side: "right",
          align: "start",
        },
      },

      {
        element: "#subscriptions-featured-plan-details",
        popover: {
          title: `Plans`,
          description: "It displays a detailed comparison of consumable items,themes and features available across the different subscription tiers. And Sellers can easily subscribe to the plan they want to purchase.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#subscriptions-plan-custom",
        popover: {
          title: `Custom Plan`,
          description: "By clicking on this button, users can request their own custom plan by paying at the same time or can pay later.",
          side: "left",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Plans", value: true } }));
            plansTour.destroy();
          },
        },
      },
    ],
  });
  useEffect(() => {
    plansTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default PlansTour;
