import Cookies from "js-cookie";
import React, { memo } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux/es/exports";
import { Navigate, Outlet, Route, Routes, useSearchParams } from "react-router-dom";
import DashboardLayout from "../Layout/DashboardLayout";
import ForcedRedirections from "../Pages/ForcedRedirections";
import PlansCards from "../Components/plans/PlansCard";
import Login from "../Pages/Login";
import SetPassword from "../Pages/SetPassword";
import { useState } from "react";
import Loader from "../Components/Loader";
import Notifications from "../Pages/Notifications";
import AdminLogin from "../Components/AdminLogin";

const DynamicRoutes = memo(() => {
  const { user } = useSelector((state) => state.auth);
  const [routesReady, setRoutesReady] = useState(false);
  const [searchParams] = useSearchParams();
  const { privateRoutes } = useSelector((state) => state.routes);
  let token = Cookies.get(process.env.REACT_APP_ACCESS_TOKEN);
  const allowed = user && token && (user?.is_active === 1 || user?.is_active === 0);
  const forceLoginToken = searchParams.get("token");
  const IsActiveTwo = user && token && user?.is_active === 2;
  const notificationPermission = user?.user_type === "primary" || Number(user?.has_global) === 1;

  const generateSubRoutes = (navData) => {
    const finalSubRoutes = navData
      .map((route) => {
        if (route?.hasOwnProperty("onlyForPrimary") ? route?.onlyForPrimary && user?.user_type === "primary" : route.Included !== "false" || route.alwaysGenerateRoute) {
          return <Route key={route.identifier} path={route.path} element={<route.Component />} />;
        }
      })
      .filter((route) => route);
    return finalSubRoutes;
  };
  const generateRoutes = (navData) => {
    const finalRoutes = navData
      .map((route) => {
        if (route.Included !== "false" && (route?.hasOwnProperty("onlyForPrimary") ? route?.onlyForPrimary && user?.user_type === "primary" : true)) {
          if (route.subNav) {
            return generateSubRoutes(route.subNav);
          } else {
            return <Route key={route.identifier} path={route.path} element={<route.Component />} />;
          }
        }
      })
      .filter((route) => route);

    setRoutesReady(true);
    return finalRoutes;
  };
  const PrRoutes = useMemo(() => generateRoutes(privateRoutes), [privateRoutes]);
  return (
    <>
      {routesReady ? (
        forceLoginToken ? (
          <Routes>
            <Route path="*" element={<AdminLogin token={forceLoginToken} />} />
          </Routes>
        ) : (
          <Routes>
            {allowed ? (
              user?.redirections?.length > 0 ? (
                <>
                  <Route path="*" element={<Navigate to="/forced-redirection" />} />
                  <Route path="/forced-redirection" element={<ForcedRedirections />} />
                </>
              ) : user?.gracePeriodEnd === 1 ? (
                <Route
                  path="*"
                  element={
                    <Routes>
                      <Route path="/" element={<DashboardLayout />}>
                        <Route path="/" element={<PlansCards />} />

                        <Route exact path="/settings/plans" element={<PlansCards />} />
                        <Route path="*" element={<Navigate to={"/settings/plans"} />} />
                      </Route>
                    </Routes>
                  }
                />
              ) : (
                PrRoutes.length && (
                  <Route path="" element={<DashboardLayout />}>
                    {PrRoutes}
                    {notificationPermission && <Route path="/notifications" element={<Notifications />} />}
                    <Route path="*" element={<Navigate to="/" />} />
                  </Route>
                )
              )
            ) : IsActiveTwo ? (
              <Route
                path="*"
                element={
                  <Routes>
                    <Route exact path="*" element={<Navigate to="/set-password" />} />
                    <Route path="/set-password" element={<SetPassword user={user} />} />
                  </Routes>
                }
              />
            ) : (
              <Route
                path="*"
                element={
                  <Routes>
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="*" element={token ? <Outlet /> : <Navigate to="/login" />} />
                  </Routes>
                }
              />
            )}
          </Routes>
        )
      ) : (
        <Routes>
          <Route exact path="*" element={token ? <Outlet /> : <Navigate to="/login" />} />
        </Routes>
      )}
    </>
  );
});

export default DynamicRoutes;
