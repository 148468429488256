import { Form } from "react-bootstrap";
const Select = ({ children, important, label, lableRequired, value, id, placeholder, onClick, handleChange, defaultValue, disabled, required }) => {
    return (
        <>
            <Form.Group>
                <Form.Label className='mb-0'>{label}{important ? <span className="text-danger">*</span> : ""}</Form.Label>
                <Form.Select className="text-capitalize" value={value} id={id} disabled={disabled} required={required} onClick={onClick} onChange={handleChange} defaultValue={defaultValue}>
                    <option disabled selected value="" className="text-secondary">{placeholder}</option>
                    {children}
                </Form.Select>
            </Form.Group>
        </>
    );
}

export default Select;