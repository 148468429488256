import { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import SearchInput, { SearchMobleInput } from "../../Components/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PopUpModal from "../../Components/Modal";
import filterImg from "../../assets/svg/filter.svg";
import AddBank from "./AddBank";
import EditBank from "./EditBank";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import { useSelector } from "react-redux";
import customloader from "../../assets/images/RollingLoader.gif";
import PaginationBar from "../../Components/PaginationBar";
import SearchableSelect from "../../Components/SearchableSelect";
import axios from "axios";
import NoResult from "../../Components/NoResult";

import Csv from "../../Components/csv/Csv";
import MobileSearchAcoordian from "../../Components/MobileSearchAcoordian";
import { useNavigate, useSearchParams } from "react-router-dom";
import { requestDateTime, TIMEOUT } from "../../Features/dateTime";
import LoaderPage from "../../Components/loaders/loaderPage";
import DepositBankAmount from "./DepositBankAmount";
import BanksTour from "../../TourGuides/BanksTour";

const BankList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state) => state.auth);
  const user_type = user.user_type;
  const shopName = user.shop_name;
  const shopId = user.shop_id;
  const [modalAddBankShow, setAddBankModalShow] = useState(false);
  const [modalEditBankShow, setEditBankModalShow] = useState(false);
  const [modalDepositAmountShow, setDepositAmountModalShow] = useState(false);
  const [toggle, refresh] = useState(false);
  const [orderBy, setOrderBy] = useState({ account_number: "DESC" });

  const [banks, setBanks] = useState([]);
  const [totalBanks, setTotalBanks] = useState();
  const [bankId, setBankId] = useState();
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [defaultAccountExist, setDefaultAccountExist] = useState(false);
  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  //searchParams
  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, toggle, searchParams]);

  function getBanks() {
    if (user?.tour && !user?.tour?.Banks) {
      setIsLoading(false);
      setBanks([
        {
          bank_name: "Test Bank",
          account_title: "bank",
          account_number: "XXXXXXXXXXXXXXXX",
          opening_balance: 9000,
          account_type: "Business",
          default_business_account: 0,
        },
      ]);
      setTotalBanks(1);
      setNoOfPages(1);
    } else {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/shops/banks/${shopId}?page=` + currentPage + "&size=" + sizeOfPages,
          {
            search: search,
            orderBy: orderBy,
          },
          { withCredentials: true }
        )
        .then((res) => {
          const data = res.data;
          setDefaultAccountExist(data.defaultAccountExist);
          const result = [];
          data.data?.map((item) => {
            if (item.default_business_account === 1) {
              item.default_business_account = "Yes";
            } else {
              item.default_business_account = "No";
            }
            result.push(item);
          });
          if (data.count.no_of_pages === 0) {
            if (searchParams.get("page")) {
              setNoOfPages(0);
              navigate("/banks?page=0");
            }
          } else {
            setTotalBanks(data.count.no_of_items);
            setNoOfPages(data.count.no_of_pages);
            if (currentPage > data.count.no_of_pages) {
              setCurrentPage(data.count.no_of_pages);
              navigate("/banks?page=" + data.count.no_of_pages);
            }
            if (searchParams.get("page") === "0") {
              setCurrentPage(1);
              navigate("/banks?page=1");
            }
          }

          setIsLoading(false);
          setBanks(result);
        })
        .catch((err) => {
          setIsLoading(false);
          const tempErr = err?.response?.data?.error;
          if (tempErr?.status === 404 && tempErr?.message === "No Record Found") {
            setBanks([]);
          }
        });
    }
  }
  useEffect(() => {
    let timeout;
    if (search?.length > 0) {
      timeout = setTimeout(() => {
        setIsLoading(true);
        getBanks();
        // getBanksNoOfPages();
      }, TIMEOUT);
    } else {
      setIsLoading(true);
      getBanks();
      // getBanksNoOfPages();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [search, currentPage, noOfPages, sizeOfPages, shopId, toggle, orderBy, user?.tour?.Banks]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, [orderBy]);

  function requestAllHistory() {
    return axios.post(
      process.env.REACT_APP_API_URL + `/shops/banks/${shopId}`,
      {
        search: search,
        orderBy: orderBy,
      },
      { withCredentials: true }
    );
  }

  // const resetFilterHandle = () => {
  //   // setSearch("");
  //   setSizeOfPages(10);
  // };

  const closeModalAdd = () => {
    setAddBankModalShow(false);
    refresh((prev) => !prev);
  };

  // Edit Bank
  const handleEditBank = (id) => {
    setEditBankModalShow(true);
    setBankId(id);
  };

  const closeModalEdit = () => {
    setEditBankModalShow(false);
    setBankId(-1);
    refresh((prev) => !prev);
  };

  // Delete Bank
  const remove = (id) => {
    axios
      .delete(process.env.REACT_APP_API_URL + `/shops/banks/delete-bank/${id}`, { withCredentials: true })
      .then(() => {
        refresh((prev) => !prev);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);
  //
  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setSearch("");
  };

  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };
  const filter = () => {
    setToogleMobileSearch(false);
  };
  const SearchData = [];

  const handleDepositeAmount = (id) => {
    setDepositAmountModalShow(true);
    setBankId(id);
  };
  const closeDepositAmountModal = () => {
    setDepositAmountModalShow(false);
    setBankId(-1);
    refresh((prev) => !prev);
  };
  return (
    <>
      {user?.tour && !user?.tour?.Banks && <BanksTour setAddBankModalShow={setAddBankModalShow} />}
      {toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian SearchData={SearchData} toggleSearchclose={handleAcoordianCLose} filter={filter} />
      ) : (
        <>
          <Container fluid id="banks">
            {toogleMobileSearch === "search" ? (
              <div className="d-flex  mb-2 justify-content-center align-items-center">
                <div className="">
                  {" "}
                  <SearchMobleInput searchlogo value={search} handleChange={(e) => setSearch(e.target.value)} />
                </div>
                <span className="ms-4" onClick={handleAcoordianCLose}>
                  X
                </span>
              </div>
            ) : (
              <Row className="d-flex align-items-center ">
                <Col xs="2" lg="3" className="d-none d-lg-inline mb-3" id="banks-search">
                  <SearchInput searchlogo handleChange={(e) => setSearch(e.target.value)} value={search} />
                </Col>
                <Col xs lg="2" xxl="4" xl="3" className="d-none d-lg-inline"></Col>

                <Col xs="2" lg="3" onClick={() => handleAcoordianOpen("search")} className="d-lg-none d-inline">
                  <SearchInput searchlogo bordered />
                </Col>
                <Col className="d-none d-flex">
                  <Button variant="outline-primary" onClick={() => handleAcoordianOpen("select")}>
                    <img src={filterImg} alt="filter" />
                    <span className=" ms-2 d-none d-sm-inline">Filter</span>{" "}
                  </Button>
                </Col>

                <Col className="mb-3  p-1"></Col>
                <Col xs="6" lg="3" xl="2" sm="3" className="mb-3  p-1" id="banks-page-size">
                  <SearchableSelect
                    placeholder={`Showing ${sizeOfPages}`}
                    defaultValue={`Showing ${sizeOfPages}`}
                    value={`Showing ${sizeOfPages}`}
                    searchable={false}
                    options={[
                      { value: "10", label: "10" },
                      { value: "20", label: "20" },
                      { value: "30", label: "30" },
                    ]}
                    handleChange={handlePageSize}
                  />
                </Col>

                <Col xs="2" sm="3" lg="2" xl="2" xxl="2" className="mb-3  p-1">
                  <Button
                    id="banks-add-bank"
                    className="h-100 w-100"
                    style={{ fontSize: "14px" }}
                    // size="sm"
                    type="submit"
                    onClick={() => setAddBankModalShow(true)}
                  >
                    <span className="d-none d-sm-inline d-lg-inline">Add New Bank</span> <FontAwesomeIcon icon={faPlus} style={{ fontSize: ".8rem" }} />
                  </Button>
                </Col>
              </Row>
            )}
            {/* <div className="pt-2">
              <Button
                size="sm"
                type="submit"
                style={{ fontSize: "14px" }}
                onClick={resetFilterHandle}
              >
                <span className="d-lg-inline">Reset Filters</span>{" "}
              </Button>
            </div> */}
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                <LoaderPage />
              </div>
            ) : !banks[0] ? (
              <NoResult name="Bank" />
            ) : (
              <div id="bank-table">
                <CustomizeTableview className={"mt-3"} data={banks} remove={remove} columnNotShow={["id"]} sortColumn={["bank_name", "account_number"]} orderBy={orderBy} setOrderBy={setOrderBy} edit={handleEditBank} deposit={handleDepositeAmount} actions={user_type === "primary"} />
                <Row className="mt-5 align-items-center">
                  <Col className="d-none d-md-block" md="3" lg="3">
                    <span className="text-secondary fs-6">{totalBanks} result(s)</span>
                  </Col>
                  <Col xs="12" md="6" lg="6">
                    <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
                  </Col>
                  <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                    <span className="text-secondary fs-6">{totalBanks} result(s)</span>
                  </Col>
                  <Col xs="6" md="3" lg="3" className="text-end mt-3">
                    <Csv
                      filename={`${
                        "Banks " +
                        shopName +
                        " " +
                        new Date().toLocaleDateString("en-US", {
                          timeZone: "Asia/Karachi",
                        })
                      }`}
                      api={requestAllHistory}
                      format={(data) => {
                        const { id, account_number, opening_balance, account_type, default_business_account, ...temp } = data;
                        return {
                          ...temp,
                          account_number: account_number.toString(),
                        };
                      }}
                      responseObject={(res) => {
                        return res.data.data;
                      }}
                      error={(err) => {
                        const tempErr = err.response.data.error;
                        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Container>
        </>
      )}

      <PopUpModal title="Add New Bank" show={modalAddBankShow} onHide={() => setAddBankModalShow(false)} id="bank-add-bank-modal">
        <AddBank close={closeModalAdd} defaultAccountExist={defaultAccountExist} />
      </PopUpModal>
      <PopUpModal title="Edit Bank" show={modalEditBankShow} onHide={() => setEditBankModalShow(false)}>
        <EditBank bankId={bankId} close={closeModalEdit} defaultAccountExist={defaultAccountExist} />
      </PopUpModal>
      <PopUpModal title="Deposit Amount" show={modalDepositAmountShow} onHide={() => setDepositAmountModalShow(false)}>
        <DepositBankAmount bankId={bankId} close={closeDepositAmountModal} />
      </PopUpModal>
    </>
  );
};
export default BankList;
