import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const SystemSettingsTour = ({ handleTabChange }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const systemSettingsTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "SystemSettings", value: true } }));
      systemSettingsTour.destroy();
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-System-Settings",
        popover: {
          title: "System Settings",
          description: "This module allows users to configure various system settings across multiple categories, enabling customization of POS functionality.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#system-settings-sales",
        popover: {
          title: `Sales Settings`,
          description: "Allows users to configure sales settings.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#system-settings-sales-invoice-terms",
        popover: {
          title: `Invoice Terms and Conditions`,
          description: "Text box for entering terms and conditions displayed on sales invoices.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#system-settings-sales-save-btn",
        popover: {
          title: `Save Button`,
          description: "Saves the entered terms for future invoices.",
          side: "right",
          align: "start",
          onNextClick: () => {
            handleTabChange("inventory-settings");
            setTimeout(() => {
              systemSettingsTour.moveNext();
            });
          },
        },
      },
      {
        element: "#system-settings-inventory",
        popover: {
          title: `Inventory`,
          description: "Allows users to configure inventory settings.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            handleTabChange("sales-settings");
            setTimeout(() => {
              systemSettingsTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#system-settings-inventory-stock-settings",
        popover: {
          title: `Stock Settings`,
          description: " Configuration options for stock management, including minimum stock levels and low inventory notifications.",
          side: "left",
          align: "start",
          onNextClick: () => {
            handleTabChange("vendor-settings");
            setTimeout(() => {
              systemSettingsTour.moveNext();
            });
          },
        },
      },
      {
        element: "#system-settings-vendor",
        popover: {
          title: `Vendor`,
          description: "Allows users to configure vendor settings.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#system-settings-vendor-settings",
        popover: {
          title: `Invoice Terms and Conditions`,
          description: "Text box for entering terms and conditions displayed on vendor invoices.",
          side: "left",
          align: "start",
          onNextClick: () => {
            handleTabChange("printer-settings");
            setTimeout(() => {
              systemSettingsTour.moveNext();
            });
          },
        },
      },
      {
        element: "#system-settings-printer",
        popover: {
          title: `Printer`,
          description: "Allows users to configure printer settings.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            handleTabChange("vendor-settings");
            setTimeout(() => {
              systemSettingsTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#system-settings-printer-settings",
        popover: {
          title: `Printer Configuration`,
          description: "Setup for receipt and invoice printers, including printer type and paper size.",
          side: "left",
          align: "start",
          onNextClick: () => {
            handleTabChange("social-settings");
            setTimeout(() => {
              systemSettingsTour.moveNext();
            });
          },
        },
      },
      {
        element: "#system-settings-social",
        popover: {
          title: `Social Media`,
          description: "Allows users to configure social media settings.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            handleTabChange("printer-settings");
            setTimeout(() => {
              systemSettingsTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#system-settings-social-settings",
        popover: {
          title: `Social Media Integration Settings`,
          description: "Options for linking social media accounts for marketing purposes.",
          side: "left",
          align: "start",
          onNextClick: () => {
            handleTabChange("customer-settings");
            setTimeout(() => {
              systemSettingsTour.moveNext();
            });
          },
        },
      },
      {
        element: "#system-settings-customer",
        popover: {
          title: `Cusomer`,
          description: "Allows users to configure customer settings.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            handleTabChange("social-settings");
            setTimeout(() => {
              systemSettingsTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#system-settings-customer-settings",
        popover: {
          title: `Customer Information Settings`,
          description: "Management of customer data, including required fields for customer registration.",
          side: "left",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "SystemSettings", value: true } }));
            systemSettingsTour.destroy();
          },
        },
      },
    ],
  });
  useEffect(() => {
    systemSettingsTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default SystemSettingsTour;
