import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { setLocation } from "../../Features/locationSlice";
import { emptyCart } from "../../Features/cart/cartSlice";
import SearchableSelect from "../SearchableSelect";
import ModalStyle from "../../styles/Modal.module.css";
const DuplicateInventoryLocationModal = ({ show, close, selectedLocation, setSelectedLocation }) => {
  const dispatch = useDispatch();
  const { user, allLocations } = useSelector((state) => state.auth);
  const location = useSelector((state) => state.location.location);
  return (
    <>
      <Modal show={show} onHide={close} animation={true} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Location Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span className="d-flex justify-content-center pb-4">Select location where you want to duplicate this inventory</span>
          </div>
          <div className="d-flex justify-content-center pb-4">
            <div className={`mb-1 ms-3 me-2 w-75 ${ModalStyle.locationDropdown}`}>
              <SearchableSelect
                placeholder="Select Location"
                searchable={false}
                options={allLocations}
                handleChange={(e) => {
                  if (Number(selectedLocation.value) !== Number(e.value)) {
                    setSelectedLocation(e);
                    dispatch(setLocation({ shop_id: user.shop_id, location: e }));
                    dispatch(emptyCart());
                    localStorage.removeItem("cartItems");
                    close();
                  }
                }}
                value={Object.keys(selectedLocation).length > 0 ? selectedLocation : location}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DuplicateInventoryLocationModal;
