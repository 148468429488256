import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../Features/authSlice";
import { useNavigate } from "react-router-dom";

const ModalRedirections = ({ show, data }) => {
  const { user } = useSelector((state) => state.auth);
  const user_id = user.user_id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getIcon = () => {
    if (JSON.parse(data?.json_object)?.icon === "info") {
      return (
        <FontAwesomeIcon
          icon={faInfoCircle}
          size="2x"
          className="text-info mr-2"
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size="2x"
          className="text-warning mr-2"
        />
      );
    }
  };
  function handleContinue(buttonClicked) {
    const userConsentRequired = JSON.parse(
      data?.json_object
    )?.userConsentrequired;
    if (
      (userConsentRequired === "Continue if Accepted" &&
        buttonClicked === "Continue if Accepted") ||
      (userConsentRequired === "Continue Anyway" &&
        buttonClicked === "Continue Anyway") ||
      buttonClicked === "Continue if Accepted"
    ) {
      HandleSubmit();
    }
  }
  const HandleSubmit = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/forced-redirections/${data?.title}`,
        { user_id, redirection_id: data?.id },
        { withCredentials: true }
      );
      if (response) {
        dispatch(authActions.forcedActionCompleted());
        navigate("/");
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        backdrop="static"
        show={show}
      >
        <Modal.Header className="border-0 pt-3 border-bottom">
          <div className="d-flex align-items-center gap-4">
            <h4>{data?.title}</h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container pt-3">
            <div className="row d-flex justify-content-center">
              <div className="text-center">
                {getIcon()}
                <h5 className="font-weight-bold">
                  {JSON.parse(data?.json_object)?.heading}
                </h5>
              </div>
              <p className="border pt-3 rounded">
                <div
                  dangerouslySetInnerHTML={{
                    __html: JSON.parse(data?.json_object)?.textbody,
                  }}
                ></div>
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-around">
          {JSON.parse(data?.json_object)?.userConsentrequired ===
          "Continue Anyway" ? (
            <Button
              variant="primary"
              className="w-25 mt-2"
              onClick={() => handleContinue("Continue Anyway")}
            >
              Continue
            </Button>
          ) : (
            <>
              <Button
                variant="secondary"
                className="w-25 mt-2"
                onClick={() => handleContinue("Continue Anyway")}
              >
                Decline
              </Button>
              <Button
                variant="primary"
                className="w-25 mt-2"
                onClick={() => handleContinue("Continue if Accepted")}
              >
                Acknowledged
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalRedirections;
