import axios from "axios";
import React from "react";
import { Button, Col, Offcanvas, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactSelect from "../ReactSelect";
import { default as DatePickerCom } from "../../Components/DatePicker/DatePicker";

import style from "../../styles/Reports.module.css";
import { getcomponent } from "../InventoryForms/utils";
import SearchableSelect from "../SearchableSelect";
import { reportsNameMapping } from "../../utils/reportsNameMapping";

function MobilrReportsFilter({
  show,
  location,
  secondaryActiveTab,
  shop_id,
  vendor,
  customer,
  setCustomer,
  salesperson,
  setSalesperson,
  paymentType,
  productOptions,
  isProductLoading,
  setProcuct,
  product,
  setFilterInput,
  setVendor,
  locationFields,
  setBrand,
  brand,
  category,
  setCategory,
  handleLocation,
  setPaymentType,
  handleClose,
  activeTab,
  tabs,
  handleTabChange,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  startDate,
  setStartDate,
}) {
  return (
    <div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header className="border-bottom bg-light " closeButton>
          <Offcanvas.Title>
            <div>{reportsNameMapping[activeTab]}</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="mt-3">
          <Row>
            <Col xs={12} md="6" lg="6">
              {(activeTab === undefined || activeTab === "profit-loss" || activeTab === "inventory") && (
                <div className={`d-flex align-items-center ms-auto ${style.dateFilter}`}>
                  <label className="me-4">Period Type:</label>
                  <DatePickerCom fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
                </div>
              )}
              {(activeTab === "daily-cash" || activeTab === "daily-transactional") && (
                <>
                  <div className="mb-2">
                    <DatePicker className={style.datpicker} selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="MMM dd, yyyy" />
                  </div>
                  <SearchableSelect className="mt-2" placeholder="Select Location" searchable={false} options={location} handleChange={handleLocation} value={locationFields && Object.keys(locationFields).length > 0 ? locationFields : null} />
                </>
              )}
              {(activeTab === "journal" || activeTab === "bank-transactions" || activeTab === "misc-items-history") && (
                <>
                  <div className={`d-flex align-items-center ms-auto ${style.dateFilter}`}>
                    <label className="me-4">Period Type:</label>
                    <DatePickerCom fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
                  </div>
                  <SearchableSelect className="mt-2" placeholder="Select Location" searchable={false} options={location} handleChange={handleLocation} value={locationFields && Object.keys(locationFields).length > 0 ? locationFields : null} />
                </>
              )}
              {activeTab === "daily-transactional" && (
                <>
                  <div className="mt-2">
                    <SearchableSelect
                      placeholder="Select Payment Type"
                      searchable={false}
                      options={[
                        { value: "", label: "All" },
                        { value: "Cash", label: "Cash" },
                        { value: "Card", label: "Card" },
                        { value: "Transfer", label: "Transfer" },
                        { value: "Check", label: "Check" },
                      ]}
                      handleChange={(e) => setPaymentType(e.value)}
                    />
                  </div>
                </>
              )}
              {(activeTab === "profit-loss" || activeTab === "inventory") && (
                <>
                  <div className="mt-2">
                    <SearchableSelect className="mt-2" placeholder="Select Location" searchable={false} options={location} handleChange={handleLocation} value={locationFields && Object.keys(locationFields).length > 0 ? locationFields : null} />
                  </div>
                </>
              )}
              {secondaryActiveTab === "Item-Wise-Sales-Report" || secondaryActiveTab === "Brand-Wise-Stock-Report" ? (
                <Col className="mb-2 mt-2" md="4" sm="4" lg="3" xl="2">
                  <ReactSelect
                    placeholder="Select Brand"
                    searchable={true}
                    options={(e) => {
                      return new Promise((resolve, reject) => {
                        getcomponent("brand", {}, true)
                          .then((res) => {
                            const getData = res.data.success.data.map((b) => ({
                              value: b.id,
                              label: b.label,
                            }));
                            const result = getData.filter((i) => {
                              return i.label.toLowerCase().includes(e.toLowerCase());
                            });
                            result.splice(0, 0, { label: "All", value: null });
                            resolve(result);
                          })
                          .catch((err) => {
                            reject("err" + err);
                          });
                      });
                    }}
                    handleChange={(e) => {
                      if (e.value) {
                        setBrand(e);
                      } else {
                        setBrand({});
                      }
                    }}
                    value={Object.keys(brand).length > 0 ? brand : null}
                    onInputChange={(newInput) => {
                      const inputValue = newInput.replace(/\s+/g, " ");
                      return inputValue;
                    }}
                  />
                </Col>
              ) : (
                ""
              )}
              {secondaryActiveTab === "Item-Wise-Sales-Report" || secondaryActiveTab === "Category-Wise-Stock-Report" || secondaryActiveTab === "By-Category" ? (
                <Col className="mb-2 mt-2" md="4" sm="4" lg="3" xl="2">
                  <ReactSelect
                    placeholder="Select Category"
                    searchable={false}
                    value={Object.keys(category).length > 0 ? category : null}
                    options={() => {
                      return new Promise((resolve, reject) => {
                        axios
                          .get(process.env.REACT_APP_API_URL + "/categories/get", {
                            withCredentials: true,
                          })
                          .then((res) => {
                            const getData = res.data.map((cat) => ({
                              value: cat.id,
                              label: cat.category,
                            }));
                            const result = getData.filter((cat) => cat.value < 7);
                            result.splice(0, 0, { label: "All", value: null });
                            resolve(result);
                          })
                          .catch((err) => {
                            reject("err" + err);
                          });
                      });
                    }}
                    handleChange={(e) => {
                      if (e.value) {
                        setCategory(e);
                      } else {
                        setCategory({});
                      }
                    }}
                  />
                </Col>
              ) : (
                ""
              )}
              {secondaryActiveTab === "Vendor-Wise-Stock-Report" ? (
                <Col className="mb-2 mt-2" md="4" sm="4" lg="3" xl="2">
                  <ReactSelect
                    placeholder="Select vendor"
                    searchable={true}
                    options={(e) => {
                      return new Promise((resolve, reject) => {
                        axios
                          .get(process.env.REACT_APP_API_URL + "/vendors/get-vendor-by-shop/" + shop_id, { withCredentials: true })
                          .then((res) => {
                            const data = res.data
                              .map((item) => {
                                return {
                                  label: item.vendor_name,
                                  value: item.vendor_id,
                                };
                              })
                              .filter((i) => {
                                return i.label.toLowerCase().includes(e.toLowerCase());
                              });
                            data.splice(0, 0, { label: "All", value: null });
                            resolve(data);
                          })
                          .catch((err) => {
                            reject("err" + err);
                          });
                      });
                    }}
                    handleChange={(e) => {
                      if (e.value) {
                        setVendor(e);
                      } else {
                        setVendor({});
                      }
                    }}
                    value={Object.keys(vendor).length > 0 ? vendor : null}
                    onInputChange={(newInput) => {
                      const inputValue = newInput.replace(/\s+/g, " ");
                      return inputValue;
                    }}
                  />
                </Col>
              ) : (
                ""
              )}
              {(secondaryActiveTab === "Item-Wise-Sales-Report" || secondaryActiveTab === "By-Product") && (
                <Col className="mb-2 mt-2" md="4" sm="4" lg="3" xl="2">
                  <SearchableSelect
                    placeholder="Select Product"
                    options={productOptions}
                    isLoading={isProductLoading}
                    handleChange={(e) => {
                      if (e.value) {
                        setProcuct(e);
                      } else {
                        setProcuct({});
                      }
                    }}
                    value={Object.keys(product).length > 0 ? product : null}
                    onInputChange={(newInput) => {
                      const inputValue = newInput.replace(/\s+/g, " ");
                      setFilterInput(inputValue);
                      return inputValue;
                    }}
                  />
                </Col>
              )}
              {secondaryActiveTab === "By-Customer" && (
                <div style={{ minWidth: "174px" }} className="me-3">
                  <ReactSelect
                    placeholder="Select Customer"
                    searchable={true}
                    options={(e) => {
                      return new Promise((resolve, reject) => {
                        axios
                          .get(process.env.REACT_APP_API_URL + "/customer/shop/" + shop_id, { withCredentials: true })
                          .then((res) => {
                            const data = res.data
                              .map((item) => {
                                return {
                                  label: item.customer_name,
                                  value: item.customer_id,
                                };
                              })
                              .filter((i) => {
                                return i.label.toLowerCase().includes(e.toLowerCase());
                              });
                            data.splice(0, 0, { label: "All", value: null });
                            resolve(data);
                          })
                          .catch((err) => {
                            reject("err" + err);
                          });
                      });
                    }}
                    handleChange={(e) => {
                      if (e.value) {
                        setCustomer(e);
                      } else {
                        setCustomer({});
                      }
                    }}
                    value={Object.keys(customer).length > 0 ? customer : null}
                    onInputChange={(newInput) => {
                      const inputValue = newInput.replace(/\s+/g, " ").trim();
                      return inputValue;
                    }}
                  />
                </div>
              )}
              {secondaryActiveTab === "By-Salesperson" && (
                <div style={{ minWidth: "220px" }} className="me-3 w-100">
                  <ReactSelect
                    placeholder="Select Salesperson"
                    searchable={true}
                    options={(e) => {
                      return new Promise((resolve, reject) => {
                        axios
                          .get(process.env.REACT_APP_API_URL + "/user/shop/" + shop_id, { withCredentials: true })
                          .then((res) => {
                            const data = res.data
                              .map((item) => {
                                return {
                                  label: item.user_full_name,
                                  value: item.user_id,
                                };
                              })
                              .filter((i) => {
                                return i.label.toLowerCase().includes(e.toLowerCase());
                              });
                            data.splice(0, 0, { label: "All", value: null });
                            resolve(data);
                          })
                          .catch((err) => {
                            reject("err" + err);
                          });
                      });
                    }}
                    handleChange={(e) => {
                      if (e.value) {
                        setSalesperson(e);
                      } else {
                        setSalesperson({});
                      }
                    }}
                    value={Object.keys(salesperson).length > 0 ? salesperson : null}
                    onInputChange={(newInput) => {
                      const inputValue = newInput.replace(/\s+/g, " ").trim();
                      return inputValue;
                    }}
                  />
                </div>
              )}
              {secondaryActiveTab === "By-Bank/Cash/Credit" && (
                <div style={{ minWidth: "220px" }} className="me-3 w-100">
                  <SearchableSelect
                    placeholder="Select Bank/Cash/Credit"
                    options={[
                      { value: null, label: "All" },
                      { value: "Card", label: "Debit/Credit Card" },
                      { value: "Cash", label: "Cash" },
                      { value: "Transfer", label: "Transfer" },
                    ]}
                    handleChange={(e) => {
                      if (e.value) {
                        setPaymentType(e);
                      } else {
                        setPaymentType({});
                      }
                    }}
                    value={Object.keys(paymentType).length > 0 ? paymentType : null}
                    onInputChange={(newInput) => {
                      const inputValue = newInput.replace(/\s+/g, " ").trim();
                      setFilterInput(inputValue);
                      return inputValue;
                    }}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Button onClick={handleClose} className={`${style.filterBtn}`}>
            Filter
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default MobilrReportsFilter;
