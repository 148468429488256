import axios from "axios";
import { useEffect, useState } from "react";
import { FormControl, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import NoResult from "../../Components/NoResult";
import PaginationBar from "../../Components/PaginationBar";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import search from "../../assets/svg/search.svg";
import eyeIcon from "../../assets/svg/union.svg";
import customloader from "../../assets/images/RollingLoader.gif";

const CustomerSales = ({ show, close, id }) => {
  const [salesData, setSalesData] = useState([]);
  const [isSalesLoading, setIsSalesLoading] = useState(false);
  const [customerSalesSearch, setCustomerSalesSearch] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state) => state.auth);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });

  const changePage = (page) => {
    setCurrentPage(page);
    navigate("/customer?pagesales=" + page);
  };

  const handleCloseModal = () => {
    close();
    setCustomerSalesSearch("");
  };

  useEffect(() => {
    if (searchParams.get("pagesales")) {
      setCurrentPage(Number(searchParams.get("pagesales")));
    }
  }, [currentPage, searchParams]);

  useEffect(() => {
    const getCustomerSales = async () => {
      try {
        setIsSalesLoading(true);
        const data = await axios.post(
          process.env.REACT_APP_API_URL + `/customer/sales/no-of-pages/${id}?size=${sizeOfPages}`,
          {
            userId: user.user_id,
            search: customerSalesSearch,
          },
          { withCredentials: true }
        );
        if (data) {
          setIsSalesLoading(false);
          if (data.data.no_of_pages === 0) {
            if (searchParams.get("pagesales")) {
              setNoOfPages(0);
              navigate("/customer?pagesales=0");
            }
          } else {
            setNoOfPages(data.data.no_of_pages);
            if (currentPage > data.data.no_of_pages) {
              setCurrentPage(data.data.no_of_pages);
              navigate("/customer?pagesales=" + data.data.no_of_pages);
            }
            if (searchParams.get("pagesales") === "0") {
              setCurrentPage(1);
              navigate("/customer?pagesales=1");
            }
          }
        }
      } catch (error) {
        setIsSalesLoading(false);
      }
    };
    if (id !== -1) {
      getCustomerSales();
    }
  }, [id, customerSalesSearch, user, currentPage, sizeOfPages, noOfPages, searchParams]);

  useEffect(() => {
    const getCustomerSales = async () => {
      try {
        setIsSalesLoading(true);
        setSalesData([]);
        const customerData = await axios.post(
          process.env.REACT_APP_API_URL + `/customer/sales/${id}?page=${currentPage}&size=${sizeOfPages}`,
          {
            userId: user.user_id,
            search: customerSalesSearch,
            orderBy: orderBy,
          },
          { withCredentials: true }
        );
        if (customerData) {
          setIsSalesLoading(false);
          setSalesData(customerData.data);
        }
      } catch (error) {
        setIsSalesLoading(true);
        setSalesData([]);
        setIsSalesLoading(false);
      }
    };
    if (id !== -1) {
      getCustomerSales();
    }
  }, [id, customerSalesSearch, user, currentPage, sizeOfPages, noOfPages, orderBy]);

  return (
    <Modal id="customer-sales-modal" aria-labelledby="contained-modal-title-vcenter" centered size="xl" backdrop="static" className="customerSalesModal" show={show} onHide={handleCloseModal}>
      <Modal.Header className="border-0 pt-4" closeButton>
        <div className="d-flex align-items-center gap-4">
          <h4>Customer Sales</h4>
          <div className="position-relative d-sm-block d-none">
            <FormControl type="search" placeholder="Search by number" className={`searchInput`} aria-label="Search" onChange={(e) => setCustomerSalesSearch(e.target.value)} value={customerSalesSearch} />
            <div className={`searchIcon d-flex`}>
              <img src={search} alt="search" />
            </div>
          </div>
        </div>
      </Modal.Header>
      <hr className="m-0 mb-3 d-sm-none d-block" />
      <div className="position-relative d-sm-none d-block mx-3">
        <FormControl type="search" placeholder="Search by number" className={`searchInput`} aria-label="Search" onChange={(e) => setCustomerSalesSearch(e.target.value)} value={customerSalesSearch} />
        <div className={`searchIcon d-flex`}>
          <img src={search} alt="search" />
        </div>
      </div>
      {isSalesLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "auto" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : salesData.length > 0 ? (
        <div
          style={{
            maxHeight: "70vh",
            overflow: "auto",
            display: "block",
          }}
          className="mb-4"
        >
          <CustomizeTableview
            className={"customerSalesModal mb-2 mt-3"}
            data={salesData}
            columnNotShow={["id"]}
            dateColumn={["created_at"]}
            sortColumn={["created_at", "base_grand_total", "received_amount"]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            hyperlink={{
              label: (label) => {
                return (
                  <img
                    src={eyeIcon}
                    style={{
                      pointerEvents: "none",
                    }}
                  />
                );
              },
              link: (id) => {
                return `/invoice-payments-sales/` + id;
              },
              target: "_blank",
            }}
            status={{
              column: "remaining",
              style: (value) => {
                if (value > 0) {
                  return "text-danger";
                } else {
                  return "text-success";
                }
              },
              format: (value) => {
                if (value > 0) {
                  return "Pending";
                } else {
                  return "Paid";
                }
              },
            }}
          />
          <div>
            <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
          </div>
        </div>
      ) : (
        <div className="mb-4">
          <NoResult height="auto" onClick={() => navigate("/sales")} name="Sale" />
        </div>
      )}
    </Modal>
  );
};

export default CustomerSales;
