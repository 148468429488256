import { BrowserRouter, Route, Routes } from "react-router-dom";
import RedirectComponent from "./RedirectComponent";
import ErrorBoundary from "./Components/ErrorBoundary";
import LogRocket from "logrocket";
import { useEffect } from "react";
import setupLogRocketReact from "logrocket-react";
import "driver.js/dist/driver.css";

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_ENV.replace(" ", "") === "production") {
      LogRocket.init("techbazaar/techbazaar");
      setupLogRocketReact(LogRocket);
    }
  }, []);

  return (
    <>
      <ErrorBoundary>
        <BrowserRouter>
          <RedirectComponent />
        </BrowserRouter>
      </ErrorBoundary>
    </>
  );
}

export default App;
