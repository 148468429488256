import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "../../../Features/cart/cartSlice";
import noImage from "../../../assets/svg/no-image.svg";
import Select from "react-select";
import search1 from "../../../assets/svg/search-1.svg";
import styles from "./searchBar.module.css";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const location = useSelector((state) => state.location.location);
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);

  const DropdownIndicator = (props) => {
    return (
      <div {...props} className="indicator-container mx-1">
        <img src={search1} />
      </div>
    );
  };
  const loadOptions = async (inputValue) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/inventory/get/${user.user_id}?page=1&size=10`, { search: inputValue || "", available: true, location_id: location.value }, { withCredentials: true });
      const responseArray = res?.data?.data?.map((result) => ({
        value: {
          id: result.id,
          title: result.title,
          sale_price: result.sale_price,
          // product_type:result.label

          img0: result.img0,
          cartQuantity: 1,
          label: result.label,
        },
        id: result.id,
        title: result.title,
        img0: `${process.env.REACT_APP_URL_S3}/${result.img0}`,

        price: result.sale_price,
        label: result.label,
      }));
      return responseArray;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const handleInputChange = (input, e) => {
    setSearchTerm(input);
  };

  const handleSelectChange = (selectedOption, event) => {
    if (event.action === "clear") {
      setSearchTerm("");
    } else {
      if (selectedOption) {
        dispatch(addToCart(selectedOption.value));
      }
    }
  };

  const isOptionDisabled = (option) => {
    return cartItems.some((cartItem) => cartItem.id === option.id);
  };
  useEffect(() => {
    loadOptions(searchTerm).then((options) => {
      setSearchResults(options);
    });
  }, [searchTerm]);

  return (
    <div style={{ width: "50%" }} className={`${styles.searchBar}`} id="sales-cart-search">
      <Select
        placeholder="Search Items"
        components={{ DropdownIndicator }}
        classNamePrefix="react-select"
        value={searchTerm?.length > 0 ? { label: searchTerm } : null}
        onChange={handleSelectChange}
        onInputChange={handleInputChange}
        inputValue={searchTerm}
        inputMode="search"
        options={searchResults}
        isOptionDisabled={isOptionDisabled}
        isClearable
        filterOption={() => {
          return true;
        }}
        noOptionsMessage={() => "No results found"}
        formatOptionLabel={({ title, img0, price }) =>
          img0 && price ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={img0}
                alt={title}
                style={{ height: 30, width: 30, marginRight: 10 }}
                onError={(e) => {
                  e.target.src = noImage;
                }}
              />
              <div>
                <p style={{ fontWeight: "600", marginBottom: 1, fontSize: "14px" }}>{title}</p>
                <p style={{ margin: 0, fontSize: "12px" }}> Rs. {price}</p>
              </div>
            </div>
          ) : (
            title
          )
        }
      />
    </div>
  );
};

export default SearchBar;
