import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const DailyTransactionalReportTour = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const dailyTransactionalReportTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "DailyTransactionalReport", value: true } }));
      setTimeout(() => {
        dailyTransactionalReportTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#daily-transactional-report",
        popover: {
          title: `Daily Transactional Report`,
          description: "This report details all types of transactions, including cash, credit, and card transactions, that occur daily.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#daily-transactional-report-payment-type",
        popover: {
          title: `Select Payment Type`,
          description: "Provides filtering options based on the payment type.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#daily-transactional-report-date-filter",
        popover: {
          title: `Date Filter`,
          description: "Provides the filter option based on the dates on the calendar.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#daily-transactional-report-location-filter",
        popover: {
          title: `Select Location`,
          description: "Provides the filtering option based on the location of shops.",
          side: "right",
          align: "start",
        },
      },

      {
        element: "#daily-transactional-report-grand-total",
        popover: {
          title: `Grand Total`,
          description: "Shows the grand total of the report.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#daily-transactional-report-sale-payments",
        popover: {
          title: `Sale Payments`,
          description: "Shows the sales revenue generated by the shop.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#daily-transactional-report-supplier-payments",
        popover: {
          title: `Supplier Payments`,
          description: "Shows the payments made to suppliers for goods or services.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#daily-transactional-report-expenses",
        popover: {
          title: `Expenses`,
          description: "Shows the costs incurred by the business, such as operating expenses, salaries, and utilities.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#daily-transactional-report-refunded-payments",
        popover: {
          title: `Refunded Payments`,
          description: "Shows the payments refunded to customers for returned items or other reasons.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "DailyTransactionalReport", value: true } }));
            setTimeout(() => {
              dailyTransactionalReportTour.destroy();
            });
          },
        },
      },
    ],
  });
  useEffect(() => {
    dailyTransactionalReportTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default DailyTransactionalReportTour;
