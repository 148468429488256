import React, { useState } from "react";
import { Button, Card, Col, Collapse, Row } from "react-bootstrap";
import style from "./listingWidget.module.css";
// Icons
import activeIcon from "../../../assets/svg/listingWidgetActive.svg";
import remainingSubscriptionIcon from "../../../assets/svg/remainingSubscriptionListings.svg";
import totalViewsIcon from "../../../assets/svg/shopViewsListings.svg";
import leadGeneratedIcon from "../../../assets/svg/leadGeneratedListings.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

function CustomCard({ icon, count, label }) {
  return (
    <Card className={`${style.cards} border-radius`}>
      <div className={`d-flex align-items-end ${style.cardBodyContent}`}>
        <div className={`${style.widgetIconDiv}`}>
          <img
            style={{
              pointerEvents: "none",
            }}
            className={`${style.widgetIcon}`}
            src={icon}
            alt="card logo"
          />
        </div>
        <div className="text-sm-start d-flex gap-2 align-items-baseline d-lg-grid d-md-grid d-sm-grid gap-md-0 gap-lg-0 gap-sm-0 p-1">
          <Card.Title className={`mb-0 ${style.cartCount}`}>
            {
              count !== null ? count : "--" //add loader in else part
            }
          </Card.Title>
          <Card.Text className={`${style.cardstxt}`}>{label}</Card.Text>
        </div>
      </div>
    </Card>
  );
}
function ActiveListingWidget({ active }) {
  return <CustomCard icon={activeIcon} count={active} label="Active" />;
}
function RemainingSubscriptionListingWidget({ remaining }) {
  return <CustomCard icon={remainingSubscriptionIcon} count={remaining} label="Remaining (Per Subscription)" />;
}

function TotalViewsListingWidget({ total }) {
  return <CustomCard icon={totalViewsIcon} count={total} label="Total shop views" />;
}

function LeadGeneratedListingWidget({ lead }) {
  return <CustomCard icon={leadGeneratedIcon} count={lead} label="Lead generated" />;
}

function CollapseListingWidget({ className, active, lead, remaining, total }) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div
      style={{
        borderRadius: "6px",
        overflow: "hidden",
      }}
      className={`${className} ${style.collapseDiv} m-3`}
    >
      <Button className={` w-100 ${style.collapseButton}`} onClick={() => setIsOpen((prev) => !prev)} aria-controls="listings-widget-collapseable-component" aria-expanded={isOpen} variant="outline-secondary">
        <span className="d-flex align-items-center gap-1">
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
          Overview
        </span>
      </Button>
      <Collapse in={isOpen} dimension={"height"}>
        <div id="listings-widget-collapseable-component">
          <ActiveListingWidget active={active} />
          <RemainingSubscriptionListingWidget remaining={remaining} />
          <TotalViewsListingWidget total={total} />
          <LeadGeneratedListingWidget lead={lead} />
        </div>
      </Collapse>
    </div>
  );
}

function ListingWidget({ active = 13, remaining = 25, total = 1000, lead = 100 }) {
  return (
    <>
      <Row className="d-none d-sm-flex ps-4 w-100 mb-5 mb-xl-4">
        <Col xl={3} lg={6} md={6} sm={6} className=" p-1" id="active-listings">
          <ActiveListingWidget active={active} />
        </Col>
        <Col xl={3} lg={6} md={6} sm={6} className=" p-1" id="remaining-listings">
          <RemainingSubscriptionListingWidget remaining={remaining} />
        </Col>
        <Col xl={3} lg={6} md={6} sm={6} className="p-1" id="total-shop-views">
          <TotalViewsListingWidget total={total} />
        </Col>
        <Col xl={3} lg={6} md={6} sm={6} className="p-1" id="lead-generated">
          <LeadGeneratedListingWidget lead={lead} />
        </Col>
      </Row>
      <CollapseListingWidget className="d-block d-sm-none" active={active} lead={lead} remaining={remaining} total={total} />
    </>
  );
}

export default ListingWidget;
