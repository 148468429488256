import AsyncSelect from "react-select/async";

const ReactSelect = ({
  label,
  value,
  id,
  onClick,
  disabled,
  handleChange,
  placeholder,
  onKeyDown,
  options,
  important,
  multiselect,
  searchable,
  defaultValue,
  isClearable,
  defaultInputValue,
  onInputChange,
  isLoading,
  ...props
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#5932EA" : "#80858C",
      background: state.isSelected ? "#F5F6FA" : "#fff",
      padding: 10,
      background: state.isDisabled ? "#E8E8E8" : "",
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: "100%",
      maxWidth: "100%",
    }),
    singleValue: (provided, state) => ({
      color: "#000",
      marginTop: "-30px",
    }),
  };

  return (
    <>
      {label ? (
        <label>
          {label}
          {important ? <span className="text-danger">*</span> : <span></span>}
        </label>
      ) : (
        ""
      )}
      <AsyncSelect
        className="text-secondary bg-white text-capitalize"
        classNamePrefix="react-select-container"
        defaultValue={defaultValue}
        backspaceRemovesValue
        defaultInputValue={defaultInputValue}
        isMulti={multiselect}
        isDisabled={disabled}
        isSearchable={searchable}
        menuPlacement="bottom"
        loadOptions={options}
        closeMenuOnSelect={true}
        cacheOptions
        defaultOptions
        isClearable={isClearable}
        value={value}
        placeholder={placeholder}
        id={id}
        onKeyDown={onKeyDown}
        onClick={onClick}
        onChange={handleChange}
        styles={customStyles}
        onInputChange={onInputChange}
        isLoading={isLoading}
        {...props}
      />
    </>
  );
};

export default ReactSelect;
