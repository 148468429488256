import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";
const MarketplaceTour = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const marketplaceTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Marketplace", value: true } }));
      setTimeout(() => {
        marketplaceTour.destroy();
      }, 100);
    },

    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Marketplace",
        popover: {
          title: "Marketplace",
          description: "Overall shop marketplace overview.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#listings",
        popover: {
          title: `Marketplace Listings`,
          description: "Shows and manages the online marketplace.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#active-listings",
        popover: {
          title: `Active`,
          description: "Shows the number of active listings on the marketplace.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#remaining-listings",
        popover: {
          title: `Remaining`,
          description: "Shows remaining number of listings(per subscription).",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#total-shop-views",
        popover: {
          title: `Total shop views`,
          description: "Shows the total number of views on shop.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#lead-generated",
        popover: {
          title: `Lead generated`,
          description: "Shows the total number of leads generated.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#listings-tabs",
        popover: {
          title: `Listing tabs`,
          description: "It shows list of Active and inactive listings with their information and product link.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#search-listings",
        popover: {
          title: `Search bar`,
          description: "From here seller can search any listed product by its title.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#size-filter",
        popover: {
          title: `Page Size`,
          description: "Seller can manage the number of listings per page.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#add-listing-btn",
        popover: {
          title: `Add listing`,
          description: "Click this button to open the Add Listing modal, where you can select inventory and send it to the listings for activation.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#category-filter",
        popover: {
          title: `Category Filter`,
          description: "From this dropdown, seller can select listing’s category.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#brand-filter",
        popover: {
          title: `Brand Filter`,
          description: "From this dropdown, seller can select which brand’s listing he wants to see.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#order-filter",
        popover: {
          title: `Order Filter`,
          description: "By selecting this, newest products will appear at the top of the list.",
          side: "right",
          align: "start",
        },
      },

      {
        element: "#reset-filter",
        popover: {
          title: `Reset Filter`,
          description: "From this button, seller can reset all the filters.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#table-settings",
        popover: {
          title: `Table`,
          description: "To customize the columns of table.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#action-button",
        popover: {
          title: `Activate listing`,
          description: `Opens the Activate Listing modal with fields like Title, Brand, Quantity, Price, and Discount. Toggle visibility, or use 'Close' and 'Back to Validation' option.`,
          side: "right",
          align: "start",
        },
      },
      {
        element: "#archive",
        popover: {
          title: `Archive listing`,
          description: "On clicking this button an archive modal will be shown from where seller can archive a product.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#unarchive",
        popover: {
          title: `Unarchive listing`,
          description: "On clicking this button an unarchive modal will be shown from where seller can unarchive a product.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Marketplace", value: true } }));
            setTimeout(() => {
              marketplaceTour.destroy();
            }, 100);
          },
        },
      },
    ],
  });
  useEffect(() => {
    marketplaceTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default MarketplaceTour;
