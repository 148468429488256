import react, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import style from "../../styles/Reports.module.css";
import styles from "../../styles/Setting.module.css";
import SearchableSelect from "../SearchableSelect";
import DatePicker from "../DatePicker/DatePicker";
import moment from "moment/moment";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomizeTableview from "../TableViews/CustomizeTableview";
import LoaderPage from "../loaders/loaderPage";
import PaginationBar from "../PaginationBar";
import ReactSelect from "../ReactSelect";
import MobilrReportsFilter from "./MobileReportsFilter";
import MiscItemsHistoryReportTour from "../../TourGuides/MiscItemsHistoryReportTour";
const MiscItemsHistoryReport = ({ fromDate = moment().subtract(29, "days").startOf("day")._d, toDate = moment().endOf("day")._d, setToDate, setFromDate, close, show, MainactiveTab }) => {
  const { user } = useSelector((state) => state.auth);
  const userId = user.user_id;
  const user_type = user.user_type;
  const shop_id = user.shop_id;
  const [location, setLocation] = useState({});
  let [locationFields, setLocationFields] = useState({});
  const [tableData, setTableData] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const dateObj = new Date();
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });

  var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const convertDate = (date) => {
    const inputDate = new Date(date);
    const formattedDate = months[inputDate.getMonth()] + " " + inputDate.getDate() + " " + inputDate.getFullYear();
    return formattedDate;
  };

  const handleLocation = (e) => {
    if (e.value) {
      setLocationFields(e);
    } else {
      setLocationFields({});
    }
  };

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + `/location/get-location-by-user/${userId}`, { shop_id: shop_id, user_type: user_type }, { withCredentials: true })
      .then((res) => {
        const locationsArr = res.data.map((loc) => ({
          value: loc.location_id,
          label: loc.location,
          isDisabled: new Date().toISOString() >= loc.active_till ? true : false,
        }));

        setLocation(locationsArr);
        setLocationFields({
          value: locationsArr[0]?.value,
          label: locationsArr[0]?.label,
          isDisabled: locationsArr[0]?.isDisabled,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [userId, shop_id, user_type]);
  const fetchData = (locationFields) => {
    const filter = {
      location_id: locationFields?.value,
      dates: { from: fromDate, to: toDate },
      orderBy,
    };
    axios
      .get(process.env.REACT_APP_API_URL + `/reports/miscellaneous-report/${shop_id}?page=` + currentPage + `&size=` + sizeOfPages, {
        withCredentials: true,
        headers: { filters: JSON.stringify(filter) },
      })
      .then((res) => {
        const data = res?.data?.data;
        const updatedObj = data?.map((item) => ({
          misc_id: item?.misc_id,
          sales_order_id: item?.sales_order_id,
          name: item?.name,
          price: item?.price,
          description: item?.description,
          ordered_qty: item?.qty_ordered,
          returned_qty: item?.returned_qty,
          discount: item?.discount,
          created_at: item.created_at,
          sales_order_number: item?.sales_order_number,
        }));
        setTableData(updatedObj);
        // setTableData(res.data?.data);
        setCount(res.data?.count?.total_items);
        setNoOfPages(res.data?.count?.total_pages);
        setIsLoading(false);
      })
      .catch((err) => {
        throw new Error(err.response.data);
      });
  };

  useEffect(() => {
    if (Object.keys(locationFields).length > 0) {
      fetchData(locationFields);
    }
  }, [shop_id, locationFields, orderBy, fromDate, toDate, currentPage]);

  const changePage = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [shop_id, fromDate, toDate, location]);
  const link = (data) => {
    return `/invoice-payments-sales/${data.sales_order_id}`;
  };
  return (
    <>
      {user?.tour && !user?.tour?.MiscItemsHistoryReport && <MiscItemsHistoryReportTour />}
      <Row className="align-items-center justify-content-start px-2 py-2 bg-white mb-3 mt-1 d-none d-sm-flex" id="misc-items-history-report-filter">
        <Col md="4" sm="4" lg="3" xl="2" className="position-relative mb-md-0 mb-2">
          <DatePicker fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
        </Col>
        <Col className="mb-2 mb-md-0" md="4" sm="4" lg="3" xl="2">
          <SearchableSelect placeholder="Select Location" searchable={false} options={location} handleChange={handleLocation} value={Object.keys(locationFields).length > 0 ? locationFields : null} style={{ width: "100px" }} disabled={!location.length} />
        </Col>
        <Col className="mb-2 mb-md-0">
          <p className="fw-bold fs-16 ms-2 float-end p-0 my-0">{`${convertDate(fromDate)}` === `${convertDate(toDate)}` ? convertDate(toDate) : `${convertDate(fromDate)} - ${convertDate(toDate)}`} </p>{" "}
        </Col>
      </Row>
      {isLoading ? (
        <div style={{ height: "50vh" }} className="">
          <LoaderPage />
        </div>
      ) : tableData.length > 0 ? (
        <>
          <div className={style.brdrd} id="misc-items-history-report-table">
            <CustomizeTableview className={"mt-1"} data={tableData} SimpleLink={{ link: link, label: "invoice" }} columnNotShow={["misc_id", "description", "sales_order_id"]} dateColumn={["created_at"]} sortColumn={["created_at"]} orderBy={orderBy} setOrderBy={setOrderBy} />
          </div>
          <Row className="mt-5 align-items-center">
            <Col className="d-none d-sm-block" sm="2" md="3" lg="3">
              <span className="text-secondary fs-6">
                {count} {count > 1 ? "results" : "result"}
              </span>
            </Col>
            <Col xs="12" sm="7" md="6" lg="6">
              <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
            </Col>
            <Col className="d-sm-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">
                {count} {count > 1 ? "results" : "result"}{" "}
              </span>
            </Col>
          </Row>
        </>
      ) : (
        <Row className={`${style.noDataAccordian} fw-bold fs-16 d-flex justify-content-center mt-2 py-3`} style={{ marginBottom: "2rem" }}>
          No data found
        </Row>
      )}
      <MobilrReportsFilter activeTab={MainactiveTab} handleLocation={handleLocation} locationFields={locationFields} location={location} show={show} handleClose={close} toDate={toDate} fromDate={fromDate} setToDate={setToDate} setFromDate={setFromDate} />
    </>
  );
};
export default MiscItemsHistoryReport;
