import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const IMEIReportTour = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const imeiReportTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "IMEIReport", value: true } }));
      setTimeout(() => {
        imeiReportTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#imei-report",
        popover: {
          title: `IMEI Report`,
          description: "Shows the sales or transactions of products tracked by their IMEI (International Mobile Equipment Identity) numbers.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#imei-report-dropdown",
        popover: {
          title: `IMEI Dropdown`,
          description: " Provides the option to select the system-saved IMEI or search them.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "IMEIReport", value: true } }));
            imeiReportTour.destroy();
          },
        },
      },
    ],
  });
  useEffect(() => {
    imeiReportTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default IMEIReportTour;
