import React, { useState } from "react";
import { Container, Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import filterStyle from "../styles/filtersButtons.module.css";
import customloader from "../assets/images/RollingLoader.gif";
import MobileSearchAcoordian from "../Components/MobileSearchAcoordian";
import PopUpModal from "../Components/Modal";
import { useEffect } from "react";
import FileUplaodModal from "../Components/Bulk-Upload/FileUplaodModal";
import CustomizeTableview from "../Components/TableViews/CustomizeTableview";
import NoResult from "../Components/NoResult";
import DataImportTour from "../TourGuides/DataImportTour";

const DELAY_TIME = 100;

const DataImport = () => {
  let [items, setItems] = useState([]);
  let [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const [refresh, toggle] = useState(false);
  const [messageModalShow, setMessageModalShow] = useState(false);
  const [messagefield, setMessagefield] = useState();

  const [sizeOfPages, setSizeOfPages] = useState(10);
  // const [orderBy, setOrderBy] = useState({ "items.created_at": "DESC" });
  const [orderBy, setOrderBy] = useState({ "items.stock_no": "DESC" });
  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);
  const [uploadShow, setUploadShow] = useState(false);

  const shop_id = user.shop_id;
  const changePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (user?.tour && !user?.tour?.DataImport) {
      setIsLoading(false);

      setItems([
        {
          request_date: "1970-01-01T06:46:24.000Z",
          id: 0,
          status: "pending",
          message: null,
          file: "uploads/documents/1729061183600_346_561_TechBazaar.csv",
        },
      ]);
    } else {
      let searchobj = {};

      const pageTimeout = setTimeout(async () => {
        setIsLoading(true);
        try {
          const res = await axios.get(process.env.REACT_APP_API_URL + `/data-import/list/${shop_id}`, { withCredentials: true });
          const data = res.data.data;

          setItems(
            data.map((item) => {
              return {
                request_date: item.requestDate,
                id: item.requestId,
                status: item.requestStatus,
                message: item.requestMessage,
                file: item.requestFile,
              };
            })
          );
          // setSearchDataa(data);
          setIsLoading(false);
        } catch (error) {
          setItems([]);
          setIsLoading(false);
          // throw new Error(error.response.data);
        }
      }, DELAY_TIME);

      return () => {
        clearTimeout(pageTimeout);
      };
    }
  }, [sizeOfPages, currentPage, refresh, orderBy, uploadShow, user?.tour?.DataImport]);

  const handleAcoordianCLose = () => {};

  const message = (value) => {
    setMessagefield(value["message"]);
    setMessageModalShow(true);
  };
  return (
    <>
      {user?.tour && !user?.tour?.DataImport && <DataImportTour setUploadShow={setUploadShow} />}
      {toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian
        // SearchData={SearchData}
        // toggleSearchclose={handleAcoordianCLose}
        // filter={filter}
        />
      ) : (
        <Container fluid>
          {toogleMobileSearch === "search" ? (
            <div className="d-flex  mb-2 justify-content-center align-items-center">
              <div className=""></div>
              <span className="ms-4" onClick={handleAcoordianCLose}>
                X
              </span>
            </div>
          ) : (
            <Row className="d-flex align-items-center">
              <Col className={`d-lg-none d-inline mb-3 ${filterStyle.filter}`} xs="2" lg="2"></Col>
              <Col xs="2" sm="3" lg="2" xl="2" xxl="2" className={`mb-3 p-0 `}>
                <Button
                  id="data-import-button"
                  type="submit"
                  style={{
                    fontSize: "1rem",
                    height: "100%",
                  }}
                  className={` `}
                  onClick={() => {
                    setUploadShow(true);
                  }}
                >
                  <span className="d-sm-inline  d-lg-inline">Upload File</span>{" "}
                </Button>
              </Col>

              {/* For Mobile screen End */}
            </Row>
          )}
          <>
            <Row style={{ marginBottom: "-3rem" }} className="mt-3">
              <Col id="data-import-table">
                {isLoading ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                    <img src={customloader} alt="customloader" width="100" height="100" />
                  </div>
                ) : !items[0] ? (
                  <NoResult name="Inventory" />
                ) : (
                  <CustomizeTableview
                    className={`mt-3`}
                    data={items}
                    message={message}
                    columnNotShow={["id", "fk_shop_id", "fk_location_id", "is_validated", "file", "status", "message"]}
                    dateColumn={["request_date"]}
                    sortColumn={["location_name", "created_at"]}
                    actions={false}
                    url={true}
                    status={{
                      column: "status",
                      style: (value) => {
                        if (value === "done") {
                          return "text-success";
                        } else if (value === "partially done") {
                          return "text-primary";
                        } else if (value === "pending") {
                          return "text-warning";
                        } else if (value === "in progress") {
                          return "text-info";
                        } else if (value === "disapproved") {
                          return "text-danger";
                        }
                      },
                      format: (value) => {
                        return value?.charAt(0).toUpperCase() + value.slice(1);
                      },
                    }}
                  />
                )}
              </Col>
            </Row>
            <Row className="mt-5 align-items-center">
              <Col className="d-none d-sm-block" sm="2" md="3" lg="3">
                <span className="text-secondary fs-6">{items.length} result(s)</span>
              </Col>
              <Col xs="12" sm="7" md="6" lg="6"></Col>
              <Col className="d-sm-none d-flex align-items-center mt-3" xs="6" lg="3">
                <span className="text-secondary fs-6">{items.length} result(s)</span>
              </Col>
            </Row>
            <PopUpModal
              title="File Upload"
              show={uploadShow}
              onHide={() => {
                setUploadShow(false);
              }}
            >
              <FileUplaodModal onClose={() => setUploadShow(false)} />
            </PopUpModal>
            <PopUpModal
              title="Message"
              show={messageModalShow}
              onHide={() => {
                setMessageModalShow(false);
              }}
            >
              {" "}
              <div>
                {messagefield?.split("\n").map((sentence, index) => (
                  <React.Fragment key={index}>
                    {sentence.trim()}.
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </PopUpModal>
          </>
        </Container>
      )}
    </>
  );
};
export default DataImport;
