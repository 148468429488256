import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../Features/authSlice";
import cartReducer from "../Features/cart/cartSlice";
import printSettings from "../Features/printSettings/printSettings";
import locationReducer from "../Features/locationSlice";
import notificationReducer from "../Features/notificationSlice";
import routesReducer from "../Features/routesSlice"
export const store = configureStore({
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
  reducer: {
    auth: authReducer,
    cart: cartReducer,
    printerType: printSettings,
    location: locationReducer,
    notification: notificationReducer,
    routes:routesReducer
  },
});
