import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const AddInventoryTour = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const addInventoryTour = driver({
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "AddInventory", value: true } }));
      setTimeout(() => {
        addInventoryTour.destroy();
      }, 100);
    },

    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Add-Inventory",
        popover: {
          title: "Add Inventory",
          description: "Add Inventory stock.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#inventory-form",
        popover: {
          title: `Add inventory form`,
          description: "A form for adding and updating inventory.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#inventory-request",
        popover: {
          title: `Inventory import request`,
          description: "Clicking on this button shows a file upload modal where seller can upload inventory files from the computer's internal storage.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#inventory-form-category",
        popover: {
          title: `Category`,
          description: "From this dropdown you can select inventory category.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#inventory-form-title",
        popover: {
          title: `Title`,
          description: "In this field seller can add the title of the product.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#inventory-form-location",
        popover: {
          title: `Location`,
          description: "Location on which the inventory will be stored.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#inventory-form-condition",
        popover: {
          title: `Condition`,
          description: "From this dropdown seller can select the condition of inventory like new,used,refurbished or other.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#inventory-form-quantity",
        popover: {
          title: `Quantity`,
          description: "In this field seller can add quantity he want to add for the product.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#inventory-form-cost-price",
        popover: {
          title: `Cost Price`,
          description: "In this field seller can add cost price of inventory product.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#inventory-form-sale-price",
        popover: {
          title: `Sale Price`,
          description: "In this field seller can add sale price of inventory product.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#image-upload",
        popover: {
          title: `Add Images`,
          description: "From where seller can add product images.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#mobile-image-upload",
        popover: {
          title: `Upload via Mobile`,
          description: "Upload product images via mobile.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#inventory-form-list-button",
        popover: {
          title: `List Button`,
          description: "Back to the inventory list.",
          side: "top",
          align: "center",
        },
      },
      {
        element: "#inventory-form-save-button",
        popover: {
          title: `Save Button`,
          description: "To save the inventory.",
          side: "top",
          align: "center",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "AddInventory", value: true } }));
            setTimeout(() => {
              addInventoryTour.destroy();
            });
          },
        },
      },
    ],
  });

  useEffect(() => {
    addInventoryTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <div></div>;
};

export default AddInventoryTour;
