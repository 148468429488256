import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
import styles from "../../styles/ImeiForm.module.css";

function PromptMoal({ show, close, removeProducts }) {
  return (
    <div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
        backdrop="static"
        show={show}
        onHide={close}
        className={`${styles.imei}`}
      >
        <Modal.Header className="border-0 pt-4" closeButton></Modal.Header>
        <hr className="m-0 mb-2" />
        <Modal.Body className="mx-4  mb-4">
          By changing location you will lose your products added in PO
          <div className="d-flex">
            <Button
              onClick={() => {
                close();
                removeProducts();
              }}
              type="button"
              className="w-75  me-3 mt-4"
            >
              Confirm
            </Button>
            <Button
              variant="secondary"
              onClick={close}
              type="button"
              className="w-75 mt-4"
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PromptMoal;
