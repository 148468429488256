import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { driver } from "driver.js";
import { updateTourGuide } from "../Features/authSlice";

const ReservationsTour = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const reservationsTour = driver({
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Reservations", value: true } }));
      setTimeout(() => {
        reservationsTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Reservations",
        popover: {
          title: `Reservations`,
          description: "Shows and manages product reservations from the marketplace.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#reservations-search",
        popover: {
          title: `Search Bar`,
          description: "Here, the seller can search any reservation by it’s listing title.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#reservations-category",
        popover: {
          title: `Category Filter`,
          description: "From this dropdown seller can select which category’s reservations he wants to see.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#reservations-status-filter",
        popover: {
          title: `Status Filter`,
          description: "Seller can select Reservation based on  their status",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#reservations-page-size",
        popover: {
          title: `Page Size`,
          description: "Seller can change the number of reservations per page.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#reservations-filter-button",
        popover: {
          title: `Reset Filters`,
          description: "Clicking on this button will remove all applied filters on the search result.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#reservations-table > div:nth-child(1)",
        popover: {
          title: `Reservations List`,
          description: "This table shows all reservation details with actions to mark that reservation as done or cancelled",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#done",
        popover: {
          title: `Done`,
          description: `Seller can sell reserve products of a pending reservation directly from here.`,
          side: "right",
          align: "start",
        },
      },
      {
        element: "#cancel",
        popover: {
          title: `Cancel`,
          description: `Seller can cancel a reservation.`,
          side: "right",
          align: "start",
        },
      },
      {
        element: "#csv-download",
        popover: {
          title: `Download CSV`,
          description: "This button downloads all the reservation information on CSV file.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Reservations", value: true } }));
            setTimeout(() => {
              reservationsTour.destroy();
            }, 100);
          },
        },
      },
    ],
  });

  useEffect(() => {
    reservationsTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default ReservationsTour;
