import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const InventoryTour = ({ handleTabs }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const inventoryTour = driver({
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Inventory", value: true } }));
      setTimeout(() => {
        inventoryTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Inventory",
        popover: {
          title: "Inventory",
          description: "Shop Inventory stock.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#inventory",
        popover: {
          title: `A guide to inventory`,
          description: "Shows and manages product stock and availability.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#inventory-search",
        popover: {
          title: `Search Bar`,
          description: "Seller can search any added inventory.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#inventory-condition",
        popover: {
          title: `Condition Filter`,
          description: "It shows condition options like: New,used,refurbished or other.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#inventory-category",
        popover: {
          title: `Category Filter`,
          description: "It shows category options like laptop ,mobile,TV/Monitor,Desktop computer and accessories.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#inventory-page-size",
        popover: {
          title: `Page Size`,
          description: "Seller can change the number of items per page.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#inventory-add-button",
        popover: {
          title: `Add inventory button`,
          description: "It shows a form which consist of fields for adding inventory.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#inventory-filter-button",
        popover: {
          title: `Reset Filters`,
          description: "Seller can reset all filters.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#table-settings",
        popover: {
          title: `Table settings`,
          description: "To customize the columns of table.",
          side: "right",
          align: "start",
        },
      },
      {
        element: ".nav-item:first-child > button",
        popover: {
          title: `Inventory`,
          description: "Added inventory.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#inventory-table > div:nth-child(2)",
        popover: {
          title: `Inventory List`,
          description: "shows list of added inventory",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#batches",
        popover: {
          title: `Batches`,
          description: "Upon clicking on batches button, it shows batch list. Seller can add, edit or delete batches.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#barcode",
        popover: {
          title: `Barcode`,
          description: "Shows barcode of inventory.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#customize-tableview-inventory-actions-0",
        popover: {
          title: `Actions`,
          description: "Seller can edit, duplicate, archive or delete inventory based on action.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#csv-download",
        popover: {
          title: `Download CSV`,
          description: "Download inventory in CSV format.",
          side: "right",
          align: "start",
          onNextClick: () => {
            handleTabs("Archived Inventory");
            inventoryTour.moveNext();
          },
        },
      },
      {
        element: ".nav-item:last-child > button",
        popover: {
          title: `Archived Inventory`,
          description: "Shows all archived inventory.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            handleTabs("Inventory");
            inventoryTour.movePrevious();
          },
        },
      },
      {
        element: "#archived-inventory-table > div:nth-child(2)",
        popover: {
          title: `Archived Inventory List`,
          description: "shows list of Archived inventory",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Inventory", value: true } }));
            setTimeout(() => {
              inventoryTour.destroy();
            }, 100);
          },
        },
      },
    ],
  });

  useEffect(() => {
    inventoryTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default InventoryTour;
