import { forwardRef } from "react";
import style1 from './../../../styles/InvoiceCard.module.css'
import style from './../../../styles/Invoice.module.css'
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './../../../styles/thermalPrintlayout.css';

const UpdateReceivePaymentThermalInvoice = forwardRef((props, ref) => {
    const { user } = useSelector((state) => state.auth);
    const logo = user.logo_path;
    
    var invoiceDate = new Date().toLocaleString().split(",")[0];
    var invoicetime = new Date().toLocaleString().split(",")[1];
    return (
        <div className={'mx-auto w-50 text-center bg-white py-3'}>
            <div ref={ref} className={'thermalInvoice text-center mx-auto'}>
                <div>
                    <div>
                        <h2 className="shopName m-0">{props?.data?.shop_name}</h2>
                        <div className='shopDetails'><strong>Email: </strong> {props?.data?.owner_email}</div>
                        <div className='shopDetails'>{props?.data?.shop_address}</div>
                        <div className='shopDetails'>
                        Phone: {(props?.data?.is_contact_no_on_invoice === 1 && props?.data?.is_alternative_no_on_invoice ===1 )?<>{props?.data?.contact_number}<br />{props?.data?.alternative_number}</>: props?.data?.is_contact_no_on_invoice === 1?props?.data?.contact_number:props?.data?.is_alternative_no_on_invoice === 1 ?props?.data?.alternative_number:props?.data?.owner_whatsapp_number }
                            {/* <strong>Mobile No: </strong> {props?.data?.owner_whatsapp_number} */}
                            </div>
                        <div className='shopDetails text-capitalize'><strong>Salesperson: </strong> {user?.user_full_name}</div>
                        <div className='shopDetails mt-2'><strong>Invoice Number: </strong> {props?.data?.invoice_number}</div>
                        <table className={`mx-auto text-start mt-1 thermalPrinterTable ${style?.itemsTable}`}>
                            <thead style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000' }}>
                                <th colSpan={1} style={{ border: '1px solid #000', padding: '5px' }}>{invoiceDate}</th>
                                <th colSpan={3} style={{ border: '1px solid #000', padding: '5px', textAlign: 'end' }}>{invoicetime}</th>
                            </thead>
                            <thead style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000' }}>
                                <th className='border-0 extraTh'></th>
                            </thead>
                            <thead style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000' }}>
                                <th style={{ border: '1px solid #000', padding: '5px' }}>Description</th>
                                <th style={{ border: '1px solid #000', padding: '5px' }}>Unit Price</th>
                                <th style={{ border: '1px solid #000', padding: '5px' }}>QTY</th>
                                <th style={{ border: '1px solid #000', padding: '5px' }}>Total</th>
                            </thead>
                            {
                                props?.items?.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ borderTop: '1px solid #000', padding: '5px' }} className="d-flex flex-column">
                                            {item?.title}
                                        </td>
                                        <td style={{ border: '1px solid #000', padding: '5px' }}>{Number(item?.price).toLocaleString('en-IN')}</td>
                                        <td style={{ border: '1px solid #000', padding: '5px' }}>{item?.cartQuantity}</td>
                                        <td style={{ border: '1px solid #000', padding: '5px' }}>{Number(item?.cartQuantity * item?.price).toLocaleString('en-IN')}</td>
                                    </tr>
                                ))
                            }
                        </table>
                        <div className='w-50 mx-auto mt-2'>
                            <Row >
                            <Col xs="1" sm="2" md="2" lg="6" className='d-none d-md-block'>
                                </Col>
                                <Col xs="6" sm="5" md="4" lg="3" className='text-start'>
                                    <p className='totals m-0'> Subtotal </p>
                                </Col>
                                <Col xs="6" sm="5" md="4" lg="3" className='text-end'>
                                    <p className='totals m-0'> {Number(props?.data?.base_subtotal).toLocaleString('en-IN')} </p>
                                </Col>
                            </Row>
                            <Row>
                            <Col xs="1" sm="2" md="2" lg="6" className='d-none d-md-block'>
                                </Col>
                                <Col xs="6" sm="5" md="4" lg="3" className='text-start'>
                                    <p className='totals m-0'>Total Discount </p>
                                </Col>
                                <Col xs="6" sm="5" md="4" lg="3" className='text-end'>
                                    <p style={{ fontWeight: '700' }} className={` ${style.tabletxt}`}> {Number(props?.totalDiscount).toLocaleString('en-IN')} </p>
                                </Col>
                            </Row>
                            <Row>
                            <Col xs="1" sm="2" md="2" lg="6" className='d-none d-md-block'>
                                </Col>
                                <Col xs="6" sm="5" md="4" lg="3" className='text-start'>
                                    <p className='totals m-0'> Shipping </p>
                                </Col>
                                <Col xs="6" sm="5" md="4" lg="3" className='text-end'>
                                    <p className='totals m-0'>{Number(props?.data?.base_shipping_amount).toLocaleString('en-IN')} </p>
                                </Col>
                            </Row>
                            <Row>
                            <Col xs="1" sm="2" md="2" lg="6" className='d-none d-md-block'>
                                </Col>
                                <Col xs="6" sm="5" md="4" lg="3" className='text-start'>
                                    <p className='totals m-0'>Grand Total </p>
                                </Col>
                                <Col xs="6" sm="5" md="4" lg="3" className='text-end'>
                                    <p className='totals m-0' > {Number(props?.data?.base_grand_total).toLocaleString('en-IN')} </p>
                                </Col>
                            </Row>
                            <Row>
                            <Col xs="1" sm="2" md="2" lg="6" className='d-none d-md-block'>
                                </Col>
                                <Col xs="6" sm="5" md="4" lg="3" className='text-start'>
                                    <p className='totals m-0'> Total Received</p>
                                </Col>
                                <Col xs="6" sm="5" md="4" lg="3" className='text-end'>
                                    <p className='totals m-0' > {Number(Number(props?.data?.base_grand_total) - Number(props?.remainingAmount)).toLocaleString('en-IN')} </p>
                                </Col>
                            </Row>
                            <Row>
                            <Col xs="1" sm="2" md="2" lg="6" className='d-none d-md-block'>
                                </Col>
                                <Col xs="6" sm="5" md="4" lg="3" className='text-start'>
                                    <p className='totals m-0'> Amount Received</p>
                                </Col>
                                <Col xs="6" sm="5" md="4" lg="3" className='text-end'>
                                    <p className='totals m-0' > {Number(props?.data?.customer_payments_amount_received).toLocaleString('en-IN')} </p>
                                </Col>
                            </Row>
                           
                            {props?.remainingAmount>0&&<Row>
                            <Col xs="1" sm="2" md="2" lg="6" className='d-none d-md-block'>
                                </Col>
                                <Col xs="6" sm="5" md="4" lg="3" className='text-start'  >
                                    <p className='totals m-0'> Total Due</p>
                                </Col>
                                <Col xs="6" sm="5" md="4" lg="3" className='text-end'>
                                    <p className='totals m-0' > {Number(props?.remainingAmount).toLocaleString('en-IN')} </p>
                                </Col>
                            </Row>}
                        </div>
                        <p className={`invoiceTerms m-0 mt-1`}>Invoice terms</p>

                        {
                            props?.remainingAmount === 0 ? (
                                <>
                                    <p className='m-0 invoiceTerms' style={{ textAlign: 'center' }}>The Buyer has <b>PAID</b> the amount.</p>
                                </>
                            ) : (
                                <>
                                    <p className='m-0 invoiceTerms' style={{ textAlign: 'center' }}>The Buyer still needs to pay <b>{Number(props?.remainingAmount).toLocaleString('en-IN')}</b> to clear the invoice.</p>
                                </>
                            )
                        }
                        {props?.data?.invoice_terms !== "nil" && <p className={`mt-0 mb-3 text-center fst-italic invoiceTerms`}>* {props?.data?.invoice_terms}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
})

export default UpdateReceivePaymentThermalInvoice;