export const decrypt = (hashFull) => {
  const [salesHash, shopHash] = hashFull.split("-");
  if (salesHash && shopHash) {
    const salesDecryptId = parseInt(salesHash, 32);
    const ShopDecryptId = parseInt(shopHash, 32);
    let salesString = salesDecryptId.toString();
    let shopString = ShopDecryptId.toString();
    salesString = salesString.replace(salesString.slice(0, 5), "");
    shopString = shopString.substring(0, shopString.length - 5);
    return { sales: Number(salesString), shop: Number(shopString) };
  }
};

