import { faLink, faUnlink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
import tiktok from "../../assets/images/tiktok.png";
import youtube from "../../assets/images/youtube.png";
import PopUpModal from "../../Components/Modal";
import axios from "axios";
import LoaderPage from "../../Components/loaders/loaderPage";
import { toast } from "react-toastify";

const SocialCard = ({ title, icon, linked, refreshData }) => {
  const [inputLink, setInputLink] = useState("");
  const [linkModalShow, setLinkModalShow] = useState(false);
  const [unlinkModalShow, setUnlinkModalShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const urlRegex = /(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
  const handleLink = async (media, link, action) => {
    try {
      if ((action === "link" && link.length > 0 && urlRegex.test(link)) || action === "unlink") {
        const res = await axios.patch(process.env.REACT_APP_API_URL + `/settings/social-media/${media}`, { link }, { withCredentials: true });
        if (res) {
          setValidated(false);
          setLinkModalShow(false);
          setUnlinkModalShow(false);
          refreshData();
          toast.success(res?.data?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    } catch (error) {
      toast.error(`${error?.response?.data?.error?.message}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };
  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title className="text-center">{title}</Card.Title>
          <img src={icon} height={128} width={128} alt={title} />
        </Card.Body>
        <Card.Footer className="text-center bg-white fw-bold cursor-pointer" onClick={() => (linked ? setUnlinkModalShow(true) : setLinkModalShow(true))}>
          {linked ? (
            <span>
              <FontAwesomeIcon icon={faUnlink} /> Unlink
            </span>
          ) : (
            <span className="link">
              <FontAwesomeIcon icon={faLink} /> Link
            </span>
          )}
        </Card.Footer>
      </Card>
      <PopUpModal show={linkModalShow} title="Link Account" onHide={() => setLinkModalShow(false)}>
        <div className="d-flex justify content-center">
          <Form
            className="w-100"
            noValidate
            validated={validated}
            onSubmit={(e) => {
              e.preventDefault();
              setValidated(true);
              handleLink(title.toLowerCase(), inputLink, "link");
            }}
          >
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Account Link</Form.Label>
              <Form.Control type="url" placeholder="Enter your account link" value={inputLink} onChange={(e) => setInputLink(e.target.value)} isInvalid={validated && !(inputLink.length > 0)} required />
              <Form.Control.Feedback type="invalid">Please enter your account link</Form.Control.Feedback>
            </Form.Group>
            <Button className="w-100 mb-3" type="submit">
              Link
            </Button>
          </Form>
        </div>
      </PopUpModal>
      <PopUpModal show={unlinkModalShow} title="Unlink Account" onHide={() => setUnlinkModalShow(false)}>
        <div className="d-flex flex-column align-items-center gap-3">
          <FontAwesomeIcon icon={faUnlink} size="3x" />
          <span className="d-flex justify-content-center pb-4">Are you sure you want to unlink?</span>
        </div>
        <div className="d-flex gap-3">
          <span
            className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
            style={{
              cursor: "pointer",
              border: "2px solid rgba(151, 156, 162, 1)",
              borderRadius: "6px",
            }}
            onClick={() => setUnlinkModalShow(false)}
          >
            Cancel
          </span>
          <Button className="w-100" onClick={() => handleLink(title.toLowerCase(), null, "unlink")}>
            Confirm
          </Button>
        </div>
      </PopUpModal>
    </>
  );
};
const SocialMediaSettings = () => {
  const [loading, setLoading] = useState(false);
  const [socialMedias, setSocialMedias] = useState([]);
  const [changeOccur, setChangeOccur] = useState(false);
  const refreshData = () => {
    setChangeOccur((prev) => !prev);
  };
  const getSocials = async () => {
    try {
      setLoading(true);
      const res = await axios.get(process.env.REACT_APP_API_URL + `/settings/social-media`, { withCredentials: true });
      setSocialMedias(res.data[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getSocials();
  }, [changeOccur]);
  return (
    <>
      {loading ? (
        <LoaderPage />
      ) : (
        <div className="d-flex gap-3 flex-wrap" id="system-settings-social-settings">
          <SocialCard title="Facebook" icon={facebook} linked={socialMedias.facebook !== null} refreshData={refreshData} />
          <SocialCard title="Instagram" icon={instagram} linked={socialMedias.instagram !== null} refreshData={refreshData} />
          <SocialCard title="Tiktok" icon={tiktok} linked={socialMedias.tiktok !== null} refreshData={refreshData} />
          <SocialCard title="YouTube" icon={youtube} linked={socialMedias.youtube !== null} refreshData={refreshData} />
        </div>
      )}
    </>
  );
};

export default SocialMediaSettings;
