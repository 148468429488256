import { forwardRef } from "react";
import "../styles/printLayout.css";

const PrintLayout = forwardRef((props, ref) => {
  return (
    <div className={`printComponent ${props.className}`}>
      <div ref={ref}>{props.children}</div>
    </div>
  );
});

export default PrintLayout;
