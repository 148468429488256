import React, { useState, useRef } from "react";
import style from "./Invoice.module.css";
import { useReactToPrint } from "react-to-print";
import { Button, Card } from "react-bootstrap";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import InvoicePurchaseTemplate from "./InvoicePurchaseTemplate";

const VendorPurchaseInvoice = ({ close, id = -1 }) => {
  const { user } = useSelector((state) => state.auth);
  const logo = user.logo_path;
  const shopId = user.shop_id;

  const layoutRef = useRef(null);

  //print button
  const [isPrinting, setIsPrinting] = useState(false);
  const handleOnBeforeGetContent = () => {
    setIsPrinting(true);
  };

  const handleBeforePrint = () => {
    setIsPrinting(true);
  };

  const handleAfterPrint = () => {
    setIsPrinting(false);
    close();
  };

  const reactToPrintContent = useCallback(() => {
    return layoutRef.current;
  }, [layoutRef.current]);

  //react to print
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Purchase Order",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
  });

  //ERROR
  const [isError, setIsError] = useState(false);

  return (
    <div>
      <div className="d-flex justify-content-end gap-3">
        <Button variant="secondary" onClick={close} size="lg">
          Close
        </Button>
        <Button
          variant="outline-primary"
          size="lg"
          disabled={isPrinting}
          onClick={!isPrinting ? handlePrint : null}
        >
          {isPrinting ? "Printing..." : "Print"}
        </Button>
      </div>
      {isError ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <h1>SOMTHING WENT WRONG!</h1>
        </div>
      ) : (
        <div
          style={{
            border: "1px solid black",
            borderRadius: "6px",
            marginTop: "5px",
            height: "70vh",
            width: "100%",
            overflow: "auto",
          }}
        >
          <InvoicePurchaseTemplate id={id} ref={layoutRef} />
        </div>
      )}
    </div>
  );
};

export default VendorPurchaseInvoice;
