import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";
const SubscriptionsTour = ({ onPayment }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const subscriptionsTour = driver({
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Subscriptions", value: true } }));
      subscriptionsTour.destroy();
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Subscriptions",
        popover: {
          title: `Subscriptions`,
          description: "This module allows users to manage their subscription plans and view detailed information.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#subscriptions-details",
        popover: {
          title: `Subscription Details`,
          description: `Displays information about the current subscription.`,
          side: "right",
          align: "start",
        },
      },
      {
        element: "#subscriptions-usage-limits",
        popover: {
          title: `Usage Limits`,
          description: "Shows limits for various services.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#subscriptions-change-plan-btn",
        popover: {
          title: `Change Plan`,
          description: "Provides an option for users to switch to a different subscription plan.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#subscriptions-cancel-plan-btn",
        popover: {
          title: `Cancel Plan`,
          description: "Upon clicking this button, the user will be prompted to confirm their cancellation.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#subscriptions-plan-extension-btn",
        popover: {
          title: `Plan Extension Request`,
          description: "Upon clicking this button, the user can request a plan extension.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#subscriptions-consumable-items-request-btn",
        popover: {
          title: `Consumable Items Request`,
          description: "Upon clicking this button, the user can request consumable items.",
          side: "left",
          align: "start",
        },
      },

      onPayment
        ? {
            element: "#subscriptions-transaction-details",
            popover: {
              title: `Transaction Details`,
              description: "It Displays a comprehensive history of subscription transactions including plan name, amount paid and transaction date.",
              side: "right",
              align: "start",
            },
          }
        : {
            element: "#subscriptions-trial",
            popover: {
              title: `Subscription Status`,
              description: "Shows if the user has a subscription or not.",
              side: "right",
              align: "start",
            },
          },
      {
        element: "#subscriptions-addons-requests-details",
        popover: {
          title: `Addons Requests Details`,
          description: "Lists all requests for subscription add-ons, along with their current status.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Subscriptions", value: true } }));
            subscriptionsTour.destroy();
          },
        },
      },
    ],
  });
  useEffect(() => {
    subscriptionsTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default SubscriptionsTour;
