import { useState } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Row,
  Col,
  InputGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import ReactSelect from "../../ReactSelect";

const ActivationForm = ({
  state = {
    validated: false,
    isChecked: false,

    listing: {
      listing_title: null,
      brand: null,
      online_price: null,
      online_discount: null,
      online_discount_unit: { value: "fixed", label: "Rs" },
      listed_quantity: null,
      stock_quantity: null,
      sale_price: null,
    },
  },
  setState,
}) => {
  const [invalidInputs, setInvalidInputs] = useState({
    listed_quantity: false,
    online_price: false,
    online_discount: false,
  });


  const handleHCangeListedQuantity = (e) => {
    if (+e.target.value === 0) {
      setInvalidInputs((prev) => ({ ...prev, listed_quantity: true }));
      setState((prev) => {
        delete prev.listing.listed_quantity;
        return prev;
      });
    } else {
      if (e.target.value <= state?.listing?.stock_quantity) {
        setInvalidInputs((prev) => ({ ...prev, listed_quantity: false }));
        setState((prev) => ({
          ...prev,
          listing: {
            ...prev.listing,
            listed_quantity: e.target.value,
          },
        }));
      } else {
        setInvalidInputs((prev) => ({ ...prev, listed_quantity: true }));
      }
    }
  };
  const handleHCangeOnlinePrice = (e) => {
    if (+e.target.value === 0) {
      setInvalidInputs((prev) => {
        if (prev.online_price) {
          delete prev.online_price;
        }
        return prev;
      });
      setInvalidInputs((prev) => ({
        ...prev,
        online_price: "Please Enter Online Price",
      }));
      setState((prev) => {
        delete prev.listing.online_price;
        return prev;
      });
    } else {
      if (e.target.value.length < 11) {
        setState((prev) => ({
          ...prev,
          listing: {
            ...prev.listing,
            online_price: e.target.value,
          },
        }));
        if (e.target.value < state?.listing?.sale_price) {
          setInvalidInputs((prev) => ({
            ...prev,
            online_price: "Online price can not be less then sale price",
          }));
        } else {
          setInvalidInputs((prev) => {
            if (prev.online_price) {
              delete prev.online_price;
            }
            return prev;
          });
        }
      } else {
        setInvalidInputs((prev) => ({
          ...prev,
          online_price: "Online price can not be more then 10 digits",
        }));
      }
    }
  };
  const handleHCangeOnlineDiscount = (e) => {
    if (state?.listing?.online_discount_unit?.value === "percentage") {
      if (+e.target.value <= 100) {
        setInvalidInputs((prev) => ({ ...prev, online_discount: false }));
        setState((prev) => ({
          ...prev,
          listing: {
            ...prev.listing,
            online_discount: e.target.value,
          },
        }));
      } else {
        setInvalidInputs((prev) => ({ ...prev, online_discount: true }));
      }
    } else {
      if (+e.target.value <= +state?.listing?.online_price) {
        setInvalidInputs((prev) => ({ ...prev, online_discount: false }));
        setState((prev) => ({
          ...prev,
          listing: {
            ...prev.listing,
            online_discount: e.target.value,
          },
        }));
      } else {
        setInvalidInputs((prev) => ({ ...prev, online_discount: true }));
      }
    }
  };

  return (
    <>
      <Form noValidate validated={state?.validated} className="m-3">
        <Row className="px-0 px-sm-3 px-lg-5 gx-0 gx-sm-3 gx-lg-5 m-0 w-100">
          <Col className="py-2" md={12}>
            <Form.Group>
              <Form.Label>
                Title<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                className="py-3 pe-5"
                type="text"
                placeholder="e.g. Apple IPhone 15 Pro"
                value={state?.listing?.listing_title}
                disabled={true}
                onChange={() => {}}
                name="model"
                required
              />
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Brand"}
                important
                placeholder="Select brand"
                disabled={true}
                value={{ label: state?.listing?.brand }}
                handleChange={() => {}}
              />

              <Form.Control
                value={state?.listing?.brand}
                hidden
                onChange={() => {}}
                name="Brand"
                required
              />
              <Form.Control.Feedback type="invalid">
                * Please Select Brand{" "}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group className="" controlId="model">
              <Form.Label className="mb-0">
                Stock Quantity<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                className="py-2"
                type="number"
                placeholder="e.g. 6"
                value={state?.listing?.stock_quantity}
                onChange={() => {}}
                name="stock quantity"
                disabled={true}
                required
              />

              <Form.Control.Feedback type="invalid">
                * Please Select Stock Quantity{" "}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group className="" controlId="model">
              <Form.Label className="mb-0">
                Listed Quantity<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                className="py-2"
                type="number"
                min={0}
                max={state?.listing?.stock_quantity}
                placeholder="e.g. 6"
                value={
                  state?.listing?.listed_quantity
                    ? state?.listing?.listed_quantity
                    : ""
                }
                onChange={handleHCangeListedQuantity}
                name="listed quantity"
                required
                isInvalid={invalidInputs?.listed_quantity}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
              />

              <Form.Control.Feedback type="invalid">
                {"* "}
                {invalidInputs?.listed_quantity
                  ? !state?.listing?.hasOwnProperty("listed_quantity")
                    ? "Listing Quantity can not be 0"
                    : "Listed Quantity must be less than Stock Quantity"
                  : state?.listing?.listed_quantity === "" ||
                    +state?.listing?.listed_quantity === 0
                  ? "Please Enter Listed Quantity"
                  : "Please Enter Listed Quantity"}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group className="" controlId="model">
              <Form.Label className="mb-0">
                Sale Price<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                className="py-2"
                type="number"
                placeholder="e.g. 6"
                value={state?.listing?.sale_price}
                onChange={() => {}}
                name="Sale Price"
                disabled={true}
                required
              />

              <Form.Control.Feedback type="invalid">
                * Please Select Sale Price{" "}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="12" md={12}>
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Hide Online Price"
              checked={state.isChecked} 
              onChange={() =>
                setState((prev) => ({
                  ...prev,
                  isChecked: !prev.isChecked,
                }))
              }
            />
          </Col>
          {!state.isChecked ? (
            
            <>
              <Col className="py-3" sm="6" md={6}>
                <Form.Group className="" controlId="model">
                  <Form.Label className="mb-0">
                    Online Price<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    className="py-2"
                    type="number"
                    min={0}
                    max={state?.listing?.sale_price}
                    placeholder="e.g. 6"
                    value={
                      state?.listing?.online_price
                        ? state?.listing?.online_price
                        : null
                    }
                    onChange={handleHCangeOnlinePrice}
                    name="listed quantity"
                    required
                    isInvalid={invalidInputs?.online_price}
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-", "."].includes(e.key) &&
                      e.preventDefault()
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    {invalidInputs?.hasOwnProperty("online_price")
                      ? "* " + invalidInputs?.online_price
                      : ""}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="py-3" sm="6" md={6}>
                <Form.Group className="" controlId="model">
                  <Form.Label className="mb-0">Online Discount</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      className="py-2"
                      type="number"
                      min={0}
                      max={Number(state?.listing?.online_price)}
                      placeholder="e.g. 6"
                      value={
                        state?.listing?.online_discount
                          ? state?.listing?.online_discount
                          : null
                      }
                      onChange={handleHCangeOnlineDiscount}
                      name="listed quantity"
                      isInvalid={invalidInputs?.online_discount}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) &&
                        e.preventDefault()
                      }
                    />
                    <DropdownButton
                      title={state?.listing?.online_discount_unit?.label}
                      // variant="outline-primary"
                      className="px-2"
                    >
                      <Dropdown.Item
                        value="fixed"
                        onClick={() => {
                          setState((prev) => ({
                            ...prev,
                            listing: {
                              ...prev.listing,
                              online_discount_unit: {
                                value: "fixed",
                                label: "Rs",
                              },
                            },
                          }));
                          if (
                            state?.listing?.online_discount &&
                            state?.listing?.online_discount !== ""
                          ) {
                            setState((prev) => ({
                              ...prev,
                              listing: {
                                ...prev.listing,
                                online_discount: 0,
                              },
                            }));
                          }
                        }}
                      >
                        Rs
                      </Dropdown.Item>
                      <Dropdown.Item
                        value="percentage"
                        onClick={() => {
                          setState((prev) => ({
                            ...prev,
                            listing: {
                              ...prev.listing,
                              online_discount_unit: {
                                value: "percentage",
                                label: "%",
                              },
                            },
                          }));
                          if (
                            state?.listing?.online_discount &&
                            state?.listing?.online_discount !== ""
                          ) {
                            setState((prev) => ({
                              ...prev,
                              listing: {
                                ...prev.listing,
                                online_discount: 0,
                              },
                            }));
                          }
                        }}
                      >
                        %
                      </Dropdown.Item>
                    </DropdownButton>
                    <Form.Control.Feedback type="invalid">
                      {"* "}
                      {state?.listing?.hasOwnProperty("online_discount")
                        ? state?.listing?.online_discount_unit?.value ===
                          "fixed"
                          ? "Online Discount must be less than Online Price"
                          : "Please enter a discount percentage below 100"
                        : ""}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
            </>
          ) : (
            ""
          )}

          {/* <Col className="py-3" sm="6" md={6}>
            <Form.Group className="" controlId="model">
              <Form.Label className="mb-0">
                Online Price<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                className="py-2"
                type="number"
                min={0}
                max={state?.listing?.sale_price}
                placeholder="e.g. 6"
                value={state?.listing?.online_price ? state?.listing?.online_price : ""}
                onChange={handleHCangeOnlinePrice}
                name="listed quantity"
                required
                isInvalid={invalidInputs?.online_price}
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
              />

              <Form.Control.Feedback type="invalid">{invalidInputs?.hasOwnProperty("online_price") ? "* " + invalidInputs?.online_price : ""}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group className="" controlId="model">
              <Form.Label className="mb-0">Online Discount</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  className="py-2"
                  type="number"
                  min={0}
                  max={Number(state?.listing?.online_price)}
                  placeholder="e.g. 6"
                  value={state?.listing?.online_discount ? state?.listing?.online_discount : ""}
                  onChange={handleHCangeOnlineDiscount}
                  name="listed quantity"
                  isInvalid={invalidInputs?.online_discount}
                  onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                />
                <DropdownButton
                  title={state?.listing?.online_discount_unit?.label}
                  // variant="outline-primary"
                  className="px-2"
                >
                  <Dropdown.Item
                    value="fixed"
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        listing: {
                          ...prev.listing,
                          online_discount_unit: { value: "fixed", label: "Rs" },
                        },
                      }));
                      if (state?.listing?.online_discount && state?.listing?.online_discount !== "") {
                        setState((prev) => ({
                          ...prev,
                          listing: {
                            ...prev.listing,
                            online_discount: 0,
                          },
                        }));
                      }
                    }}
                  >
                    Rs
                  </Dropdown.Item>
                  <Dropdown.Item
                    value="percentage"
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        listing: {
                          ...prev.listing,
                          online_discount_unit: {
                            value: "percentage",
                            label: "%",
                          },
                        },
                      }));
                      if (state?.listing?.online_discount && state?.listing?.online_discount !== "") {
                        setState((prev) => ({
                          ...prev,
                          listing: {
                            ...prev.listing,
                            online_discount: 0,
                          },
                        }));
                      }
                    }}
                  >
                    %
                  </Dropdown.Item>
                </DropdownButton>
                <Form.Control.Feedback type="invalid">
                  {"* "}
                  {state?.listing?.hasOwnProperty("online_discount") ? (state?.listing?.online_discount_unit?.value === "fixed" ? "Online Discount must be less than Online Price" : "Please enter a discount percentage below 100") : ""}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col> */}
        </Row>
      </Form>
    </>
  );
};

ActivationForm.propTypes = {
  state: PropTypes.exact({
    validated: PropTypes.bool,
    isChecked: PropTypes.bool,
    listing: PropTypes.shape({
      listing_title: PropTypes.string,
      brand: PropTypes.string,
      online_price: PropTypes.number,
      online_discount: PropTypes.number | null,
      online_discount_unit: PropTypes.string | null,
      listed_quantity: PropTypes.number,
      stock_quantity: PropTypes.number,
    }),
  }),
  setState: PropTypes.func,
};

export default ActivationForm;
