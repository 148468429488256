import React, { useEffect, useState } from "react";
import { emptyCart } from "../Features/cart/cartSlice";
import { Button, Col, Form, Row } from "react-bootstrap";
import OpeningRegister from "./Register/OpeningRegister";
import PopUpModal from "./Modal";
import SearchableSelect from "./SearchableSelect";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setLocation, closeLocationRegister } from "../Features/locationSlice";
import { authActions } from "../Features/authSlice";
import style from "../styles/GlobalLocation.module.css";

const GlobalLocation = ({ disableCondition }) => {
  const { user } = useSelector((state) => state.auth);
  const register = useSelector((state) => state.location.register);
  const location = useSelector((state) => state.location.location);
  const userId = user.user_id;
  const user_type = user.user_type;
  const shop_id = user.shop_id;
  const dispatch = useDispatch();
  const [closeRegisterModal, setCloseRegisterModal] = useState(false);
  const [cashInHand, setCashInHand] = useState();
  const [openingBalanceData, setOpeningBalanceData] = useState(null);
  const [closingBalance, setclosingBalance] = useState("");
  const [isValidate, setIsValidate] = useState(false);
  const [openingBalance, setOpeningBalance] = useState("");
  const [cashRegisterModal, setCashRegisterModal] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState("");
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [fcous, setFcous] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [disableTimer, setDisableTimer] = useState(false);

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + `/location/get-location-by-user/${userId}`, { shop_id: shop_id, user_type: user_type }, { withCredentials: true })
      .then((res) => {
        const locationsArray = res.data.map((loc) => ({
          value: loc.location_id,
          label: loc.location,
          isDisabled: new Date().toISOString() >= loc.active_till ? true : false,
        }));
        const checkIsDisable = locationsArray.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.value]: curr.isDisabled,
          };
        }, {});
        setLocations(locationsArray);
        dispatch(authActions.setAllLocations(locationsArray));
        const firstLocation = locationsArray.find((loc) => {
          return loc.isDisabled === false;
        });
        setSelectedLocation(
          Object.keys(location).length > 0 && !checkIsDisable[location.value]
            ? location
            : {
                value: firstLocation.value,
                label: firstLocation.label,
              }
        );
        if (!(Object.keys(location).length > 0) || register?.registerId === -1 || checkIsDisable[location.value]) {
          dispatch(
            setLocation({
              shop_id,
              location: {
                value: firstLocation.value,
                label: firstLocation.label,
              },
            })
          );
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [userId, shop_id, user_type]);

  useEffect(() => {
    setSelectedLocation(location);
  }, [location]);

  const closeRegister = () => {
    setCloseRegisterModal(true);
  };
  let fromcurrentDate = new Date();
  let fromyear = fromcurrentDate.getFullYear();
  let frommonth = ("0" + (fromcurrentDate.getMonth() + 1)).slice(-2);
  let fromday = ("0" + fromcurrentDate.getDate()).slice(-2);
  let fromdateresult = fromyear + "-" + frommonth + "-" + fromday;

  let tocurrentDate = new Date();
  tocurrentDate.setDate(tocurrentDate.getDate() - 1);
  let toyear = tocurrentDate.getFullYear();
  let tomonth = ("0" + (tocurrentDate.getMonth() + 1)).slice(-2);
  let today = ("0" + tocurrentDate.getDate()).slice(-2);
  let todateresult = toyear + "-" + tomonth + "-" + today;

  useEffect(() => {
    // setLoading(true);
    const cashInHand = () => {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/cash-in-hand/${userId}`,
          {
            dates: {
              from: register?.openingBalance?.opening_balance_time.split(".")[0].replace("T", " "),
              to: register?.openingBalance?.closing_balance_time ? register?.openingBalance?.closing_balance_time.split(".")[0].replace("T", " ") : null,
            },
            locationFields: location,
            openingBalance: register?.openingBalance?.opening_balance_id,
          },
          { withCredentials: true }
        )
        .then((res) => {
          let n = res?.data?.length;
          setCashInHand(res.data[n - 1]);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(location).length > 0 && register?.openingBalance) cashInHand();
  }, [location, closeRegisterModal, register?.openingBalance, fcous]);
  const handleClosingBalance = async (e) => {
    setIsValidate(true);
    e.preventDefault();
    if (closingBalance) {
      try {
        const data = await axios.post(
          process.env.REACT_APP_API_URL + `/cash-registers/close-register/${register.registerId}`,
          {
            closing_balance: closingBalance,
          },
          { withCredentials: true }
        );
        if (data) {
          setCloseRegisterModal(false);
          dispatch(closeLocationRegister());
          dispatch(emptyCart());
          setIsValidate(false);
          setclosingBalance("");
        }
      } catch {}
    }
  };
  const currentMonth = new Date().toDateString().split(" ")[1];
  const currentDate = new Date().getDate() + " " + currentMonth + ", " + new Date().getFullYear();
  const today_sh = new Date();
  const currentTime = today_sh.getHours();
  let showtime;
  if (currentTime < 12) {
    showtime = "Good Morning";
  } else if (currentTime < 18) {
    showtime = "Good Afternoon";
  } else {
    showtime = "Good Evening";
  }
  const handleCashRegister = () => {
    setValidated(false);

    setCashRegisterModal(true);
    setDisableTimer(true);
    setTimeout(() => {
      setDisableTimer(false);
    }, 60000);
  };
  const handleCashOpeningBalance = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);
    try {
      if (register.registerId === -1) {
        setError("Register is not Set!");
      } else {
        if (openingBalance && +openingBalance >= 0) {
          const res = await axios.post(
            process.env.REACT_APP_API_URL + `/cash-registers/opening-balance`,
            {
              register_id: register.registerId,
              opening_balance: openingBalance,
            },
            { withCredentials: true }
          );
          if (res) {
            dispatch(setLocation({ shop_id, location: selectedLocation }));
            setRegisterSuccess(res.data);
            setTimeout(() => {
              setCashRegisterModal(false);
              setRegisterSuccess("");
              setValidated(false);
              setOpeningBalance("");
            }, 1000);
          }
        }
      }
    } catch (error) {
      setError(error);
    }
  };
  return (
    <>
      <div className={`row ${style.globalLocation} `}>
        {Object.keys(location).length > 0 ? (
          <>
            <div className="col-md-7 col-6 p-0">
              <div className="mb-1 ms-3 me-2" id="topbar_location">
                <SearchableSelect
                  placeholder="Select Location"
                  searchable={false}
                  options={locations}
                  disabled={disableCondition}
                  handleChange={(e) => {
                    if (Number(selectedLocation.value) !== Number(e.value)) {
                      setSelectedLocation(e);
                      dispatch(setLocation({ shop_id, location: e }));
                      dispatch(emptyCart());
                      localStorage.removeItem("cartItems");
                    }
                  }}
                  value={location}
                />
              </div>
            </div>
            <div className="col-md-5 col-6  p-0  pe-1" id="topbar_register_button">
              {register?.isCashRegisterOpen === false ? (
                <Button
                  variant="outline-primary"
                  disabled={validated || disableCondition}
                  onClick={() => {
                    setCashRegisterModal(true);
                  }}
                  className={`p-2 w-100 ${style.registerButton}`}
                >
                  Open Register
                </Button>
              ) : register?.isCashRegisterOpen === true ? (
                <Button variant="outline-primary" disabled={isValidate || disableTimer || disableCondition} onClick={closeRegister} className={`p-2 w-100 ${style.registerButton}`}>
                  Close Register
                </Button>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <OpeningRegister
        cashRegisterModal={cashRegisterModal}
        setCashRegisterModal={setCashRegisterModal}
        validated={validated}
        handleCashOpeningBalance={handleCashOpeningBalance}
        showtime={showtime}
        registerSuccess={registerSuccess}
        error={error}
        setIsValidate={setValidated}
        openingBalance={openingBalance}
        setOpeningBalance={setOpeningBalance}
        handleCashRegister={handleCashRegister}
      />
      <PopUpModal title={"Close Register"} size={"md"} marginright={true} mdSize={true} show={closeRegisterModal} onHide={() => setCloseRegisterModal(false)}>
        <Form validated={isValidate} noValidate onSubmit={handleClosingBalance} className="px-3">
          <div className="text-center  mb-3">
            <p className="fs-5"> Expected Closing Balance</p>
            <p className="fs-5 ">{cashInHand?.cash_in_hand}</p>
          </div>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Form.Group className="mb-4" controlId="price">
                <Form.Label className="mb-0">Enter amount</Form.Label>
                <Form.Control
                  min={0}
                  type="number"
                  placeholder="i.e 500"
                  onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                  value={closingBalance}
                  onChange={(e) => {
                    setIsValidate(false);
                    e.target.value.length < 10 ? setclosingBalance(e.target.value) : setclosingBalance(0);
                  }}
                  name="opening_balance"
                  required
                />

                <Form.Control.Feedback type="invalid">Please Enter Cash Closing Balance</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="d-flex" lg="12" md="12" sm="12" xs="12">
              <Button
                className="w-100 me-2"
                type="button"
                variant="secondary"
                onClick={() => {
                  setclosingBalance("");
                  setIsValidate(false);
                  setCloseRegisterModal(false);
                }}
              >
                Cancel
              </Button>
              <Button className="w-100 " disabled={isValidate} type="submit">
                Confirm
              </Button>
            </Col>
          </Row>
        </Form>
      </PopUpModal>
    </>
  );
};

export default GlobalLocation;
