import { useRef, useCallback } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "react-bootstrap";
import jsPDF from "jspdf";
import { useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import QuotationInvoice from "./QuotationInvoice";
const QuotationInvoicePage = () => {
  const componentRef = useRef(null);
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);
  const { id } = useParams();
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
  });
  const handleSaveAsPDF = () => {
    const input = componentRef.current;
    html2canvas(input)
      .then((canvas) => {
        const pdf = new jsPDF("p", "mm", "a4");
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210; // A4 width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // maintain aspect ratio
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight, undefined, "FAST");
        pdf.save(`${id}_quote.pdf`);
      })
      .catch((error) => {
        console.error("Error generating PDF: ", error);
      });
  };
  return (
    <>
      <div className={`my-4 d-flex justify-content-center gap-3`}>
        <Button variant="outline-primary" className="px-4" onClick={handlePrint}>
          Print{" "}
        </Button>
        <Button variant="outline-primary" className="px-4" onClick={handleSaveAsPDF}>
          Save as PDF
        </Button>
      </div>

      <div className="printComponent">
        <QuotationInvoice ref={componentRef} />
      </div>
    </>
  );
};

export default QuotationInvoicePage;
