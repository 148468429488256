import { forwardRef } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import style from "../../styles/Invoice.module.css";
import "../../styles/thermalPrintlayout.css";
import { dateTime } from "../../Features/dateTime";

const printDailyCashReport = forwardRef((props, ref) => {
  const { user } = useSelector((state) => state.auth);
  const shopName = user.shop_name;

  const formatTimeForDropdown = (date) => {
    return (
      (date.getHours() % 12).toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0") +
      (date.getHours() / 12 > 0 ? "PM" : "AM")
    );
  };

  let invoicetime;
  const currentDate = new Date();

  const start = dateTime(props?.openingBalanceData?.opening_balance_time);
  if (props?.openingBalanceData?.closing_balance_time) {
    const end = dateTime(props?.openingBalanceData?.closing_balance_time);
    invoicetime =
      formatTimeForDropdown(start) + " - " + formatTimeForDropdown(end);
  } else {
    if (start?.toDateString() === currentDate?.toDateString()) {
      invoicetime = formatTimeForDropdown(start) + " - " + "Till Now";
    } else {
      invoicetime = formatTimeForDropdown(start) + " - " + "11:59PM";
    }
  }
  var invoiceDate = start.toLocaleString().split(",")[0];

  return (
    <>
      <div ref={ref} className="thermalInvoice">
        <Row>
          <Col lg={12} sm={12} md={12} xs={12} className="text-center">
            <h5 style={{ color: "#000", fontSize: "16px" }}>
              Daily Cash Report{" "}
            </h5>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={6} md={6} xs={6} className="text-center">
            <h5 style={{ color: "#000", fontSize: "16px" }}>{shopName}</h5>
          </Col>

          <Col lg={6} sm={6} md={6} xs={6}>
            <h5 style={{ color: "#000", fontSize: "10px" }}>
              {invoiceDate} <br /> {invoicetime}
            </h5>
          </Col>
        </Row>

        <table
          className={`mx-auto mb-1 text-start thermalPrinterTable ${style?.itemsTable}`}
        >
          <th
            style={{
              border: "1px solid #000",
              padding: "5px",
              width: "10rem",
              textAlign: "center",
              color: "#000",
              fontSize: "10px",
            }}
          >
            #
          </th>

          <th
            style={{
              border: "1px solid #000",
              padding: "5px",
              textAlign: "center",
              color: "#000",
              fontSize: "10px",
            }}
          >
            Total
          </th>

          <tr>
            <td
              style={{
                border: "1px solid #000",
                padding: "5px",
                color: "#000",
                fontSize: "10px",
              }}
            >
              <h5 style={{ color: "#000", fontSize: "10px" }}>Sale Payments</h5>
            </td>

            <td
              style={{
                border: "1px solid #000",
                padding: "5px",
                textAlign: "end",
                color: "#000",
                fontSize: "10px",
              }}
            >
              <h5 style={{ color: "#000", fontSize: "10px" }}>
                {props.totalSalesPaymentData?.cash_received === undefined
                  ? 0
                  : props.totalSalesPaymentData?.cash_received}
              </h5>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #000",
                padding: "5px",
                color: "#000",
                fontSize: "10px",
              }}
            >
              <h5 style={{ color: "#000", fontSize: "10px" }}>
                Vendor Payments
              </h5>
            </td>

            <td
              style={{
                border: "1px solid #000",
                padding: "5px",
                textAlign: "end",
                color: "#000",
                fontSize: "10px",
              }}
            >
              <h5 style={{ color: "#000", fontSize: "10px" }}>
                {props.totalVendorPaymentsData?.payment_amount === undefined
                  ? 0
                  : props.totalVendorPaymentsData?.payment_amount}
              </h5>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #000",
                padding: "5px",
                color: "#000",
                fontSize: "10px",
              }}
            >
              <h5 style={{ color: "#000", fontSize: "10px" }}>Expenses</h5>
            </td>

            <td
              style={{
                border: "1px solid #000",
                padding: "5px",
                textAlign: "end",
                color: "#000",
                fontSize: "10px",
              }}
            >
              <h5 style={{ color: "#000", fontSize: "10px" }}>
                {props.totalExpensesData?.expenses === undefined
                  ? 0
                  : props.totalExpensesData?.expenses}
              </h5>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #000",
                padding: "5px",
                color: "#000",
                fontSize: "10px",
              }}
            >
              <h5 style={{ color: "#000", fontSize: "10px" }}>Refunds</h5>
            </td>

            <td
              style={{
                border: "1px solid #000",
                padding: "5px",
                textAlign: "end",
                color: "#000",
                fontSize: "10px",
                height: "20px",
              }}
            >
              <h5 style={{ color: "#000", fontSize: "10px" }}>
                {props.totalRefundsData?.refunded_amount === undefined
                  ? 0
                  : props.totalRefundsData?.refunded_amount}
              </h5>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #000",
                padding: "5px",
                color: "#000",
                fontSize: "10px",
              }}
            >
              <h5 style={{ color: "#000", fontSize: "10px" }}>Withdrawals</h5>
            </td>

            <td
              style={{
                border: "1px solid #000",
                padding: "5px",
                textAlign: "end",
                color: "#000",
                fontSize: "10px",
                height: "20px",
              }}
            >
              <h5 style={{ color: "#000", fontSize: "10px" }}>
                {props.totalWithdrawAmount === null
                  ? 0
                  : props.totalWithdrawAmount}
              </h5>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #000",
                padding: "5px",
                color: "#000",
                fontSize: "10px",
              }}
            >
              <h5 style={{ color: "#000", fontSize: "10px" }}>Deposits</h5>
            </td>

            <td
              style={{
                border: "1px solid #000",
                padding: "5px",
                textAlign: "end",
                color: "#000",
                fontSize: "10px",
                height: "20px",
              }}
            >
              <h5 style={{ color: "#000", fontSize: "10px" }}>
                {props.totalDepositeAmount === null
                  ? 0
                  : props.totalDepositeAmount}
              </h5>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid transparent",
                padding: "5px",
                color: "#000",
                fontSize: "10px",
              }}
            >
              <h5 style={{ color: "#000", fontSize: "10px" }}>
                Opening Balance
              </h5>
            </td>

            <td
              style={{
                border: "1px solid transparent",
                padding: "5px",
                textAlign: "end",
                color: "#000",
                fontSize: "10px",
                height: "20px",
              }}
            >
              <h5 style={{ color: "#000", fontSize: "10px" }}>
                {props.openingBalanceData?.opening_balance
                  ? props.openingBalanceData?.opening_balance
                  : 0}
              </h5>
            </td>
          </tr>

          <tr>
            <td
              style={{
                border: "1px solid transparent",
                padding: "5px",
                color: "#000",
                fontSize: "10px",
              }}
            >
              <h5 style={{ color: "#000", fontSize: "10px" }}>
                Estimated Closing Balance
              </h5>
            </td>

            <td
              style={{
                border: "1px solid transparent",
                padding: "5px",
                textAlign: "end",
                color: "#000",
                fontSize: "10px",
                height: "20px",
              }}
            >
              <h5 style={{ color: "#000", fontSize: "10px" }}>
                {(Number(props.totalSalesPaymentData?.cash_received) > 0
                  ? Number(props.totalSalesPaymentData?.cash_received)
                  : 0) +
                  (Number(props.totalDepositeAmount) > 0
                    ? Number(props.totalDepositeAmount)
                    : 0) +
                  Number(props.openingBalanceData?.opening_balance) -
                  ((Number(props.totalExpensesData?.expenses) > 0
                    ? Number(props.totalExpensesData?.expenses)
                    : 0) +
                    (Number(props.totalRefundsData?.refunded_amount) > 0
                      ? Number(props.totalRefundsData?.refunded_amount)
                      : 0) +
                    (Number(props.totalVendorPaymentsData?.payment_amount) > 0
                      ? Number(props.totalVendorPaymentsData?.payment_amount)
                      : 0) +
                    (Number(props.totalWithdrawAmount) > 0
                      ? Number(props.totalWithdrawAmount)
                      : 0))}
              </h5>
            </td>
          </tr>
        </table>
      </div>
    </>
  );
});

export default printDailyCashReport;
