import React from "react";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import PopUpModal from "../../Components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import styleModal from "../../styles/Modal.module.css";
import { useState, useEffect } from "react";
import axios from "axios";

const RequestModal = ({ shop_id, planId, close, nextPlanDate, frequency, request_status, priority, request_type, title, describe, placeholder }) => {
  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [locations, setLocations] = useState([]);
  const [secondaryUsers, setSecondaryUsers] = useState([]);
  const [locationLimit, setLocationLimit] = useState();
  const [selectedLocations, setSelectedLocations] = useState([
    {
      isChecked: false,
      id: "",
    },
  ]);
  const [selectedUsers, setSelectedUsers] = useState([
    {
      isChecked: false,
      id: "",
    },
  ]);
  const [checkedLocationsFalse, setCheckedLocationsFalse] = useState({});
  const [checkedLocations, setCheckedLocations] = useState({});
  const [requestForm, setRequestForm] = useState({
    Title: title,
    Description: "",
  });

  function getSecondaryUsers() {
    axios
      .post(process.env.REACT_APP_API_URL + `/user/get-secondary-user/${shop_id}?page=` + 1 + "&size=" + 30, {}, { withCredentials: true })
      .then((data) => {
        const res = data?.data;
        setSecondaryUsers(res?.data);
        // setIsLoading(false);
      })
      .catch((err) => {
        // setIsLoading(false);
        const tempErr = err.response.data.error;
        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
          setSecondaryUsers([]);
        }
      });
  }
  useEffect(() => {
    getSecondaryUsers();
  }, []);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `/location/get-location/${shop_id}`, { withCredentials: true })
      .then((res) => {
        setLocations(res.data);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [shop_id]);

  useEffect(() => {
    setSelectedLocations(
      locations.map((data) => {
        return {
          id: data.location_id,
          name: data.location_nick,
          isChecked: false,
        };
      })
    );
  }, [locations]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `/plans/get/${planId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setLocationLimit(res.data);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, []);

  const handleRequest = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);
    try {
      if (requestForm.Title && requestForm.Description && (requestForm.Title.includes("Downgrade_Plan_Request") ? checkedLocations.length > 0 : true)) {
        if (locationLimit?.locations_limit >= checkedLocations.length && locationLimit?.secondary_users_limit >= selectedUsers.filter((i) => i.isChecked === true).length) {
          const res = await axios.post(
            process.env.REACT_APP_API_URL + `/subscriptions/request-plan/${shop_id}`,
            {
              request_title: requestForm.Title,
              request_type: request_type,
              handled_by: -1,
              request_status: request_status,
              priority: priority,
              request_description: requestForm.Description,
              plan_id: planId,
              checkedLocationsFalse: checkedLocationsFalse,
              nextPlanDate: nextPlanDate,
              checkedLocations: checkedLocations,
              secondaryUsers: selectedUsers.filter((i) => i.isChecked === undefined).map((i) => i.id),
            },
            { withCredentials: true }
          );
          if (res.data.message) {
            setSuccess(res.data.message);
            setModalShow(true);
            setTimeout(() => close(), 1000);
            setValidated(false);
          }
          if (res.data.error) {
            setError(res.data.error);
            setModalShow(true);
            setTimeout(() => close(), 3000);
            setValidated(false);
          }
        } else {
          setError(
            `Can not add more than ${locationLimit?.locations_limit < checkedLocations.length ? locationLimit?.locations_limit + " locations" : locationLimit?.secondary_users_limit < selectedUsers.filter((i) => i.isChecked === true).length ? locationLimit?.secondary_users_limit + "users" : ""} `
          );
          setModalShow(true);
          setTimeout(() => setModalShow(false), 2000);
        }
      }
    } catch (error) {
      setError("Something Went Wrong!");
      setModalShow(true);
      setTimeout(() => close(), 2000);
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setRequestForm({ ...requestForm, [name]: value });
  };
  useEffect(() => {
    setCheckedLocations(selectedLocations.filter((i) => i.isChecked === true));
  }, [selectedLocations]);
  useEffect(() => {
    setCheckedLocationsFalse(selectedLocations.filter((i) => i.isChecked === false));
  }, [selectedLocations]);
  const handleLocations = (e, i) => {
    let values = [...selectedLocations];
    values[i]["id"] = e.target.value;
    values[i]["isChecked"] = e.target.checked;
    setSelectedLocations(values);
  };
  const handleUsers = (e, i) => {
    let values = [...secondaryUsers];
    values[i]["id"] = +e.target.value;
    values[i]["isChecked"] = e.target.checked;
    setSelectedUsers(values);
  };
  return (
    <>
      {requestForm.Title.includes("Downgrade_Plan_Request") && (
        <Alert variant="warning">
          <strong>Notice:</strong> You are requesting a downgrade to your plan. This may result in reduced features and capabilities. Please review the details before proceeding.
        </Alert>
      )}
      <Form onSubmit={handleRequest} noValidate validated={validated}>
        {
          <PopUpModal
            title={success ? success : error}
            icon={<FontAwesomeIcon icon={success ? faCircleCheck : faCircleXmark} />}
            text={success ? success : error}
            type={success ? styleModal.successBg : styleModal.errorBg}
            iconModal={success ? styleModal.successIcon : styleModal.errorIcon}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        }
        {title.includes("Downgrade_Plan_Request") ? (
          <>
            <div>
              <p className="text-center fw-bold fs-5"> Maximum allowed loactions {locationLimit?.locations_limit}</p>
              <Row>
                {locations.map((i, index) => (
                  <>
                    <Col className="fs-14" key={index} lg={"4"} md={6} sm={6} xs={6}>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label={i.location_nick}
                          value={i.location_id}
                          onChange={(e) => {
                            handleLocations(e, index);
                          }}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </>
                ))}
              </Row>{" "}
            </div>
            <hr />
            <div>
              <p className="text-center fw-bold fs-5"> Maximum allowed employees {locationLimit?.secondary_users_limit}</p>
              <Row>
                {secondaryUsers.map((i, index) => (
                  <>
                    <Col className="d-flex align-items-baseline fs-14" key={index} lg={"4"} md={6} sm={6} xs={6}>
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label={i.user_full_name}
                          value={i.id}
                          onChange={(e) => {
                            handleUsers(e, index);
                          }}
                          required
                        />
                      </Form.Group>
                      <span className="text-secondary fs-12">({i?.location_name})</span>
                    </Col>
                  </>
                ))}
              </Row>{" "}
            </div>{" "}
          </>
        ) : (
          ""
        )}

        <Form.Group className="mb-3 mt-3">
          <Form.Label className="mb-1">
            Request Title <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <div className="position-relative">
            <Form.Control className="p-2 " min={1} disabled required type="text" placeholder="Enter Title" name="Title" value={requestForm.Title} onChange={(e) => handleChange(e)} />
            <Form.Control.Feedback type="invalid">Please Select Request Title </Form.Control.Feedback>
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label className="mb-1">
            Describe Request <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control placeholder={placeholder} as="textarea" rows={3} required name="Description" onChange={(e) => handleChange(e)} />
          <Form.Control.Feedback type="invalid">{describe}</Form.Control.Feedback>
        </Form.Group>

        <Button className="w-100" type="submit">
          Confirm
        </Button>
      </Form>
    </>
  );
};

export default RequestModal;
