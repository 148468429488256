import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

function TableSettings({ columns, isValidate, handlesubmitSettings, handleSettings, setReset, selectAll }) {
  const transform = (text) => {
    const temp = text.split("_");
    let ret = "";
    temp.forEach((txt) => {
      ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1);
    });
    return ret;
  };
  return (
    <div>
      <div className="">
        {" "}
        <a onClick={selectAll} className="fs-5  text-decoration-none cursor-pointer me-4 ">
          Select All
        </a>
        <a onClick={() => setReset((prev) => !prev)} className="fs-5 text-decoration-none cursor-pointer">
          Reset
        </a>
      </div>
      <label className=" fw-bold mt-4 fs-5">Columns: </label>
      <ul>
        <Row className="py-2 mb-2 fs-20 ">
          {columns?.map((col, index) => (
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  className="fs-17"
                  label={transform(col.label)}
                  checked={col.value}
                  //   value={isInventory}
                  onChange={(e) => handleSettings(e, col?.label, index)}
                />
              </Form.Group>
            </Col>
          ))}
        </Row>
      </ul>
      {isValidate && <p className="text-center fs-17 text-danger mb-1"> Please fill atleast one </p>}
      <Button onClick={handlesubmitSettings} disabled={isValidate} className="w-100">
        {" "}
        Update{" "}
      </Button>
    </div>
  );
}

export default TableSettings;
