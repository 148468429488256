import axios from "axios";
import { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import SearchableSelect from "../../Components/SearchableSelect";
const Bank = ({ setBanks, close, data, error }) => {
  useEffect(() => {
    if (data) {
      setAccountName(data.accountName);
      setAccountNumber(data.accountNumber);
      setBankName({ value: data.bankName, label: data.bankName });
      setAccountEmail(data.accountEmail);
    }
  }, [data]);
  //validation
  const [validatedBank, setValidatedBank] = useState(false);

  //states
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountEmail, setAccountEmail] = useState("");
  const [accountEmailState, setAccountEmailState] = useState(true);
  const [bankFields, setBanksFields] = useState([]);

  //onchange handles
  const handleAccounctNameChange = (e) => {
    setAccountName(e.target.value);
  };
  const handleAccounctNumberChange = (e) => {
    if (e.target.value.length < 35) {
      setAccountNumber(e.target.value.toUpperCase().replace(/[^\w\s]/gi, ""));
    }
  };

  const validateAccountNumberField = (name) => {
    const formatOnlyNumber = /^[A-Z]*$/;
    const formatAlphanumeric = /^[a-zA-Z0-9]*$/;
    if (formatAlphanumeric.test(name) && !formatOnlyNumber.test(name)) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `/shops/banks/get-banks-name`, {
        withCredentials: true,
      })
      .then((res) => {
        const getData = res.data.map((bank) => ({
          value: bank.full_name,
          label: bank.full_name + "  (" + bank.short_name + ")",
        }));
        setBanksFields(getData);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, []);
  const handleBankNameChange = (e) => {
    setBankName(e.target.value);
  };
  var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  function validate(iemail) {
    if (iemail.match(emailFormat)) {
      return true;
    } else {
      return false;
    }
  }
  const handleAccountEmailChange = (e) => {
    const emailstate = validate(e.target.value);
    setAccountEmail(e.target.value);
    if (emailstate === true) {
      setAccountEmailState(true);
    } else {
      setAccountEmailState(false);
    }
  };

  //submit method

  const handleBankSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidatedBank(true);
    if (accountName && accountNumber.length > 0 && validateAccountNumberField(accountNumber) && bankName && (accountEmail === "" || accountEmailState === true)) {
      const obj = {
        accountName,
        accountNumber,
        bankName,
        accountEmail,
      };
      if (data) {
        setBanks((prev) => {
          return prev.map((bank) => {
            if (bank.bankId === data.bankId) {
              bank.accountName = obj.accountName;
              bank.accountNumber = obj.accountNumber;
              bank.bankName = obj.bankName?.value;
              bank.accountEmail = obj.accountEmail;
              return bank;
            } else {
              return bank;
            }
          });
        });
      } else {
        setBanks((prev) => [...prev, { ...obj, bankName: obj.bankName?.value }]);
      }
      setValidatedBank(false);
      close(false);
      setAccountName("");
      setAccountNumber("");
      setBankName("");
      setAccountEmail("");
      setAccountEmailState(true);
    }
  };

  return (
    <>
      <Form onSubmit={handleBankSubmit} id="supplier-bank-details">
        <Form.Group className="mb-3" id="supplier-bank-accountant-name">
          <Form.Label className="mb-1">
            Account holder name<span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            value={accountName}
            pattern="^^[A-Za-z ]+$"
            onChange={handleAccounctNameChange}
            className="p-2"
            type="text"
            placeholder="Name here"
            required
            onKeyDown={(e) => ["!", "#", "$", "%", "^", "&", "*", "(", ")", ":", "'", "/", "`", "{", "}", "[", "]", "~", "+", "@", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-", "=", ";", ",", ".", "_", "|", ">", "<", "?", '"'].includes(e.key) && e.preventDefault()}
          />
          <Form.Control.Feedback type="invalid">Please Enter Account Holder Name.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" id="supplier-bank-account-number">
          <Form.Label className="mb-1">
            Account number<span className="text-danger">*</span>
          </Form.Label>
          <Form.Control value={accountNumber} onChange={handleAccounctNumberChange} className="p-2" type="text" placeholder="xxxxxxxxxxxxxx" required />
          <Form.Control.Feedback type="invalid">Please Enter Account Number.</Form.Control.Feedback>

          <div
            className="text-center p-2"
            style={{
              border: "1px dashed #dedfe4",
              backgroundColor: "#dedfe47a",
              borderRadius: "5px",
              fontSize: "10px",
            }}
          >
            Standard IBAN number contain maximum <strong>34</strong> character
          </div>
        </Form.Group>
        <div className="mb-3" id="supplier-bank-name">
          <SearchableSelect important label={"Bank Name"} placeholder="Select Bank Name" options={bankFields} value={bankName} handleChange={(e) => setBankName({ value: e.value, label: e.value })} required />
          <Form.Control hidden type="text" name="bankName" value={bankName} onChange={() => {}} required />
          <Form.Control.Feedback type="invalid">* Please Select Bank</Form.Control.Feedback>
        </div>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" id="supplier-bank-email-address">
          <Form.Label className="mb-1">Email Address</Form.Label>
          <Form.Control
            value={accountEmail === "nil" ? "" : accountEmail}
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            onKeyDown={(e) => ["!", "#", "$", "%", "^", "&", "*", "(", ")", ":", "'", "/", "`", "{", "}", "[", "]", "~", "+", "-"].includes(e.key) && e.preventDefault()}
            onChange={handleAccountEmailChange}
            className="p-2"
            type="email"
            placeholder="example@mail.com"
          />
        </Form.Group>
        {accountNumber.length > 0 && !validateAccountNumberField(accountNumber) ? (
          <div className="alert alert-warning" role="alert">
            Account number can be alphanumeric or numeric only!
          </div>
        ) : (
          ""
        )}
        <Button type="submit" onClick={(e) => handleBankSubmit(e)} className="w-100" id="supplier-bank-submit-button">
          Submit
        </Button>
      </Form>
    </>
  );
};

export default Bank;
