import { Button, Col, Form, Row } from "react-bootstrap";
import AddForm from "../Components/AddForm";
import style from "./../styles/AddLocation.module.css";
import Select from "../Components/Select";
import { useState, useEffect, useRef } from "react";
import { InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import PopUpModal from "../Components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import styleModal from "../styles/Modal.module.css";
import PhoneInput from "./../Components/PhoneInput/PhoneInput";
import { default as ReactSelect } from "react-select";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Map from "../Components/Map";

const AddLocation = () => {
  const [province_id, setProvinceid] = useState();
  const [province_name, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [city_id, setCityid] = useState();
  const [daysField, setDaysField] = useState([]);
  const [workingDays, setWorkingDays] = useState([]);
  const [locationName, setLocationName] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [alternativeNumber, setAlternativeNumber] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const [isNumberChecked, setIsNumberChecked] = useState(false);
  const [isAlternativeNumberChecked, setIsAlternativeNumberChecked] = useState(false);

  const [coords, setCoords] = useState({ lat: 0, lng: 0 });
  const [isLocationSelected, setIsLocationSelected] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const userId = user.user_id;
  const navigate = useNavigate();

  const validateNameField = (name) => {
    const formatOnlyNumber = /^[0-9\s]*$/;
    const formatAlphanumeric = /^[a-zA-Z0-9\s]*$/;
    if (formatAlphanumeric.test(name) && !formatOnlyNumber.test(name)) {
      return true;
    } else {
      return false;
    }
  };

  const handleLocationSubmit = async (e) => {
    try {
      e.preventDefault();

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
      }
      setValidated(true);
      if (locationName.length > 0 && validateNameField(locationName) && province_id && city_id && address.length > 0 && daysField.length > 0 && isLocationSelected && number) {
        setIsClicked(true);
        const location = {
          location_nick: locationName,
          province_id,
          city_id,
          address,
          latitude: coords?.lat,
          longitude: coords?.lng,
          shop_working_days,
          location_number: number,
          location_backup_number: alternativeNumber,
          is_contact_no_on_invoice: number.length > 0 && isNumberChecked ? isNumberChecked : false,
          is_alternative_no_on_invoice: alternativeNumber.length > 0 && isAlternativeNumberChecked ? isAlternativeNumberChecked : false,
        };
        const locationUser = { user_id: userId };
        const cashRegister = {
          shop_id: shop_id,
          register_name: `${locationName}_register`,
        };
        const res = await axios.post(process.env.REACT_APP_API_URL + `/location/add-location/${shop_id}`, { location, locationUser, cashRegister }, { withCredentials: true });
        if (res) {
          setLocationName("");
          setAddress("");
          setNumber("");
          setAlternativeNumber("");
          setDaysField([]);
          setError("");
          setValidated(false);
          setTimeout(() => {
            setTimeout(() => {
              navigate("/settings/locations");
            }, 1000);
            setModalShow(true);
          }, 500);
        }
      }
    } catch (error) {
      setIsClicked(true);
      setError(error.response.data.error.message);
      setIsClicked(false);
    }
  };
  const shop_working_days = JSON.stringify(workingDays);

  useEffect(() => {
    const getprovince = async () => {
      try {
        const req = await axios.get(process.env.REACT_APP_API_URL + `/location/get-province`, { withCredentials: true });
        const data = req.data;
        setProvince(data);
      } catch (error) {
        throw new Error(error.response.data);
      }
    };
    getprovince();
  }, []);

  const handleprovince = (event) => {
    event.preventDefault();
    setProvinceid(event.target.value);
  };

  useEffect(() => {
    const getcity = (id) => {
      axios
        .get(process.env.REACT_APP_API_URL + `/location/get-city/${id}`, {
          withCredentials: true,
        })
        .then((res) => {
          setCity(res.data);
        })
        .catch((err) => {
          throw new Error(err);
        });
    };
    if (province_id) {
      getcity(province_id);
    }
  }, [province_id]);

  const handlecity = (event) => {
    event.preventDefault();
    setCityid(event.target.value);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#5932EA" : "#80858C",
      background: state.isSelected ? "#F5F6FA" : "#fff",
      padding: 10,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: "100%",
      maxWidth: "100%",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
  };

  const daysOptions = [
    { value: "mon", label: "Mon" },
    { value: "tue", label: "Tue" },
    { value: "wed", label: "Wed" },
    { value: "thur", label: "Thu" },
    { value: "fri", label: "Fri" },
    { value: "sat", label: "Sat" },
    { value: "sun", label: "Sun" },
  ];
  const handleChange = (selected) => {
    const selectDays = selected.map((day) => day.value);
    setDaysField(selected);
    setWorkingDays(selectDays);
  };

  return (
    <AddForm>
      {<PopUpModal title={""} icon={<FontAwesomeIcon icon={faCircleCheck} />} text={"Location Added succesfully."} type={styleModal.successBg} iconModal={styleModal.successIcon} show={modalShow} onHide={() => setModalShow(false)} />}
      <div className="d-flex justify-content-between align-items-center px-5">
        <h5>Add New Location</h5>
      </div>
      <div className="text-center">{error && <span className="text-danger p-2 text-center my-2 rounded-1">*{error}</span>}</div>

      <hr />

      <div className={`${style.formMain}`} id="add-location-form">
        <Form onSubmit={handleLocationSubmit} noValidate validated={validated}>
          <Row>
            <Col className="py-2" md={6}>
              <Form.Group className="mb-3" controlId="price" id="add-location-name">
                <Form.Label className="mb-0">
                  Name of Location<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Write address"
                  value={locationName}
                  onChange={(e) => {
                    setLocationName(e.target.value);
                  }}
                  name="locationName"
                  pattern="^([A-Za-z ]|[0-9])+$"
                  required
                />

                <Form.Control.Feedback type="invalid">Please Select Location </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="py-2" md={6}>
              <Form.Group id="add-location-province">
                <label>
                  Location Province <span className="text-danger">*</span>
                </label>
                <Form.Select name="province" placeholder="Select Province" onChange={(e) => handleprovince(e)} required>
                  <option value="" disabled selected>
                    Select Province
                  </option>
                  {province_name?.map((getprov) => (
                    <option key={getprov.province_id} value={getprov.province_id}>
                      {" "}
                      {getprov.province_name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control value={province_id} hidden name="province" required />

                <Form.Control.Feedback type="invalid">Please Select Province </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="py-2" md={6}>
              <Form.Group id="add-location-city">
                <label>
                  City <span className="text-danger">*</span>
                </label>
                <Form.Select name="city" onChange={(e) => handlecity(e)} required>
                  <option value="" disabled selected>
                    Select City
                  </option>
                  {city.map((st, index) => (
                    <option key={st.city_id} value={st.city_id}>
                      {st.city_name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control value={city_id} hidden name="city" required />
                <Form.Control.Feedback type="invalid">Please Select City </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col className="py-2" md={6}>
              <Form.Group className="mb-3" controlId="price" id="add-location-street-address">
                <Form.Label className="mb-0">
                  Street Address<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Write Street Address" value={address} onChange={(e) => setAddress(e.target.value)} name="address" required />

                <Form.Control.Feedback type="invalid">Please Select Address </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col className="py-2" md={6}>
              <Form.Group controlId="formBasicTiming" id="add-location-opening-days">
                <label className="mb-0">
                  Shop Opening Days <span className="text-danger">*</span>
                </label>
                <div className={`${style.flexAlign} ${style.multiSelect} scrollable_multiselect`}>
                  <InputGroup hasValidation>
                    <ReactSelect
                      classNamePrefix="react-select-container"
                      options={daysOptions}
                      isMulti
                      closeMenuOnSelect={false}
                      components={{
                        Select,
                      }}
                      onChange={handleChange}
                      allowSelectAll={true}
                      value={daysField}
                      styles={customStyles}
                      name="shop_working_days"
                    />
                  </InputGroup>
                </div>
                <Form.Control value={daysField} hidden name="shopTimings" required />

                <Form.Control.Feedback type="invalid">Please Select Shop Opening Days </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="py-2" md={6}>
              <Form.Group className="mb-3" controlId="price" id="add-location-contact-number">
                <Form.Label className="mb-0">
                  Contact number(Whatsapp)
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                {/* <Form.Control type="text" placeholder="xxx xxxxxxxxxx" value={number} onChange={(e) => setNumber(e.target.value)} name='number' required /> */}
                <PhoneInput
                  placeholder="+92-3XX-XXXXXXX"
                  value={number}
                  onChange={(e) => {
                    if (e.target.value.length === 0) {
                      setIsNumberChecked(false);
                      setNumber(e.target.value);
                    } else {
                      setNumber(e.target.value);
                    }
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">Please Enter Contact Number </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="py-2" md={6}>
              <Form.Group className="mb-3" controlId="price" id="add-location-alternative-number">
                <Form.Label className="mb-0">Alternative number</Form.Label>
                {/* <Form.Control type="text" placeholder="xxx xxxxxxxxxx" value={alternativeNumber} onChange={(e) => setAlternativeNumber(e.target.value)} /> */}
                <PhoneInput
                  placeholder="+92-3XX-XXXXXXX"
                  value={alternativeNumber}
                  onChange={(e) => {
                    if (e.target.value.length === 0) {
                      setIsAlternativeNumberChecked(false);
                      setAlternativeNumber(e.target.value);
                    } else {
                      setAlternativeNumber(e.target.value);
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col className="py-2" md={6}>
              <Form.Group className="pb-3" id="add-location-show-contact-number">
                <Form.Check.Input value="" id="isNumber" disabled={!(number.length > 0)} checked={isNumberChecked} onChange={() => setIsNumberChecked((prev) => !prev)} />
                <Form.Check.Label>
                  <span className="mx-1">Show Contact Number on Invoice</span>
                </Form.Check.Label>
              </Form.Group>
              <Form.Group className="pb-3" id="add-location-show-alternative-number">
                <Form.Check.Input
                  value=""
                  id="isAlternative"
                  disabled={!(alternativeNumber.length > 0)}
                  checked={isAlternativeNumberChecked}
                  onChange={() => setIsAlternativeNumberChecked((prev) => !prev)}
                  // onChange={(e) => {
                  //   setIsAlternativeNumberChecked((prev) => !prev)}}
                />
                <Form.Check.Label>
                  <span className="mx-1">Show Alternate Number on Invoice</span>
                </Form.Check.Label>
              </Form.Group>
            </Col>
            <Map coords={coords} setCoords={setCoords} isLocationSelected={isLocationSelected} setIsLocationSelected={setIsLocationSelected} />
            {locationName.length > 0 && !validateNameField(locationName) ? (
              <div className="alert alert-warning" role="alert">
                Name of Location can be Alphanumeric and only Numberic Name is not allowed!
              </div>
            ) : (
              ""
            )}
            <Button disabled={isClicked} type="submit" className="w-100" id="add-location-save-button">
              Save
            </Button>
          </Row>
        </Form>
      </div>
    </AddForm>
  );
};

export default AddLocation;
