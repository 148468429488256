import React from "react";
import { Accordion, Card } from "react-bootstrap";
import style from "../styles/SearchAcoordian.module.css";
import DatePicker from "./DatePicker/DatePicker";
function AccordionItem({ index, mapdata }) {
  return (
    <Accordion.Item className={`pb-2  ${style.accordion_item}`} eventKey={index}>
      <Accordion.Header className=" d-flex justify-content-between">{mapdata.name}</Accordion.Header>
      <Accordion.Body className=" pb-5" aria-label={mapdata.name}>
        {mapdata.filter?.map((data, index) => (
          <>
            <div className=" mt-2 form-check">
              <input onChange={(e) => mapdata.handleChange(e)} className={`${style.radio} form-check-input`} value={data.label} type="radio" name={mapdata.name} id={data.value} checked={mapdata.value?.label === data.label ? true : false} disabled={data.isDisabled} />
              <label className="form-check-label" for={data.label}>
                {data.label}
              </label>
            </div>
          </>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
}
function MobileSearchAcoordian({ toggleSearchclose, filter, SearchData, dateName, fromDate, toDate, setFromDate, setToDate }) {
  return (
    <>
      <div className="d-lg-none d-md-inline d-inline">
        <Card className="border-0" style={{ background: "transparent" }}>
          <Card.Header style={{ background: "transparent" }}>
            <div className="d-flex  justify-content-between">
              <strong>Filter by</strong>
              <span onClick={toggleSearchclose}>Close X</span>
            </div>
          </Card.Header>
          <Card.Body className={`${style.accordionCardBody}`}>
            <Accordion defaultActiveKey="0">
              {SearchData.map((mapdata, index) => (
                <>
                  {typeof mapdata?.isShowOn === "function" && typeof mapdata?.isShowOnValue === "string" ? (
                    mapdata?.isShowOn(SearchData?.filter((val) => val.name === mapdata?.isShowOnValue)[0]) ? (
                      <AccordionItem index={index} mapdata={mapdata} />
                    ) : (
                      <></>
                    )
                  ) : (
                    <AccordionItem index={index} mapdata={mapdata} />
                  )}
                </>
              ))}
              {dateName && (
                <Accordion.Item className={`pb-2  ${style.accordion_item}`}>
                  <Accordion.Header className=" d-flex justify-content-between">{dateName}</Accordion.Header>
                  <Accordion.Body aria-label={dateName}>
                    <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>
          </Card.Body>
          <div className={style.bottomDiv}>
            <button onClick={filter} className="btn btn-primary w-75">
              {" "}
              Filter Now
            </button>
          </div>
        </Card>
      </div>
    </>
  );
}

export default MobileSearchAcoordian;
