import { useRef, useCallback } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "react-bootstrap";
import "./../../styles/printLayout.css";
import style from "./../../styles/InvoiceCard.module.css";
import InvoicePurchaseTemplate from "./Invoices/InvoicePurchaseTemplate";
import { useParams } from "react-router-dom";

const PurchaseOrderInvoicePage = () => {
  const componentRef = useRef(null);

  const { id } = useParams();

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
  });

  return (
    <>
      <div className={`my-4 d-flex justify-content-center gap-3`}>
        <Button
          variant="outline-primary"
          className="px-4"
          onClick={handlePrint}
        >
          Print{" "}
        </Button>
      </div>
      <div className={`${style.invoiceCard}`}>
        <InvoicePurchaseTemplate id={id} ref={componentRef} />
      </div>
    </>
  );
};

export default PurchaseOrderInvoicePage;
