import { createSlice, current } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

const localCart = localStorage.getItem("__cart") ? JSON.parse(localStorage.getItem("__cart")) : null;

const initialState = localCart
  ? {
      cartItems: localCart.cartItems,
      totalCartQuantity: localCart.totalCartQuantity,
      totalCartAmount: localCart.totalCartAmount,
      totalItemCartAmount: localCart.totalItemCartAmount,
      totalMiscItemCartAmount: localCart.totalMiscItemCartAmount,
      totalItemDiscount: localCart.totalItemDiscount,
      totalMiscItemDiscount: localCart.totalMiscItemDiscount,
      totalCartDiscount: localCart.totalCartDiscount,
      customer: localCart.customer,
      location: localCart.location,
      discount: localCart.discount,
      isCustomerWalkIn: localCart.isCustomerWalkIn,
      shippingCost: localCart.shippingCost,
      toggle: localCart.toggle == "True" ? true : false,
    }
  : {
      cartItems: [],
      totalCartQuantity: 0,
      totalCartAmount: 0,
      totalItemCartAmount: 0,
      totalMiscItemCartAmount: 0,
      totalCartDiscount: 0,
      totalItemDiscount: 0,
      totalMiscItemDiscount: 0,
      customer: {},
      location: {},
      discount: null,
      isCustomerWalkIn: true,
      shippingCost: 0,
      toggle: false,
    };

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      const itemIndex = state.cartItems.findIndex((item) => item.id === action.payload.id);
      if (itemIndex >= 0) {
        if (state.cartItems[itemIndex].quantity > state.cartItems[itemIndex].cartQuantity) {
          state.cartItems[itemIndex].cartQuantity += 1;
        }
      } else {
        const tempProduct = {
          ...action.payload,
          cartQuantity: 1,
          itemDiscount: 0,
        };
        state.cartItems.push(tempProduct);
        toast.success(`${action.payload.title} added to cart`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          pauseOnHover: false,
          className: "toastMsg",
        });
      }
      if (state.discount) {
        applyingCollectiveDiscountOnCartItems(state);
      }
      calculatingTotalCounts(state);

      localStorage.setItem("__cart", JSON.stringify(state));
    },
    removeFromCart(state, action) {
      const nextCartItems = state.cartItems?.filter((cartItem) => cartItem.id !== action.payload.itemId);
      state.cartItems = nextCartItems;

      if (state.discount) {
        applyingCollectiveDiscountOnCartItems(state);
      }
      calculatingTotalCounts(state);

      localStorage.setItem("__cart", JSON.stringify(state));
    },
    toggle(state) {
      state.toggle = !state.toggle;
    },
    handleImeiNumber(state, action) {
      state.cartItems = state.cartItems.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            imeis: action.payload.imeis,
          };
        } else {
          return item;
        }
      });
      localStorage.setItem("__cart", JSON.stringify(state));
    },

    updateCartItems(state, action) {
      state.cartItems = action.payload.cartItems;
      localStorage.setItem("__cart", JSON.stringify(state));
    },
    updateCartItemQuantity(state, action) {
      const { itemId, newQuantity } = action.payload;
      const updatedCartItems = state.cartItems.map((item) => {
        if (item.id === itemId) {
          const quantity = parseInt(newQuantity, 10) || "";
          const validQuantity = item.quantity ? Math.min(quantity, item.quantity) : quantity;
          const totalPrice = validQuantity * item.sale_price;
          return { ...item, cartQuantity: validQuantity || "", totalPrice };
        }
        return item;
      });
      state.cartItems = updatedCartItems;
      localStorage.setItem("__cart", JSON.stringify(state));
    },
    updateCartItemDiscount(state, action) {
      const { itemId, newDiscount, sale_price } = action.payload;
      let isValid = true;

      const updatedCartItems = state.cartItems.map((item) => {
        if (item.id === itemId) {
          if (newDiscount.includes("%")) {
            // Percentage Discount
            const discountPercentage = parseFloat(newDiscount.replace("%", "")) || 0;
            const discountAmount = (discountPercentage / 100) * +item.cartQuantity * +item.sale_price;
            if (newDiscount > "100%") {
              if (Number(newDiscount.split("%")[0]) > 100) {
                isValid = false;
              } else {
                isValid = true;
              }
            }

            return { ...item, itemDiscount: discountAmount, discount: newDiscount, isValid: isValid };
          } else {
            // Absolute Discount
            const discountAmount = parseFloat(newDiscount) || 0;

            if (newDiscount > sale_price) {
              isValid = false;

              //     return;
            } else if (newDiscount < 0) {
              isValid = false;
            } else {
              isValid = true;
            }
            return { ...item, itemDiscount: newDiscount, discount: newDiscount, isValid: isValid };
          }
        }
        return item;
      });
      state.cartItems = updatedCartItems;
      // state.totalCartDiscount = updatedCartItems.reduce((dis, item) => {}, 0);
      localStorage.setItem("__cart", JSON.stringify(state));
    },

    updateCartProducts(state, action) {
      let values = action.payload?.filter((item) => state.cartItems.some((val) => val.id === item.id));
      const updatedArray1 = state.cartItems.map((item1) => {
        const matchingItem = values?.find((item2) => item2.id === item1.id);
        if (matchingItem) {
          return {
            cartQuantity: item1.cartQuantity,
            created_at: item1.created_at,
            id: item1.id,
            img0: matchingItem.img0,
            itemDiscount: item1.itemDiscount,
            label: matchingItem.type,
            location_id: state.location_field.value,
            quantity: matchingItem.in_stock,
            sale_price: matchingItem.sale_price,
            title: matchingItem.title,
            updated_at: matchingItem.updated_at,
          };
        }
        return item1;
      });
      state.cartItems = updatedArray1;
      localStorage.setItem("__cart", JSON.stringify(state));
    },
    updateDiscount(state, action) {
      // It is used to add collective discount
      if (action.payload === "") {
        state.discount = null;
        state.cartItems.map((item) => {
          item.discount = 0;
          item.itemDiscount = 0;
        });
      } else {
        state.discount = action.payload;
        applyingCollectiveDiscountOnCartItems(state);
      }
      localStorage.setItem("__cart", JSON.stringify(state));
    },

    getTotals(state, action) {
      calculatingTotalCounts(state);
      localStorage.setItem("__cart", JSON.stringify(state));
    },

    emptyCart(state, action) {
      state.cartItems = [];
      state.totalCartQuantity = 0;
      state.totalCartAmount = 0;
      state.customer = {};
      state.discount = null;
      state.isCustomerWalkIn = true;
      state.shippingCost = 0;

      localStorage.removeItem("__cart");
    },
    getlocation(state, action) {
      state.location_field = action.payload;
      // localStorage.setItem("location", JSON.stringify(state.location_field));
    },
    addCustomer(state, action) {
      state.customer = action.payload;
      localStorage.setItem("__cart", JSON.stringify(state));
    },
    removeCustomer(state, action) {
      state.customer = {};
      localStorage.setItem("__cart", JSON.stringify(state));
    },
    emptyLocation(state, action) {
      state.location = {};
    },
    onChangeWalkInCustomer(state, action) {
      state.isCustomerWalkIn = action.payload;
      if (action.payload) {
        state.customer = {};
      }
      localStorage.setItem("__cart", JSON.stringify(state));
    },
    onChangeShippingCost(state, action) {
      state.shippingCost = action.payload;

      localStorage.setItem("__cart", JSON.stringify(state));
    },
    addMiscToCart(state, action) {
      const tempProduct = action.payload.map((item) => ({ id: uuidv4(), img0: "images/dummyImages/miscThumbnail.png", title: item.title, sale_price: Number(item.salePrice), cartQuantity: item.quantity, itemDiscount: 0, isMisc: true }));
      state.cartItems = [...state.cartItems, ...tempProduct];

      if (state.discount) {
        applyingCollectiveDiscountOnCartItems(state);
      }
      calculatingTotalCounts(state);

      localStorage.setItem("__cart", JSON.stringify(state));
    },
  },
});

const applyingCollectiveDiscountOnCartItems = (state) => {
  if (state.discount.includes("%")) {
    state.cartItems.map((item) => {
      item.discount = state.discount;
      const totalItemPrice = Number(item.cartQuantity) * Number(item.sale_price);
      item.itemDiscount = Math.floor((totalItemPrice * Number(state.discount.replace("%", ""))) / 100, 10);
    });
  } else {
    const totalCartItemPrice = state.cartItems.reduce((acc, item) => acc + Number(item.cartQuantity) * Number(item.sale_price), 0);
    let highestPrice = 0;
    let highestPriceItemIndex = -1;
    let tempTotalDiscount = 0;
    state.cartItems.map((item, index) => {
      const totalItemPrice = Number(item.cartQuantity) * Number(item.sale_price);
      if (totalItemPrice > highestPrice) {
        highestPrice = totalItemPrice;
        highestPriceItemIndex = index;
      }
      const calculatedDiscount = (totalItemPrice * Number(state.discount)) / totalCartItemPrice;
      tempTotalDiscount += Math.floor(calculatedDiscount, 10);
      item.itemDiscount = Math.floor(calculatedDiscount, 10);
      item.discount = Math.floor(calculatedDiscount, 10).toString();
    });
    if (highestPriceItemIndex !== -1 && tempTotalDiscount !== +state.discount && state.cartItems[highestPriceItemIndex]) {
      const discountDifference = +state.discount - tempTotalDiscount;
      let { itemDiscount } = state.cartItems[highestPriceItemIndex];
      state.cartItems[highestPriceItemIndex].itemDiscount = itemDiscount + discountDifference;
      state.cartItems[highestPriceItemIndex].discount = (itemDiscount + discountDifference).toString();
    }
  }
};

const calculatingTotalCounts = (state) => {
  const { cartItems } = state;

  // Initialize total values
  let totalCartQuantity = 0;
  let totalCartAmount = 0;
  let totalCartDiscount = 0;
  let totalItemDiscount = 0;
  let totalMiscItemDiscount = 0;
  let totalItemCartAmount = 0;
  let totalMiscItemCartAmount = 0;

  cartItems.forEach((cartItem) => {
    const { sale_price, cartQuantity, itemDiscount, isMisc } = cartItem;
    const itemTotal = sale_price * cartQuantity;

    // Accumulate total amount and quantity
    totalCartAmount += +itemTotal;
    totalCartQuantity += +cartQuantity;
    totalCartDiscount += +itemDiscount;
    if (isMisc) {
      totalMiscItemCartAmount += +itemTotal;
      totalMiscItemDiscount += +itemDiscount;
    } else {
      totalItemCartAmount += +itemTotal;
      totalItemDiscount += +itemDiscount;
    }
  });

  // Update state with calculated totals
  state.totalCartQuantity = totalCartQuantity;
  state.totalCartAmount = totalCartAmount;
  state.totalCartDiscount = totalCartDiscount;
  state.totalItemCartAmount = totalItemCartAmount;
  state.totalMiscItemCartAmount = totalMiscItemCartAmount;
  state.totalItemDiscount = totalItemDiscount;
  state.totalMiscItemDiscount = totalMiscItemDiscount;
};

export const {
  addToCart,
  removeFromCart,
  handleCartQuantity,
  updateCartProducts,
  handleItemDiscount,
  addMiscToCart,
  decreaseCart,
  getTotals,
  emptyCart,
  addCustomer,
  removeCustomer,
  getlocation,
  emptyLocation,
  handleImeiNumber,
  updateCartItems,
  updateDiscount,
  updateCartItemQuantity,
  updateCartItemDiscount,
  onChangeWalkInCustomer,
  onChangeShippingCost,
  toggle,
} = cartSlice.actions;

export default cartSlice.reducer;
