import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useEffect } from "react";
import { toast } from "react-toastify";
import ReactSelect from "./../../Components/ReactSelect";
import axios from "axios";

const AddonsRequestModal = ({ toggle, close, data }) => {
  const [addons, setAddons] = useState({
    marketplace_items_limit: {
      quantity: "",
      whole_price: 0,
    },
    emails_limit: {
      quantity: "",
      whole_price: 0,
    },
    sms_limit: {
      quantity: "",
      whole_price: 0,
    },
  });
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [item, setItem] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paidAmount, setPaidAmount] = useState();
  const [amountPayable, setAmountPayable] = useState(0);
  const [transDate, setTransDate] = useState(new Date().toISOString().split("T")[0]);
  const [transactionId, settransactionId] = useState();
  const [paidCheck, setPaidCheck] = useState(false);
  const [selectImage, setSelectImag] = useState();
  const [selectImageURL, setSelectImagURL] = useState();
  const [paymentDetails, setPaymentDetails] = useState(false);
  const fetchConsumableItems = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_API_URL + `/addons/pricing/consumable-item`, { withCredentials: true });
      const data = res?.data?.data;
      if (data) {
        const convertedObject = data?.consumableItems?.reduce((acc, item) => {
          acc[item.name] = +item.per_unit_price;
          return acc;
        }, {});
        setItem(convertedObject);
      }
    } catch (error) {
      // throw error;
      console.error(error);
    }
  };
  useEffect(() => {
    fetchConsumableItems();
  }, []);

  const handleRequestSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (data?.shop_id && validateLimits(addons) && (paymentDetails ? paidAmount && paymentType && (paymentType === "transfer" ? transactionId && transDate && selectImage : true) : true)) {
      if (paymentDetails) {
        if (+paidAmount !== +amountPayable) {
          setPaidCheck(true);
          return;
        }
      }
      try {
        const requestedItems = [];
        for (const [key, value] of Object.entries(addons)) {
          if (value?.quantity !== "") requestedItems.push({ name: key, quantity: value.quantity, whole_price: value.whole_price });
        }
        let imgData;
        if (selectImage) {
          imgData = await axios.post(`${process.env.REACT_APP_URL_IMAGE_CDN}/images/transactions/${data?.shop_id}`, selectImage, { withCredentials: true });
        }
        let finalObject = {
          shop_id: data?.shop_id,
          requested_items: requestedItems,
          is_paid: true,
        };
        let transaction;
        if (paymentDetails) {
          transaction = {
            amount_received: paidAmount ? paidAmount : 0,
            transaction_image_url: imgData ? imgData.data.files[0] : null,
            transaction_date: transDate ? transDate : null,
            transaction_id: transactionId ? transactionId : null,
            payment_type: paymentType ? paymentType : null,
          };
        }
        const res = await axios.post(process.env.REACT_APP_API_URL + `/addons/consumable-item-request`, { ...finalObject, transaction }, { withCredentials: true });
        if (res) {
          toast.success("Request Added Successfully", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          toggle((prev) => !prev);
          close();
        }
      } catch (err) {
        setError(err.response.data.error.message);
      }
    }
  };

  function validateLimits(limits) {
    return Object.values(limits).some((limit) => limit.quantity !== "");
  }

  const handleChangeCount = (e) => {
    if (Number(e.target.value) < 0) {
      setAddons((prev) => ({ ...prev, [e.target.name]: { quantity: "", whole_price: 0 } }));
    } else {
      if (e.target.value === "" || e.target.value === "0") {
        setAddons((prev) => ({ ...prev, [e.target.name]: { quantity: "", whole_price: 0 } }));
      } else {
        setAddons((prev) => ({ ...prev, [e.target.name]: { quantity: e.target.value, whole_price: Number(item[e.target.name] * e.target.value).toFixed(2) } }));
      }
    }
  };
  const handleImageUpload = async (e) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0], e.target.files[0].name);
    setSelectImag(formData);
    setSelectImagURL(URL.createObjectURL(e.target.files[0]));
  };
  useEffect(() => {
    setAmountPayable(
      Math.floor(Number(addons.marketplace_items_limit.whole_price) + Number(addons.emails_limit.whole_price) + Number(addons.sms_limit.whole_price)) > 0
        ? Math.floor(Number(addons.marketplace_items_limit.whole_price) + Number(addons.emails_limit.whole_price) + Number(addons.sms_limit.whole_price))
        : 0
    );
  }, [addons]);
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleRequestSubmit}>
        {error && <div className="text-danger text-center my-1 rounded-1 w-100">*{error}</div>}

        <Row className="">
          <Col md={12} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Shop</label>
              <Form.Control value={data?.shop_name} name="shop_name" disabled={true} />
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Marketplace Listings</label>
              <Form.Control
                value={addons.marketplace_items_limit.quantity}
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                name="marketplace_items_limit"
                onChange={(e) => {
                  handleChangeCount(e);
                }}
                min={1}
                type="number"
                placeholder="eg.1000"
              />
              <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label> Listings Amount</label>
              <Form.Control value={addons.marketplace_items_limit.whole_price} name="marketplace_items_limit" type="number" placeholder="Rs.1000" disabled={true} />
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Emails Limit</label>
              <Form.Control
                value={addons.emails_limit.quantity}
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                name="emails_limit"
                onChange={(e) => {
                  handleChangeCount(e);
                }}
                type="number"
                placeholder="eg.1000"
              />
              <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Emails Amount</label>
              <Form.Control value={addons.emails_limit.whole_price} name="emails_limit" type="number" placeholder="Rs.1000" disabled={true} />
            </Form.Group>
          </Col>
          <Col className="py-2" md={6}>
            <Form.Group className="" controlId="price">
              <label>SMS Limits</label>
              <Form.Control
                value={addons.sms_limit.quantity}
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                name="sms_limit"
                onChange={(e) => {
                  handleChangeCount(e);
                }}
                type="number"
                placeholder="eg.1000"
              />
              <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>SMS Amount</label>
              <Form.Control value={addons.sms_limit.whole_price} name="sms_limit" type="number" placeholder="Rs.1000" disabled={true} />
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="price">
              <label>
                Amount Payable <span className="text-danger">*</span>
              </label>
              <Form.Control value={amountPayable} type="number" placeholder="Rs.1000" disabled />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Add Payment"
              onChange={(e) => {
                setPaymentDetails(e.target.checked);
              }}
              checked={paymentDetails}
            />
          </Col>
          {paymentDetails ? (
            <>
              <Col md={6} className="py-2">
                <Form.Group className="w-100" controlId="formBasicShopName">
                  <label>Payment Type</label>
                  <Form.Select
                    onChange={(e) => {
                      setPaymentType(e.target.value);
                    }}
                    required
                  >
                    <option value="" selected disabled>
                      Select Payment Type
                    </option>
                    <option value="cash">Cash</option>
                    <option value="transfer">Transfer</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">Please Select Payment Type</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="py-2">
                {" "}
                <Form.Group className="w-100" controlId="price">
                  <label>
                    Paid Amount <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    value={paidAmount ? paidAmount : ""}
                    placeholder="Rs.1000"
                    required
                    onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                    min={Number(amountPayable)}
                    max={Number(amountPayable)}
                    onChange={(e) => {
                      if (Number(e.target.value) > 0) {
                        setPaidAmount(e.target.value);
                        if (Number(amountPayable) === Number(e.target.value)) {
                          setPaidCheck(false);
                        } else {
                          setPaidCheck(true);
                        }
                      } else {
                        setPaidAmount(0);
                      }
                    }}
                  />

                  <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
                </Form.Group>
              </Col>
              {paymentType === "transfer" && (
                <>
                  <Form.Group className="mt-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                    <label>
                      Transaction Id <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      value={transactionId}
                      name="contact_person_name"
                      onChange={(e) => {
                        settransactionId(e.target.value);
                      }}
                      placeholder="eg.AA11"
                      required={paymentType === "transfer" ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">Please Enter Transaction Id.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                    <label>
                      Transaction Date<span className="text-danger">*</span>
                    </label>
                    <Form.Control value={transDate} name="trans_date" disabled={true} required={paymentType === "transfer" ? true : false} type="date" />

                    <Form.Control.Feedback type="invalid">Please select Date.</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                    <label className="mb-1">
                      Upload Image<span className="text-danger">*</span>
                    </label>
                    <Form.Control type="file" size="sm" accept="image/*" name="contact_person_name" onChange={(e) => handleImageUpload(e)} required={paymentType === "transfer" ? true : false} />
                    <Form.Control.Feedback type="invalid">Please upload image.</Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
              {paidCheck && (
                <div class="alert alert-danger  text-center w-100 mt-2" role="alert">
                  Paid amount must be equal to payable
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </Row>
        <Row className="py-3">
          <Col>
            <Button className="w-100" onClick={handleRequestSubmit} disabled={!(data.shop_id && validateLimits(addons))}>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddonsRequestModal;
