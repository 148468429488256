import { forwardRef } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import style from "../../styles/Invoice.module.css";
import "../../styles/thermalPrintlayout.css";

const PrintProfitLossReport = forwardRef(
  ({ fromDate, toDate, location, data }, ref) => {
    const { user } = useSelector((state) => state.auth);
    const shopName = user.shop_name;

    return (
      <>
        <div ref={ref} className="thermalInvoice">
          <Row>
            <Col lg={12} sm={12} md={12} xs={12} className="text-center">
              <h5 style={{ color: "#000", fontSize: "16px" }}>
                Profit/Loss Report
              </h5>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} md={6} xs={6} className="text-center">
              <h5 style={{ color: "#000", fontSize: "16px" }}>{shopName}</h5>
            </Col>

            <Col lg={6} sm={6} md={6} xs={6} className="text-center">
              <h5 style={{ color: "#000", fontSize: "16px" }}>
                {location.label}
              </h5>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} md={6} xs={6} className="text-end">
              <h5 style={{ color: "#000", fontSize: "16px" }}>From:</h5>
              <h5 style={{ color: "#000", fontSize: "10px" }}>
                {new Date(fromDate).toLocaleDateString()}
              </h5>
            </Col>
            <Col lg={6} sm={6} md={6} xs={6} className="text-start">
              <h5 style={{ color: "#000", fontSize: "16px" }}>To:</h5>
              <h5 style={{ color: "#000", fontSize: "10px" }}>
                {new Date(toDate).toLocaleDateString()}
              </h5>
            </Col>
          </Row>
          <table
            className={`mx-auto mb-1 text-start thermalPrinterTable ${style?.itemsTable}`}
          >
            <th
              style={{
                border: "1px solid #000",
                padding: "5px",
                width: "10rem",
                textAlign: "center",
                color: "#000",
                fontSize: "10px",
              }}
            >
              #
            </th>

            <th
              style={{
                border: "1px solid #000",
                padding: "5px",
                textAlign: "center",
                color: "#000",
                fontSize: "10px",
              }}
            >
              Total (Rs)
            </th>

            <tr>
              <td
                style={{
                  border: "1px solid #000",
                  padding: "5px",
                  color: "#000",
                  fontSize: "10px",
                }}
              >
                <h5 style={{ color: "#000", fontSize: "10px" }}>
                  Sales Revenue
                </h5>
              </td>

              <td
                style={{
                  border: "1px solid #000",
                  padding: "5px",
                  textAlign: "end",
                  color: "#000",
                  fontSize: "10px",
                }}
              >
                <h5 style={{ color: "#000", fontSize: "10px" }}>
                  {data?.salesRevenue
                    ? Number(data?.salesRevenue).toLocaleString("en-IN")
                    : 0}
                </h5>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #000",
                  padding: "5px",
                  color: "#000",
                  fontSize: "10px",
                }}
              >
                <h5 style={{ color: "#000", fontSize: "10px" }}>
                  Cost Of Goods Sold
                </h5>
              </td>

              <td
                style={{
                  border: "1px solid #000",
                  padding: "5px",
                  textAlign: "end",
                  color: "#000",
                  fontSize: "10px",
                }}
              >
                <h5 style={{ color: "#000", fontSize: "10px" }}>
                  {data?.costOfGoodSold
                    ? Number(data?.costOfGoodSold).toLocaleString("en-IN")
                    : 0}
                </h5>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #000",
                  padding: "5px",
                  color: "#000",
                  fontSize: "10px",
                }}
              >
                <h5 style={{ color: "#000", fontSize: "10px" }}>
                  Gross Profit
                </h5>
              </td>

              <td
                style={{
                  border: "1px solid #000",
                  padding: "5px",
                  textAlign: "end",
                  color: "#000",
                  fontSize: "10px",
                }}
              >
                <h5 style={{ color: "#000", fontSize: "10px" }}>
                  {data?.grosProfit
                    ? Number(data?.grosProfit).toLocaleString("en-IN")
                    : 0}
                </h5>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #000",
                  padding: "5px",
                  color: "#000",
                  fontSize: "10px",
                }}
              >
                <h5 style={{ color: "#000", fontSize: "10px" }}>
                  Operating Expenses
                </h5>
              </td>

              <td
                style={{
                  border: "1px solid #000",
                  padding: "5px",
                  textAlign: "end",
                  color: "#000",
                  fontSize: "10px",
                  height: "20px",
                }}
              >
                <h5 style={{ color: "#000", fontSize: "10px" }}>
                  {data?.operatingExpense
                    ? Number(data?.operatingExpense).toLocaleString("en-IN")
                    : 0}
                </h5>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid transparent",
                  padding: "5px",
                  color: "#000",
                  fontSize: "10px",
                }}
              >
                <h5 style={{ color: "#000", fontSize: "10px" }}>
                  Status Report
                </h5>
              </td>

              <td
                style={{
                  border: "1px solid transparent",
                  padding: "5px",
                  textAlign: "end",
                  color: "#000",
                  fontSize: "10px",
                  height: "20px",
                }}
              >
                <h5 style={{ color: "#000", fontSize: "10px" }}>
                  {data?.netValue > 0 ? "Profit" : "Loss"}
                </h5>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  border: "1px solid transparent",
                  padding: "5px",
                  color: "#000",
                  fontSize: "10px",
                }}
              >
                <h5 style={{ color: "#000", fontSize: "10px" }}>
                  Net{data?.netValue > 0 ? " Profit" : " Loss"} (Rs)
                </h5>
              </td>

              <td
                style={{
                  border: "1px solid transparent",
                  padding: "5px",
                  textAlign: "end",
                  color: "#000",
                  fontSize: "10px",
                  height: "20px",
                }}
              >
                <h5 style={{ color: "#000", fontSize: "10px" }}>
                  {data?.netValue
                    ? Number(data?.netValue).toLocaleString("en-IN")
                    : 0}
                </h5>
              </td>
            </tr>
          </table>
        </div>
      </>
    );
  },
);

export default PrintProfitLossReport;
