import React, { useEffect, useState } from "react";
import OTPInput from "otp-input-react";
import { currentUser } from "../../Features/authSlice";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import style from "../../styles/Login.module.css";

const LoginOtp = ({ userInput, setLoading, setErrors, otpMessage, otpResendTimer, setOtpResendTimer, otpError, setOtpError, setInputType, username }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [OTPsent, setOTPsent] = useState(true);

  const tick = () => {
    setOtpResendTimer(otpResendTimer - 1);
  };
  useEffect(() => {
    let timer;
    if (OTPsent && otpResendTimer !== 0) {
      timer = setInterval(() => {
        tick();
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [otpResendTimer, OTPsent]);

  const resendOTP = async (e) => {
    setOtp("");

    setLoading(true);
    // setSuccess("");
    setErrors("");
    try {
      const res = await axios.post(process.env.REACT_APP_API_URL + "/otp/send", { phone_number: userInput, ...(username.length > 0 ? { username } : {}) });
      if (res) {
        setOtpResendTimer(60);
        setLoading(false);
        // setSuccess(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      setOtpError(error?.response?.data?.message);
      //   setErrors(e.response.data.message);
    }
  };
  const VerifyOTP = async (event) => {
    event.preventDefault();
    setLoading(true);
    await axios
      .post(process.env.REACT_APP_API_URL + "/otp/verify", { phone_number: userInput, otp, ...(username.length > 0 ? { username } : {}) }, { withCredentials: true })
      .then((res) => {
        setOtp("");
        localStorage.setItem("refreshToken", res.data.refreshToken);
        dispatch(currentUser(res.data.refreshToken));
        setOTPsent(false);
        // setSuccess("");
      })
      .catch((e) => {
        setOtp("");
        setErrors(e?.response?.data?.message);
        setLoading(false);
      });
    // dispatch(loginByOtp({ otp, user_email }));
  };
  return (
    <>
      <div style={{ width: "100%" }}>
        {otpMessage && otpError !== "OTP limit exceeded" && <p className="my-3 text-center">{otpMessage.replace("$", "")}</p>}
        <Form onSubmit={VerifyOTP} className="mt-2">
          {otpError !== "OTP limit exceeded" ? (
            <>
              <div className="d-flex justify-content-center">
                <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={4} otpType="number" disabled={false} />
              </div>
              <div className="d-flex justify-content-around me-2 mt-2">
                <div className="" data-testid="otp-resend-root" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  {otpResendTimer ? <span>{otpResendTimer} seconds remaining</span> : <span>OTP not recieved yet? </span>}
                  <button type="button" disabled={otpResendTimer > 0} className={"py-0 btn btn-outline-secondary"} style={{ marginLeft: "20px", height: "2rem" }} onClick={resendOTP}>
                    Resend
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className={`d-flex justify-content-around my-1 ${otpError === "OTP limit exceeded" ? "py-5 fs-5" : ""}`}>
              <p className="text-danger">{otpError === "OTP limit exceeded" ? ` ${otpError}, try again tomorrow ` : otpError ? otpError : ""}</p>
            </div>
          )}

          {otpError !== "OTP limit exceeded" ? (
            <>
              <div className="mt-4">
                <Button className="w-100" disabled={otp.length !== 4} type="submit">
                  Login
                </Button>
              </div>
            </>
          ) : (
            <div className={`link-main cursor-pointer ${style.backToLogin}`} onClick={() => setInputType("input")}>
              <FontAwesomeIcon className="link mx-2" icon={faArrowLeft} />
              <span className="link">Back to login</span>
            </div>
          )}
        </Form>
      </div>
    </>
  );
};

export default LoginOtp;
