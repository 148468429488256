import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const InventoryReportTour = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const inventoryReportTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "InventoryReport", value: true } }));
      setTimeout(() => {
        inventoryReportTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#inventory-report",
        popover: {
          title: `Inventory Report`,
          description: "The Inventory Report lists current stock levels, including in-stock, low-stock, and out-of-stock items.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#inventory-report-location-filter",
        popover: {
          title: `Select Location`,
          description: " Shows the option to filter reports based on specific locations.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#inventory-report-day-filter",
        popover: {
          title: `Days Filter`,
          description: "Shows data for transactions and reports from the last 15, 30, etc. days.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#inventory-report-category-filter",
        popover: {
          title: `Select Category`,
          description: " Shows the ability to filter items by their respective categories.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#inventory-report-product-filter",
        popover: {
          title: `Select Product`,
          description: " Shows the option to filter reports based on specific products.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#inventory-report-reset-filter",
        popover: {
          title: `Reset Filters`,
          description: "Shows a button to reset all applied filters to their default state.",
          side: "right",
          align: "start",
        },
      },

      {
        element: "#inventory-report-net-worth",
        popover: {
          title: `Net Worth`,
          description: "Shows the total net worth based on current assets and liabilities.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#inventory-report-items-wise-stock-report",
        popover: {
          title: `Item-wise Stock Report`,
          description: "Shows detailed stock levels for each item.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#inventory-report-category-wise-stock-report",
        popover: {
          title: `Category-wise Stock Report`,
          description: " Shows stock levels categorized by product types.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#inventory-report-vendor-wise-stock-report",
        popover: {
          title: `Vendor Wise Stock Report`,
          description: "Shows stock levels organized by different vendors.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "InventoryReport", value: true } }));
            inventoryReportTour.destroy();
          },
        },
      },
    ],
  });
  useEffect(() => {
    inventoryReportTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default InventoryReportTour;
