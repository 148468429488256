import React, { useEffect, useMemo, useRef, useState } from "react";
import style from "./UploadViaMobile.module.css";
import { QRCode } from "react-qrcode-logo";
import techbazaarLogo from "../../assets/images/TechBazaar.png";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { io } from "socket.io-client";
import LoaderPage from "../loaders/loaderPage";
import Cookies from "../../Features/Cookies";

function UploadViaMobile({ close, setSelectedFiles, directory, maxLimit, setImageUploadedViaMobile }) {
  const { shop_id, user_id } = useSelector((state) => state.auth.user);
  const socketRef = useRef();
  /* STATES OF COMPONENT */

  const [refreshToken, setRefreshToken] = useState(false);
  const toggleToken = () => {
    setRefreshToken((prev) => !prev);
  };
  const [isConnected, setIsConnected] = useState(false);

  /* END STATES OF COMPONENT */
  const token = useMemo(() => Number(`${shop_id}${user_id}${new Date().getTime()}`).toString(32), [shop_id, user_id, refreshToken]);
  const [qrCode, setQrCode] = useState("");
  useEffect(() => {
    setQrCode(`${process.env.REACT_APP_BASE_URL}/upload?token=${token}&limit=${maxLimit}`);
  }, [token, directory, maxLimit]);

  /* SOCKET CALLBACKS */
  const readyToConnectCB = (message) => {};
  const connectedCB = (message) => {
    if (message.connected) {
      setIsConnected(true);
    }
  };
  const disconnectedCB = (message) => {
    toggleToken();
    setIsConnected(false);
  };
  const uploadedCB = (message) => {
    setSelectedFiles(message.urls.map((url) => ({ data: url, type: "tempUrl", preview: process.env.REACT_APP_URL_S3 + "/" + url })));
    toggleToken();
    setIsConnected(false);
    setImageUploadedViaMobile(true);
    close();
  };

  /* END SOCKET CALLBACKS */
  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(process.env.REACT_APP_API_URL, {
        path: "/imageUpload",
        auth: {
          token: token,
        },
      });
    }
    const socket = socketRef.current;
    socket.on("imageUpload:readyToConnect", readyToConnectCB);
    socket.on("imageUpload:connected", connectedCB);
    socket.on("imageUpload:disconnected", disconnectedCB);
    socket.on("imageUpload:uploaded", uploadedCB);

    return () => {
      socket.disconnect();
      if (socketRef.current) {
        socketRef.current = null;
      }
    };
  }, [token, refreshToken]);
  return (
    <div className={`${style.container}`}>
      {isConnected ? (
        <>
          <LoaderPage noForPage={true} />
          <h4>Waiting for Images to upload....</h4>
        </>
      ) : (
        <>
          <div className={`${style.close}`} onClick={close}>
            <FontAwesomeIcon icon={faClose} />
          </div>
          <div className={style.qr_container}>
            <QRCode value={qrCode} size={256} eyeRadius={48} fgColor="#100045" eyeColor="#9e3ce7" ecLevel="H" logoImage={techbazaarLogo} qrStyle="dots" logoPadding={8} logoPaddingStyle="circle" logoWidth={64} logoHeight={64} removeQrCodeBehindLogo={true} />
          </div>
        </>
      )}
    </div>
  );
}

export default UploadViaMobile;
