import { useRef, useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import PhoneInput from "../../Components/PhoneInput/PhoneInput";
import CnicInput from "../../Components/CnicInput/CnicInput";
import { faCheck, faUpload } from "@fortawesome/free-solid-svg-icons";
import style from "../../styles/UploadFile.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const transform = (text) => {
  const temp = text.split("_");
  let ret = "";
  temp.forEach((txt) => {
    ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1);
  });
  return ret;
};

const EditTradeInCustomer = ({ id, close }) => {
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const [customer_name, setCustomerName] = useState("");
  const [customer_email, setCustomerEmail] = useState("");
  const [customer_number, setCustomerNumber] = useState("");
  const [customer_cnic, setCustomerCnic] = useState("");
  const [cnicFront, setCnicFront] = useState(null);
  const [cnicBack, setCnicBack] = useState(null);
  const [success, setSucces] = useState("");
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    const getCustomerById = async () => {
      if (id !== -1) {
        try {
          const response = await axios.get(process.env.REACT_APP_API_URL + `/trade-in/get-customer/${id}`, {
            withCredentials: true,
          });
          if (response) {
            const data = response?.data?.success?.data?.customer;
            setCustomerName(data.customer_name);
            setCustomerEmail(data.customer_email);
            setCustomerNumber(data.customer_number);
            setCustomerCnic(data.customer_cnic);
            const images = response?.data?.success?.data?.images?.reduce(
              (oldObj, imageObj) => {
                const newObj = { ...oldObj, [imageObj.type]: { url: imageObj?.url, id: imageObj?.id, name: imageObj.type + ".jpg" } };
                return newObj;
              },
              { cnic_back: null, cnic_front: null },
            );
            setCnicBack({ preview: process.env.REACT_APP_URL_S3 + "/" + images.cnic_back.url, key: images.cnic_back.url, id: images.cnic_back.id, name: transform(images.cnic_back?.name) });
            setCnicFront({ preview: process.env.REACT_APP_URL_S3 + "/" + images.cnic_front.url, key: images.cnic_front.url, id: images.cnic_front.id, name: transform(images.cnic_front?.name) });
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    };
    getCustomerById();
  }, [id]);
  const nameFormat = /^[a-zA-Z\s]+$/;
  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let emailValidate = customer_email.length > 0 && !emailFormat.test(customer_email) ? false : true;
  const handleCustomerEdit = async (e) => {
    e.preventDefault();
    try {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
      }
      setValidated(true);
      if (nameFormat.test(customer_name) && customer_number.replace(/\D/g, "").length === 12 && emailValidate && customer_cnic.replace(/\D/g, "").length === 13 && Object.values(cnicBack).length > 0 && Object.values(cnicFront).length > 0) {
        setIsClicked(true);
        const cnicFrontURLs = cnicFront.hasOwnProperty("file") ? await handleUpload(cnicFront) : [cnicFront.key];
        const cnicBackURLs = cnicBack.hasOwnProperty("file") ? await handleUpload(cnicBack) : [cnicBack.key];
        if (cnicFrontURLs || cnicBackURLs) {
          if (cnicFrontURLs?.length > 0 && cnicBackURLs?.length > 0) {
            const images = [];
            if (cnicFront.hasOwnProperty("file")) {
              images.push({
                id: cnicFront?.id,
                type: "cnic_front",
                url: cnicFrontURLs[0],
              });
            }
            if (cnicBack.hasOwnProperty("file")) {
              images.push({
                id: cnicBack?.id,
                type: "cnic_back",
                url: cnicBackURLs[0],
              });
            }
            const res = await axios.put(
              process.env.REACT_APP_API_URL + `/trade-in/update-customer/${id}`,
              {
                customer: {
                  customer_name,
                  customer_email,
                  customer_number,
                  customer_cnic,
                  shop_id: shop_id,
                  customer_type: "trade_in",
                },
                images,
              },
              { withCredentials: true },
            );

            if (res) {
              setSucces("Customer Updated Successfully");
              setError("");
              setTimeout(() => close(), 1000);
            }
          }
        }
      }
    } catch (error) {
      setIsClicked(true);
      setError(error?.response?.data?.error?.message);
      setIsClicked(false);
    }
  };
  const handleCNICFrontChange = (e) => {
    const file = e?.target?.files[0];
    setCnicFront((prev) => {
      return { ...prev, preview: URL.createObjectURL(file), file, name: file.name };
    });
  };
  const handleCNICBackChange = (e) => {
    const file = e?.target?.files[0];
    setCnicBack((prev) => {
      return { ...prev, preview: URL.createObjectURL(file), file, name: file.name };
    });
  };
  const handleUpload = (file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("files", file.file, file.file.name);
      axios
        .post(
          `${process.env.REACT_APP_URL_IMAGE_CDN}/trade-in`,
          formData,
          { withCredentials: true },
          {
            headers: {
              Accept: "application/json",
              "content-type": "multipart/form-data",
            },
          },
        )
        .then(({ data }) => {
          resolve(data.files);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleCustomerEdit}>
      {success && <p className="text-success p-2 text-center my-2 rounded-1">*{success}</p>}
      {error && <p className="text-danger p-2 text-center my-2 rounded-1">*{error}</p>}
      <Form.Group className="mb-3">
        <Form.Label className="mb-1">
          Customer Full Name <span style={{ color: "red" }}>*</span>
        </Form.Label>
        <Form.Control
          className="p-2"
          type="text"
          name="customer_name"
          placeholder="Full Name"
          value={customer_name}
          pattern="^^[A-Za-z ]+$"
          onChange={(e) => {
            if (e.target.value.length < 30) {
              setCustomerName(e.target.value);
            }
          }}
          required
        />
        <Form.Control.Feedback type="invalid">* Invalid Username</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="mb-1">
          Customer Contact <span style={{ color: "red" }}>*</span>
        </Form.Label>
        <PhoneInput className="p-2" name="customer_number" placeholder="+92-3XX-XXXXXXX" value={customer_number} isInvalid={validated && customer_number.replace(/\D/g, "").length !== 12} onChange={(e) => setCustomerNumber(e.target.value)} required />
        <Form.Control.Feedback type="invalid">* Invalid Phone Number</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="mb-1">Customer Email</Form.Label>
        <Form.Control className="p-2" type="email" name="customer_email" placeholder="example@mail.com" isInvalid={validated && !emailValidate} value={customer_email} onChange={(e) => setCustomerEmail(e.target.value)} />
        <Form.Control.Feedback type="invalid">* Invalid Email</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="mb-1">
          Customer CNIC <span style={{ color: "red" }}>*</span>
        </Form.Label>
        <CnicInput className="p-2" name="customer_number" value={customer_cnic} isInvalid={validated && customer_cnic.replace(/\D/g, "").length !== 13} onChange={(e) => setCustomerCnic(e.target.value)} placeholder="XXXXX-XXXXXXX-X" required />
        <Form.Control.Feedback type="invalid">* Invalid CNIC Number</Form.Control.Feedback>
      </Form.Group>
      <div className={`${style.upload_btn_wrapper} mt-4 d-flex  w-100`}>
        <Form.Group className="w-100" controlId="formBasicTiming">
          <Form.Label className="mb-1">
            CNIC Front <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Button variant="outline-primary" className="w-100 border">
            {" "}
            <FontAwesomeIcon className="mx-2" icon={cnicFront?.url ? faCheck : faUpload} /> {cnicFront?.preview ? cnicFront?.name : "Upload File"}
          </Button>
          <input
            className="p-2"
            type="file"
            name="cnic_front"
            style={{ height: "42px" }}
            onChange={handleCNICFrontChange}
            accept="image/*" // Restrict to image files
          />
          <Form.Control.Feedback type="invalid">* Select CNIC Front Image</Form.Control.Feedback>
          <div style={{ width: "150px" }}>
            <img src={cnicFront?.preview} alt="" width="100%" height="auto" />
          </div>
        </Form.Group>
      </div>
      <div className={`${style.upload_btn_wrapper} mt-4 d-flex  w-100 mb-3`}>
        <Form.Group className="w-100" controlId="formBasicTiming">
          <Form.Label className="mb-1">
            CNIC Back <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Button variant="outline-primary" className="w-100 border">
            {" "}
            <FontAwesomeIcon className="mx-2" icon={cnicBack?.url ? faCheck : faUpload} /> {cnicBack?.preview ? cnicBack?.name : "Upload File"}
          </Button>
          <input
            className="p-2"
            type="file"
            name="cnic_back"
            style={{ height: "42px" }}
            onChange={handleCNICBackChange}
            accept="image/*" // Restrict to image files
          />
          <Form.Control.Feedback type="invalid">* Select CNIC Back Image</Form.Control.Feedback>
          <div style={{ width: "150px" }}>
            <img src={cnicBack?.preview} alt="" width="100%" height="auto" />
          </div>
        </Form.Group>
      </div>
      <Button disabled={isClicked} type="submit" className="w-100">
        Save
      </Button>
    </Form>
  );
};

export default EditTradeInCustomer;
