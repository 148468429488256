import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";
const DailyCashReportTour = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const dailyCashReportTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "DailyCashReport", value: true } }));
      setTimeout(() => {
        dailyCashReportTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#daily-cash-report",
        popover: {
          title: `Daily Cash Report`,
          description: "This report shows the total cash transactions made on a specific day, including cash inflows and outflows.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#daily-cash-report-date-filter",
        popover: {
          title: `Select Date Range`,
          description: "Select the date range for which you want to generate the report.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#daily-cash-report-location-filter",
        popover: {
          title: `Select Location`,
          description: "Select the location for which you want to generate the report.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#daily-cash-report-register-filter",
        popover: {
          title: `Select Register`,
          description: "Select the register of the day of a location for which you want to generate the report.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#daily-cash-report-opening-balance",
        popover: {
          title: `Opening Balance`,
          description: "This shows the starting cash balance for the day.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#daily-cash-report-cash-in-hand",
        popover: {
          title: `Cash In Hand`,
          description: "Shows the current cash in hand.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#daily-cash-report-sale-payments",
        popover: {
          title: `Sale Payments`,
          description: "Shows the total amount received from sales.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#daily-cash-report-vendor-payments",
        popover: {
          title: `Vendor Payments`,
          description: "Shows the total amount paid to vendors.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#daily-cash-report-expense",
        popover: {
          title: `Expense`,
          description: "Shows the total expenses incurred.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#daily-cash-report-refunded-payments",
        popover: {
          title: `Refunded Payments`,
          description: "Shows the total amount refunded to customers.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#daily-cash-report-withdraw-payments",
        popover: {
          title: `Withdraw Payments`,
          description: "Shows the total amount withdrawn from the business.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#daily-cash-report-deposit-payments",
        popover: {
          title: `Deposit Payments`,
          description: "Shows the total amount deposited into the business.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "DailyCashReport", value: true } }));
            setTimeout(() => {
              dailyCashReportTour.destroy();
            });
          },
        },
      },
    ],
  });
  useEffect(() => {
    dailyCashReportTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default DailyCashReportTour;
