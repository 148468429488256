import CustomizeTableview from "../TableViews/CustomizeTableview";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { Col, Row, Button } from "react-bootstrap";
import SearchableSelect from "../SearchableSelect";
import customloader from "../../assets/images/RollingLoader.gif";
import style from "../../styles/Reports.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import dateIcon from "../../assets/svg/dateIcon.svg";
import styles from "../../styles/Setting.module.css";
import filterStyle from "../../styles/filtersButtons.module.css";
import filterr from "../../assets/svg/filter.svg";
import MobileSubReportsTabs from "./MobileSubReportsTab";
import MobilrReportsFilter from "./MobileReportsFilter";
import LoaderPage from "../loaders/loaderPage";
import DailyTransactionalReportTour from "../../TourGuides/DailyTransactionalReportTour";

const DailyTransactionalReport = ({ setGrandTotalProp, MainactiveTab, close, show }) => {
  const [salesPaymentData, setSalesPaymentData] = useState([]);
  const [totalSalesPaymentData, setTotalSalesPaymentData] = useState([]);
  const [expensesData, setExpensesData] = useState([]);
  const [refundsData, setRefundsData] = useState([]);
  const [totalRefundsData, setTotalRefundsData] = useState([]);
  const [totalExpensesData, setTotalExpensesData] = useState([]);
  const [vendorPaymentsData, setVendorPaymentsData] = useState([]);
  const [totalVendorPaymentsData, setTotalVendorPaymentsData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [location, setLocation] = useState({});
  let [locationFields, setLocationFields] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isToggledSales, setIsToggledSales] = useState(true);
  const [isToggledExpenses, setIsToggledExpenses] = useState(true);
  const [isToggledVendorPayments, setIsToggledVendorPayments] = useState(true);
  const [isToggledRefund, setIsToggledRefund] = useState(true);
  const [orderBySales, setOrderBySales] = useState({});
  const [orderByExpenses, setOrderByExpenses] = useState({});
  const [orderByVendorPayments, setOrderByVendorPayments] = useState({});
  const [orderByRefund, setOrderByRefund] = useState({});
  const [grandTotal, setGrandTotal] = useState();
  const [activeTAb, setActiveTAb] = useState("Sale-payment");
  const [tableData, settableData] = useState([]);
  const [showMobileTabs, setShowMobileTabs] = useState(false);
  const [paymentType, setPaymentType] = useState("");

  const handleClickSales = () => {
    setIsToggledSales(!isToggledSales);
  };
  const handleClickExpenses = () => {
    setIsToggledExpenses(!isToggledExpenses);
  };
  const handleClickVendorPayments = () => {
    setIsToggledVendorPayments(!isToggledVendorPayments);
  };
  const handleClickRefund = () => {
    setIsToggledRefund(!isToggledRefund);
  };

  let fromcurrentDate = new Date(startDate);
  let fromyear = fromcurrentDate.getFullYear();
  let frommonth = ("0" + (fromcurrentDate.getMonth() + 1)).slice(-2);
  let fromday = ("0" + fromcurrentDate.getDate()).slice(-2);
  let fromdateresult = fromyear + "-" + frommonth + "-" + fromday;

  let tocurrentDate = new Date(startDate);
  tocurrentDate.setDate(tocurrentDate.getDate() - 1);
  let toyear = tocurrentDate.getFullYear();
  let tomonth = ("0" + (tocurrentDate.getMonth() + 1)).slice(-2);
  let today = ("0" + tocurrentDate.getDate()).slice(-2);
  let todateresult = toyear + "-" + tomonth + "-" + today;

  const { user } = useSelector((state) => state.auth);
  const userId = user.user_id;
  const user_type = user.user_type;
  const shop_id = user.shop_id;

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + `/location/get-location-by-user/${userId}`, { shop_id: shop_id, user_type: user_type }, { withCredentials: true })
      .then((res) => {
        const locationsArr = res.data.map((loc) => ({
          value: loc.location_id,
          label: loc.location,
          isDisabled: new Date().toISOString() >= loc.active_till ? true : false,
        }));

        setLocation(locationsArr);
        setLocationFields({
          value: locationsArr[0]?.value,
          label: locationsArr[0]?.label,
          isDisabled: locationsArr[0]?.isDisabled,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [userId, shop_id, user_type]);

  useEffect(() => {
    if (Object.keys(locationFields).length > 0) {
      const SalesPayment = (locationFields) => {
        let dates = {
          from: todateresult,
          to: fromdateresult,
        };

        axios
          .post(
            process.env.REACT_APP_API_URL + `/reports/daily-transactional/sales-payment/${userId}`,
            {
              dates,
              orderBySales,
              locationFields,
              paymentType,
            },
            { withCredentials: true }
          )
          .then((res) => {
            setSalesPaymentData(
              res.data.map((row) => ({
                ...row,
                customer_name: row.customer_number === "nil" ? "Walk-in Customer" : row.customer_name,
                customer_number: row.customer_number === "nil" ? "" : row.customer_number,
                customer_email: row.customer_number === "nil" ? "" : row.customer_email,
              }))
            );
            setIsLoading(false);
          })
          .catch((err) => {
            throw new Error(err.response.data);
          });
      };
      SalesPayment(locationFields);
    }
  }, [userId, startDate, locationFields, orderBySales, paymentType, todateresult, fromdateresult]);

  useEffect(() => {
    const TotalSalesPayment = (locationFields) => {
      let dates = {
        from: todateresult,
        to: fromdateresult,
      };

      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/daily-transactional/total/sales-payment/${userId}`,
          {
            dates,
            locationFields,
            paymentType,
          },
          { withCredentials: true }
        )
        .then((res) => {
          setTotalSalesPaymentData(res.data[0]);
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(locationFields).length > 0) {
      TotalSalesPayment(locationFields);
    }
  }, [userId, startDate, locationFields, paymentType, todateresult, fromdateresult]);

  useEffect(() => {
    const Expenses = (locationFields) => {
      let dates = {
        from: todateresult,
        to: fromdateresult,
      };

      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/daily-transactional/expenses/${userId}`,
          {
            dates,
            orderByExpenses,
            locationFields,
            paymentType,
          },
          { withCredentials: true }
        )
        .then((res) => {
          setExpensesData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(locationFields).length > 0) {
      Expenses(locationFields);
    }
  }, [userId, startDate, locationFields, orderByExpenses, paymentType, todateresult, fromdateresult]);
  useEffect(() => {
    const TotalExpenses = (locationFields) => {
      let dates = {
        from: todateresult,
        to: fromdateresult,
      };

      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/daily-transactional/total/expenses/${userId}`,
          {
            dates,
            locationFields,
            paymentType,
          },
          { withCredentials: true }
        )
        .then((res) => {
          setTotalExpensesData(res.data[0]);
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(locationFields).length > 0) {
      TotalExpenses(locationFields);
    }
  }, [userId, startDate, locationFields, paymentType, todateresult, fromdateresult]);
  useEffect(() => {
    const VendorPayments = (locationFields) => {
      let dates = {
        from: todateresult,
        to: fromdateresult,
      };

      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/daily-transactional/vendors-payment/${userId}`,
          {
            dates,
            orderByVendorPayments,
            locationFields,
            paymentType,
          },
          { withCredentials: true }
        )
        .then((res) => {
          setVendorPaymentsData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(locationFields).length > 0) {
      VendorPayments(locationFields);
    }
  }, [userId, startDate, locationFields, orderByVendorPayments, paymentType, todateresult, fromdateresult]);
  useEffect(() => {
    const TotalVendorPayments = (locationFields) => {
      let dates = {
        from: todateresult,
        to: fromdateresult,
      };

      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/daily-transactional/total/vendors-payment/${userId}`,
          {
            dates,
            locationFields,
            paymentType,
          },
          { withCredentials: true }
        )
        .then((res) => {
          const data = {
            ...res.data[0],
            a: null,
            b: null,
          };
          setTotalVendorPaymentsData(data);
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };

    if (Object.keys(locationFields).length > 0) {
      TotalVendorPayments(locationFields);
    }
  }, [userId, startDate, locationFields, paymentType, todateresult, fromdateresult]);
  useEffect(() => {
    const Refunds = (locationFields) => {
      let dates = {
        from: todateresult,
        to: fromdateresult,
      };

      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/daily-transactional/refunds/${userId}`,
          {
            dates,
            orderByRefund,
            locationFields,
            paymentType,
          },
          { withCredentials: true }
        )
        .then((res) => {
          setRefundsData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(locationFields).length > 0) {
      Refunds(locationFields);
    }
  }, [userId, startDate, locationFields, orderByRefund, paymentType, todateresult, fromdateresult]);
  useEffect(() => {
    const TotalRefunds = (locationFields) => {
      let dates = {
        from: todateresult,
        to: fromdateresult,
      };

      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/daily-transactional/total/refunds/${userId}`,
          {
            dates,
            locationFields,
            paymentType,
          },
          { withCredentials: true }
        )
        .then((res) => {
          setTotalRefundsData(res.data[0]);
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(locationFields).length > 0) {
      TotalRefunds(locationFields);
    }
  }, [userId, startDate, locationFields, paymentType, todateresult, fromdateresult]);
  useEffect(() => {
    const grandTotal = () => {
      let dates = {
        from: todateresult,
        to: fromdateresult,
      };

      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/grand-total/${userId}`,
          {
            dates,
            locationFields,
            paymentType,
          },
          { withCredentials: true }
        )
        .then((res) => {
          setGrandTotal(res.data[0]?.grand_total);
          setGrandTotalProp(res.data[0]?.grand_total);
          setIsLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(locationFields).length > 0) {
      grandTotal();
    }
  }, [userId, startDate, paymentType, locationFields, todateresult, fromdateresult]);
  useEffect(() => {
    if (activeTAb === "Sale-payment") {
      settableData(salesPaymentData);
    }
  }, [salesPaymentData, activeTAb, expensesData]);
  const handleTabChange = (tab) => {
    setActiveTAb(tab);
  };
  const handleClose = () => {
    setShowMobileTabs(false);
  };
  const handleShow = () => {
    setShowMobileTabs(true);
  };
  const handleLocation = (e) => {
    if (e.value) {
      setLocationFields(e);
    } else {
      setLocationFields({});
    }
  };
  return (
    <>
      {user?.tour && !user?.tour?.DailyTransactionalReport && <DailyTransactionalReportTour />}
      <Row className="align-items-center  px-2 py-2 bg-white mb-4 mt-1 ">
        <Col xs lg="3" md="4" sm="4" xl="3" className="mb-md-0 mb-2 d-lg-block d-md-block d-sm-block d-none" id="daily-transactional-report-payment-type">
          <SearchableSelect
            placeholder="Select Payment Type"
            searchable={false}
            options={[
              { value: "", label: "All" },
              { value: "Cash", label: "Cash" },
              { value: "Card", label: "Card" },
              { value: "Transfer", label: "Transfer" },
              { value: "Check", label: "Cheque" },
            ]}
            handleChange={(e) => setPaymentType(e.value)}
          />
        </Col>
        <Col md="4" sm="4" lg="3" xl="2" className="position-relative mb-md-0 mb-2 d-lg-block d-md-block d-sm-block d-none" id="daily-transactional-report-date-filter">
          <DatePicker className={style.datpicker} selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="MMM dd, yyyy" />
          <div
            style={{
              position: "absolute",
              right: "25px",
              top: "10px",
            }}
            className=" d-flex align-items-center"
          >
            <img src={dateIcon} height="18"></img>
          </div>
        </Col>
        <Col className="mb-2 mb-md-0 d-lg-block d-md-block d-sm-block d-none" md="4" sm="4" lg="3" xl="2" id="daily-transactional-report-location-filter">
          <SearchableSelect placeholder="Select Location" searchable={false} options={location} handleChange={handleLocation} value={Object.keys(locationFields).length > 0 ? locationFields : null} style={{ width: "100px" }} disabled={!location.length} />
        </Col>

        <Col xs lg="3" xl="3" className="d-lg-grid d-md-none d-lg-none d-xl-inline  d-none"></Col>
        <Col xs md="2" lg="2" xl="2" className="d-lg-grid ms-2 mt-2 bg-light " id="daily-transactional-report-grand-total">
          <span className={`${style.boldTxt} fs-20`}>Rs {grandTotal}</span>
          <br></br>
          <span>Grand Total</span>
        </Col>
      </Row>
      <Row className=" w-100 bg-white p-2 mb-3 overflow-auto text-nowrap ob ">
        <Col xl="11" lg="11" md="12">
          <Button
            id="daily-transactional-report-sale-payments"
            onClick={() => {
              settableData(salesPaymentData);
              handleTabChange("Sale-payment");
            }}
            className={`${styles.btn} ${activeTAb === "Sale-payment" ? styles.activebtn : ""}  mb-2 mx-2 rounded-5 `}
            variant="outline-primary"
          >
            Sale Payments
          </Button>
          <Button
            id="daily-transactional-report-supplier-payments"
            // disabled={true}
            onClick={() => {
              settableData(vendorPaymentsData);
              handleTabChange("Vendor-Payments");
            }}
            className={`${styles.btns} ${activeTAb === "Vendor-Payments" ? styles.activebtn : ""}  mb-2 mx-2 rounded-5`}
            variant="outline-primary"
          >
            Supplier Payments
          </Button>
          <Button
            id="daily-transactional-report-expenses"
            onClick={() => {
              settableData(expensesData);
              handleTabChange("Expense");
            }}
            className={`${styles.btns} ${activeTAb === "Expense" ? styles.activebtn : ""}  mb-2 mx-2 rounded-5`}
            variant="outline-primary"
          >
            Expense
          </Button>
          <Button
            id="daily-transactional-report-refunded-payments"
            onClick={() => {
              settableData(refundsData);
              handleTabChange("Refunded-Payments");
            }}
            className={`${styles.btns} ${activeTAb === "Refunded-Payments" ? styles.activebtn : ""} mb-2 mx-2 rounded-5`}
            variant="outline-primary"
          >
            Refunded Payments
          </Button>
        </Col>
      </Row>
      {isLoading ? (
        <div style={{ height: "50vh" }} className="">
          <LoaderPage />
        </div>
      ) : tableData.length > 0 ? (
        <>
          <div className="bg-white p-3 w-100 fs-20 fw-bold">{activeTAb.replace("-", " ")}</div>
          <div className={style.brdrd}>
            <CustomizeTableview
              className={"mt-1"}
              data={tableData}
              actions={false}
              dateColumn={["customer_payments_date"]}
              sortColumn={["customer_name", "received_amount", "base_grand_total", "total_amount", "cash_received", "Time"]}
              orderBy={orderBySales}
              setOrderBy={setOrderBySales}
              isToggled={isToggledSales}
            />
          </div>
        </>
      ) : (
        <Row className={`${style.noDataAccordian} fw-bold fs-16 d-flex justify-content-center py-3`} style={{ marginBottom: "2rem" }}>
          no data found
        </Row>
      )}
      <MobileSubReportsTabs tabs={["Sale-payment", "Vendor-Payments", "Expense", "Refunded-Payments"]} activeTAb={activeTAb} handleTabChange={handleTabChange} show={showMobileTabs} handleClose={handleClose} />
      <MobilrReportsFilter activeTab={MainactiveTab} setPaymentType={setPaymentType} handleLocation={handleLocation} locationFields={locationFields} location={location} show={show} handleClose={close} startDate={startDate} setStartDate={setStartDate} />
    </>
  );
};

export default DailyTransactionalReport;
