import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  printerType: localStorage.getItem("printerType")
    ? JSON.parse(localStorage.getItem("printerType"))
    : {},
  isThermalLogo: localStorage.getItem("isThermalLogo")
    ? JSON.parse(localStorage.getItem("isThermalLogo"))
    : {},
};

const printSlice = createSlice({
  name: "printerType",
  initialState,
  reducers: {
    setPrinterType(state, action) {
      state.printerType = action.payload;
      localStorage.setItem("printerType", JSON.stringify(state.printerType));
    },
    setIsThermalLogo(state, action) {
      state.isThermalLogo = action.payload;
      localStorage.setItem(
        "isThermalLogo",
        JSON.stringify(state.isThermalLogo),
      );
    },
  },
});

export const { setPrinterType, setIsThermalLogo } = printSlice.actions;

export default printSlice.reducer;
