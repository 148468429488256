import { Button, Col, Form, Row } from "react-bootstrap";
import style from "../styles/AddLocation.module.css";
import { useState, useEffect, useRef } from "react";
import { InputGroup } from "react-bootstrap";
import useMarkedFields from "../Hooks/validationHook";
import { default as ReactSelect } from "react-select";
import axios from "axios";
import PhoneInput from "../Components/PhoneInput/PhoneInput";
import Map from "../Components/Map";

const EditLocation = ({ locationId, shop_id, close, setError, setSuccess }) => {
  const [LocationNameEdit, setLocationNameEdit] = useState({});
  const [ProvinceNameEdit, setProvinceNameEdit] = useState();
  const [CityNameEdit, setCityNameEdit] = useState({});
  const [AddressEdit, setAddressEdit] = useState({});
  const [NumberEdit, setNumberEdit] = useState({});
  const [AlternativeNumberEdit, setAlternativeNumberEdit] = useState({});
  const [daysField, setDaysField] = useState([]);
  const [bindField, setAllFieldsTouched] = useMarkedFields();
  const [cityName, setCityName] = useState([]);
  const [provinceName, setProvinceName] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const [isNumberChecked, setIsNumberChecked] = useState(false);
  const [isAlternativeNumberChecked, setIsAlternativeNumberChecked] = useState(false);

  const [coords, setCoords] = useState({ lat: 0, lng: 0 });
  const [isLocationSelected, setIsLocationSelected] = useState(false);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#5932EA" : "#80858C",
      background: state.isSelected ? "#F5F6FA" : "#fff",
      padding: 10,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: "100%",
      maxWidth: "100%",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
  };

  const daysOptions = [
    { value: "mon", label: "Mon" },
    { value: "tue", label: "Tue" },
    { value: "wed", label: "Wed" },
    { value: "thur", label: "Thu" },
    { value: "fri", label: "Fri" },
    { value: "sat", label: "Sat" },
    { value: "sun", label: "Sun" },
  ];

  useEffect(() => {
    const getLocation = async (locationId) => {
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL + `/location/edit-location/${locationId}`, { withCredentials: true });
        const data = res.data[0];
        // const locationName = data.map((loc) => ({ value: loc.location_nick }))
        setLocationNameEdit(res.data[0].location_nick);
        setProvinceNameEdit(res.data[0].province_id);
        setCityNameEdit(res.data[0].city_id);
        // setProvinceNameEdit({ value: res.data[0].province_id, label: res.data[0].province_name })
        // setCityNameEdit({ value: res.data[0].city_id, label: res.data[0].city_name })
        setAddressEdit(res.data[0].address);
        const days = JSON.parse(res.data[0].shop_working_days);
        const shopDays = days.map((day) => day);
        setDaysField(shopDays.map((day) => ({ value: day, label: day })));
        setNumberEdit(res.data[0].location_number);
        setAlternativeNumberEdit(res.data[0].location_backup_number);

        setIsNumberChecked(res.data[0].is_contact_no_on_invoice);
        setIsAlternativeNumberChecked(res.data[0].is_alternative_no_on_invoice);
        if (res.data[0].latitude !== 0 && res.data[0].longitude !== 0) {
          setCoords({ lat: +res.data[0].latitude, lng: +res.data[0].longitude });
          setIsLocationSelected(true);
        }
      } catch (error) {
        throw new Error(error.response.data);
      }
    };
    getLocation(locationId);
  }, [locationId]);

  const handleEdit = async (e) => {
    try {
      e.preventDefault();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
      }
      setValidated(true);
      if (LocationNameEdit.length > 0 && ProvinceNameEdit && CityNameEdit && AddressEdit.length > 0 && daysField.length > 0 && isLocationSelected && NumberEdit) {
        setIsClicked(true);
        const res = await axios.post(
          process.env.REACT_APP_API_URL + `/location/update-location/${locationId}`,
          {
            location_nick: LocationNameEdit,
            province_id: ProvinceNameEdit,
            city_id: CityNameEdit,
            address: AddressEdit,
            latitude: coords?.lat,
            longitude: coords?.lng,
            shop_working_days: JSON.stringify(daysField.map((obj) => obj.value)),
            location_number: NumberEdit,
            location_backup_number: AlternativeNumberEdit,
            shop_id: shop_id,
            is_contact_no_on_invoice: NumberEdit.length > 0 && isNumberChecked ? true : false,
            is_alternative_no_on_invoice: AlternativeNumberEdit.length > 0 && isAlternativeNumberChecked ? true : false,
          },
          { withCredentials: true }
        );
        if (res) {
          close();
        }
      }
    } catch (error) {
      setIsClicked(true);
      setError(error.response.data.error.message);
      setIsClicked(false);
    }
  };

  useEffect(() => {
    const getcity = async (id) => {
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL + `/location/get-city/${id}`, { withCredentials: true });
        if (res) {
          setCityName(res.data.map((i) => ({ value: i.city_id, label: i.city_name })));
          setCityNameEdit(res.data.map((i) => i.city_id).includes(CityNameEdit) ? CityNameEdit : res.data[0].city_id);
          // setCityNameEdit(res.data[0].city_id);
        }
      } catch (error) {
        throw new Error(error.response.data);
      }
    };
    if (ProvinceNameEdit) {
      getcity(ProvinceNameEdit);
    }
  }, [ProvinceNameEdit]);
  // useEffect(() => {
  //     const getProvince = async () => {

  //         const res = await axios.post(process.env.REACT_APP_API_URL +`/location/get-province-location/${shop_id}`,{},{withCredentials:true})
  //         const data = res.data
  //         const getProvinceName = res.data.map((loc) => ({ value: loc.province_id, label: loc.province_name }));
  //         setProvinceName(getProvinceName)
  //     }
  //     getProvince();

  // }, [])
  useEffect(() => {
    const getprovince = async () => {
      try {
        const req = await axios.get(process.env.REACT_APP_API_URL + `/location/get-province`, { withCredentials: true });
        const data = req.data;
        setProvinceName(data.map((i) => ({ value: i.province_id, label: i.province_name })));
      } catch (error) {
        throw new Error(error.response.data);
      }
    };
    getprovince();
  }, []);

  return (
    <Form onSubmit={handleEdit} noValidate validated={validated}>
      <Row>
        <Col className="py-2" md={6}>
          <Form.Group className="mb-3" controlId="price">
            <Form.Label className="mb-0">
              Name of Location<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control type="text" placeholder="Write address" value={LocationNameEdit} onChange={(e) => setLocationNameEdit(e.target.value)} pattern="^([A-Za-z ]|[0-9])+$" name="location" {...bindField("location")} required />

            <span className="invalid-text" type="invalid">
              * Please Select Location
            </span>
          </Form.Group>
        </Col>

        <Col className="py-2" md={6}>
          <label>
            Province <span className="text-danger">*</span>
          </label>
          {/* <SearchableSelect label={'Location Province'} placeholder='Select Province' important searchable={false} value={ProvinceNameEdit} options={provinceName} handleChange={(e) => setProvinceNameEdit(e)} /> */}
          <Form.Select onChange={(e) => setProvinceNameEdit(e.target.value)} value={ProvinceNameEdit}>
            <option value="" disabled selected>
              Select Province
            </option>
            {provinceName.map((data) => (
              <option value={data.value}>{data.label}</option>
            ))}
          </Form.Select>
        </Col>
        <Col className="py-2" md={6}>
          <label>
            City <span className="text-danger">*</span>
          </label>
          {/* <SearchableSelect label={'City'} placeholder='Select City' searchable={false} important value={CityNameEdit} options={cityName} handleChange={(e) => setCityNameEdit(e)} /> */}
          <Form.Select onChange={(e) => setCityNameEdit(e.target.value)} value={CityNameEdit}>
            <option value="" disabled selected>
              Select City
            </option>
            {cityName.map((data) => (
              <option value={data.value}>{data.label}</option>
            ))}
          </Form.Select>
        </Col>
        <Col className="py-2" md={6}>
          <Form.Group className="mb-3" controlId="price">
            <Form.Label className="mb-0">
              Street Address<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control type="text" placeholder="Write Street Address" value={AddressEdit} onChange={(e) => setAddressEdit(e.target.value)} name="Address" {...bindField("Address")} required />

            <span className="invalid-text" type="invalid">
              * Please Select Address
            </span>
          </Form.Group>
        </Col>

        <Col className="py-2" md={6}>
          <Form.Group controlId="formBasicTiming">
            <label className="mb-0">
              Shop Opening Days <span className="text-danger">*</span>
            </label>
            <div className={`${style.flexAlign}`}>
              <InputGroup hasValidation>
                <ReactSelect classNamePrefix="react-select-container" options={daysOptions} isMulti closeMenuOnSelect={false} onChange={(e) => setDaysField(e)} allowSelectAll={true} value={daysField} styles={customStyles} name="shop_working_days" />
              </InputGroup>
            </div>
            <Form.Control value={daysField} hidden name="shopTimings" required />

            <Form.Control.Feedback type="invalid">Select Shop Opening Days </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col className="py-2" md={6}>
          <Form.Group className="mb-3" controlId="price">
            <Form.Label className="mb-0">
              Contact number<span style={{ color: "red" }}>*</span>
            </Form.Label>
            {/* <Form.Control type="number" placeholder="xxx xxxxxxxxxx" value={NumberEdit} onChange={(e) => setNumberEdit(e.target.value)} name='number' {...bindField("number")} required /> */}
            <PhoneInput
              placeholder="+92-3XX-XXXXXXX"
              value={NumberEdit}
              // onChange={(e) => setNumberEdit(e.target.value)}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  setIsNumberChecked(false);
                  setNumberEdit(e.target.value);
                } else {
                  setNumberEdit(e.target.value);
                }
              }}
              required
            />
            <Form.Control.Feedback type="invalid">Please Enter Contact Number </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col className="py-2" md={6}>
          <Form.Group className="mb-3" controlId="price">
            <Form.Label className="mb-0">Alternative number</Form.Label>
            {/* <Form.Control type="number" placeholder="xxx xxxxxxxxxx" value={AlternativeNumberEdit} onChange={(e) => setAlternativeNumberEdit(e.target.value)} /> */}
            <PhoneInput
              placeholder="+92-3XX-XXXXXXX"
              value={AlternativeNumberEdit}
              // onChange={(e) => setAlternativeNumberEdit(e.target.value)}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  setIsAlternativeNumberChecked(false);
                  setAlternativeNumberEdit(e.target.value);
                } else {
                  setAlternativeNumberEdit(e.target.value);
                }
              }}
            />
          </Form.Group>
        </Col>
        <Col className="py-2" md={6}>
          <Form.Group className="pb-3">
            <Form.Check value="" label="Show Contact Number on Invoice" id="isNumber" disabled={!(NumberEdit.length > 0)} checked={isNumberChecked} onChange={() => setIsNumberChecked((prev) => !prev)} />
          </Form.Group>
          <Form.Group className="pb-3">
            <Form.Check label="Show Alternate Number on Invoice" value="" id="isAlternative" disabled={!(AlternativeNumberEdit.length > 0)} checked={isAlternativeNumberChecked} onChange={() => setIsAlternativeNumberChecked((prev) => !prev)} />
          </Form.Group>
        </Col>
        <Map coords={coords} setCoords={setCoords} isLocationSelected={isLocationSelected} setIsLocationSelected={setIsLocationSelected} />
        <Button disabled={isClicked} type="submit" className="w-100" onClick={() => setAllFieldsTouched()}>
          Save
        </Button>
      </Row>
    </Form>
  );
};

export default EditLocation;
