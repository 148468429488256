import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import SearchableSelect from "../SearchableSelect";

function Paynow({ yearlyTotal, monthlyTotal, quarterlyTotal, paymentObj, setPaymentObj, setPayable, payable }) {
  const [file, setFile] = useState(null);
  const handleChange = (e) => {
    setPaymentObj({ ...paymentObj, [e.target.name]: e.target.value });
  };
  const handleImageUpload = (e) => {
    setPaymentObj({ ...paymentObj, [e.target.name]: e.target.files[0] });
  };

  useEffect(() => {}, [payable]);

  return (
    <div>
      <Row>
        <Form.Group className="mt-3" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
          <label>
            Transaction Id <span className="text-danger">*</span>
          </label>
          <Form.Control
            value={paymentObj.transactionId}
            name="transactionId"
            onChange={(e) => {
              handleChange(e);
            }}
            placeholder="Enter transaction Id"
            required
          />
          <Form.Control.Feedback type="invalid">Please Enter Id.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mt-3" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
          <label>
            Transaction Date<span className="text-danger">*</span>
          </label>
          <Form.Control
            name="transDate"
            value={paymentObj.transDate}
            onChange={(e) => {
              handleChange(e);
            }}
            max={new Date().toISOString().split("T")[0]}
            required
            type="date"
          />

          <Form.Control.Feedback type="invalid">Please select Date.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mt-3" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
          <label>
            Upload receipt <span className="text-danger">*</span>
          </label>
          <Form.Control required={paymentObj?.imageURl ? false : true} type="file" size="sm" accept="image/*" name="imageURl" onChange={(e) => handleImageUpload(e)} />
          {!paymentObj?.imageURl && <Form.Control.Feedback type="invalid">Please upload image.</Form.Control.Feedback>}
        </Form.Group>
        <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="price">
          <label>
            Payable <span className="text-danger">*</span>
          </label>
          <Form.Control value={Math.round(payable)} type="number" placeholder="Rs.1000" disabled />
          <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
        </Form.Group>
        <Col lg="12" className="text-center mt-3">
          {paymentObj?.imageURl && (
            <div style={{ maxWidth: "100%", maxHeight: "400px" }} className=" ">
              {" "}
              <img src={URL.createObjectURL(paymentObj?.imageURl)} height="350" width="250" alt="reciept" />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default Paynow;
