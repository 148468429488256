import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import styles from "../../styles/Setting.module.css";
import SearchableSelect from "../../Components/SearchableSelect";
import { useSelector, useDispatch } from "react-redux";
import { setPrinterType, setIsThermalLogo } from "./../../Features/printSettings/printSettings";

const PrinterSettings = ({ setModalShow, setSuccess }) => {
  const dispatch = useDispatch();
  const { printerType, isThermalLogo } = useSelector((state) => state.printerType);
  const [printer, setPrinter] = useState(Object.values(printerType)?.length > 0 ? printerType : { value: "laser/inkjet", label: "Laser/Inkjet Printer" });
  const [isLogoOnThermalPrint, setIsLogoOnThermalPrint] = useState(Object.values(isThermalLogo)?.length > 0 ? isThermalLogo : { value: false, label: "No" });
  const [saved, setSaved] = useState(false);
  const [logoOnThermalSaved, setLogoOnThermalSaved] = useState(false);
  const handlePrintSave = (e) => {
    setSuccess("");
    dispatch(setPrinterType(printer));
    setSaved(true);
    setSuccess("Printer setting saved successfully.");
    setModalShow(true);
    setTimeout(() => setSaved(false), 1500);
    setTimeout(() => {
      setModalShow(false);
    }, 1000);
  };

  const handleLogoOnThermalPrintSave = (e) => {
    setSuccess("");
    dispatch(setIsThermalLogo(isLogoOnThermalPrint));
    setLogoOnThermalSaved(true);
    setSuccess("Thermal Printer Logo setting saved successfully.");
    setModalShow(true);
    setTimeout(() => setLogoOnThermalSaved(false), 1500);
    setTimeout(() => {
      setModalShow(false);
    }, 1000);
  };
  return (
    <>
      <Row className=" mt-3 gap-3" id="system-settings-printer-settings">
        <Col lg={5} className={`${styles.setcol}  ${styles.row}  p-3`}>
          <strong className="fw-bold"> Printer Settings</strong>
          <div className="mt-2 d-flex flex-column justify-content-between">
            <div>
              <SearchableSelect
                searchable={false}
                options={[
                  {
                    value: "laser/inkjet",
                    label: "Laser/Inkjet Printer",
                  },
                  { value: "thermal", label: "Thermal Printer" },
                ]}
                placeholder={"Select Printer"}
                handleChange={(e) => {
                  setPrinter(e);
                }}
                value={printer}
              />
            </div>
            <div style={{ marginTop: "170px" }} className="text-end">
              <Button className="w-25" disabled={saved} onClick={(e) => handlePrintSave(e)}>
                {saved === true ? "Saving..." : "Save"}
              </Button>
            </div>
          </div>
        </Col>
        {printerType?.value === "thermal" ? (
          <Col lg={5} className={`${styles.setcol}  ${styles.row}  p-3 `}>
            <strong className="fw-bold"> Do you want to add shop logo in Thermal Printer?</strong>
            <div className="mt-2 d-flex flex-column justify-content-between">
              <div>
                <SearchableSelect
                  searchable={false}
                  options={[
                    {
                      value: true,
                      label: "Yes",
                    },
                    { value: false, label: "No" },
                  ]}
                  placeholder={"Select your preference"}
                  handleChange={(e) => {
                    setIsLogoOnThermalPrint(e);
                  }}
                  value={isLogoOnThermalPrint}
                />
              </div>
              <div style={{ marginTop: "170px" }} className="text-end">
                <Button className="w-25" disabled={logoOnThermalSaved} onClick={(e) => handleLogoOnThermalPrintSave(e)}>
                  {logoOnThermalSaved === true ? "Saving..." : "Save"}
                </Button>
              </div>
            </div>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </>
  );
};

export default PrinterSettings;
