export const reportsNameMapping = {
  undefined: "General Report",
  "daily-cash": "Daily Cash Report",
  "daily-transactional": "Daily Transactional Report",
  "profit-loss": "Profit/Loss Report",
  inventory: "Inventory Report",
  sales: "Sales Report",
  "sales-summary": "Sales Summary",
  journal: "Journal",
  imei: "IMEI Report",
  "bank-transactions": "Bank Transactions Report",
  "misc-items-history": "Misc Items History Report",
};
