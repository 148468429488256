import React, { useEffect } from "react";
// import logo from './../assets/images/logo.png'
import { Button } from "react-bootstrap";
import axios from "axios";
import style1 from "./../styles/InvoiceCard.module.css";
import style from "./../styles/Invoice.module.css";
import { Col, Row, Table, Card } from "react-bootstrap";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useRef, useCallback } from "react";
import { useReactToPrint } from "react-to-print";
import Loader from "../Components/Loader";
import PopUpModal from "./../Components/Modal";
import styleModal from "./../styles/Modal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import "./../styles/printLayout.css";
import UpdateReceivePaymentThermalInvoice from "./SalesHistory/InvoicePaymentSales/UpdateReceivePaymentThermalInvoice";

const InvoiceView = ({ refs }) => {
  const componentRef = useRef(null);
  const thermalcomponentRef = useRef(null);
  const loc = useLocation();
  // const data = loc.state.data.items[0];
  const [data, setData] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const { printerType } = useSelector((state) => state.printerType);
  const shopId = user.shop_id;
  const logo = user.logo_path;
  const [items, setItems] = useState(null);
  const [createdAtDate, setCreatedAt] = useState();
  const [remainingAmount, setRemainingAmount] = useState();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [searcParams] = useSearchParams();
  // const countReceivedAmount = searcParams.get("asdd");
  const [receivedAmount, setReceivedAmount] = useState();
  const [totalDiscount, setTotalDiscount] = useState();

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + `/sales/partial-payment-invoice/${id}`, { shopId: shopId }, { withCredentials: true })
      .then((res) => {
        let arr = [];
        const products = res.data.items.map((i) => ({ title: i.title, cartQuantity: i.qty_ordered, price: i.sale_price, discount: i.discount }));
        const miscProducts = res.data.miscItems.map((i) => ({ title: i.title, cartQuantity: i.qty_ordered, price: i.sale_price, description: i.description, discount: i.discount }));
        arr.push(...products);
        arr.push(...miscProducts);
        setItems(arr);
        setData(res.data.data[0]);
        setReceivedAmount(res.data.totalReceivedAmout[0].total_received_amount);
        setCreatedAt(new Date(res.data.data[0]?.invoice_date).toLocaleDateString());
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [shopId, id]);

  useEffect(() => {
    const amount = Number(data?.base_grand_total) - Number(receivedAmount);
    setRemainingAmount(amount);
  }, [data, loc]);

  useEffect(() => {
    const sum = items?.reduce((accumulator, object) => {
      return Number(accumulator) + Number(object.discount);
    }, 0);
    setTotalDiscount(sum);
  }, [items]);

  const handleEmail = async () => {
    try {
      setLoading(true);
      const res = await axios.post(process.env.REACT_APP_API_URL + "/sales/send-receive-payment-email", { data: data, items: items, remaining: remainingAmount, logo: logo }, { withCredentials: true });
      if (res.data.status === 404) {
        setSuccess(res.data.message);
        setModalShow(true);
        setTimeout(() => setModalShow(false), 2500);
        setLoading(false);
      } else if (res.data.status === 200) {
        setSuccess(res.data.success.message);
        setModalShow(true);
        setTimeout(() => setModalShow(false), 2500);
        setLoading(false);
      }
    } catch (error) {
      setLoading(true);
      setError(error?.response?.data?.error?.message);
      setLoading(false);
    }
  };

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
  });
  const reactToPrintThermalContent = useCallback(() => {
    return thermalcomponentRef.current;
  }, []);

  const handleThermalPrint = useReactToPrint({
    content: reactToPrintThermalContent,
  });

  return (
    <>
      {
        <PopUpModal
          title={success ? "Email Sent" : "Email Not sent."}
          icon={<FontAwesomeIcon icon={faCircleCheck} />}
          text={success ? success : error}
          type={success ? styleModal.successBg : styleModal.errorBg}
          iconModal={success ? styleModal.successIcon : styleModal.errorIcon}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      }
      {data !== undefined ? (
        <>
          <div className={`my-4 d-flex justify-content-center gap-3`}>
            {printerType?.value === "thermal" ? (
              <Button variant="outline-primary" className="px-4" onClick={handleThermalPrint}>
                Print{" "}
              </Button>
            ) : (
              <Button variant="outline-primary" className="px-4" onClick={handlePrint}>
                Print{" "}
              </Button>
            )}
            {data?.customer_email && data?.customer_email !== "nil" && data?.customer_email !== "" ? (
              <Button type="submit" onClick={() => handleEmail()} disabled={loading}>
                {loading ? <Loader /> : "Send Email"}
              </Button>
            ) : (
              <></>
            )}
          </div>
          {printerType?.value !== "thermal" ? (
            <div className="printComponent">
              <Card className={`border-0 ${style1.invoiceCard} ${style1.formCard} mb-5`}>
                <div className="p-5 laser-invoice" ref={componentRef}>
                  <div className={`d-flex ${logo !== null ? "justify-content-between" : "justify-content-end"} ${style.logoadd}`}>
                    {logo !== null && <img src={process.env.REACT_APP_URL_S3 + "/" + logo} alt="" className={`${style1.logoimg} ${style.logo}`} />}
                    <div className={`d-flex flex-column text-end mb-4 ${style.address}`}>
                      <h5 style={{ color: "#39414A", marginBottom: "0rem" }}>Address</h5>
                      <span style={{ color: "#39414A", marginBottom: "0rem" }} className={`${style.addresstxt}`}>
                        {data?.shop_address}
                      </span>
                      <span style={{ color: "#39414A", marginBottom: "0rem" }} className={`${style.addresstxt}`}>
                        Phone:{" "}
                        {data?.is_contact_no_on_invoice === 1 && data?.is_alternative_no_on_invoice === 1 ? (
                          <div>
                            {data?.contact_number}
                            <br />
                            {data?.alternative_number}
                          </div>
                        ) : data?.is_contact_no_on_invoice === 1 ? (
                          data?.contact_number
                        ) : data?.is_alternative_no_on_invoice === 1 ? (
                          data?.alternative_number
                        ) : (
                          data?.owner_whatsapp_number
                        )}
                        {/* Phone: {data?.owner_whatsapp_number} */}
                      </span>
                      <span style={{ color: "#39414A" }} className={`${style.addresstxt}`}>
                        {data?.owner_email}
                      </span>
                      <span style={{ color: "#39414A" }} className={`${style.addresstxt} text-capitalize`}>
                        {user?.user_full_name}
                      </span>
                    </div>
                  </div>
                  <h1 className={`mb-3 ${style.invoice}`}>Invoice</h1>
                  <Row>
                    <Col xs sm="5" md="5" lg="5" className="px-0 ps-3">
                      <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                        Customer details
                      </p>
                      {data?.customer_name === "nil" ? (
                        <span className="text-capitalize" style={{ color: "#39414A", marginBottom: "0rem", fontSize: "18px", fontWeight: "700" }}>
                          Walk-in Customer
                        </span>
                      ) : (
                        <>
                          <span className="text-capitalize" style={{ color: "#39414A", marginBottom: "0rem", fontSize: "18px", fontWeight: "700" }}>
                            {data?.customer_name}
                          </span>
                          <div className={`mb-2 ${style.phn} px-0`}>
                            <p className={`${style.emailphone}`}>
                              <strong>Phone:</strong> {data?.customer_number}
                            </p>
                            {data?.customer_email && data?.customer_email !== "nil" && data?.customer_email !== "" ? (
                              <p className={`${style.emailphone}`}>
                                <strong>Email:</strong> {data?.customer_email}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      )}
                    </Col>
                    <Col xs sm="4" md="4" lg="4" className={`${style.invoicenum}`}>
                      <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                        Invoice number
                      </p>
                      <h5 className={`${style.invoicetxt} fw-2`}>{data?.invoice_number}</h5>
                    </Col>
                    <Col xs sm="3" md="3" lg="3" className={`${style.invoicenum}`}>
                      <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                        Issue date
                      </p>
                      <h5 className={`${style.invoicetxt}`}>{createdAtDate}</h5>
                    </Col>
                  </Row>
                  <Table style={{ border: "1px solid #DEDFE4", tableLayout: "fixed" }} className={`mb-4 mt-3 ${style.invoiceTable}`}>
                    <tbody>
                      <tr style={{ background: "#F5F6FA" }}>
                        <th className={`${style.tabledescription}`}>Products</th>
                        <th className={`${style.tabletxt}`}>Unit price</th>
                        <th className={`${style.tabletxt}`}>QTY</th>
                        <th className={`${style.tabletxt}`}>Discount</th>
                        <th className={`${style.tabletxt}`}>Total</th>
                      </tr>
                      {items?.map((data, index) => (
                        <tr key={index}>
                          <td className={`${style.tabledescription} d-flex flex-column`}>
                            {data?.title}
                            <span style={{ color: "#686E76", fontSize: "13px" }}>{data?.description}</span>
                          </td>
                          <td className={`${style.tabletxt}`}>{Number(data?.price).toLocaleString("en-IN")}</td>
                          <td className={`${style.tabletxt}`}>{data?.cartQuantity}</td>
                          <td className={`${style.tabletxt}`}>{data?.discount}</td>
                          <td className={`${style.tabletxt}`}>{Number(Number(data?.cartQuantity * data?.price) - Number(data?.discount)).toLocaleString("en-IN")}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Row>
                    <Col xs="2" md="6" lg="6"></Col>
                    <Col xs="5" md="3" lg="3" style={{ marginBottom: "0.2rem" }}>
                      <span className={`${style.tabletxt}`}> Subtotal </span>
                    </Col>
                    <Col xs="5" md="3" lg="3" className="text-end">
                      <span className={`${style.tabletxt}`}> {Number(data?.base_subtotal).toLocaleString("en-IN")} </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="2" md="6" lg="6"></Col>
                    <Col xs="5" md="3" lg="3" style={{ marginBottom: "0.2rem" }}>
                      <span className={`${style.tabletxt}`}> Shipping </span>
                    </Col>
                    <Col xs="5" md="3" lg="3" className="text-end">
                      <span className={`  ${style.tabletxt}`}> {Number(data?.base_shipping_amount).toLocaleString("en-IN")} </span>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs="2" md="6" lg="6"></Col>
                    <Col xs="5" md="3" lg="3" style={{ marginBottom: "0.2rem" }}>
                      <span className={`${style.tabletxt}`}> Total </span>
                    </Col>
                    <Col xs="5" md="3" lg="3" className="text-end">
                      <span className={`${style.tabletxt}`}> {Number(data?.base_grand_total).toLocaleString("en-IN")} </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="2" md="6" lg="6"></Col>
                    <Col xs="5" md="3" lg="3" style={{ marginBottom: "0.2rem" }}>
                      <span className={`${style.tabletxt}`}>Total Received </span>
                    </Col>
                    <Col xs="5" md="3" lg="3" className="text-end">
                      <span className={` text-success fw-bold`}> {Number(Number(data?.base_grand_total) - Number(remainingAmount)).toLocaleString("en-IN")} </span>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs="2" md="6" lg="6"></Col>
                    <Col xs="5" md="3" lg="3" style={{ marginBottom: "0.2rem" }}>
                      <span className={`${style.tabletxt}`}>Amount Received </span>
                    </Col>
                    <Col xs="5" md="3" lg="3" className="text-end">
                      <span className={` text-success fw-bold`}>{Number(data?.customer_payments_amount_received).toLocaleString("en-IN")} </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="2" md="6" lg="6"></Col>
                    <Col xs="5" md="3" lg="3" style={{ marginBottom: "0.2rem" }}>
                      <span className={`${style.tabletxt}`}>Total Discount </span>
                    </Col>
                    <Col xs="5" md="3" lg="3" className="text-end">
                      <span className={` ${style.tabletxt}`}> {Number(totalDiscount).toLocaleString("en-IN")} </span>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs="2" md="6" lg="6"></Col>
                    <Col xs="5" md="3" lg="3">
                      <span className={`${style.tabletxt}`}> Total Due</span>
                    </Col>
                    <Col xs="5" md="3" lg="3" className="text-end">
                      <span className={` ${style.tabletxt}`}> {Number(remainingAmount).toLocaleString("en-IN")} </span>
                    </Col>
                  </Row>
                  <Row style={{ display: "contents" }}>
                    <div className="my-3" style={{ borderTop: "1px solid #DEDFE4" }}></div>
                  </Row>
                  <p className={`${style.invoiceterms} mb-1`}>Invoice terms</p>

                  {remainingAmount === 0 ? (
                    <>
                      <p className="mb-0" style={{ color: "#39414A", textAlign: "center" }}>
                        The Buyer has <b className="text-success fs-5">PAID</b> the amount.
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="mb-0" style={{ color: "#39414A", textAlign: "center" }}>
                        The Buyer still needs to pay <b className="text-danger fs-5">{Number(remainingAmount).toLocaleString("en-IN")}</b> to clear the invoice.
                      </p>
                    </>
                  )}
                  {data?.invoice_terms !== "nil" && <p className={`my-0 text-center terms ${style.terms}`}>* {data?.invoice_terms}</p>}
                  <p className={`${style.invoiceterms} mt-2 mb-0`}>Comments</p>
                  <p className="text-capitalize text-center mb-0">{data?.customer_payments_comment === "" ? "-" : data?.customer_payments_comment}</p>
                </div>
              </Card>
            </div>
          ) : (
            <UpdateReceivePaymentThermalInvoice ref={thermalcomponentRef} data={data} items={items} remainingAmount={remainingAmount} totalDiscount={totalDiscount} />
          )}
        </>
      ) : (
        <div style={{ height: "80vh" }} className="d-flex justify-content-center align-items-center">
          <h3>No data exists for this invoice</h3>
        </div>
      )}
    </>
  );
};
export default InvoiceView;
