import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";
import { addToCart, emptyCart } from "../Features/cart/cartSlice";

const SalesTour = ({ setIsCartOpen }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const salesTour = driver({
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      setIsCartOpen(false);
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Sales", value: true } }));
      dispatch(emptyCart());
      setTimeout(() => {
        salesTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Sales",
        popover: {
          title: "Sales",
          description: "Shows and manages all sales transactions.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#sales",
        popover: {
          title: `A guide to sales`,
          description: "On this page all in-stock product’s cards are shown with their picture,price and stock quantity on it.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-product-view",
        popover: {
          title: `Product view`,
          description: "From here seller can select the layout for his sales product page.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-product-search",
        popover: {
          title: `Search Bar`,
          description: "Here seller can search product by product name, stock # or IMEI.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-category-options",
        popover: {
          title: `Category`,
          description: "On this page all in-stock product’s cards are shown with their picture,price and stock quantity on it.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-products-status",
        popover: {
          title: `Status`,
          description: "From this filter seller can select if he wants to see available products or out of stock products.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-products-grid",
        popover: {
          title: `Products`,
          description: "Shows all products of a specific shop.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setIsCartOpen(true);
            dispatch(
              addToCart({
                id: 0,
                title: "test 001",
                quantity: 1,
                sale_price: 3000,
                is_on_marketplace: 0,
                created_at: "1970-01-01T15:24:05.000Z",
                updated_at: null,
                label: "Mobile",
                img0: "images/dummyImages/mobiles.jpg",
                reserved_quantity: 0,
              })
            );
            setTimeout(() => {
              salesTour.moveNext();
            });
          },
        },
      },
      {
        element: "#sales-cart",
        popover: {
          title: `Cart`,
          description: "A sidebar is also shown  where all added/selected products will be shown.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setIsCartOpen(false);
            salesTour.movePrevious();
          },
        },
      },
      {
        element: "#sales-empty-cart-button",
        popover: {
          title: `Empty Cart`,
          description: "By clicking on it seller will be able to remove all products from the cart.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#sales-cart-search",
        popover: {
          title: `Search Box`,
          description: "From here seller can search and add any product into cart to sell.",
          side: "right",
          align: "start",
        },
      },

      {
        element: "#sales-cart-misc-items",
        popover: {
          title: `Add Misc Items`,
          description: "Through this button seller can add misc items in cart.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-cart-items",
        popover: {
          title: `Products`,
          description: "It shows all added products in cart.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-walk-in-customer",
        popover: {
          title: `Walk-in customer`,
          description: "By checking this, Seller can sell product to a customer who is not registered in the system.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#sales-cart-customer",
        popover: {
          title: `Customer`,
          description: "By adding number of already registered customer, seller can sell product to them.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#sales-cart-shipping",
        popover: {
          title: `Shipping Cost`,
          description: "Here seller can add the shipping cost.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#sales-cart-discount",
        popover: {
          title: `Discount`,
          description: "Seller can add discount in amount or in percentage.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#sales-cart-payment-method",
        popover: {
          title: `Payment methods`,
          description: "Seller will be able to select any payment method from given options(Cash,Debit/Credit card & Transfer).",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#sales-cart-received-amount",
        popover: {
          title: `Received Amount`,
          description: "Seller can sell items with partial payments.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#sales-cart-quotation-btn",
        popover: {
          title: `Quotation`,
          description: "Clicking on this button seller will be able to generate quotation.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#sales-cart-sell-button",
        popover: {
          title: `Sell Button`,
          description: "Clicking on this button seller will be able to sell all products in the cart.",
          side: "left",
          align: "start",
          onNextClick: () => {
            setIsCartOpen(false);
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Sales", value: true } }));
            dispatch(emptyCart());
            setTimeout(() => {
              salesTour.destroy();
            }, 100);
          },
        },
      },
    ],
  });
  useEffect(() => {
    salesTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default SalesTour;
