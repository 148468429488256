import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import datePickerStyle from "./DatePicker.module.css";
import chevron from "./../../assets/svg/grayChevron.svg";

const DatePicker = ({
  fromDate = moment()._d,
  toDate = moment()._d,
  setFromDate,
  setToDate,
  important,
}) => {
  const range = {
    Today: [moment().startOf("day"), moment().endOf("day")],
    Yesterday: [
      moment().subtract(1, "days").startOf("day"),
      moment().subtract(1, "days").endOf("day"),
    ],
    "Last 7 Days": [
      moment().subtract(6, "days").startOf("day"),
      moment().endOf("day"),
    ],
    "Last 30 Days": [
      moment().subtract(29, "days").startOf("day"),
      moment().endOf("day"),
    ],
    "This Month": [
      moment().startOf("month").startOf("day"),
      moment().endOf("month").endOf("day"),
    ],
    "Last Month": [
      moment().subtract(1, "month").startOf("month").startOf("day"),
      moment().subtract(1, "month").endOf("month").endOf("day"),
    ],
    "This Year": [
      moment().startOf("year").startOf("day"),
      moment().endOf("year").endOf("day"),
    ],
    "Last Year": [
      moment().subtract(1, "year").startOf("year").startOf("day"),
      moment().subtract(1, "year").endOf("year").endOf("day"),
    ],
  };

  const handleEvent = (event, picker) => {
    if (event.type === "apply") {
      setFromDate(picker.startDate._d);
      setToDate(picker.endDate._d);
    }
  };

  function showInButton(range, from, to) {
    let ret;
    Object.entries(range).forEach((item) => {
      let result;
      const forFrom = item[1][0]._d;
      const forTo = item[1][1]._d;
      // console.log(from)
      if (
        forFrom.getDate() === from?.getDate() &&
        forFrom.getMonth() === from?.getMonth() &&
        forFrom.getYear() === from?.getYear() &&
        forTo.getDate() === to?.getDate() &&
        forTo.getMonth() === to?.getMonth() &&
        forTo.getYear() === to?.getYear()
      ) {
        result = item;
      }
      if (result) {
        ret = result[0].toString();
      }
    });
    if (ret) {
      return ret;
    } else {
      return `${moment(from.toISOString()).format("LL")} to ${moment(
        to.toISOString(),
      ).format("LL")}`;
    }
  }

  return (
    <>
      <DateRangePicker
        startDate={fromDate}
        endDate={toDate}
        ranges={range}
        alwaysShowCalendars={false}
        onEvent={handleEvent}
      >
        <button
          className={
            "text-secondary d-flex justify-content-between align-items-center py-2 px-2 bg-white text-capitalize rounded-1 " +
            datePickerStyle.button
          }
        >
          {showInButton(range, fromDate, toDate)}
          <img
            style={{
              pointerEvents: "none",
            }}
            src={chevron}
            alt="chevron"
          />
        </button>
      </DateRangePicker>
    </>
  );
};

export default DatePicker;
