import React, { useEffect, useState } from "react";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import styles from "../../styles/ImeiForm.module.css";
import SearchInput from "../SearchInput";
import { useDispatch, useSelector } from "react-redux";
import customloader from "../../assets/images/RollingLoader.gif";

import { handleImeiNumber } from "../../Features/cart/cartSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Loader from "../Loader";
function ViewIMEI({ show, itemTitle, close, id, setId }) {
  const [search, setsSearch] = useState("");
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [isClicked, setIsClicked] = useState(false);
  const [imeiData, setImeiData] = useState();
  const [imeis, setImeis] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [IMEICheck, setIMEICheck] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    setIsClicked(false);
    setIsLoading(false);
    setsSearch();
    setIMEICheck(false);
    const fetchImeis = async () => {
      if (id && id !== -1) {
        try {
          setIsLoading(true);
          const responseData = await axios.get(process.env.REACT_APP_API_URL + `/imei/${id}`, { withCredentials: true });

          if (responseData) {
            setIsLoading(false);
            setImeiData(responseData.data.data);
          }
        } catch (error) {
          setIsLoading(false);
        }
      }
    };
    fetchImeis();
  }, [id, show, imeis]);

  useEffect(() => {
    setImeiData();
  }, [id]);

  let filterValue;
  if (search) {
    filterValue = imeiData?.filter((ime) => {
      return ime.includes(search?.toLowerCase());
    });
  } else {
    filterValue = imeiData;
  }
  useEffect(() => {
    const filteredItem = cart.cartItems.filter((item) => item.id === id);
    if (filteredItem.length > 0) {
      if (filteredItem[0]?.imeis?.length > 0) {
        setImeis(filteredItem[0]?.imeis);
      } else {
        setImeis([]);
      }
    }
  }, [cart, imeis, show, id]);

  const handleChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  function getRandomColor() {
    const tagColors = ["rgba(149, 55, 255, 0.15)", "rgba(217, 87, 249, 0.15)", "rgba(255, 209, 102, 0.15)", "rgba(6, 214, 160, 0.15)", "rgba(17, 138, 178, 0.15)", "rgba(7, 59, 76, 0.15)"];

    const randomIndex = Math.floor(Math.random() * tagColors.length);
    return tagColors[randomIndex];
  }

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();
    if (key === "Enter" && trimmedInput.length && !imeis.some((item) => item.imeiNumber === trimmedInput)) {
      setIsClicked(false);
      e.preventDefault();
      if (input.length > 14) {
        const randomColor = getRandomColor();
        setImeis((prevState) => [...prevState, { imeiNumber: trimmedInput, color: randomColor }]);
        setInput("");
        setIMEICheck(false);
      } else {
        setIMEICheck(true);
      }
    } else {
      if (key === "Enter") {
        e.preventDefault();
      }
    }
  };

  const deleteImei = (index) => {
    setImeis((prevState) => prevState.filter((tag, i) => i !== index));
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsClicked(true);
    let data = {
      item_batch_id: id,
      imei: imeis?.map((im) => {
        return im.imeiNumber;
      }),
    };

    try {
      const responseData = await axios.post(
        process.env.REACT_APP_API_URL + `/imei`,
        {
          item_batch_id: id,
          imei: imeis?.map((im) => {
            return im.imeiNumber;
          }),
        },
        { withCredentials: true },
      );

      if (responseData) {
        if (responseData?.data?.data) {
          setError(responseData.data.data.join(",") + " " + responseData.data.message);
        } else {
          setError("");
          setImeis([]);
        }
      }
    } catch (error) {
      // console.error(error);
    }
    dispatch(handleImeiNumber(data));
    // setId(-1);
    // setIsClicked(false);
  };

  //   const handleSubmit = () => {};
  const handleSearchChange = (e) => {
    setsSearch(e.target.value);
  };

  const soldItems = [];
  const availableItems = [];

  filterValue?.forEach((data) => {
    if (data[1] !== -1) {
      soldItems.push(data);
    } else {
      availableItems.push(data);
    }
  });
  const mergedItems = [...availableItems, ...soldItems];
  return (
    <div>
      {" "}
      <Form noValidate>
        {error && <p className="text-danger p-2 text-center my-1 rounded-1">*{error}</p>}
        <Row className="d-flex align-items-center">
          {imeiData ? (
            <>
              {" "}
              <Col xs="2" lg="3" xl="6" className="d-none d-lg-inline">
                <SearchInput searchlogo value={search} handleChange={handleSearchChange} />
              </Col>
              <div className="">
                <label className="mt-4 fs-16">{itemTitle}</label>
                <div style={{ maxHeight: "250px", overflowY: "auto" }} className="border rounded-3  mt-2">
                  {mergedItems?.map((data, index) => (
                    <div className="border-bottom d-flex my-3 justify-content-between align-items-center">
                      <div className="mb-2 ms-3">
                        {index + 1}: {data[0]}
                      </div>
                      <div className="mb-2 ms-3">{data[1] !== -1 ? <span className="badge bg-danger text-white">SOLD</span> : <span className="badge bg-success text-white">AVAILABLE</span>}</div>
                    </div>
                  ))}
                  {!filterValue.length > 0 && <span className="ms-3 my-2"> No Record Found</span>}
                </div>
              </div>
            </>
          ) : (
            <strong className="mt-4 fs-20 text-center">No record Found against this batch</strong>
          )}
          {/* Add IMei */}
          <div className="mt-4">
            <Form noValidate>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Add IMEI(s)</Form.Label>
                <div className={`${styles.tagArea} w-100 w-md-auto`}>
                  <ul>
                    {imeis?.map((imei, index) => (
                      <li
                        style={{
                          background: imei.color,
                          borderColor: imei.color,
                        }}
                        className={styles.tag}
                        key={index}
                      >
                        {imei.imeiNumber}
                        <button type="button" onClick={() => deleteImei(index)}>
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      </li>
                    ))}
                    <input
                      placeholder="e.g 2359389584"
                      type="text"
                      value={input}
                      maxLength="15"
                      pattern="[0-9\-]*"
                      title="Please enter 15 digits"
                      className={styles.tagInput}
                      onInput={(event) => {
                        // Remove any non-numeric characters
                        event.target.value = event.target.value.replace(/[^0-9\-]/g, "").slice(0, 15);
                      }}
                      id="tag-input"
                      onKeyDown={onKeyDown}
                      onChange={handleChange}
                    />
                  </ul>
                </div>
                {IMEICheck && <span className="text-danger  "> IMEI should contain 15 digits</span>}
                {isClicked && !imeis && <span className="text-danger  "> Please Fill</span>}
              </Form.Group>
              <div className="d-flex justify-content-end  ">
                <Button type="" variant="primary" disabled={imeis.length > 0 && !isClicked && !isLoading ? false : true} onClick={handleSubmit} className="w-25  mt-2">
                  {" "}
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <span className="fs-20">+</span>ADD
                    </>
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </Row>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1"></Form.Group>
      </Form>
    </div>
  );
}

export default ViewIMEI;
