import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PopUpModal from "../Modal";

function OpeningRegister({ cashRegisterModal, setCashRegisterModal, setIsValidate, validated, handleCashOpeningBalance, showtime, registerSuccess, error, openingBalance, setOpeningBalance, handleCashRegister }) {
  const onHide = () => {
    setOpeningBalance("");
    setIsValidate(false);
    setCashRegisterModal(false);
  };
  return (
    <div>
      <PopUpModal style={{ background: "rgba(0,0,0,0.3)" }} show={cashRegisterModal} onHide={onHide}>
        <Form validated={validated} noValidate onSubmit={handleCashOpeningBalance}>
          <div className="text-center mb-5">
            <h3>{showtime} 👋🏼,</h3>
            <p>Add opening balance for the selected location</p>
          </div>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              {registerSuccess && <p className="text-success my-2 text-center">{registerSuccess}</p>}
              {/* {error && (
                <p className="text-danger my-2 text-center">*{error}</p>
              )} */}
              <Form.Group className="mb-2" controlId="price">
                <Form.Label className="mb-0">Enter amount</Form.Label>
                <Form.Control
                  min={0}
                  type="number"
                  placeholder="i.e 500"
                  onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                  value={openingBalance}
                  onChange={(e) => {
                    setIsValidate(false);
                    e.target.value.length < 10 ? setOpeningBalance(e.target.value) : setOpeningBalance(0);
                  }}
                  name="opening_balance"
                  required
                />

                <Form.Control.Feedback type="invalid">Please Enter Cash Opening Balance</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <Button disabled={validated} className="w-100 " type="submit" onClick={handleCashRegister}>
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </PopUpModal>
    </div>
  );
}

export default OpeningRegister;
