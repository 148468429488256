import React from "react";
import { Col, Row } from "react-bootstrap";

const ViewImei = ({ ImeiData }) => {
  function getRandomColor() {
    const tagColors = [
      "rgba(149, 55, 255, 0.15)",
      "rgba(217, 87, 249, 0.15)",
      "rgba(255, 209, 102, 0.15)",
      "rgba(6, 214, 160, 0.15)",
      "rgba(17, 138, 178, 0.15)",
      "rgba(7, 59, 76, 0.15)",
    ];

    const randomIndex = Math.floor(Math.random() * tagColors.length);
    return tagColors[randomIndex];
  }

  return (
    <div className="p-3">
      <div className="d-grid  ">
        {ImeiData?.map((data, index) => (
          <>
            <div className="d-flex justify-content-center">
              <strong className="label mx-2">{data.title}</strong>
              <strong className="">Qty:{data.qty_ordered}</strong>
            </div>
            <Row className="d-flex justify-content-center">
              {data.imeis.split(",").map((imei) => (
                <Col
                  style={{ background: getRandomColor() }}
                  lg="4"
                  className="imei my-2 py-1 px-2 mx-2 rounded-4"
                >
                  {imei}
                </Col>
              ))}
            </Row>
          </>
        ))}
      </div>
    </div>
  );
};

export default ViewImei;
