import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import LoaderPage from "../../Components/loaders/loaderPage";
import { io } from "socket.io-client";
import ImageUploader from "../../Components/ImageUploader/ImageUploader";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faPlus, faClose } from "@fortawesome/free-solid-svg-icons";
import inputStyle from "./ImageUpload.module.css";
import Cookies from "../../Features/Cookies";
import inValidLink from "../../assets/images/hyperlink.png";
function ImageUpload() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const limit = searchParams.get("limit");
  const directory = searchParams.get("directory");
  const socketRef = useRef();
  const fileInputRef = useRef();
  const cameraInputRef = useRef();

  const [connected, setConnected] = useState(false);
  const [errorInConnection, setErrorInConnection] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const [images, setImages] = useState([]); // State to store captured images
  const [noOfImageError, setNoOfImageError] = useState(false);
  const [sizeOfImageError, setSizeOfImageError] = useState(false);
  const [typeOfImageError, setTypeofImageError] = useState(false);
  /* API CALLS */

  /* END API CALLS */
  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(process.env.REACT_APP_API_URL, {
        path: "/imageUpload",
        query: {
          token: token,
        },
      });
    }
    const socket = socketRef.current;
    socket.on("connect", () => {
      setConnected(true);
    });
    socket.on("imageUpload:disconnected", () => {
      setErrorInConnection(true);
    });

    return () => {
      socket.disconnect();
      if (socketRef.current) {
        socketRef.current = null;
      }
    };
  }, [token]);
  const handleUpload = async (files) => {
    try {
      const formData = new FormData();
      files.map((file) => {
        formData.append("files", file.file, file.file.name);
      });
      const resData = await axios.post(
        `${process.env.REACT_APP_URL_IMAGE_CDN}/temp`,
        formData,
        { withCredentials: true },
        {
          headers: {
            Accept: "application/json",
            "content-type": "multipart/form-data",
          },
        }
      );

      return resData.data.files;
    } catch (error) {
      throw error;
    }
  };

  const submitImages = async () => {
    try {
      setIsClicked(true);
      // upload image on bucket
      const uploadedFiles = await handleUpload(images);
      if (socketRef.current.connected) {
        socketRef.current.emit("imageUpload:submit", { urls: uploadedFiles });
        window.close();
      } else {
        // If socket not connected, told user to rescan QR to open active page.
      }
      setIsClicked(false);
    } catch {
      // Error handling in image upload fail... disconnect socket if fit in logic
    }
  };

  if (!connected) return <LoaderPage />;

  if (errorInConnection)
    return (
      <>
        <div className="min-vh-100 d-flex flex-column justify-content-center align-items-center position-relative">
          <FontAwesomeIcon icon={faClose} size="3x" className="position-absolute" style={{ top: "10px", right: "10px", cursor: "pointer" }} onClick={() => window.close()} />
          <div>
            <img src={inValidLink} height={"150px"} width={"150px"} alt={"inValidLink"} />
          </div>
          <h1 className="text-warning text-center">Invalid QR Code</h1>
          <p className="text-center px-3">This QR code has been expired. Please try again with a valid QR code</p>
          <div className="p-3 fs-5">Scan Again</div>
        </div>
      </>
    );

  const maxSize = 10485760; //10mb
  const allowedTypes = ["image/png", "image/jpeg", "image/heic", "image/heif"];
  const handleImageSelection = (e) => {
    const files = Array.from(e.target.files);
    let validFiles = [];
    let oversizedFiles = false;
    let invalidTypeFiles = false;

    files.forEach((file) => {
      if (allowedTypes.includes(file.type)) {
        if (file.size <= maxSize) {
          validFiles.push({ file: file, preview: URL.createObjectURL(file) });
        } else {
          oversizedFiles = true;
        }
      } else {
        invalidTypeFiles = true;
      }
    });

    if (validFiles.length + images.length <= limit) {
      setImages([...images, ...validFiles]);
      setNoOfImageError(false);
      setSizeOfImageError(false);
      setTypeofImageError(false);
    } else {
      setNoOfImageError(true);
    }

    if (oversizedFiles) {
      setSizeOfImageError(true);
    } else {
      setSizeOfImageError(false);
    }

    if (invalidTypeFiles) {
      setTypeofImageError(true);
    } else {
      setTypeofImageError(false);
    }
  };
  const handleSelectFiles = () => {
    fileInputRef.current.click();
  };
  const handleCaptureImage = () => {
    cameraInputRef.current.click(); // Trigger the camera input click
  };
  const Image = ({ src, onDelete }) => {
    return (
      <>
        <div className={`${inputStyle.image} shadow`} style={{ overflow: "hidden" }}>
          {!isClicked && <FontAwesomeIcon className="icon" icon={faClose} onClick={() => onDelete(src)} />}
          <div className="img">
            <img src={src.preview} alt="" width="100%" height="auto" />
          </div>
        </div>
      </>
    );
  };
  const onDelete = (photo) => {
    setImages((prevImage) => prevImage.filter((img) => img !== photo));
    setNoOfImageError(false);
  };
  return (
    <>
      <h3 className="pt-3 text-center" style={{ color: "#170f25" }}>
        Upload Images
      </h3>
      {images.length < limit ? (
        <div className="d-flex justify-content-center align-items-center gap-3 p-3">
          <input ref={fileInputRef} type="file" accept={allowedTypes.join(",")} multiple hidden onChange={handleImageSelection}></input>
          <button type="button" className="btn btn-outline-primary btn-sm" onClick={handleSelectFiles}>
            <FontAwesomeIcon icon={faPlus} />
            &nbsp; Select Files
          </button>
          {/* Button to Capture using Camera */}
          <input
            ref={cameraInputRef}
            type="file"
            accept={allowedTypes.join(",")}
            multiple
            capture="environment" // This attribute opens the camera on mobile devices hidden
            hidden
            onChange={handleImageSelection}
          ></input>
          <button type="button" className="btn btn-outline-primary btn-sm" onClick={handleCaptureImage}>
            <FontAwesomeIcon icon={faCamera} />
            &nbsp; Capture Image
          </button>
        </div>
      ) : (
        <></>
      )}
      {sizeOfImageError ? <span style={{ color: "red" }}>You have added an image with a size greater than 10MB. Please try with a smaller size.</span> : typeOfImageError ? <span style={{ color: "red" }}>You have added an Invalid Image </span> : <></>}
      <br />
      {noOfImageError ? <span style={{ color: "red" }}>You Try to Add more then {limit} Images</span> : <span>{images?.length} Images Added</span>}
      <br />
      <div className="d-flex flex-wrap justify-content-center align-items-center" style={{ backgroundColor: "#dfdfdf", minHeight: "200px" }}>
        {images?.length > 0 ? (
          images?.map((photo, index) => {
            return (
              <div key={index} className={`${inputStyle.minWidth}`}>
                <Image src={photo} onDelete={onDelete} />
              </div>
            );
          })
        ) : (
          <h4>No Images Selected</h4>
        )}
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Button style={{ width: "90px" }} onClick={submitImages} disabled={images.length === 0 || isClicked}>
          {isClicked ? (
            <div className={`${inputStyle.ldsEllipsis}`}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            "Upload"
          )}
        </Button>
      </div>
    </>
  );
}

export default ImageUpload;
