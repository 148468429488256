import { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";

import styles from "../../styles/ImeiForm.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const AddIMEIS = ({ show, itemId, close, getImeis, IMEIS }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [imeis, setImeis] = useState([]);
  const [IMEICheck, setIMEICheck] = useState(false);
  const [error, setError] = useState("");

  const [input, setInput] = useState("");
  useEffect(() => {
    if (IMEIS?.length > 0) {
      setImeis(IMEIS);
    } else {
      // setImeis([]);
    }
  }, [IMEIS, isClicked]);
  const handleChange = (e) => {
    const { value } = e.target;
    setInput(value);
    // if (
    //   value.length === 15 &&
    //   !imeis.some((imei) => imei.imeiNumber === value)
    // ) {
    //   e.preventDefault();
    //   const randomColor = getRandomColor();
    //   setImeis((prevState) => [
    //     ...prevState,
    //     { imeiNumber: value, color: randomColor },
    //   ]);
    //   setInput("");
    // }
  };
  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();
    if (key === "Enter" && trimmedInput.length && !imeis.some((item) => item.imeiNumber === trimmedInput)) {
      setIsClicked(false);
      e.preventDefault();
      if (input.length > 14) {
        const randomColor = getRandomColor();
        setImeis((prevState) => [...prevState, { imeiNumber: trimmedInput, color: randomColor }]);
        setInput("");
        setIMEICheck(false);
      } else {
        setIMEICheck(true);
      }
    } else {
      if (key === "Enter") {
        e.preventDefault();
      }
    }
  };
  function getRandomColor() {
    const tagColors = ["rgba(149, 55, 255, 0.15)", "rgba(217, 87, 249, 0.15)", "rgba(255, 209, 102, 0.15)", "rgba(6, 214, 160, 0.15)", "rgba(17, 138, 178, 0.15)", "rgba(7, 59, 76, 0.15)"];

    const randomIndex = Math.floor(Math.random() * tagColors.length);
    return tagColors[randomIndex];
  }

  const deleteImei = (index) => {
    setImeis((prevState) => prevState.filter((tag, i) => i !== index));
    setError("");
  };
  const uniqueImeis = async () => {
    try {
      const responseData = await axios.post(
        process.env.REACT_APP_API_URL + `/imei/check-unique-imei`,
        {
          imei: imeis?.map((im) => {
            return im.imeiNumber;
          }),
        },
        { withCredentials: true },
      );
      if (responseData) {
        if (responseData?.data?.data) {
          setError(responseData.data.data.join(",") + " " + responseData.data.message);
        } else {
          setError("");
          getImeis(imeis, itemId);
          setImeis([]);
          close();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsClicked(true);
    uniqueImeis();
    setError("");
  };
  const handleEdit = (e) => {
    // e.preventDefault();
    // uniqueImeis();
    e.preventDefault();
    uniqueImeis();
    // setIsClicked(true);
    // setInput("");
    // setIsClicked(false);
  };
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="md"
      backdrop="static"
      show={show}
      onHide={() => {
        close();
      }}
      className={`${styles.imei}`}
    >
      <Modal.Header className="border-0 pt-4" closeButton>
        {IMEIS?.length > 0 ? <h4>Edit IMEI(s)</h4> : <h4>IMEI(s)</h4>}
      </Modal.Header>
      <hr className="m-0 mb-2" />
      <Modal.Body className="mx-4 mb-4">
        <Form noValidate>
          {error && <p className="text-danger p-2 text-center my-1 rounded-1">*{error}</p>}
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Add IMEI(s)</Form.Label>
            <div className={styles.tagArea}>
              <ul className="w-100">
                {imeis?.map((imei, index) => (
                  <li style={{ background: imei.color, borderColor: imei.color }} className={styles.tag} key={index}>
                    {imei.imeiNumber}
                    <button type="button" onClick={() => deleteImei(index)}>
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </li>
                ))}
                <input
                  placeholder="e.g 485908803384049"
                  type="text"
                  value={input}
                  maxLength="15"
                  pattern="[0-9\-]*"
                  title="Please enter 15 digits"
                  className={`${styles.tagInput} w-100`}
                  onKeyDown={onKeyDown}
                  onInput={(event) => {
                    // Remove any non-numeric characters
                    event.target.value = event.target.value.replace(/[^0-9\-]/g, "").slice(0, 15);
                  }}
                  id="tag-input"
                  onChange={handleChange}
                />
              </ul>
            </div>
            {IMEICheck && <span className="text-danger  "> IMEI should contain 15 digits</span>}
          </Form.Group>
          <Button onClick={IMEIS?.length > 0 ? handleEdit : handleSubmit} type="" className="w-100 mt-2">
            Save
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddIMEIS;
