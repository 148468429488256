import React, { useEffect, useMemo } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const EmployeesTour = ({ setAddUserModalShow }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const employeesTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Employees", value: true } }));
      setTimeout(() => {
        employeesTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Employees",
        popover: {
          title: "Employees",
          description: "Overall shop Employees.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#employees",
        popover: {
          title: `A guide to shop employees`,
          description: "Shows and manages employee roles and information.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#employees-search",
        popover: {
          title: `Search employee`,
          description: "seller can search any employee by his name or email.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#employees-page-size",
        popover: {
          title: `Page size`,
          description: "Seller can choose that how many employees he wants to see at a time.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#employees-add-employee",
        popover: {
          title: `Add employee`,
          description: "Upon clicking on this button, seller can add new employee.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setAddUserModalShow(true);
            setTimeout(() => {
              employeesTour.moveNext();
            });
          },
        },
      },
      {
        element: "#employees-add-modal > .modal-content",
        popover: {
          title: `Add New employee`,
          description: "A form to add a new employee.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setAddUserModalShow(false);
            setTimeout(() => {
              employeesTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#employees-add-employees-name",
        popover: {
          title: `Employee Full Name`,
          description: "Enter employee's full name.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#employees-add-employee-email",
        popover: {
          title: `Employee Email`,
          description: "Enter employee's email.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#employees-add-employee-password",
        popover: {
          title: `Password`,
          description: "Enter employee's password.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#employees-add-employee-location",
        popover: {
          title: `Location`,
          description: "Seller can select any location on which he wants to add employee.",
          side: "right",
          align: "start",
        },
      },

      {
        element: "#employees-add-employee-role",
        popover: {
          title: `Employee Role`,
          description: "Seller can give role to the employee as Cashier or Sales Person.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#employees-add-employee-commission",
        popover: {
          title: `Commission`,
          description: "Here seller can add commission of Sales Person in Rs or %.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#employees-add-employee-contact",
        popover: {
          title: `Phone Number`,
          description: "Enter employee's contact number.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#employees-add-employee-alternate-contact",
        popover: {
          title: `Alternative Phone No.`,
          description: "Enter employee's alternate phone number if any.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#employees-add-employee-modules",
        popover: {
          title: `Associated Modules.`,
          description: "Seller can give access to any of these modules to the employee.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#employees-add-employee-notifications",
        popover: {
          title: `Associated Modules.`,
          description: "Seller can give access to notifications to the employee.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#employees-add-employee-button",
        popover: {
          title: `Save`,
          description: "On clicking save button added information of employees will be saved.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setAddUserModalShow(false);
            employeesTour.moveNext();
          },
        },
      },
      {
        element: "#employees-reset-filter",
        popover: {
          title: `Reset Filters`,
          description: "Clicking on this button will remove all applied filters on the search result.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setAddUserModalShow(true);
            setTimeout(() => {
              employeesTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#employees-table > div:nth-child(1)",
        popover: {
          title: `Employees List`,
          description: "On this page, all added employees will be shown with their information.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#settings",
        popover: {
          title: "Reset Password",
          description: "Here seller can reset password of any employee.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#edit",
        popover: {
          title: `Edit`,
          description: "Upon clicking on this button, seller can edit any employee.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#remove",
        popover: {
          title: `Delete`,
          description: "On clicking this button seller can delete that employee.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#csv-download",
        popover: {
          title: `Download CSV`,
          description: "On clicking this, seller can download employees record in csv file.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Employees", value: true } }));
            setTimeout(() => {
              employeesTour.destroy();
            }, 100);
          },
        },
      },
    ],
  });
  useEffect(() => {
    employeesTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default EmployeesTour;
