import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import inputStyle from "./videoUploader.module.css";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const VideoPreview = ({ src, setSrc, selectedFile, setSelectedFile, setDeletedFile }) => {
  let videoSrc;
  if (src) {
    if (selectedFile) {
      videoSrc = src;
    } else {
      videoSrc = URL.createObjectURL(src);
    }
  } else {
    videoSrc = null;
  }

  return (
    <div className={inputStyle.videoContainer}>
      <div className={inputStyle.videoBox}>
        <FontAwesomeIcon
          className="icon"
          icon={faClose}
          onClick={() => {
            if (selectedFile) {
              setDeletedFile(selectedFile);
              setSelectedFile(null);
            }
            setSrc(null);
          }}
        />
        <video style={{ width: "300px", height: "300px", border: "1px solid black" }} src={videoSrc} controls />
      </div>
    </div>
  );
};

const VideoUploader = forwardRef(({ selectedFile, setSelectedFile, isInvalid, disabled = false }, ref) => {
  const { user } = useSelector((state) => state.auth);
  const [error, setError] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [deletedFile, setDeletedFile] = useState(null);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "video/mp4": [".mp4"],
      "video/mpeg": [".mpeg"],
      "video/ogg": [".ogv"],
      "video/webm": [".webm"],
    },
    onDrop: (acceptedFiles, fileRejections) => {
      setFileLoading(true);
      setError("");
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setError("File is too large");
            setFileLoading(false);
          }
        });
      });
      if (acceptedFiles[0]) {
        const reader = new FileReader();
        reader.onload = () => {
          const video = new Audio(reader.result);
          video.onloadedmetadata = () => {
            const duration = video.duration;
            if (duration <= 30) {
              setSelectedVideo(acceptedFiles[0]);
              setError("");
            } else {
              setError("Video should be less than 30 seconds");
            }
            setFileLoading(false);
          };
        };
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onerror = () => {
          setError("File could not be read");
          setFileLoading(false);
        };
      }
    },
    maxFiles: 1,
    maxSize: 104857600, //100mb
  });

  // ON DELETE Action HERE
  // const onDelete = (photo) => {
  //   setSelectedFiles((prevImage) => prevImage.filter((img) => img !== photo));

  //   setNoOfImageError(false);
  //   setSizeOfImageError(false);
  // };

  useEffect(() => {
    if (!selectedVideo && selectedFile) {
      setSelectedVideo(process.env.REACT_APP_URL_S3 + "/" + selectedFile);
    }
  }, [selectedFile]);

  useImperativeHandle(ref, () => ({
    // Upload video Handle Here
    uploadVideoHandle: async () => {
      try {
        if (selectedVideo) {
          if (!selectedFile) {
            const formData = new FormData();
            formData.append("files", selectedVideo, selectedVideo.name);
            const res = await axios.post(
              `${process.env.REACT_APP_URL_IMAGE_CDN}/videos/listings/${user?.shop_id}`,
              formData,
              { withCredentials: true },
              {
                headers: {
                  Accept: "application/json",
                  "content-type": "multipart/form-data",
                },
              },
            );
            if (res) {
              if (setSelectedFile) {
                setSelectedFile(res?.data?.file);
                return res?.data?.file;
              }
            }
          } else {
            return selectedFile;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    deleteFromCDN: () => {
      if (deletedFile) {
        axios.delete(`${process.env.REACT_APP_URL_IMAGE_CDN}/${deletedFile}`, {}, { withCredentials: true });
      }
    },
  }));

  return (
    <>
      <Form.Group
        className={inputStyle.section}
        style={{
          ...(isInvalid ? { border: "1px solid red", borderRadius: "8px" } : {}),
          ...(disabled ? { pointerEvents: "none", background: "#e9ecef", opacity: "0.7" } : {}),
        }}
      >
        {fileLoading ? (
          <div style={{ textAlign: "center", width: "100%", height: "180px" }}>Loading....</div>
        ) : (
          <>
            {selectedVideo ? <VideoPreview src={selectedVideo} setSrc={setSelectedVideo} selectedFile={selectedFile} setSelectedFile={setSelectedFile} setDeletedFile={setDeletedFile} /> : <></>}
            <Form.Label
              {...getRootProps({
                onClick: (e) => {
                  e.preventDefault();
                },
              })}
              // style={selectedVideo?.length > 0 ? { display: "none" } : {}}
              className={`${selectedVideo ? "d-none" : ""} px-3 py-4 border-1 d-flex justify-content-center align-items-center gap-2`}
            >
              <div className="text-center">
                <p className={`m-0 ${inputStyle.txt}`}>
                  Drop your file here or <span className={inputStyle.browseTxt}>browse</span>
                </p>
                <span className={`text-secondary ${inputStyle.txtSize}`}>Allowed files: (webm, ogv, mpeg, mp4)</span>
                <span className={`text-secondary ${inputStyle.txtSize}`}>
                  (maximum <span className={inputStyle.browseTxt}>100 MB</span> file size and <span className={inputStyle.browseTxt}>30s</span> duration)
                </span>
              </div>
              <Form.Control {...getInputProps()} multiple={false} />
            </Form.Label>
          </>
        )}

        {error?.length > 0 ? <span style={{ color: "red" }}>{error}*</span> : <></>}
      </Form.Group>
    </>
  );
});

export default VideoUploader;
