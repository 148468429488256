import React, { useEffect, useRef, useState, useCallback } from "react";
import { Alert, Badge, Button, Container, Form } from "react-bootstrap";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import Select from "react-select";
import ImageUploader from "../../Components/ImageUploader/ImageUploader";
import FeatureditemsModal from "../../Components/Listings/FeaturedItems/FeatureditemsModal";
import axios from "axios";
import styles from "../../styles/Customize.module.css";
import { useSelector } from "react-redux";

import Theme5 from "../../assets/images/Themes/Theme 5.png";
import ThemesView from "./ThemesView";
import ThemeStoreTour from "../../TourGuides/ThemeStoreTour";

const Themes = () => {
  const { user } = useSelector((state) => state.auth);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [sections, setSections] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [secId, setSecId] = useState();
  const [trigger, setTrigger] = useState(false);
  const [userSelectedTheme, setUserSelectedTheme] = useState();
  const getCategories = useCallback(async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + "/categories/get", {
        withCredentials: true,
      });
      setCategoryOptions(response.data.map(({ id, category }) => ({ label: category, value: id })));
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }, []);
  const getuser = useCallback(async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + "/user/seller-verification", {
        withCredentials: true,
      });
      setUserSelectedTheme(response.data[0]);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }, []);
  useEffect(() => {
    getuser();
    getCategories();
  }, [getCategories, trigger]);
  const fields = [{ value: "Theme 1" }, { value: "Theme 2" }, { value: "Theme 3" }, { value: "Theme 4" }, { value: "Theme 5", Theme: Theme5 }];

  return (
    <>
      {user?.tour && !user?.tour?.ThemeStore && <ThemeStoreTour />}
      <div className="d-flex justify-content-center d-sm-none d-xs-none ">
        <Alert variant="info" className="mt-5">
          <h3>Ths feature is only available on larger screens</h3>
        </Alert>
      </div>
      <Container className="d-none d-lg-block d-xl-block d-md-block" fluid id="theme-store-apply-btn">
        <ThemesView setTrigger={setTrigger} userSelectedTheme={userSelectedTheme} />
        <div className="main">
          <div className="d-flex justify-content-center my-3"></div>
        </div>
        <FeatureditemsModal secId={secId} show={showModal} onHide={() => setShowModal(false)} sections={sections} setSections={setSections} />
      </Container>
    </>
  );
};

export default Themes;
