import React, { useEffect } from "react";
// import logo from './../../../assets/images/logo.png'
import axios from "axios";
import style1 from "./../../../styles/InvoiceCard.module.css";
import style from "./../../../styles/Invoice.module.css";
import { Col, Row, Table, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import "./../../../styles/printLayout.css";
import Loading from "../../../Components/Loading/Loading";

const InvoicePaymentSales = forwardRef((props, ref) => {
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const logo = user.logo_path;
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [items, setItems] = useState([]);
  const [payments, setPayments] = useState(null);
  const [isIdUndifined, setIsIdUndifined] = useState(false);
  const [totalDiscount, setTotalDiscount] = useState();
  const [isloading, setisLoading] = useState(true);

  useEffect(() => {
    const pageTimeout = setTimeout(() => {
      try {
        if (id || props.printId) {
          setIsIdUndifined(false);
          const getData = async () => {
            const res = await axios.post(process.env.REACT_APP_API_URL + `/sales/invoice-payments-sales/${id ? id : props.printId}`, { id: shop_id }, { withCredentials: true });
            if (res) {
              let arr = [];
              const products = res.data.items.map((i) => ({
                title: i.title,
                cartQuantity: i.qty_ordered,
                price: i.sale_price,
                discount: i.discount,
              }));

              const miscProducts = res.data.miscItems.map((i) => ({
                title: i.name,
                cartQuantity: i.mis_qty,
                price: i.price,
                discount: i.discount,
                description: i.description,
              }));

              arr.push(...products);
              arr.push(...miscProducts);

              setData(res.data.data[0]);

              setItems(arr);

              setPayments(res.data.payments);
              setisLoading(false);
            }
          };
          getData();
        } else {
          setIsIdUndifined(true);
        }
      } catch (err) {
        setisLoading(false);
        setIsIdUndifined(true);
      }
    }, 0);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [id, props, shop_id]);
  var invoiceDate = new Date().toLocaleDateString();

  useEffect(() => {
    const sum = items?.reduce((accumulator, object) => {
      return Number(accumulator) + Number(object.discount);
    }, 0);
    setTotalDiscount(sum);
  }, [items]);
  return (
    <>
      {isloading ? (
        <Loading />
      ) : items?.length === 0 ? (
        <div style={{ height: "80vh" }} className="d-flex justify-content-center align-items-center">
          <h3>No data exists for this invoice</h3>
        </div>
      ) : (
        <Card className={`border-0 ${style1.invoiceCard} ${style1.formCard} mb-5`}>
          <div className={"p-5 laser-invoice"} ref={ref} {...props}>
            <div className={`d-flex ${logo !== null ? "justify-content-between" : "justify-content-end"} ${style.logoadd}`}>
              {logo !== null && <img src={process.env.REACT_APP_URL_S3 + "/" + logo} alt="" className={`${style1.logoimg} ${style.logo}`} />}
              <div className={`d-flex flex-column text-end mb-4 ${style.address}`}>
                <h5 style={{ color: "#39414A", marginBottom: "0rem" }}>Address</h5>
                <span style={{ color: "#39414A", marginBottom: "0rem" }} className={`${style.addresstxt}`}>
                  {data?.shop_address}
                </span>
                <span style={{ color: "#39414A", marginBottom: "0rem" }} className={`${style.addresstxt}`}>
                  Phone:{" "}
                  {data?.is_contact_no_on_invoice === 1 && data?.is_alternative_no_on_invoice === 1 ? (
                    <>
                      {data?.contact_number}
                      <br />
                      {data?.alternative_number}
                    </>
                  ) : data?.is_contact_no_on_invoice === 1 ? (
                    data?.contact_number
                  ) : data?.is_alternative_no_on_invoice === 1 ? (
                    data?.alternative_number
                  ) : (
                    data?.owner_whatsapp_number
                  )}
                </span>
                <span style={{ color: "#39414A", marginBottom: "0rem" }} className={`${style.addresstxt}`}></span>

                <span style={{ color: "#39414A" }} className={`${style.addresstxt}`}>
                  {data?.owner_email}
                </span>
                <span style={{ color: "#39414A" }} className={`${style.addresstxt} text-capitalize`}>
                  {user?.user_full_name}
                </span>
              </div>
            </div>
            <h1 className={`mb-3 ${style.invoice}`}>Invoice</h1>
            <Row>
              <Col xs sm="5" md="5" lg="5" className="px-0 ps-3">
                <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                  Customer details
                </p>
                {data?.customer_name === "nil" ? (
                  <span
                    className="text-capitalize"
                    style={{
                      color: "#39414A",
                      marginBottom: "0rem",
                      fontSize: "18px",
                      fontWeight: "700",
                    }}
                  >
                    Walk-in Customer
                  </span>
                ) : (
                  <>
                    <span
                      className="text-capitalize"
                      style={{
                        color: "#39414A",
                        marginBottom: "0rem",
                        fontSize: "18px",
                        fontWeight: "700",
                      }}
                    >
                      {data?.customer_name}
                    </span>
                    <div className={`mb-2 ${style.phn} px-0`}>
                      <p className={`${style.emailphone}`}>
                        <strong>Phone:</strong> {data?.customer_number}
                      </p>
                      {data?.customer_email && data?.customer_email !== "nil" && data?.customer_email !== "" ? (
                        <p className={`${style.emailphone}`}>
                          <strong>Email:</strong> {data?.customer_email}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
              </Col>
              <Col xs sm="4" md="4" lg="4" className={`${style.invoicenum}`}>
                <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                  Sales Order Number
                </p>
                <h5 className={`${style.invoicetxt} fw-2`}>{data?.sales_order_number}</h5>
              </Col>
              <Col xs sm="3" md="3" lg="3" className={`${style.invoicenum}`}>
                <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                  Issue Date
                </p>
                <h5 className={`${style.invoicetxt}`}>{invoiceDate}</h5>
              </Col>
            </Row>
            <Table style={{ border: "1px solid #DEDFE4", tableLayout: "fixed" }} className={`mb-4 mt-3 ${style.invoiceTable}`}>
              {/* <tbody> */}
              <thead style={{ background: "#F5F6FA" }}>
                <th className={`${style.tabledescription}`}>Products</th>
                <th className={`${style.tabletxt}`}>Unit price</th>
                <th className={`${style.tabletxt}`}>QTY</th>
                <th className={`${style.tabletxt}`}>Discount</th>
                <th className={`${style.tabletxt}`}>Total</th>
              </thead>
              {items?.map((item, index) => (
                <tr key={index}>
                  <td className={`${style.tabledescription} d-flex flex-column`}>
                    {item?.title}
                    {item?.description && <span style={{ color: "#686E76", fontSize: "13px" }}>{item?.description}</span>}
                  </td>
                  <td className={`${style.tabletxt}`}>{Number(item?.price).toLocaleString("en-IN")}</td>
                  <td className={`${style.tabletxt}`}>{item?.cartQuantity}</td>
                  <td className={`${style.tabletxt}`}>{item?.discount}</td>
                  <td className={`${style.tabletxt}`}>{Number(Number(item.cartQuantity * item.price) - Number(item.discount)).toLocaleString("en-IN")}</td>
                </tr>
              ))}
              {/* </tbody> */}
            </Table>
            <Table style={{ border: "1px solid #DEDFE4", tableLayout: "fixed" }} className={`mb-4 ${style.invoiceTablePayments}`}>
              {/* <tbody> */}
              <thead style={{ background: "#F5F6FA" }}>
                <th className={`${style.tabledescription}`}>Amount Received</th>
                <th className={`${style.tabletxt}`}>Payment Type</th>
                <th className={`${style.tabletxt}`}>Invoice Number</th>
                <th className={`${style.tabletxt}`}>Comment</th>
                <th className={`${style.tabletxt}`}>Payment Date</th>
              </thead>
              {payments?.map((payment, index) => (
                <tr key={index}>
                  <td className={`${style.tabledescription}`}>{Number(payment?.customer_payments_amount_received).toLocaleString("en-IN")}</td>
                  <td className={`${style.tabletxt}`}>{payment?.bank_name === "" || payment?.bank_name == "nill" ? "Cash" : `Bank Name (${payment?.bank_name})`}</td>
                  <td className={`${style.tabletxt}`}>{payment?.invoice_number}</td>
                  <td className={`${style.tabletxt}`}>{payment?.customer_payments_comment}</td>
                  <td className={`${style.tabletxt}`}>{payment?.customer_payments_date ? new Date(payment?.customer_payments_date).toLocaleDateString() : null}</td>
                </tr>
              ))}
              {/* </tbody> */}
            </Table>
            <Row>
              <Col xs="2" md="6" lg="6"></Col>
              <Col xs="5" md="3" lg="3">
                <span className={`${style.tabletxt}`}> Subtotal </span>
              </Col>
              <Col xs="5" md="3" lg="3" className="text-end">
                <span className={`${style.tabletxt}`}> {Number(data?.base_subtotal).toLocaleString("en-IN")} </span>
              </Col>
            </Row>
            <Row>
              <Col xs="2" md="6" lg="6"></Col>
              <Col xs="5" md="3" lg="3">
                <span className={`${style.tabletxt}`}> Discount </span>
              </Col>
              <Col xs="5" md="3" lg="3" className="text-end">
                <span className={` ${style.tabletxt}`}> {Number(totalDiscount).toLocaleString("en-IN")} </span>
              </Col>
            </Row>
            {/* <Row className="">
              <Col xs="2" md="6" lg="6"></Col>
              <Col xs="5" md="3" lg="3" >
                <span className={`${style.tabletxt}`}> Total </span>
              </Col>
              <Col xs="5" md="3" lg="3" className="text-end">
                <span className={`${style.tabletxt}`}>
                  {" "}
                  {Number(data?.base_subtotal- Number(totalDiscount)).toLocaleString(
                      "en-IN",
                    )}{" "}
                </span>
              </Col>
            </Row> */}
            <Row>
              <Col xs="2" md="6" lg="6"></Col>
              <Col xs="5" md="3" lg="3">
                <span className={`${style.tabletxt}`}> Shipping </span>
              </Col>
              <Col xs="5" md="3" lg="3" className="text-end">
                <span className={`  ${style.tabletxt}`}>{Number(data?.base_shipping_amount).toLocaleString("en-IN")} </span>
              </Col>
            </Row>
            <Row>
              <Col xs="2" md="6" lg="6"></Col>
              <Col xs="5" md="3" lg="3">
                <span className={`${style.tabletxt} fw-bold`}> Grand Total </span>
              </Col>
              <Col xs="5" md="3" lg="3" className="text-end">
                <span className={`fw-bold  ${style.tabletxt}`}> {Number(data?.base_grand_total).toLocaleString("en-IN")} </span>
              </Col>
            </Row>
            <Row className="p-0">
              <Col xs="2" md="6" lg="6"></Col>

              <Col xs="10" md="10" lg="6" className="text-end">
                <hr className="my-1" />
              </Col>
            </Row>
            <Row className="">
              <Col xs="2" md="6" lg="6"></Col>
              <Col xs="5" md="3" lg="3">
                <span className={`${style.tabletxt}`}> Total Received</span>
              </Col>
              <Col xs="5" md="3" lg="3" className="text-end">
                <span className={`${style.tabletxt}`}> {Number(data?.total_received).toLocaleString("en-IN")} </span>
              </Col>
            </Row>

            {data?.base_grand_total - data?.total_received > 0 && (
              <Row className="mb-4">
                <Col xs="2" md="6" lg="6"></Col>
                <Col xs="5" md="3" lg="3">
                  <span className={`${style.tabletxt}`}> Total Due</span>
                </Col>
                <Col xs="5" md="3" lg="3" className="text-end">
                  <span className={` ${style.tabletxt}`}> {Number(data?.base_grand_total - data?.total_received).toLocaleString("en-IN")} </span>
                </Col>
              </Row>
            )}
            <Row style={{ display: "contents" }}>
              <div className="mb-4" style={{ borderTop: "1px solid #DEDFE4" }}></div>
            </Row>
            <p className={`${style.invoiceterms} m-0`}>Invoice terms</p>

            {data?.base_grand_total - data?.total_received === 0 ? (
              <>
                <p className="m-0" style={{ color: "#39414A", textAlign: "center" }}>
                  The Buyer has <b className="text-success fs-5">PAID</b> the amount.
                </p>
              </>
            ) : (
              <>
                <p className="m-0" style={{ color: "#39414A", textAlign: "center" }}>
                  The Buyer still needs to pay <b className="text-danger fs-5">{Number(data?.base_grand_total - data?.total_received).toLocaleString("en-IN")}</b> to clear the invoice.
                </p>
              </>
            )}
            {data?.invoice_terms !== "nil" && <p className={`my-0 text-center terms ${style.terms}`}>* {data?.invoice_terms}</p>}
          </div>
        </Card>
      )}
    </>
  );
});

export default InvoicePaymentSales;
