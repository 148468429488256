import { Card, Col, Row } from "react-bootstrap";
import style from "./../styles/WidgetCard.module.css";

const WidgetCard = ({ data }) => {
  return (
    <Card className={`border-0 px-2 px-xl-4 ${style.widgetCard}`} id={`dashboard_overview_${data?.name}`}>
      <Row className="align-items-center">
        <Col xs="3" lg="3">
          <img
            height={"52px"}
            width="52px"
            style={{
              pointerEvents: "none",
            }}
            src={data.icon}
            alt={data.icon}
          />
        </Col>
        <Col xs="9" lg="9">
          <Card.Body className="pe-0 ps-1">
            <Card.Title>{data.name}</Card.Title>
            <h3 className={`${style.textWrap}`}>{data.number}</h3>
            <span>{data.total}</span>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default WidgetCard;
