import axios from "axios";
import { dateTime } from "./dateTime";

const getRegisterId = async (shop_id, location_id) => {
  try {
    const headers = {
      details: JSON.stringify({ shop_id, location_id }),
    };
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/cash-registers/get-id",
      {
        withCredentials: true,
        headers: headers,
      }
    );
    return res.data.id;
  } catch (err) {
    throw new Error(err.response?.data.error.message);
  }
};
const checkOpeningBalance = async (registerID) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL +
        `/cash-registers/check-opening-balance/${registerID}`,
      { withCredentials: true }
    );
    if (res?.data[0]) {
      const dateFormat = new Date().toDateString();
      const created_at = dateTime(
        res.data[0]?.opening_balance_time
      ).toDateString();
      const openingBalance = res.data[0];
      const closing_balance = res.data[0].closing_balance;
      if (dateFormat === created_at && closing_balance === null) {
        openingBalance["isCashRegisterOpen"] = true;
      } else {
        openingBalance["isCashRegisterOpen"] = false;
      }
      return openingBalance;
    } else {
      const date = dateTime();
      date.setDate(date.getDate() - 1);
      return {
        closing_balance: null,
        closing_balance_time: null,
        isCashRegisterOpen: false,
        opening_balance: 0,
        opening_balance_id: -1,
        opening_balance_time: date.toISOString(),
        register_id: registerID,
      };
    }
  } catch {}
};

export default {
  getRegisterId,
  checkOpeningBalance,
};
