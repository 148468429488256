import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const DataImportTour = ({ setUploadShow }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const dataImportTour = driver({
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "DataImport", value: true } }));
      setTimeout(() => {
        dataImportTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#data-import-button",
        popover: {
          title: `Data Import`,
          description: "Clicking on this button shows a file upload modal where seller can upload inventory files from the computer's internal storage.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setUploadShow(true);
            setTimeout(() => {
              dataImportTour.moveNext();
            });
          },
        },
      },
      {
        element: "#data-import-source",
        popover: {
          title: `Source POS`,
          description: "It shows options for POS source ,which data seller want to import e.g Vayapar, EPOS ,Square ,TechBazaar or Other.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setUploadShow(false);
            dataImportTour.movePrevious();
          },
        },
      },
      {
        element: "#data-import-location",
        popover: {
          title: `Location`,
          description: "It shows location against which data import request will be processed.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#data-import-file-upload",
        popover: {
          title: `File Upload`,
          description: "To select your file.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#data-import-submit",
        popover: {
          title: `Submit`,
          description: "A button to submit your request.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#data-import-cancel",
        popover: {
          title: `Cancel`,
          description: "A button to cancel this request and close the modal.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setUploadShow(false);
            dataImportTour.moveNext();
          },
        },
      },
      {
        element: "#data-import-table",
        popover: {
          title: `Data Import`,
          description: `It show a List of requested file import request  with ‘Click to show’ , ‘Download file’ buttons & their status(Done ,Pending & Cancelled).`,
          side: "right",
          align: "start",
          onPrevClick: () => {
            setUploadShow(true);
            setTimeout(() => {
              dataImportTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#message",
        popover: {
          title: `Data Import Message`,
          description: `It shows message of import request added by techbazaar admin.`,
          side: "right",
          align: "start",
        },
      },
      {
        element: "#download-file",
        popover: {
          title: `Download File`,
          description: `It downloads file of data import request.`,
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "DataImport", value: true } }));
            setTimeout(() => {
              dataImportTour.destroy();
            }, 100);
          },
        },
      },
    ],
  });
  useEffect(() => {
    dataImportTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <div></div>;
};

export default DataImportTour;
