import { Container, Row, Col, Button, Tabs, Tab, Form } from "react-bootstrap";
import SearchInput, { SearchMobleInput } from "../Components/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCircleCheck, faXmarkCircle, faGlassCheers } from "@fortawesome/free-solid-svg-icons";
import styleModal from "./../styles/Modal.module.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SearchableSelect from "../Components/SearchableSelect";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import DatePicker from "../Components/DatePicker/DatePicker";
import moment from "moment";
import PopUpModal from "../Components/Modal";
import navStyle from "./../styles/InventoryList.module.css";
import axios from "axios";
import InvoicePaymentSales from "./SalesHistory/InvoicePaymentSales/InvoicePaymentSales";
import ThermalInvoicePaymentSales from "./SalesHistory/InvoicePaymentSales/ThermalInvoicePaymentSales";
import MobileSearchAcoordian from "../Components/MobileSearchAcoordian";
import filterImg from "../assets/svg/filter.svg";
import History from "../Components/sales/History";
import Dummy from "../Components/sales/Dummy";
import TableSettings from "../Components/TableSettings";
import SaleHistoryTour from "../TourGuides/SaleHistoryTour";

const SalesHistory = () => {
  const { user } = useSelector((state) => state.auth);
  const locationFields = useSelector((state) => state.location?.location);
  const componentRef = useRef(null);
  const thermalcomponentRef = useRef(null);
  const userId = user.user_id;
  const shop_id = user.shop_id;
  const [search, setSearch] = useState("");
  const [payment, setPayment] = useState(null);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams] = useSearchParams();
  const [modalPaymentShow, setPaymentModalShow] = useState(false);
  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);
  const [emailSuccess, setEmailSuccess] = useState();
  const [emailError, setEmailError] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [printId, setPrintId] = useState();
  const [toggle, refresh] = useState(false);
  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [emailPaymentModalShow, setEmailPaymentModalShow] = useState(false);
  const [emailStatus, setEmailStatus] = useState("");
  const [columnNotToShow, setColumnNotToShow] = useState([]);
  const [reset, setReset] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [onlyWalkInCustomer, setOnlyWalkInCustomer] = useState(false);
  const [activeTab, setActiveTab] = useState("Sales History");

  const [columns, setColumns] = useState([
    { label: "customer_name", value: 1 },
    { label: "customer_email", value: 1 },
    { label: "customer_number", value: 1 },
    { label: "sales_number", value: 1 },
    { label: "created_at", value: 1 },
    { label: "base_grand_total", value: 1 },
    // { label: "received_amount", value: 1 },
    { label: "remaining", value: 1 },
    { label: "status", value: 1 },
  ]);

  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, searchParams]);

  // settings
  useEffect(() => {
    const fetchTableColumns = async () => {
      try {
        const data = await axios.get(process.env.REACT_APP_API_URL + `/settings/get-sales-history-settings/${userId}`, {
          withCredentials: true,
        });
        const res = data?.data?.sales_history_table;
        const desiredArray = [];
        for (const key in res) {
          if (res.hasOwnProperty(key)) {
            desiredArray.push({ label: key, value: res[key] });
          }
        }
        if (desiredArray.length > 0) {
          setColumns(desiredArray);
        } else {
          setColumns([...columns]);
        }
        setColumnNotToShow(desiredArray.filter((obj) => obj.value === 0).map((obj) => obj.label));
      } catch {}
    };
    fetchTableColumns();
  }, [reset, settingsModal]);
  // handle settings change
  const handleSettings = (e, label, i) => {
    setIsValidate(false);

    let values = [...columns];
    values[i].value = e.target.checked === false ? 0 : 1;
    setColumns(values);
  };

  // select all
  const selectAll = () => {
    setColumns([
      { label: "customer_name", value: 1 },
      { label: "customer_email", value: 1 },
      { label: "customer_number", value: 1 },
      { label: "sales_number", value: 1 },
      { label: "created_at", value: 1 },
      { label: "base_grand_total", value: 1 },
      { label: "received_amount", value: 1 },
      { label: "remaining", value: 1 },
      { label: "status", value: 1 },
    ]);
  };
  // Search Sales Order
  const searchSalesOrder = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearch("");
    } else {
      setSearch(e.target.value);
    }
  };
  const handlePaymentFilter = (e) => {
    if (e.value) {
      setPayment(e);
    } else {
      setPayment(null);
    }
  };

  const resetFilterHandle = (e) => {
    setPayment(null);
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
    // setSearch("");
    // setLocationId();
    // setLocationFields([]);
    // setLocationValue(defaultLocationValue);
  };
  const closeUpdatePaymentModal = () => {
    setTimeout(() => setPaymentModalShow(false), 1500);
    refresh((prev) => !prev);
  };
  const handlePaymentEmailModal = () => {
    setEmailPaymentModalShow(true);
    setTimeout(() => setEmailPaymentModalShow(false), 1500);
  };

  const handleEmailStatus = (data) => {
    setEmailStatus(data);
  };

  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };
  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setSearch("");
    setPayment(null);
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
  };
  const Filter = () => {
    setToogleMobileSearch(false);
  };

  const handleChangeStatusMobile = (e) => {
    if (e.target.value === "All") {
      setPayment({ value: null, label: e.target.value });
    } else {
      setPayment({ value: e.target.id, label: e.target.value });
    }
  };
  const SearchData = [
    {
      name: "Select Status",
      handleChange: handleChangeStatusMobile,
      value: payment,
      filter: [
        { value: "none", label: "All" },
        { value: "paid", label: "Paid" },
        { value: "pending", label: "pending" },
      ],
    },
  ];
  const handleTabs = (e) => {
    setActiveTab(e);
    resetFilterHandle();
  };

  // settings submit
  const handlesubmitSettings = async (e) => {
    e.preventDefault();

    const desiredObject = {};

    columns.forEach((obj) => {
      desiredObject[obj.label] = obj.value;
    });
    if (!Object.values(desiredObject).every((item) => item === 0)) {
      try {
        const data = await axios.patch(
          process.env.REACT_APP_API_URL + `/settings/add-sales-history-settings`,
          { user_id: userId, sales_history_table: desiredObject },
          {
            withCredentials: true,
          }
        );
        if (data) {
          setSubmitted((prev) => !prev);
          setSettingsModal(false);
        }
      } catch {}
    } else {
      setIsValidate(true);
    }
  };

  return (
    <>
      {user?.tour && !user?.tour?.SaleHistory && <SaleHistoryTour setActiveTab={setActiveTab} />}
      {
        <PopUpModal
          title={emailSuccess ? "Email Sent" : "Email Not sent."}
          icon={<FontAwesomeIcon icon={faCircleCheck} />}
          text={emailSuccess ? emailSuccess : emailError}
          type={emailSuccess ? styleModal.successBg : styleModal.errorBg}
          iconModal={emailSuccess ? styleModal.successIcon : styleModal.errorIcon}
          show={emailModalShow}
          onHide={() => setEmailModalShow(false)}
        />
      }

      <div className="d-none">
        <InvoicePaymentSales printId={printId} ref={componentRef} />
        <ThermalInvoicePaymentSales printId={printId} ref={thermalcomponentRef} />
      </div>
      {toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian SearchData={SearchData} toggleSearchclose={handleAcoordianCLose} dateName={"Select Date"} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} filter={Filter} />
      ) : (
        <Container fluid>
          {toogleMobileSearch === "search" ? (
            <div className="d-flex  mb-2 justify-content-center align-items-center">
              <div className="">
                {" "}
                <SearchMobleInput searchlogo value={search} handleChange={searchSalesOrder} />
              </div>
              <span className="ms-4" onClick={handleAcoordianCLose}>
                X
              </span>
            </div>
          ) : (
            <Row className="d-flex align-items-center ">
              <Col xs="2" lg="3" xl="2" className="d-none d-lg-inline mb-3" id="sale-history-search">
                <SearchInput searchlogo handleChange={searchSalesOrder} />
              </Col>
              <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3  "></Col>
              <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3" id="sale-history-date-filter">
                <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
              </Col>
              <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3" id="sale-history-status-filter">
                <SearchableSelect
                  placeholder="By Status"
                  searchable={false}
                  value={payment}
                  options={[
                    { value: null, label: "All" },
                    { value: "pending", label: "Pending" },
                    { value: "paid", label: "Paid" },
                  ]}
                  handleChange={handlePaymentFilter}
                />
              </Col>

              <Col xs="2" lg="3" onClick={() => handleAcoordianOpen("search")} className="d-lg-none d-inline mb-3">
                <SearchInput searchlogo bordered />
              </Col>
              <Col className="d-lg-none d-flex mb-3" xs="2" lg="2">
                <Button className="py-2" onClick={() => handleAcoordianOpen("select")} variant="outline-primary">
                  <img src={filterImg} alt="filter" />
                  <span className=" ms-2 d-none d-sm-inline">Filter</span>{" "}
                </Button>
              </Col>
              <Col className="d-none d-sm-inline d-lg-none d-md-inline" sm={2}></Col>
              <Col xs="6" lg="3" xl="2" sm="3" className="mb-3" id="sale-history-page-size">
                <SearchableSelect
                  placeholder={`Showing ${sizeOfPages}`}
                  value={`${sizeOfPages}`}
                  defaultValue={`Showing ${sizeOfPages}`}
                  searchable={false}
                  options={[
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "30", label: "30" },
                  ]}
                  handleChange={(e) => setSizeOfPages(e.value)}
                />
              </Col>
              <Col xs="2" sm="3" lg="2" xl="2" xxl="2" className="mb-3 p-0 ">
                <Link to={"/sales"}>
                  <Button className="h-100 w-100" style={{ fontSize: "0.75rem" }} size="sm" type="submit" id="sales-history-add-sales">
                    <span className="d-none d-sm-inline d-lg-inline">Add Sale</span> <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </Link>
              </Col>
            </Row>
          )}
          <div className="d-flex pt-2 gap-3 align-items-center">
            <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle} id="sale-history-reset-filter">
              <span className="d-lg-inline">Reset Filters</span>{" "}
            </Button>
            <div id="sale-history-walk-in-customer">
              <input
                type="checkbox"
                name="walk-in customer"
                checked={onlyWalkInCustomer}
                onChange={(e) => {
                  setOnlyWalkInCustomer(e.target.checked);
                }}
              />{" "}
              <label className="text-muted" for="walk-in customer">
                Only Walk-In Customer
              </label>
            </div>
          </div>
          {
            <PopUpModal
              title={emailSuccess ? "Email Sent" : "Email Not sent."}
              icon={<FontAwesomeIcon icon={emailSuccess ? faCircleCheck : faXmarkCircle} />}
              text={emailSuccess ? emailSuccess : emailError}
              type={emailSuccess ? styleModal.successBg : styleModal.errorBg}
              iconModal={emailSuccess ? styleModal.successIcon : styleModal.errorIcon}
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          }
          <Row style={{ marginBottom: "-3rem" }} className="mt-3">
            <Col>
              <Tabs defaultActiveKey="Sales History" className={`${navStyle.navtabs} `} activeKey={activeTab} onSelect={handleTabs}>
                <Tab eventKey="Sales History" title="Sales History" className="text-secondary  " id="sales-history-table">
                  <History columnNotToShow={columnNotToShow} submitted={submitted} setSettingsModal={setSettingsModal} toDate={toDate} fromDate={fromDate} payment={payment} search={search} locationFields={locationFields} sizeOfPages={sizeOfPages} onlyWalkInCustomer={onlyWalkInCustomer} />
                </Tab>

                <Tab eventKey="Archived Sales" title={`Archive`} className="text-secondary  " id="sales-archive-history-table">
                  <Dummy columnNotToShow={columnNotToShow} submitted={submitted} setSettingsModal={setSettingsModal} toDate={toDate} fromDate={fromDate} payment={payment} search={search} locationFields={locationFields} sizeOfPages={sizeOfPages} onlyWalkInCustomer={onlyWalkInCustomer} />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      )}
      {<PopUpModal icon={<FontAwesomeIcon icon={faCircleCheck} />} text={emailStatus} type={styleModal.successBg} iconModal={styleModal.successIcon} show={emailPaymentModalShow} onHide={() => setEmailPaymentModalShow(false)} />}

      <PopUpModal
        title="Customize table"
        show={settingsModal}
        onHide={() => {
          setSettingsModal(false);
        }}
      >
        <TableSettings setReset={setReset} isValidate={isValidate} handlesubmitSettings={handlesubmitSettings} selectAll={selectAll} handleSettings={handleSettings} columns={columns} show={settingsModal} close={() => setSettingsModal(false)} />
      </PopUpModal>
    </>
  );
};
export default SalesHistory;
