import React, { useEffect } from "react";
// import logo from './../../../assets/images/logo.png'
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import style1 from "../../../styles/InvoiceCard.module.css";
import style from "./../../../styles/Invoice.module.css";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import "./../../../styles/thermalPrintlayout.css";
import { dateTime } from "../../../Features/dateTime";

const ThermalInvoicePaymentSales = forwardRef((props, ref) => {
  const { user } = useSelector((state) => state.auth);
  const logo = user.logo_path;
  const shop_id = user.shop_id;
  const { isThermalLogo } = useSelector((state) => state.printerType);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [items, setItems] = useState(null);
  const [payments, setPayments] = useState(null);
  const [isIdUndifined, setIsIdUndifined] = useState(false);
  const [totalDiscount, setTotalDiscount] = useState();

  useEffect(() => {
    try {
      if (id || props.printId) {
        setIsIdUndifined(false);
        const getData = async () => {
          const res = await axios.post(process.env.REACT_APP_API_URL + `/sales/invoice-payments-sales/${id ? id : props.printId}`, { id: shop_id }, { withCredentials: true });
          if (res) {
            let arr = [];
            const products = res.data.items.map((i) => ({
              title: i.title,
              cartQuantity: i.qty_ordered,
              price: i.sale_price,
              discount: i.discount,
            }));
            const miscProducts = res.data.miscItems.map((i) => ({
              title: i.name,
              cartQuantity: i.mis_qty,
              price: i.price,
              discount: i.discount,
              description: i.description,
            }));
            arr.push(...products);
            arr.push(...miscProducts);
            setData(res.data.data[0]);
            setItems(arr);
            setPayments(res.data.payments);
          }
        };
        getData();
      } else {
        setIsIdUndifined(true);
      }
    } catch (err) {
      setIsIdUndifined(true);
    }
  }, [id, props, shop_id]);

  var invoiceDate = dateTime(data?.sold_on).toLocaleString().split(",")[0];
  var invoicetime = dateTime(data?.sold_on).toLocaleString().split(",")[1];

  useEffect(() => {
    const sum = items?.reduce((accumulator, object) => {
      return Number(accumulator) + Number(object.discount);
    }, 0);
    setTotalDiscount(sum);
  }, [items]);
  return (
    <div className={"mx-auto w-50 text-center bg-white py-3"}>
      <div ref={ref} {...props} className={"thermalInvoice text-center mx-auto"}>
        <div>
          <div>
            <h2 className="shopName m-0">{data?.shop_name}</h2>
            {isThermalLogo.value ? logo !== null && <img src={process.env.REACT_APP_URL_S3 + "/" + logo} alt="" className={`${style1.logoimg} ${style1.greyscale} ${style.logo}`} /> : ""}
            <div className="shopDetails">
              <strong>Email: </strong> {data?.owner_email}
            </div>
            <div className="shopDetails">{data?.shop_address}</div>
            <div className="shopDetails">
              {/* <strong>Mobile No: </strong> {data?.owner_whatsapp_number} */}
              Phone:{" "}
              {data?.is_contact_no_on_invoice === 1 && data?.is_alternative_no_on_invoice === 1 ? (
                <>
                  {data?.contact_number}
                  <br />
                  {data?.alternative_number}
                </>
              ) : data?.is_contact_no_on_invoice === 1 ? (
                data?.contact_number
              ) : data?.is_alternative_no_on_invoice === 1 ? (
                data?.alternative_number
              ) : (
                data?.owner_whatsapp_number
              )}
            </div>
            <div className="shopDetails text-capitalize">
              <strong>Salesperson: </strong> {user?.user_full_name}
            </div>
            <div className="shopDetails mt-2">
              <strong>Sales Order Number: </strong> {data?.sales_order_number}
            </div>
            <table className={`mx-auto mt-1 text-start thermalPrinterTable ${style?.itemsTable}`}>
              <thead
                style={{
                  borderTop: "1px solid #000",
                  borderBottom: "1px solid #000",
                }}
              >
                <th colSpan="1" style={{ border: "1px solid #000", padding: "5px" }}>
                  {invoiceDate}
                </th>
                <th
                  colSpan="4"
                  style={{
                    border: "1px solid #000",
                    padding: "5px",
                    textAlign: "end",
                  }}
                >
                  {invoicetime}
                </th>
              </thead>
              <thead
                style={{
                  borderTop: "1px solid #000",
                  borderBottom: "1px solid #000",
                }}
              >
                <th className="border-0 extraTh"></th>
              </thead>
              <thead
                style={{
                  borderTop: "1px solid #000",
                  borderBottom: "1px solid #000",
                }}
              >
                <th style={{ border: "1px solid #000", padding: "5px" }}>Description</th>
                <th style={{ border: "1px solid #000", padding: "5px" }}>Unit Price</th>
                <th style={{ border: "1px solid #000", padding: "5px" }}>QTY</th>
                <th style={{ border: "1px solid #000", padding: "5px" }}>Total</th>
              </thead>
              {items?.map((item, index) => (
                <tr key={index}>
                  <td style={{ borderTop: "1px solid", padding: "5px" }} className="d-flex flex-column">
                    {item?.title}
                  </td>
                  <td style={{ border: "1px solid #000", padding: "5px" }}>{Number(item?.price).toLocaleString("en-IN")}</td>
                  <td style={{ border: "1px solid #000", padding: "5px" }}>{item?.cartQuantity}</td>
                  <td style={{ border: "1px solid #000", padding: "5px" }}>{Number(item?.cartQuantity * item?.price).toLocaleString("en-IN")}</td>
                </tr>
              ))}
            </table>
            <div className="w-75 mx-auto mt-2">
              <Row>
                <Col xs="1" sm="2" md="2" lg="6" className="d-none d-md-block"></Col>
                <Col xs="6" sm="5" md="4" lg="3" className="text-start">
                  <p className="totals m-0"> Subtotal </p>
                </Col>
                <Col xs="6" sm="5" md="4" lg="3" className="text-end">
                  <p className="totals m-0" style={{ textAlign: "end" }}>
                    {" "}
                    {Number(data?.base_subtotal).toLocaleString("en-IN")}{" "}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs="1" sm="2" md="2" lg="6" className="d-none d-md-block"></Col>
                <Col xs="6" sm="5" md="4" lg="3" className="text-start">
                  <p className="totals m-0"> Discount </p>
                </Col>
                <Col xs="6" sm="5" md="4" lg="3" className="text-end">
                  <p className="totals m-0"> {Number(totalDiscount).toLocaleString("en-IN")} </p>
                </Col>
              </Row>
              {/* <Row>
              <Col
                  xs="1"
                  sm="2"
                  md="2"
                  lg="6"
                  className="d-none d-md-block"
                ></Col>
                <Col xs="6" sm="5" md="4" lg="3" className="text-start">
                  <p className="totals m-0"> Total </p>
                </Col>
                <Col xs="6" sm="5" md="4" lg="3" className="text-end">
                  <p className="totals m-0">
                    {" "}
                    {Number(data?.base_subtotal- Number(totalDiscount)).toLocaleString(
                      "en-IN",
                    )}{" "}
                  </p>
                </Col>

              </Row> */}
              <Row>
                <Col xs="1" sm="2" md="2" lg="6" className="d-none d-md-block"></Col>
                <Col xs="6" sm="5" md="4" lg="3" className="text-start">
                  <p className="totals m-0"> Shipping </p>
                </Col>
                <Col xs="6" sm="5" md="4" lg="3" className="text-end">
                  <p className="totals m-0 m-0">{Number(data?.base_shipping_amount).toLocaleString("en-IN")} </p>
                </Col>
              </Row>
              <Row>
                <Col xs="1" sm="2" md="2" lg="6" className="d-none d-md-block"></Col>
                <Col xs="6" sm="5" md="4" lg="3" className="text-start">
                  <p className="totals m-0 fw-bold "> Grand Total </p>
                </Col>
                <Col xs="6" sm="5" md="4" lg="3" className="text-end">
                  <p className="totals m-0"> {Number(data?.base_grand_total).toLocaleString("en-IN")} </p>
                </Col>
              </Row>
              <Row className="p-0">
                <Col xs="2" md="6" lg="6"></Col>

                <Col xs="12" md="3" lg="6" className="text-end">
                  <hr className="my-1" />
                </Col>
              </Row>
              <Row>
                <Col xs="1" sm="2" md="2" lg="6" className="d-none d-md-block"></Col>
                <Col xs="6" sm="5" md="4" lg="3" className="text-start">
                  <p className="totals m-0"> Total Received</p>
                </Col>
                <Col xs="6" sm="5" md="4" lg="3" className="text-end">
                  <p className="totals m-0"> {Number(data?.total_received).toLocaleString("en-IN")} </p>
                </Col>
              </Row>

              {data?.base_grand_total - data?.total_received > 0 && (
                <Row>
                  <Col xs="1" sm="2" md="2" lg="6" className="d-none d-md-block"></Col>
                  <Col xs="6" sm="5" md="4" lg="3" className="text-start">
                    <p className="totals m-0"> Total Due</p>
                  </Col>
                  <Col xs="6" sm="5" md="4" lg="3" className="text-end">
                    <p className="totals m-0"> {Number(data?.base_grand_total - data?.total_received).toLocaleString("en-IN")} </p>
                  </Col>
                </Row>
              )}
            </div>
            <p className={`invoiceTerms m-0 mt-1 fw-bold`}>Invoice terms</p>

            {data?.base_grand_total - data?.total_received === 0 ? (
              <>
                <p className="m-0 invoiceTerms" style={{ textAlign: "center" }}>
                  The Buyer has <b>PAID</b> the amount.
                </p>
              </>
            ) : (
              <>
                <p className="m-0 invoiceTerms" style={{ textAlign: "center" }}>
                  The Buyer still needs to pay <b>{Number(data?.base_grand_total - data?.total_received).toLocaleString("en-IN")}</b> to clear the invoice.
                </p>
              </>
            )}
            {data?.invoice_terms !== "nil" && <p className={`mt-0 mb-3 text-center invoiceTerms fst-italic`}>* {data?.invoice_terms}</p>}
            <p className={`mt-0 mb-3 text-center invoiceTerms fst-italic`}>Powered by TechBazaar POS</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ThermalInvoicePaymentSales;
