import FormCard from "./../Components/FormCard";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import style from "./../styles/Login.module.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions, currentUser } from "../Features/authSlice";
import { emptyCart, emptyLocation } from "../Features/cart/cartSlice";

const SetPassword = ({ user }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowcPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [cpasswordError, setCPasswordError] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [validUrl, setValidUrl] = useState(false);
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  //   const [email, setEmail] = useState("");

  // const { state } = useLocation();
  // useEffect(() => {
  //     const userToken = state?.token;
  //     if(userToken){
  //         const currentDate = new Date();
  //         const decodedToken = jwt_decode(userToken);
  //         setEmail(decodedToken.aud);
  //         if (decodedToken.exp * 1000 < currentDate.getTime()) {
  //             setValidUrl(false)
  //         } else {
  //             setValidUrl(true)
  //         }
  //     }else{
  //         setValidUrl(false)
  //     }
  // }, [validUrl, state?.token])

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);
    try {
      if (password.length > 5 && cpassword.length > 5 && password === cpassword) {
        const res = await axios.post(process.env.REACT_APP_API_URL + `/auth/set-seller-password`, { password, cpassword }, { withCredentials: true });
        setSuccess(res.data.message);
        const refreshToken = localStorage.getItem("refreshToken");
        dispatch(currentUser(refreshToken));
      }
    } catch (error) {
      setError(error.response.data.error?.message);
    }
  };
  const logout = () => {
    localStorage.removeItem("refreshToken");
    dispatch(authActions.logout());
    dispatch(emptyCart());
    // dispatch(emptyMiscCart());
    dispatch(emptyLocation());
    localStorage.removeItem("cartItems");
    localStorage.removeItem("cartMiscItems");
    // localStorage.removeItem("location");
    localStorage.removeItem("customer");
    navigate("/login");
  };
  useEffect(() => {
    if (!password) {
      setPasswordError("Enter password");
    } else if (password && password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
    } else {
      setPasswordError("");
    }
    if (!cpassword) {
      setCPasswordError("Enter confirm password");
    } else if (cpassword && password !== cpassword) {
      setCPasswordError("Confirm password does not match with password");
    } else {
      setCPasswordError("");
    }
  }, [password, cpassword]);

  return (
    <div className={`d-flex justify-content-center align-items-center ${style.wrapper}`}>
      <Button style={{ position: "fixed", top: "10px", right: "10px" }} onClick={logout}>
        Logout
      </Button>
      <FormCard>
        <h4 className="mt-5 mb-4 text-center fw-bold">Create Password</h4>
        {/* {validUrl ? ( */}
        <div className="text-center my-2">
          {error && <span className="text-danger p-2 text-center my-2 rounded-1">* {error}</span>}
          {success && <span className="text-success p-2 text-center my-2 rounded-1">* {success}</span>}
        </div>
        {/*  ) : (
           <div className="text-center my-2">
             {error && (
               <span className="text-danger p-2 text-center my-2 rounded-1">
                 * {error}
               </span>
             )}
             {success && (
               <span className="text-success p-2 text-center my-2 rounded-1">
                 * {success}
               </span>
             )}
             <span className="text-danger p-2 text-center my-2 rounded-1">
               * Your Link has Expired
             </span>
           </div>
         )} */}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className={`mb-4 `} controlId="formBasicEmail">
            <Form.Control name="email" value={user.user_email} disabled className="text-secondary" onChange={() => {}} />
          </Form.Group>
          <Form.Group className={`mb-4 ${style.passwordInput}`} controlId="formBasicPassword">
            <Form.Control className={`p-2 ${style.passwordValidate}`} name="password" type={showPassword ? "text" : "password"} placeholder="Enter New Password" onChange={(e) => setPassword(e.target.value)} isInvalid={validated && password.length < 6} required />
            <div className={`${style.passwordField}`}>
              <FontAwesomeIcon className="text-secondary" icon={showPassword ? faEye : faEyeSlash} onClick={() => setShowPassword((a) => !a)} />
            </div>
            <Form.Control.Feedback type="invalid">* {passwordError}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className={`mb-4 ${style.passwordInput}`} controlId="formBasicCPassword">
            <Form.Control className={`p-2 ${style.passwordValidate}`} name="cpassword" type={showcPassword ? "text" : "password"} placeholder="Confirm Password" onChange={(e) => setCPassword(e.target.value)} isInvalid={validated && cpassword && password !== cpassword} required />
            <div className={`${style.passwordField}`}>
              <FontAwesomeIcon className="text-secondary" icon={showcPassword ? faEye : faEyeSlash} onClick={() => setShowcPassword((a) => !a)} />
            </div>
            <Form.Control.Feedback type="invalid">* {cpasswordError}</Form.Control.Feedback>
          </Form.Group>
          <div className="mb-4">
            <Button className="w-100" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </FormCard>
    </div>
  );
};

export default SetPassword;
