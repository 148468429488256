import { faArrowLeft, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form } from "react-bootstrap";
import style from "../../styles/Login.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../Features/authSlice";
import { Link } from "react-router-dom";

const LoginPassword = ({ userInput, setLoading, setInputType }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const SubmitHandle = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    const userData = { user_email: userInput, password };
    try {
      if (userInput && password.length > 0) {
        setLoading(true);
        dispatch(login(userData));
      }
    } catch {
      setLoading(false);
    }
  };
  return (
    <Form noValidate onSubmit={SubmitHandle}>
      <Form.Group className={`mb-4 ${style.passwordInput}`} md="4" controlId="validationCustomPassword">
        <Form.Control className="p-2" type={showPassword ? "text" : "password"} name="password" placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} required />
        <div className={`${style.passwordField}`}>
          <FontAwesomeIcon className="text-secondary" icon={showPassword ? faEye : faEyeSlash} onClick={() => setShowPassword((a) => !a)} />
        </div>
        <Form.Control.Feedback type="invalid">* Please enter password</Form.Control.Feedback>
      </Form.Group>
      <div className="d-flex justify-content-between mb-4">
        <div className={`link-main cursor-pointer`} onClick={() => setInputType("input")}>
          <FontAwesomeIcon className="link mx-2" icon={faArrowLeft} />
          <span className="link">Go Back</span>
        </div>
        <Link className="link" to="/forget-password" state={{ email: userInput }}>
          Forget Password?
        </Link>
      </div>
      <div className="mb-4">
        <Button className="w-100" disabled={!(password.length > 0)} type="submit">
          Login
        </Button>
      </div>
    </Form>
  );
};

export default LoginPassword;
