import React from "react";
// import * from 'react-web'
import Slider from "react-slick";

import Theme5 from "../../assets/images/Themes/Theme 5.png";
import styles from "../../styles/Customize.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SlideshowLightbox } from "lightbox.js-react";
import { Button } from "react-bootstrap";
import { useState } from "react";
import ApplyThemeModal from "./ApplyThemeModal";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight, faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import LoaderPage from "../../Components/loaders/loaderPage";
function ThemesView({ userSelectedTheme, setTrigger }) {
  const [applyModal, setApplyModal] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [themes, setThemes] = useState();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [mainSlider, setMainSlider] = useState(null);
  const [edit, setEdit] = useState(false);
  let [isView, setIsView] = useState(false);
  const sliderRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const { user } = useSelector((state) => state.auth);

  const fetchthemes = async () => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/themes/" + user.shop_id, {
        withCredentials: true,
      })
      .then((response) => {
        setThemes(response.data);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchthemes();
  }, []);

  const fields = [
    {
      value: "Theme 5",
      Theme: Theme5,
      topSlider: true,
      topSliderLimit: 1,
      des: {
        name: "Classic",
        list: [
          "This is Default Theme with Preset Shop Banner, No Sections and Single Page",
          // "Default theme",
          // "Preset Shop Banner",
          // "No Sections",
          // "Single Page",
        ],
      },
    },
    ...(themes?.includedThemes || []).map((theme) => ({
      value: theme.theme_name,
      Theme: process.env.REACT_APP_URL_S3 + "/" + theme.thumbnail,
      topCategory: theme.isTopCategories,
      topSlider: theme?.isPrimaryBanner,
      noOfSections: theme?.num_sections,
      bottomSlider: theme?.isSecondaryBanner,
      MaxpProducsPerSections: theme?.max_products_per_section,
      PrimaryBannerLimit: theme?.primaryBannerLimit,
      SecondaryBannerLimit: theme?.secondaryBannerLimit,
      des: {
        name: theme.theme_name,
        list: theme?.description,
      },
    })),

    ...(themes?.excludedThemes || []).map((theme) => ({
      value: theme.theme_name,
      Theme: process.env.REACT_APP_URL_S3 + "/" + theme.thumbnail,
      topCategory: theme.isTopCategories,
      topSlider: theme?.isPrimaryBanner,
      noOfSections: theme?.num_sections,
      bottomSlider: theme?.isSecondaryBanner,
      MaxpProducsPerSections: theme?.max_products_per_section,
      PrimaryBannerLimit: theme?.primaryBannerLimit,
      excludedThemes: true,
      SecondaryBannerLimit: theme?.secondaryBannerLimit,
      des: {
        name: theme.theme_name,
        list: theme?.description,
      },
    })),
  ];

  // const fields = [
  //   { value: "Theme 5", Theme: Theme5, topSlider: true, topSliderLimit: 1, des:{
  //     name: "Classic",
  //     list: [
  //       "Default theme",
  //       "Preset Shop Banner",
  //       "No Sections",
  //       "Single Page"
  //     ]
  //   }, },

  //   themes?.map(theme=>{
  //     value: theme.theme_name, Theme: process.env.REACT_APP_URL_S3+"/"+themes?.thumbnail, topCategory: true, topSlider: true, noOfSections: 2, bottomSlider: true ,des:{
  //       name: "Theme 1",
  //       list: [
  //         "Multi Page",
  //         "Top Categories",
  //         "2x Sliding Banners",
  //         "2x Product Showcase Sections",
  //         "8-12 Products in Main Section",
  //         "6+ Products in Bottom Section"
  //       ]
  //     },

  //   })]

  //   { value: "Theme 1", Theme: Theme1, topCategory: true, topSlider: true, noOfSections: 2, bottomSlider: true ,des:{
  //     name: "Theme 1",
  //     list: [
  //       "Multi Page",
  //       "Top Categories",
  //       "2x Sliding Banners",
  //       "2x Product Showcase Sections",
  //       "8-12 Products in Main Section",
  //       "6+ Products in Bottom Section"
  //     ]
  //   },},
  //   { value: "Theme 2", Theme: Theme2 , nofSections:2 , topSlider:true, noOfSections:2,bottomSlider:true,des:{
  //     name: "Theme 2",
  //     list: [
  //       "Multi Page",
  //       "2x Sliding Banners",
  //       "2x Product Showcase Sections",
  //       "8-12 Products in Main Section",
  //       "3+ Products in Bottom Section"
  //     ]
  //   } },
  //   { value: "Theme 3", Theme: Theme3, nofSections: 3, topCategory: true, bottomSlider: true, bottomSliderLimit: 2, topSlider: true, topSliderLimit: 1, noOfSections: 3, des:{
  //     name: "Theme 3",
  //     list: [
  //       "Multi Page",
  //       "Top Categories",
  //       "1x Sliding Banners",
  //       "2x Bottom Banners",
  //       "3x Product Showcase Sections",
  //       "6+ Products in Each Section"
  //     ]
  //   }, },
  //   { value: "Theme 4", Theme: Theme4,  topSlider: true, noOfSections: 2, des: {
  //     name: "Theme 4",
  //     list: [
  //       "Multi Page",
  //       "1x Sliding Banners",
  //       "4x Product Showcase Sections"
  //     ]
  //   } },
  // ];

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style, display: "block", background: "red" }} onClick={onClick} />;
  }

  const settings = {
    // className: "center",
    centerMode: true,
    // infinite: true,
    centerPadding: "0px",
    slidesToShow: themes?.includedThemes.length === 0 && themes?.excludedThemes.length === 0 ? 1 : 3,
    speed: 500,
    arrows: false,
    focusOnSelect: true,

    prevArrow: <SampleNextArrow />,
    nextArrow: <SampleNextArrow />,
  };
  useEffect(() => {}, [selectedTheme, userSelectedTheme]);

  useEffect(() => {
    setTrigger((prev) => !prev);
  }, [applyModal]);
  return isLoading ? (
    <LoaderPage />
  ) : (
    <div>
      {/* <h1 className="text-center" >Themes</h1> */}
      <div className="d-flex justify-content-center">
        <SlideshowLightbox
          currentIndex={selectedImageIndex}
          images={fields.map(({ Theme }) => ({ source: Theme.Theme }))}
          showThumbnails={true}
          open={isView}
          lightboxIdentifier="lbox1"
          // startingSlideIndex={activeSlideIndex}
          onClose={() => {
            setIsView(false);
          }}
        ></SlideshowLightbox>

        <div className={` position-relative text-center   ${styles.sliderDiv}`}>
          <div
            style={{
              height: "300px",
              width: "100%",
              position: "absolute",
              top: "18%",
            }}
            className=" d-flex align-items-center justify-content-between"
          >
            <div
              onClick={() => {
                sliderRef?.current?.slickPrev();
              }}
              style={{
                zIndex: "9999",
                height: "50px",
                width: "50px",
                borderRadius: "50%",
                backgroundColor: "white",
                boxShadow: "0 0 10px 0 rgb(0 0 0 / 20%)",
              }}
              className="d-flex align-items-center cursor-pointer justify-content-center"
            >
              {" "}
              <FontAwesomeIcon style={{ fontSize: "25px" }} icon={faArrowAltCircleLeft} />{" "}
            </div>
            <div
              onClick={() => sliderRef?.current?.slickNext()}
              style={{
                zIndex: "9999",
                height: "50px",
                width: "50px",
                borderRadius: "50%",
                backgroundColor: "white",
                boxShadow: "0 0 10px 0 rgb(0 0 0 / 20%)",
              }}
              className="d-flex align-items-center cursor-pointer justify-content-center"
            >
              {" "}
              <FontAwesomeIcon style={{ fontSize: "25px" }} icon={faArrowAltCircleRight} />{" "}
            </div>
          </div>
          {themes?.includedThemes.length === 0 && themes?.excludedThemes.length === 0 ? (
            fields.map((field, index) => (
              <div
                key={index}
                style={{
                  backgroundImage: `url(/src/assets/images/Themes/Theme 1.png)`,
                }}
                className={`${styles.mainDiv} ${userSelectedTheme?.final_configuration?.themeType === field?.value ? styles.selectedOne : ""} ${
                  !userSelectedTheme?.final_configuration && field.value === "Theme 5" ? styles.selectedOne : ""
                } d-flex position-relative justify-content-center slick-center`}
              >
                <img src={field.Theme} alt="placeholder" />
                <div className={`d-flex flex-column w-100 mx-2 justify-content-center position-absolute ${styles.applyBtnDiv}`}>
                  <h4 className="text-white">{field?.des?.name}</h4>
                  <ul className="text-white text-start">
                    {/* {field?.des?.list?.map((list, index) => (
                      <li key={index} className="p-1 text-break" >
                        {list}
                      </li>
                    ))} */}

                    {field.topSlider === 1 && <li className="mb-2">Number of Primary Banners :{field?.PrimaryBannerLimit}</li>}
                    {field.bottomSlider === 1 && <li className="mb-2">Number of Secondary Banners :{field?.SecondaryBannerLimit}</li>}
                    {field.noOfSections > 0 && <li className="mb-2">Number of Sections :{field?.noOfSections}</li>}
                    {field.maxProductsPerSec > 0 && <li className="mb-2">Number Products per Section :{field?.maxProductsPerSec}</li>}
                    {field.topCategory > 0 && <li className="mb-2">Top Categories</li>}
                  </ul>
                  {/* {field?.des?.map((list, index) => (
                       
                      ))} */}
                  <p className="text-white text-ceter text-break  ">{field?.des?.list}</p>
                  <div className="text-center">
                    {field.value !== "Theme 5" ? (
                      <>
                        {userSelectedTheme?.final_configuration?.themeType === field?.value ? (
                          <Button
                            onClick={() => {
                              setSelectedTheme(field);
                              setApplyModal(true);
                              setEdit(true);
                            }}
                            variant="success"
                            className="mb-3"
                          >
                            Edit
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              setSelectedTheme(field);
                              setApplyModal(true);
                            }}
                            style={
                              field.excludedThemes
                                ? {
                                    background: "rgb(52,47,73)",
                                    pointerEvents: "none",
                                  }
                                : {}
                            }
                            className="mb-3"
                          >
                            {field.excludedThemes === true ? "Available in other Plan" : "Apply"}
                          </Button>
                        )}
                      </>
                    ) : userSelectedTheme?.final_configuration?.themeType === "Theme 5" || userSelectedTheme === null ? (
                      <Button variant="success">Applied</Button>
                    ) : (
                      <Button
                        onClick={() => {
                          setSelectedTheme(field);
                          setApplyModal(true);
                        }}
                        className="mb-3"
                      >
                        Apply
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <Slider ref={sliderRef} {...settings}>
              {fields.map((field, index) => (
                <div
                  key={index}
                  style={{
                    backgroundImage: `url(/src/assets/images/Themes/Theme 1.png)`,
                  }}
                  className={`${styles.mainDiv} ${userSelectedTheme?.final_configuration?.themeType === field?.value ? styles.selectedOne : ""} ${
                    !userSelectedTheme?.final_configuration && field.value === "Theme 5" ? styles.selectedOne : ""
                  } d-flex position-relative justify-content-center slick-center`}
                >
                  <img src={field.Theme} alt="placeholder" />
                  <div className={`d-flex flex-column w-100 mx-2 justify-content-center position-absolute ${styles.applyBtnDiv}`}>
                    <h4 className="text-white">{field?.des?.name}</h4>
                    <ul className="text-white text-start ">
                      {/* {field?.des?.list?.map((list, index) => (
                        <li key={index} className="p-1 text-break">
                          {list}
                        </li>
                      ))} */}

                      {field.topSlider === 1 && <li className="mb-2">Number of Primary Banners :{field?.PrimaryBannerLimit}</li>}
                      {field.bottomSlider === 1 && <li className="mb-2">Number of Secondary Banners :{field?.SecondaryBannerLimit}</li>}
                      {field.noOfSections > 0 && <li className="mb-2">Number of Sections :{field?.noOfSections}</li>}
                      {field.maxProductsPerSec > 0 && <li className="mb-2">Number Products per Section :{field?.maxProductsPerSec}</li>}
                      {field.topCategory > 0 && <li className="mb-2">Top Categories</li>}
                    </ul>

                    {/* {field?.des?.map((list, index) => (
                      
                      ))} */}
                    <p className="text-white text-ceter text-break  ">{field?.des?.list}</p>

                    <div className="text-center">
                      {field.value !== "Theme 5" ? (
                        <>
                          {userSelectedTheme?.final_configuration?.themeType === field?.value ? (
                            <Button
                              onClick={() => {
                                setSelectedTheme(field);
                                setApplyModal(true);
                                setEdit(true);
                              }}
                              variant="success"
                              className="mb-3"
                            >
                              Edit
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                setSelectedTheme(field);
                                setApplyModal(true);
                              }}
                              style={
                                field.excludedThemes
                                  ? {
                                      background: "rgb(52,47,73)",
                                      pointerEvents: "none",
                                    }
                                  : {}
                              }
                              className="mb-3"
                            >
                              {field.excludedThemes === true ? "Available in other Plan" : "Apply"}
                            </Button>
                          )}
                        </>
                      ) : userSelectedTheme?.final_configuration?.themeType === "Theme 5" || userSelectedTheme === null ? (
                        <Button
                          onClick={() => {
                            setSelectedTheme(field);
                            setApplyModal(true);
                            setEdit(true);
                          }}
                          variant="success"
                          className="mb-3"
                        >
                          Edit
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            setSelectedTheme(field);
                            setApplyModal(true);
                          }}
                          className="mb-3"
                        >
                          Apply
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          )}

          <ApplyThemeModal fields={fields} setTrigger={setTrigger} setEdit={setEdit} edit={edit} userSelectedTheme={userSelectedTheme} selectedTheme={selectedTheme} setApplyModal={setApplyModal} applyModal={applyModal} />
        </div>
      </div>
    </div>
  );
}

export default ThemesView;
