var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
];
var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
];

export function inWords(num) {
    if (num > 0) {


        if ((num = num?.toString()).length > 9) return 'overflow';
        let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return; var str = '';
        str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
        str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
        str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
        str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
        str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
        return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    } else if (num === 0) {
        return "Zero"
    } else {
        if ((num = Math.abs(num)?.toString()).length > 9) return 'overflow';
        let n = ('000000000' + Math.abs(num)).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return; var str = '';
        str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
        str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
        str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
        str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
        str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
        return "Negative " + str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

    }
}

export function moneyToMillions (labelValue) 
                    {
                          // Nine Zeroes for Billions
                          return Math.abs(Number(labelValue)) >= 1.0e+9

                          ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
                          // Six Zeroes for Millions 
                          : Math.abs(Number(labelValue)) >= 1.0e+6
                      
                          ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
                          // Three Zeroes for Thousands
                          : Math.abs(Number(labelValue)) >= 1.0e+3
                      
                          ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"
                      
                          : Math.abs(Number(labelValue));
                   }