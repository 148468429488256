import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import style from "../../styles/Sales.module.css";
import editpencil from "../../assets/svg/editpencil.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactSelect from "../../Components/ReactSelect";
import PopUpModal from "../../Components/Modal";
const PaymentOnSell = ({ show, onHide, data }) => {
  const [isDisabled, setDisabled] = useState(true);
  const [salesComment, setSalesComment] = useState("");
  const [generateInvoice, setGenerateInvoice] = useState(false);
  const [receivePayment, setReceivePayment] = useState();
  const [payableAmount, setPayableAmount] = useState();
  const [isPayableAmountAvailable, setIsPayableAmountAvailable] = useState(false);
  const [paymentType, setPyamentType] = useState("Cash");
  const [validated, setValidated] = useState(false);
  const [errorPaidAmount, setPaidTotalAmount] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [success, setSuccess] = useState("");
  const [sendSms, setSendSms] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [bank, setBank] = useState(null);
  const [error, setError] = useState("");
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const shop_id = user.shop_id;
  const user_id = user.user_id;
  const location = useSelector((state) => state.location.location);
  const close = (action = true) => {
    setIsPayableAmountAvailable(false);
    setPayableAmount("");
    setReceivePayment("");
    setValidated(false);
    setError("");
    setPaidTotalAmount(false);
    setBank(null);
    setPyamentType("Cash");
    setSalesComment("");
    setDisabled(true);
    setSendSms(false);
    setGenerateInvoice(false);
    setSendEmail(false);
    setIsClicked(false);

    if (action) {
      onHide();
    } else {
      onHide(true);
    }
  };
  useEffect(() => {
    if (data.effective_price) {
      setPayableAmount(data.effective_price * data.quantity);
      setIsPayableAmountAvailable(true);
    } else {
      setIsPayableAmountAvailable(false);
    }
  }, [data.effective_price]);

  useEffect(() => {
    setReceivePayment(payableAmount);
  }, [payableAmount]);
  const handlePayment = (e) => {
    if (Number(e.target.value) <= Number(payableAmount) && e.target.value >= 0) {
      setReceivePayment(e.target.value);
      setPaidTotalAmount(false);
    } else {
      setReceivePayment("");
      setPaidTotalAmount(true);
    }
  };
  const handlePayableAmount = (e) => {
    if (Number(e.target.value) > 0) {
      setPayableAmount(e.target.value);
    } else {
      setPayableAmount("");
    }
  };
  const loadBanksOptions = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + `/shops/banks/${shop_id}`, {
          withCredentials: true,
        })
        .then((banksRes) => {
          const banks = banksRes.data.map((bank) => ({
            value: bank,
            label: (
              <>
                <span style={{ fontSize: "12px" }}>
                  Bank Name: <strong className="fs-12">{bank?.bank_name}</strong>
                </span>
                <br />
                <span style={{ fontSize: "12px" }}>Account Title: {bank?.account_title}</span>
                <br />
                <span className="d-block" style={{ fontSize: "12px" }}>
                  Account Number:{bank?.account_number}
                </span>
              </>
            ),
            name: bank.bank_name,
          }));
          resolve(banks);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const handleChangeBank = (e) => {
    setBank({ value: e.value, label: e.name });
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `/subscriptions/subscription-details/${shop_id}`, { withCredentials: true })
      .then((res) => {
        setSubscriptionDetails(res.data?.subscription[0]);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, []);

  const handlePaymentType = (e) => {
    setPyamentType(e.target.value);
  };
  const updateReceivePayment = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);

    try {
      if (paymentType === "Cash" ? isRegisterOpen : true) {
        setError("");
        if (Number(receivePayment) >= 0 && (paymentType === "Card" || paymentType === "Transfer") ? (paymentType === "Card" || paymentType === "Transfer") && bank?.label?.length : true) {
          setIsClicked(true);
          const formdata = {
            payment: {
              receive_amount: Number(receivePayment),
              payable_amount: Number(payableAmount),
              comment: salesComment,
              bank_id: paymentType === "Cash" ? -1 : bank.value.bank_id,
              payment_type: paymentType,
            },
            status: "Done",
            sms: sendSms,
            email: sendEmail,
            isInvoice: generateInvoice,
          };
          const res = await axios.patch(process.env.REACT_APP_API_URL + `/inventory/update-reservation-status/${data.id}`, formdata, { withCredentials: true });
          if (res) {
            toast.success("The reserved Product has been sell. ", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 3000,
              pauseOnHover: false,
              closeButton: true,
              className: "toastMsg",
            });
            if (generateInvoice) {
              window.open(`/invoice/` + res.data.salesOrderId, "_blank", "noopener,noreferrer");
            }

            close();
          }
        }
      } else {
        setError("Register is not set!");
      }
    } catch (error) {
      setSuccess("");
      setIsClicked(false);
      console.error(error?.response?.data);
    }
  };

  // Opening Balances For checks
  async function getOpeningBalances(id) {
    try {
      const openingBalanceRespponse = await axios.get(process.env.REACT_APP_API_URL + "/location/opening-balances/" + id, { withCredentials: true });
      if (openingBalanceRespponse.data.code === 200) {
        setIsRegisterOpen(openingBalanceRespponse.data.success.data.filter((bal) => bal.location_id === location.value)[0]?.isRegisterOpen);
      }
    } catch (err) {}
  }
  useEffect(() => {
    if (user_id) {
      getOpeningBalances(user_id);
    }
  }, [user_id, show]);

  return (
    <PopUpModal title="Payment" show={show} onHide={() => close(false)}>
      <Form onSubmit={updateReceivePayment} noValidate validated={validated}>
        {success && <p className="text-success text-center">{success}</p>}
        <div className="text-center">{error && <span className="text-danger p-2 text-center my-2 rounded-1">*{error}</span>}</div>
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">Payment Type *</Form.Label>
          <Row>
            <Col md="3">
              <Form.Check name="paymentType" type="radio" label="Cash" value="Cash" checked={paymentType === "Cash"} onChange={handlePaymentType} />
            </Col>
            <Col md="6">
              <Form.Check name="paymentType" type="radio" label="Debit/Credit Card" value="Card" checked={paymentType === "Card"} onChange={handlePaymentType} />
            </Col>
            <Col md="3">
              <Form.Check name="paymentType" type="radio" label="Transfer" value="Transfer" checked={paymentType === "Transfer"} onChange={handlePaymentType} />
            </Col>
          </Row>
        </Form.Group>
        <hr className={`${style.borderedHr}`} />
        <Form.Group className="mb-3 mt-3">
          <Form.Label className="mb-1">Payable Amount</Form.Label>

          <Form.Control
            className="p-2"
            min={1}
            required
            type="text"
            placeholder="Enter Amount"
            onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
            disabled={isPayableAmountAvailable}
            value={payableAmount}
            isInvalid={!(parseInt(payableAmount) > 0) && payableAmount?.length}
            onChange={handlePayableAmount}
          />
          <Form.Control.Feedback type="invalid">Payable Amount is required</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 mt-3">
          <Form.Label className="mb-1">Receive Amount</Form.Label>

          <div className="position-relative">
            <Form.Control
              className="p-2"
              min={1}
              required
              type="text"
              placeholder="Enter Amount"
              onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
              disabled={isDisabled} // add payable amount check
              value={receivePayment}
              isInvalid={!(parseInt(receivePayment) > 0) && receivePayment?.length}
              onChange={handlePayment}
            />
            <div className="position-absolute w-100 d-flex justify-flex-end">
              <img src={editpencil} alt="edit" className={`position-absolute`} style={{ bottom: "13px", right: "0.75rem", cursor: "pointer" }} onClick={() => setDisabled(false)} />
            </div>
            {errorPaidAmount && (
              <div className="alert alert-warning" role="alert">
                Receive Amount should be less then or equal to Remaining Amount.
              </div>
            )}
          </div>
        </Form.Group>
        {paymentType === "Cash" ? (
          ""
        ) : (
          <>
            <Form.Group className="w-100" controlId="formBasicShopName">
              <Form.Label className="mb-1">
                Select Bank <span className="text-danger">*</span>
              </Form.Label>
              <ReactSelect placeholder="Select" value={bank} options={loadBanksOptions} handleChange={handleChangeBank} searchable={false} />
              <Form.Control value={bank} onChange={() => {}} hidden required />
              <Form.Control.Feedback type="invalid">Please Select Bank.</Form.Control.Feedback>
            </Form.Group>
            <div>
              <Card className="mb-4 border-0 mt-2 bg-white">
                <Row style={{ background: "#F5F6FA" }} className="">
                  <Card.Body>
                    {bank ? (
                      <Row>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex gap-2" lg="10" xs="11">
                            <div className="d-flex flex-column">
                              <span style={{ fontSize: "12px" }}>Bank Name: {bank?.value?.bank_name}</span>
                              <span style={{ fontSize: "12px" }}>Account Title: {bank?.value?.account_title}</span>
                              <span className="d-block" style={{ fontSize: "12px" }}>
                                Account Number: {bank?.value?.account_number}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Row>
                    ) : (
                      <Row className="text-center text-secondary p-1">
                        <span className="ms-1">No Bank Added.</span>
                      </Row>
                    )}
                  </Card.Body>
                </Row>
              </Card>
            </div>
          </>
        )}
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label className="mb-1">Comments</Form.Label>
          <Form.Control placeholder="e.g. 10 days warranty" as="textarea" maxLength={"200"} rows={3} onChange={(e) => setSalesComment(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check className="py-1" name="paymentType" type="checkbox" label="Generate Invoice" checked={generateInvoice} onChange={(e) => setGenerateInvoice(e.target.checked)} />
          <div className="d-flex align-items-baseline">
            <Form.Check className="py-1" name="paymentType" type="checkbox" label="Send SMS" checked={sendSms} onChange={(e) => setSendSms(e.target.checked)} disabled={subscriptionDetails.consumed_sms >= subscriptionDetails.sms_limit ? true : false} />
            {subscriptionDetails.consumed_sms >= subscriptionDetails.sms_limit ? <span className="ms-4 text-danger">Sms limit exceeded</span> : ""}
          </div>

          <div className="d-flex align-items-baseline">
            <Form.Check className="py-1" name="paymentType" type="checkbox" label="Send Email" checked={sendEmail} disabled={subscriptionDetails.consumed_emails < subscriptionDetails.emails_limit ? false : true} onChange={(e) => setSendEmail(e.target.checked)} />

            {subscriptionDetails.consumed_emails >= subscriptionDetails.emails_limit ? <span className="ms-4 text-danger">Emails limit exceeded</span> : ""}
          </div>
        </Form.Group>

        <Button disabled={isClicked} className="w-100" type="submit">
          Confirm
        </Button>
      </Form>
    </PopUpModal>
  );
};

export default PaymentOnSell;
