import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import Csv from "../../csv/Csv";
import CustomizeTableview from "../../TableViews/CustomizeTableview";
import PaginationBar from "../../PaginationBar";
import Sttyle from "../../../styles/ProgressBar.module.css";
import { requestDateTime } from "../../../Features/dateTime";

function SalesReportTab({
  filter,
  shopId,
  fromDate,
  toDate,
  location,
  category,
  customer,
  salesperson,
  paymentType,
  product,
  activeTab

}) {
  let [items, setItems] = useState([]);
  let [isLoading, setIsLoading] = useState(true);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);

  const changePage = (page) => {
    setCurrentPage(page);
  };

  let filterObject = {
    location: location?.value,
    item: product?.value,
    ...filter,
    dates: { from: requestDateTime(fromDate), to: requestDateTime(toDate) },
    category: category?.value,
    customer: customer?.value,
    salesperson: salesperson?.value,
    paymentType: paymentType?.value,
  };
  useEffect(() => {
    try {
      const getData = async () => {
        const res = await axios.get(
          process.env.REACT_APP_API_URL +
            `/reports/sales-report/${shopId}?page=${currentPage}&size=10`,
          {
            withCredentials: true,
            headers: { filters: JSON.stringify(filterObject) },
          },
        );
        if (res.data.code === 200) {
          const data = res.data.success.data.salesReport.map(item=>{
            if(activeTab==="By-Product"){

            
            return   {
              stock_no: item?.stock_no,
              product_name: item?.product_name,
              no_of_items_Sold: item?.qty,
              sales_value: item?.sales_price,
              COGS: item?.COGS,
              profit: item?.profit
          }
        }else if(activeTab === "By-Category"){
          return{
            category_name: item.category_name,
            no_of_items_Sold: item.qty,
            sales_value: item.sales_price
        }
      }
      else if(activeTab === "By-Location"){
        return  {
          location: item.location,
          no_of_items_Sold: item.qty,
          sales_value: item.sales_price
      }
      }
      else if(activeTab === "By-Customer"){
        return item
      }
      else if(activeTab === "By-Salesperson"){
        return  {
          user_full_name: item.user_full_name,
          no_of_items_Sold: item.qty,
          sales_value: item.sales_price
      }
      
      } else if(activeTab === "By-Bank/Cash/Credit"){
        return  {
         payment_type: item.payment_type,
         No_of_Payments_incl_Partial_Payments: item.qty,
          sales_value: item.sales_price
      }
      } else{
        return item
      }
          });
          setItems(data);
        } else {
          setItems([]);
        }
        setIsLoading(false);
      };
      const getNoOfPages = async () => {
        const res = await axios.get(
          process.env.REACT_APP_API_URL +
            `/reports/sales-report/no-of-pages/${shopId}?size=10`,
          {
            withCredentials: true,
            headers: { filters: JSON.stringify(filterObject) },
          },
        );
        setNoOfPages(res.data.success.no_of_pages);
        setCount(res.data.success.no_of_items);
        setIsLoading(false);
      };
      if (location?.value ) {
        setIsLoading(true);
        getNoOfPages();
        getData();
      }
    } catch (error) {
      setItems([]);
      // dfds
      setIsLoading(false);
      throw new Error(error.response.data);
    }
  }, [
    filter,
    shopId,
    fromDate,
    toDate,
    location,
    category,
    customer,
    paymentType,
    salesperson,
    product,
    currentPage,
  ]);
  useEffect(() => {
    setCurrentPage(1);
  }, [
    shopId,
    fromDate,
    toDate,
    location,
    category,
    customer,
    paymentType,
    salesperson,
    product,
  ]);

  return (
    <>
      {isLoading ? (
        <div className={Sttyle.statusBar}></div>
      ) : items.length === 0 ? (
        <Container
          fluid
          className="d-flex justify-content-center align-items-center"
          style={{ height: "40vh" }}
        >
          <div className="text-center">
            {/* <img height="45px" width="45px" alt="search" className="mb-3" /> */}
            <h5 className="text-dark">No result found!</h5>
            <p className="text-secondary">0 result.</p>
            <h5>Thanks for visiting!</h5>
          </div>
        </Container>
      ) : (
        <>
          <CustomizeTableview
            data={items}
            actions={false}
            columnNotShow={["id"]}
          />
          <Row className="mt-5 align-items-center">
            <Col className="d-none d-sm-block" sm="2" md="3" lg="3">
              <span className="text-secondary fs-6">{count} {count>1?"results":"result"}</span>
            </Col>
            <Col xs="12" sm="7" md="6" lg="6">
              <PaginationBar
                currentPage={currentPage}
                noOfPages={noOfPages}
                changePage={changePage}
              />
            </Col>
            <Col
              className="d-sm-none d-flex align-items-center mt-3"
              xs="6"
              lg="3"
            >
              <span className="text-secondary fs-6">{count} {count>1?"results":"result"} </span>
            </Col>
            {/* <Col xs="6" sm="3" md="3" lg="3" className="text-end pe-2 px-0">
              <Csv
                filename={`${
                  "Inventory " +
                  " " +
                  new Date().toLocaleDateString("en-US", {
                    timeZone: "Asia/Karachi",
                  })
                }`}
                api={
                  // requestAllHistory
                  () => {
                    return new Promise((resolve, reject) => {
                      resolve();
                    });
                  }
                }
                format={(data) => {
                  const {
                    id,
                    img0,
                    category_id,
                    created_at,
                    updated_at,
                    ...temp
                  } = data;
                  const newObj = {
                    ...temp,
                    created_at: created_at.split("T")[0],
                    updated_at:
                      updated_at === null ? null : updated_at.split("T")[0],
                  };
                  return newObj;
                }}
                error={(err) => {
                  const tempErr = err.response.data.error;
                  if (
                    tempErr.status === 404 &&
                    tempErr.message === "No Record Found"
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }}
              />
            </Col> */}
          </Row>
        </>
      )}
    </>
  );
}

export default SalesReportTab;
