import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import customloader from "./../../../assets/images/RollingLoader.gif";
import RefundItems from "./RefundItems";
import Select from "../../../Components/SearchableSelect";
import styles from "../../../styles/Refund.module.css";

import ReactSelect from "../../../Components/ReactSelect";
import { useSelector } from "react-redux";
import { setLocation } from "../../../Features/locationSlice";
import { useDispatch } from "react-redux";
import OpeningRegister from "../../../Components/Register/OpeningRegister";
// import style from "./../styles/Sales.module.css";

const AddRefund = ({ id, openingBalances, close }) => {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location?.location);
  const register = useSelector((state) => state.location?.register);
  const registerID = register?.registerId;
  const [validated, setValidated] = useState(false);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let [refunditems, setRefundItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [refundTotal, setRefundTotal] = useState();
  const [negativeItem, setNegativeItme] = useState([]);
  const [paymentType, setPaymentType] = useState("");
  const [registerClosedAlert, setRegisterClosedAlert] = useState(false);
  // const [location, setLocation] = useState(-1);

  const [bank, setBank] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const [cashInHand, setCashInHand] = useState();
  const [openingBalance, setOpeningBalance] = useState();
  const [openRegisterModal, setOpenRegisterModal] = useState(false);

  const [isValidate, setIsValidated] = useState(false);

  useEffect(() => {
    // setLoading(true);
    const cashInHand = () => {
      axios
        .post(
          process.env.REACT_APP_API_URL + `/reports/cash-in-hand/${user?.user_id}`,
          {
            dates: {
              from: register?.openingBalance?.opening_balance_time.split(".")[0].replace("T", " "),
              to: register?.openingBalance?.closing_balance_time ? register?.openingBalance?.closing_balance_time.split(".")[0].replace("T", " ") : null,
            },
            locationFields: location,
            openingBalance: register?.openingBalance?.opening_balance_id,
          },
          { withCredentials: true }
        )
        .then((res) => {
          let n = res?.data?.length;
          setCashInHand(res.data[n - 1]);
          // setIsLoading(false);
          // setLoading(false);
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    };
    if (Object.keys(location).length > 0 && register?.openingBalance) cashInHand();
  }, [location, register?.openingBalance]);

  const handleItems = (i) => {
    setRefundItems([...refunditems, i]);
  };

  const removeItem = (i) => {
    const filteredItem = refunditems.filter((rItem) => rItem.id !== i.id);
    setRefundItems(filteredItem);
  };
  const loadBanksOptions = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + `/shops/banks/${shop_id}`, {
          withCredentials: true,
        })
        .then((banksRes) => {
          const banks = banksRes.data.map((bank) => ({
            value: bank,
            label: (
              <>
                <span style={{ fontSize: "12px" }}>
                  Bank Name: <strong className="fs-12">{bank?.bank_name}</strong>
                </span>
                <br />
                <span style={{ fontSize: "12px" }}>Account Title: {bank?.account_title}</span>
                <br />
                <span className="d-block" style={{ fontSize: "12px" }}>
                  Account Number:{bank?.account_number}
                </span>
              </>
            ),
            name: bank.bank_name,
          }));
          resolve(banks);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const handleChangeBank = (e) => {
    setBank({ value: e.value, label: e.name });
  };

  useEffect(() => {
    const timeout = setTimeout(async () => {
      axios
        .get(process.env.REACT_APP_API_URL + `/sales/get/${id}`, {
          withCredentials: true,
        })
        .then((res) => {
          setItems(res.data.items);
          const filteredItem = res.data.items.filter((item) => Number(item.quantity) <= 0);
          setNegativeItme(filteredItem);
          setIsLoading(false);
        })
        .catch((error) => {
          throw new Error(error.response.data);
        });
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [id]);

  const handleTotal = (total) => {
    setRefundTotal(total);
    setTotalAmount(total);
  };
  const handlePaymentType = (e) => {
    setPaymentType(e.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    try {
      setValidated(true);
      if (paymentType === "Cash" ? register.isCashRegisterOpen : true) {
        setError("");

        if (paymentType === "Cash" ? cashInHand.cash_in_hand >= Number(totalAmount) : true) {
          setError("");
          if (paymentType !== "") {
            if (refunditems?.length > 0) {
              setIsClicked(true);
              setError("");
              let refundItems = [];
              let miscItems = refunditems.filter((i) => {
                if (i.is_misc === true) {
                  return i;
                }
              });
              let items = refunditems.filter((i) => {
                if (i.is_misc !== true) {
                  return i;
                }
              });
              const miscArr = miscItems.map((i) => {
                const { id, ...arr } = i;
                const obj = {
                  ...arr,
                  addInventory: false,
                  misc_id: id,
                  item_id: -1,
                };
                return obj;
              });
              const itemArr = items.map((i) => {
                const { id, ...arr } = i;
                const obj = {
                  ...arr,
                  addInventory: true,
                  item_id: id,
                  misc_id: -1,
                };
                return obj;
              });
              refundItems.push(...miscArr, ...itemArr);

              const res = await axios.post(
                process.env.REACT_APP_API_URL + `/refund/add`,
                {
                  salesRefund: {
                    sales_order_id: id,
                    total_amount: totalAmount,
                    payment_type: paymentType,
                    bank_id: paymentType === "Cash" ? -1 : bank.value.bank_id,
                  },
                  items: refundItems.map((i) => ({
                    item_id: i.item_id,
                    misc_id: i.misc_id,
                    quantity: i.refund_qty,
                    item_amount: i.refund_amount,
                    addInventory: i.addInventory,
                  })),
                },
                { withCredentials: true }
              );
              if (res.data) {
                dispatch(setLocation({ shop_id: user?.shop_id, location: location }));
                setSuccess("Refunded Succefully!");
                setError("");
                setTimeout(() => close(), 1000);
                setValidated(false);
                // setIsClicked(false)
              }
            } else {
              setError("Please select at least one item to refund");
            }
          }
        } else {
          setRegisterClosedAlert(false);
          setError("Amount is more then the Cash in Hand!");
        }
      } else {
        setRegisterClosedAlert(true);
        setError("Register is not set!");
      }
    } catch (error) {
      setIsClicked(false);
      if (error.response?.data?.error?.message === "Error in commit") {
        setError("Please select item to refund.");
      } else if (error.response.data.error.message === "Please refund with some quantity & amount.") {
        setError(error.response.data.error.message);
      } else if (error.response.data.error.message === "This Sales Order has been fully refunded.") {
        setError(error.response.data.error.message);
      } else {
        setError("Something went wrong.");
      }
      setIsClicked(false);
      // throw new Error(error);
    }
  };
  const handleCashOpeningBalance = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setIsValidated(true);
    try {
      if (registerID === -1) {
        setError("Register is not Set!");
      } else {
        if (openingBalance >= 0) {
          const res = await axios.post(process.env.REACT_APP_API_URL + `/cash-registers/opening-balance`, { register_id: registerID, opening_balance: openingBalance }, { withCredentials: true });
          if (res) {
            dispatch(setLocation({ shop_id: user?.shop_id, location: location }));
            setRegisterClosedAlert(false);
            setError("");
            // refresh();
            // setIsValidated(false);
            // setOpeningBalance();
            setTimeout(() => setOpenRegisterModal(false), 1000);
          }
        }
      }
    } catch (error) {
      setError(error);
    }
  };
  const currentMonth = new Date().toDateString().split(" ")[1];
  const currentDate = new Date().getDate() + " " + currentMonth + ", " + new Date().getFullYear();
  const today_sh = new Date();
  const currentTime = today_sh.getHours();
  let showtime;
  if (currentTime < 12) {
    showtime = "Good Morning";
  } else if (currentTime < 18) {
    showtime = "Good Afternoon";
  } else {
    showtime = "Good Evening";
  }
  return (
    <>
      {isLoading ? (
        <div className={`d-flex justify-content-center align-items-center ${styles.loading}`}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : !items[0] || negativeItem.length > 0 ? (
        <div className="pb-5 pt-2 fs-4 fw-bold text-center">The Sales Order has been fully refunded.</div>
      ) : (
        <Form onSubmit={handleSubmit} noValidate validated={validated}>
          {success ? (
            <p className="text-center text-success">{success}</p>
          ) : registerClosedAlert ? (
            <div className="alert alert-warning border-dotted" style={{ border: "2px dotted #8834ff" }}>
              <div className="d-flex justify-content-between align-items-center">
                *{error}
                <button
                  onClick={() => {
                    setOpenRegisterModal(true);
                  }}
                  className="btn btn-primary"
                >
                  Open Register
                </button>
              </div>
            </div>
          ) : error ? (
            <div className="alert alert-warning border-dotted" style={{ border: "2px dotted #8834ff" }}>
              *{error}
            </div>
          ) : (
            ""
          )}
          <Form.Group className="mb-3">
            <div className={styles.refundItem}>
              <Row className={`my-1 align-items-center px-3 ${styles.refundRow} ${styles.refundItemheader}`}>
                <Col className="px-0 d-flex" lg="5" md="5" sm="5" xs="5">
                  <span className="text-capitalize">Product Name</span>
                </Col>
                <Col className="px-0 d-flex" lg="2" md="2" sm="2" xs="2">
                  <span className="text-capitalize">Refundable Qty</span>
                </Col>
                <Col className="px-0 d-flex" lg="2" md="2" sm="2" xs="2">
                  <span className="text-capitalize">Refund Qty</span>
                </Col>
                <Col className="px-0 d-flex" lg="3" md="3" sm="3" xs="3">
                  <span className="text-capitalize">Refund Amount</span>
                </Col>
              </Row>
            </div>
            <RefundItems item={items} handleItems={handleItems} removeItem={removeItem} setTotal={handleTotal} />
            <div className="d-flex justify-content-end mt-1 me-lg-5 me-d-4 me-4">
              <Form.Group className={styles.paymentType}>
                <Select
                  placeholder={"Select payment type"}
                  label="Payment Type"
                  important
                  options={[
                    { value: "Transfer", label: "Transfer" },
                    { value: "Cash", label: "Cash" },
                  ]}
                  handleChange={handlePaymentType}
                />
                <Form.Control value={paymentType} hidden required onChange={() => {}} />
                <Form.Control.Feedback type="invalid">Please Select Payment Type.</Form.Control.Feedback>
              </Form.Group>
            </div>

            {paymentType === "Cash" || paymentType === "" ? (
              ""
            ) : (
              <>
                <div className={`d-flex justify-content-end mt-1 me-lg-5 me-d-4 me-4 ${styles.bankDropdown}`}>
                  {/* <div className="d-flex justify-content-end mt-1 me-lg-5 me-d-4 me-4"> */}
                  <Form.Group className={styles.paymentType}>
                    <ReactSelect label="Select Bank" placeholder="Select" value={bank} options={loadBanksOptions} handleChange={handleChangeBank} searchable={false} />
                    <Form.Control value={bank} onChange={() => {}} hidden required={paymentType === "Transfer"} />

                    <Form.Control.Feedback type="invalid">Please Select Bank.</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </>
            )}
            <div className={`text-end pt-4 me-4 me-md-4 me-lg-5 ${styles.totals}`}>
              Total Refund Amount: <strong>Rs. {Number(totalAmount)?.toLocaleString("en-IN")}</strong>
            </div>
          </Form.Group>
          <div className="text-end me-4 me-md-4 me-lg-5">
            <Button className="px-5 py-2 mt-2" type="submit" disabled={isClicked && true}>
              Refund
            </Button>
          </div>
        </Form>
      )}
      <OpeningRegister
        cashRegisterModal={openRegisterModal}
        setCashRegisterModal={setOpenRegisterModal}
        validated={isValidate}
        setIsValidate={setIsValidated}
        handleCashOpeningBalance={handleCashOpeningBalance}
        showtime={showtime}
        error={error}
        openingBalance={openingBalance}
        setOpeningBalance={setOpeningBalance}
      />
    </>
  );
};

export default AddRefund;
