import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const ReportsTour = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const reportsTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "GeneralReport", value: true } }));
      reportsTour.destroy();
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Reports",
        popover: {
          title: `Reports`,
          description: "Shows and generates performance reports.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#general-report",
        popover: {
          title: `General Report`,
          description: "The General Report provides an overview of the key metrics, including total sales, customers, expenses, liabilities, and daily product sales for the selected date range.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#general-report-date-filter",
        popover: {
          title: `Select Date Range`,
          description: "Select the date range for which you want to generate the report.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#general-report-total-sales",
        popover: {
          title: `Total Sales`,
          description: "Displays the number of sales made within the selected date range.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#general-report-total-customers",
        popover: {
          title: `Total Customers`,
          description: "Shows the number of unique customers during the selected period.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#general-report-total-expenses",
        popover: {
          title: `Total Expenses`,
          description: "Represents the total expenses incurred.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#general-report-total-liability",
        popover: {
          title: `Total Liability`,
          description: "Shows the liabilities associated with the business.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#general-report-products-sold",
        popover: {
          title: `Product Sold per Day`,
          description: "A graphical representation of products sold on each day of the month.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#general-report-selling-products",
        popover: {
          title: `Most Selling Products`,
          description: "Displays the top 10 best-selling products based on sales figures.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "GeneralReport", value: true } }));
            reportsTour.destroy();
          },
        },
      },
    ],
  });
  useEffect(() => {
    reportsTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default ReportsTour;
