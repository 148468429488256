import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";

const WithdrawAmount = ({ close, openingBalanceData, cashInHand, toggle }) => {
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const [name, setName] = useState("");
  const [amount, setAmount] = useState();
  const [success, setSucces] = useState("");
  const [error, setError] = useState("");
  const [note, setNote] = useState();
  const [validated, setValidated] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [checkUserName, setcheckUserName] = useState(false);

  const [amountCheck, setAmountCheck] = useState(false);
  const ourRequest = axios.CancelToken;
  var cancel;
  // Add Customer
  const handleCustomerSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);
    let isnum = /^\d+$/.test(name);
    if (isnum) {
      setcheckUserName(true);
    }
    try {
      if (amount > 0 && name && !isnum) {
        setIsClicked(true);
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/transaction/withdraw-amount",
          {
            user_id: user.user_id,
            opening_balance_id: openingBalanceData?.opening_balance_id,
            withdrawer: name,
            amount: amount,
            note: note ? note : "",
          },
          { withCredentials: true },
        );
        if (res) {
          toggle((prev) => !prev);
          setAmount();
          setName();
          setNote();
          close();
        }
      }
    } catch (error) {
      setIsClicked(true);
      setError(error.response?.data.error.message);
      setIsClicked(false);
    }
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleCustomerSubmit}>
        {error && (
          <p className="text-danger p-2 text-center my-2 rounded-1">*{error}</p>
        )}
        {success && (
          <p className="text-success p-2 text-center my-2 rounded-1">
            *{success}
          </p>
        )}
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">
            Full Name<span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            className="p-2"
            type="text"
            name="name"
            placeholder="Name"
            // pattern="^^[A-Za-z ]+$"
            value={name}
            onChange={(e) => {
              if (e.target.value.length < 70) {
                setcheckUserName(false);

                setName(e.target.value.replace(/[^\w\s]/gi, ""));
              }
            }}
            required
          />
          <Form.Control.Feedback type="invalid">
            Enter Username
          </Form.Control.Feedback>
          {checkUserName && <p className="text-danger"> Enter valid name</p>}
        </Form.Group>

        <Form.Group className="mb-4" controlId="price">
          <Form.Label className="mb-0">
            Enter Amount<span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            min={1}
            type="number"
            placeholder="i.e 500"
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            value={amount}
            onChange={(e) => {
              if (e.target.value <= Number(cashInHand)) {
                setAmount(e.target.value);
                setAmountCheck(false);
              } else {
                setAmountCheck("Amount must be less then cash in hand");
                setAmount("");
              }
            }}
            name="opening_balance"
            required
          />

          <Form.Control.Feedback type="invalid">
            Please Enter Amount
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="price">
          <Form.Label className="mb-0">Note</Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            rows={3}
            maxlength="200"
            placeholder="Write Note"
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            name="note"
            pattern="^([A-Za-z ]|[0-9])+$"
            // required
          />
        </Form.Group>
        {amountCheck ? (
          <p className="text-danger">Amount must be less then cash in hand</p>
        ) : (
          ""
        )}

        <Button disabled={isClicked} type="submit" className="w-100">
          Save
        </Button>
      </Form>
    </>
  );
};

export default WithdrawAmount;
