import { useSelector } from "react-redux";
import { Container, Row, Col, Card, Tabs, Tab, Modal } from "react-bootstrap";
import WidgetCard from "../Components/WidgetCard";
import product from "./../assets/svg/products.svg";
import sales from "./../assets/svg/sales.svg";
import calender from "./../assets/svg/os-icon-calendar.svg";
import expense from "./../assets/svg/newExpense.svg";
import customers from "./../assets/svg/customers.svg";
import ProductTableView from "../Components/ProductTableView";
import LineChart from "../Components/Charts/LineChart";
import { useEffect } from "react";
import axios from "axios";
import DatePicker from "../Components/DatePicker/DatePicker";
import moment from "moment";
import { useState } from "react";
import { requestDateTime } from "../Features/dateTime";
import PlansModal from "../Components/plans/PlansModal";
import DashboardTour from "../TourGuides/DashboardTour";

const Dashboard = () => {
  const [data, setData] = useState();
  const [selectedCategory, setSeletedCategory] = useState("weekly");
  const [showModal, setShowModal] = useState(false);
  let [ChartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [productData, setProductData] = useState([]);
  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);
  const { user, throughLogin } = useSelector((state) => state.auth);
  const [reportType, setReportType] = useState({
    value: 1,
    label: "Total Sales",
  });
  const shop_id = user?.shop_id;
  const userId = user?.user_id;
  const handleDependency = (e) => {
    setSeletedCategory(e);
  };

  useEffect(() => {
    if (throughLogin) {
      if (user.gracePeriodStart === 1 && user.gracePeriodEnd === 0) {
        // setShowModal(true);
      }
    }
  }, [user, throughLogin]);

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + `/sales/average-sales/${userId}`, { shop_id: shop_id, chart: selectedCategory }, { withCredentials: true })
      .then((res1) => {
        const res2 = res1.data;
        var data1 = res2?.map((i) => i.total_sales);
        var data2 = res2?.map((i) => i.label);
        setChartData({
          labels: data2,
          datasets: [
            {
              label: "Average of Sales",
              data: data1,
              borderColor: "rgba(211, 152, 240, 1)",
              backgroundColor: "rgba(211, 152, 240, 1)",
              lineTension: 0.5,
              pointStyle: "cricle",
              pointRadius: 5,
              fill: false,
              pointBorderColor: "rgb(255, 255, 255)",
            },
          ],
        });
      })
      .catch((err) => {
        throw new Error(err.response.data);
      });
  }, [shop_id, selectedCategory, userId]);
  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + `/shops/get-counts/${userId}`, { shop_id }, { withCredentials: true })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        throw new Error(err.response.data);
      });
  }, [shop_id, userId]);
  useEffect(() => {
    let dates = {
      from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
      to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
    };
    axios
      .post(process.env.REACT_APP_API_URL + `/sales/best-selling-product/${userId}`, { dates, reportType }, { withCredentials: true })
      .then((res) => {
        const Obj = res.data?.map((data) => {
          const { stock, price, total_sales, ...dataObj } = data;
          return {
            ...dataObj,
            stock: `${stock?.toLocaleString("en-IN")} in stock`,
            price: `Rs. ${price?.toLocaleString("en-IN")}`,
            total_sales: total_sales,
          };
        });
        setProductData(Obj);
      })
      .catch((err) => {
        throw new Error(err.response.data);
      });
  }, [userId, fromDate, toDate]);
  const widgetData = [
    {
      name: "Products",
      number: data ? data?.items[0].items : 0,
      total: "In Stock",
      icon: product,
    },
    {
      name: "Customers",
      number: data ? data?.customer[0].customers : 0,
      total: "Total customers",
      icon: customers,
    },
    {
      name: "Sales",
      number: data ? data?.sales[0].sales : 0,
      total: "Total sales",
      icon: sales,
    },
    {
      name: "Expenses",
      number: data ? Number(data?.expenses[0].expenses).toLocaleString("en-IN") : 0,
      total: "Total Expense",
      icon: expense,
    },
  ];
  const currentMonth = new Date().toDateString().split(" ")[1];
  const currentDate = new Date().getDate() + " " + currentMonth + ", " + new Date().getFullYear();
  const today = new Date();
  const currentTime = today.getHours();
  let showtime;
  if (currentTime < 12) {
    showtime = "Good Morning";
  } else if (currentTime < 18) {
    showtime = "Good Afternoon";
  } else {
    showtime = "Good Evening";
  }

  return (
    <Container fluid className="px-xl-5">
      {user?.tour && !user?.tour?.Dashboard && <DashboardTour setSeletedCategory={setSeletedCategory} />}
      <Row>
        <Col lg="6" sm="6" md="6">
          <h4>
            Hello <span className="text-capitalize">{user && user.user_full_name}</span> 👋🏼,
          </h4>
        </Col>
        <Col lg="6" sm="6" md="6" className="text-start text-md-end d-md-block d-none py-2">
          <div className="d-sm-flex d-block align-items-center justify-content-end gap-1 dashboard_calender">
            <img
              src={calender}
              alt="calender"
              style={{
                pointerEvents: "none",
              }}
            />{" "}
            <span className="ms-1">{currentDate}</span>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col lg="6" sm="6" xs="6" md="6">
          <p className="m-0">{showtime}!</p>
        </Col>
        <Col lg="6" sm="6" xs="6" md="6" className="text-end text-md-end d-sm-block d-md-none py-2">
          <div className="d-sm-flex d-block align-items-center justify-content-end gap-1 dashboard_calender">
            <img src={calender} alt="calender" /> <span className="ms-1">{currentDate}</span>
          </div>
        </Col>
      </Row>
      <Row className=" d-flex justify-content-between">
        {widgetData.map((data, index) => (
          <Col className="py-2  " md="6" xs="12" lg="3" sm={6} key={index}>
            <WidgetCard data={data} />
          </Col>
        ))}
      </Row>
      <Row className="pt-4">
        <Col>
          <Card className="border-0 p-2 p-md-5 cardShadow" id="dashboard_average_sales">
            <Tabs id="uncontrolled-tab-example" className="mb-3" activeKey={selectedCategory} onSelect={handleDependency}>
              <Tab eventKey="weekly" title="Weekly" className="text-secondary">
                <h4>Weekly Average Sales</h4>
                <div className="h-75">
                  <LineChart data={ChartData} />
                </div>
              </Tab>
              <Tab eventKey="monthly" title="Monthly" className="text-secondary">
                <h4>Monthly Average Sales</h4>
                <div className="h-75">
                  <LineChart data={ChartData} />
                </div>
              </Tab>
              <Tab eventKey="yearly" title="Yearly" className="text-secondary">
                <h4>Yearly Average Sales</h4>
                <div className="h-75">
                  <LineChart data={ChartData} />
                </div>
              </Tab>
            </Tabs>
          </Card>
        </Col>
      </Row>
      <Card className="my-5 border-0 px-3 cardShadow" id="dashboard_best_selling_products">
        <Row className=" py-3 gap-2 flex-md-row ">
          <Col md="6" sm="6" className="pe-0">
            <h5>Top 10 Best Selling Products.</h5>
          </Col>
          <Col lg="3" sm="4" className="d-lg-block d-none"></Col>
          <Col xs md="5" sm="4" lg="2" className="d-lg-inline">
            <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
          </Col>
        </Row>
        <ProductTableView
          data={productData}
          thumbnail={{
            title: "Product Name",
            img: "img0",
            description: "product_name",
          }}
        />
      </Card>

      <PlansModal show={showModal} setShow={setShowModal} />
    </Container>
  );
};

export default Dashboard;
