import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
const Loading = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/reset-password", { state: { token: token } });
  });

  return (
    <div className="min-vh-100 d-flex justify-content-center align-items-center">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loading;
