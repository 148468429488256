import React, { useState } from 'react'
import { Button, Form, Table, Dropdown } from 'react-bootstrap';
import editpencil from '../assets/svg/editpencil.svg'
import archiveIcon from '../assets/svg/archive.svg'
import unarchiveIcon from '../assets/svg/Unarchive.svg'

import duplicatelogo from '../assets/svg/save.svg'
import icon from '../assets/svg/icon.svg'
import forward from '../assets/svg/forward.svg'
import { Link } from 'react-router-dom';
import print from './../assets/svg/print.svg'
import messageIcon from './../assets/svg/email.svg'
import watchIcon from './../assets/svg/union.svg';
import removeIcon from './../assets/svg/remove.svg';
import ellipsis from './../assets/svg/Ellipsis.svg';

const transform = (text) => {
    const temp = text.split('_');
    let ret = "";
    temp.forEach((txt) => {
        ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1);
    })
    return ret;
}

const TableView = ({
    className,
    data,
    edit,
    forwardlogo,
    remove,
    editLink,
    unarchive,
    archive,
    duplicate,
    payment,
    receipt,
    message,
    watch,
    VARIABLE,
    CheckList,
    sortFields,
    sortMethod,
    toggleinventory,
    ...props }) => {

    const [checkAll, setCheckAll] = useState(false);
    const [checkRow, setCheckRow] = useState([]);

    const handleSelectAll = e => {
        if (checkAll) {
            setCheckAll(false);
            return setCheckRow([]);
        }
        setCheckAll(true);
        return setCheckRow(data.map(li => li.City));
    };
    const handleClick = e => {
        const { name } = e.target;
        if (checkRow.includes(name)) {
            setCheckRow(checkRow.filter(checked_name => checked_name !== name));
            return setCheckAll(false);
        }
        checkRow.push(name);
        setCheckRow([...checkRow]);
        setCheckAll(checkRow.length === data.length)
    };
    return (
        <>
            <Table responsive striped className={`rounded-3 bg-white border-1 border-secondary overflow-auto mb-3 ${className}`}>
                <thead className='text-secondary' style={{ fontSize: '13px', fontWeight: '400', lineHeight: '18px' }}>
                    <tr>
                        {CheckList ? <th className='border-start' style={{ width: '2%' }}><Form.Check id='selectAll' name='selectAll' checked={checkAll} onChange={handleSelectAll}></Form.Check></th> : ''}
                        {
                            Object.keys(data[0]).slice(2).map((s, index) => (<th key={index}>{transform(s)} {sortFields.includes(s) ? <img src={icon} onClick={() => { sortMethod(s); toggleinventory() }} alt='icon' /> : ''} </th>))
                        }
                        <th className='border-start text-end'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {

                        data.map((row, index) => (
                            <tr className='border-bottom' key={index}>
                                {CheckList ? <td className='border-bottom-0' style={{ width: '2%' }}><Form.Check id={row.City} name={row.City} checked={checkRow.includes(row.City)} onChange={handleClick}></Form.Check></td> : ''}
                                {Object.entries(row).slice(2).map(([k, v], i) =>
                                (
                                    k === 'status' ?
                                        <td className={`border-bottom-0 ${v === 1 ? 'text-success' : 'text-danger'}`} key={i}>{VARIABLE[v]}</td> :
                                        <td className='border-bottom-0' key={i}>{v}</td>
                                )
                                )}
                                <td className=' border-start border-bottom-0 ' style={{ maxWidth: '20%', width: '100%' }}>
                                    <div className='d-none d-lg-flex align-items-center justify-content-end gap-3 px-2' >
                                        {payment ? <Button className='py-1' variant="outline-primary">Receive Payment </Button> : ''}
                                        {receipt ? <img src={print} alt="print" style={{ cursor: 'pointer' }} /> : ""}
                                        {watch ? <img src={watchIcon} alt="watch" style={{ cursor: 'pointer' }} /> : ""}
                                        {message ? <img src={messageIcon} alt="message" style={{ cursor: 'pointer' }} /> : ""}
                                        {
                                            duplicate ?
                                                <div className='tooltipp'>

                                                    <Link to={`duplicate-inventory/${row.id}`} state={{ id: Object.entries(row).map(([k, v]) => (k === 'category_id' ? v : "")) }}>

                                                        <img src={duplicatelogo} alt="save" style={{ cursor: 'pointer' }} onClick={() => duplicate(row.id)} />

                                                    </Link>
                                                    <span className='tooltiptext'>Duplicate</span>
                                                </div>

                                                : ""
                                        }

                                        {edit ?
                                            <div className='tooltipp'>
                                                <Link to={`${editLink}/${row.id}`} state={{ id: Object.entries(row).map(([k, v]) => (k === 'category_id' ? v : "")) }} >


                                                    <img src={editpencil} alt="edit" style={{ cursor: 'pointer' }} onClick={() => edit(row.id)} />


                                                </Link>
                                                <span className='tooltiptext'>Edit</span>
                                            </div>
                                            : ""
                                        }


                                        {
                                            archive ?
                                                <div className='tooltipp'>
                                                    <img src={archiveIcon} alt="archive" style={{ cursor: 'pointer' }} data-bs-toggle="tooltip" data-bs-placement="top"

                                                        title="Archive" onClick={() => archive(row.id)} />
                                                    <span className='tooltiptext'>Archive</span>
                                                </div> : ""
                                        }
                                        {
                                            unarchive ?
                                                <div className='tooltipp'>
                                                    <img src={unarchiveIcon} alt="unarchive" style={{ cursor: 'pointer' }} onClick={() => unarchive(row.id)} />
                                                    <span className='tooltiptext'>Unarchive</span>
                                                </div> : ""
                                        }
                                        {
                                            remove ?
                                                <div className='tooltipp'>
                                                    <img src={removeIcon} alt="removeIcon" data-bs-toggle="tooltip" data-bs-placement="top"

                                                        title="Delete" style={{ cursor: 'pointer' }} onClick={() => remove(row.id)} />
                                                    <span className='tooltiptext'>Delete</span>
                                                </div> : ""
                                        }

                                        {
                                            forwardlogo ?
                                                <div className='tooltipp'>
                                                    <img src={forward} alt="forwarIcon" data-bs-toggle="tooltip" data-bs-placement="top"

                                                        title="Forward" style={{ cursor: 'pointer' }} onClick={() => forward()} />
                                                    <span className='tooltiptext'>Forward</span>
                                                </div> : ""
                                        }
                                    </div>
                                    {/* For Mobile */}
                                    <Dropdown className='d-flex justify-content-center d-lg-none'>
                                        <Dropdown.Toggle variant="light" className="border-0 p-0" id="dropdown-basic" style={{ background: 'transparent' }}>
                                            <img src={ellipsis} alt="ellipsis" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='rounded-0 border-0 shadow'>
                                            {payment ? <Dropdown.Item className='purple' href="">Receive Payment</Dropdown.Item> : ""}
                                            {receipt ? <Dropdown.Item className='purple' href="">Receipt</Dropdown.Item> : ""}
                                            {watch ? <Dropdown.Item className='purple' href="">View Invoice</Dropdown.Item> : ""}
                                            {message ? <Dropdown.Item className='purple' href="">Send Mail</Dropdown.Item> : ""}
                                            {duplicate ? <Dropdown.Item className='purple' href="" as={Link} to={`duplicate-inventory/${row.id}`} state={{ id: Object.entries(row).map(([k, v]) => (k === 'category_id' ? v : "")) }}>Duplicate</Dropdown.Item> : ""}
                                            {edit ? <Dropdown.Item className='purple' href="" as={Link} to={`${editLink}/${row.id}`} state={{ id: Object.entries(row).map(([k, v]) => (k === 'category_id' ? v : "")) }} onClick={() => edit(row.id)}>Edit</Dropdown.Item> : ""}
                                            {unarchive ? <Dropdown.Item className='purple' href="" onClick={() => unarchive(row.id)}>Unarchive</Dropdown.Item> : ""}

                                            {archive ? <Dropdown.Item className='purple' href="" onClick={() => archive(row.id)}>Archive</Dropdown.Item> : ""}
                                            {remove ? <Dropdown.Item className='purple' href="" onClick={() => remove(row.id)}>Delete</Dropdown.Item> : ""}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>

        </>
    );
}

export default TableView;