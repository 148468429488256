import React, { useEffect, useState } from "react";
import SalesPageFilters from "./SalesPageFilters";
import { useSelector } from "react-redux";
import TilesView from "./Views/TilesView";
import axios from "axios";
import GridView from "./Views/GridView";
import PaginationBar from "../PaginationBar";
import SalesPageCategoryTabs from "./SalesPageCategoryTabs";
import style from "./SalesPage.module.css";
import { useNavigate } from "react-router-dom";

function SalesPageItems({}) {
  const { user } = useSelector((state) => state.auth);
  const cartToggle = useSelector((state) => state.cart.toggle);
  const userId = user.user_id;

  const location = useSelector((state) => state.location.location);

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [accessoryType, setAccessoryType] = useState(null);
  const [viewMode, setViewMode] = useState("smallGrid");

  const [itemStatus, setItemStatus] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [products, setProducts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfItems, setNoOfItems] = useState(0);
  const [status, setStatus] = useState("Available");

  const navigate = useNavigate();

  useEffect(() => {
    if (user?.tour && !user?.tour?.Sales) {
      setProducts([
        {
          id: 0,
          title: "test 001",
          quantity: 1,
          sale_price: 3000,
          is_on_marketplace: 0,
          created_at: "1970-01-01T15:24:05.000Z",
          updated_at: null,
          label: "Mobile",
          img0: "images/dummyImages/mobiles.jpg",
          reserved_quantity: 0,
        },
      ]);
      setNoOfPages(1);
      setNoOfItems(1);
    } else {
      const temp = Number(selectedCategory);
      let categoryObj = {};
      if (temp === 0) {
        categoryObj = { all: true };
      } else if (temp === 1) {
        categoryObj = { is_laptop: true };
      } else if (temp === 2) {
        categoryObj = { is_mobile: true };
      } else if (temp === 3) {
        categoryObj = { is_tab: true };
      } else if (temp === 4) {
        categoryObj = { is_led: true };
      } else if (temp === 5) {
        categoryObj = { is_desktop: true };
      } else if (temp === 6) {
        categoryObj = { is_accessory: true };
      }
      let statusObj = {};
      if (status === "Available") {
        statusObj = { available: true };
      } else if (status === "Sold") {
        statusObj = { sold: true };
      }
      let searchobj = {};
      let locationObj = {};

      if (searchInput.length > 0) {
        searchobj = { ...searchobj, search: searchInput };
      } else {
        searchobj = { ...searchobj, search: "" };
      }
      if (location && Object.values(location)?.length > 0) {
        locationObj["location_id"] = location.value;
      }
      const quantityFilter = {
        ...categoryObj,
        ...statusObj,
        ...searchobj,
        ...locationObj,
        accessoryType: accessoryType ? accessoryType.value : null,
      };

      const getProducts = async () => {
        setIsLoading(true);
        try {
          if (location && Object.keys(location).length > 0) {
            const res = await axios.post(process.env.REACT_APP_API_URL + `/inventory/get/${userId}?page=${currentPage}&size=${sizeOfPages}`, quantityFilter, { withCredentials: true });
            if (res) {
              const data = res.data;
              setProducts(data.data);
              setNoOfPages(data.count.no_of_pages);
              setNoOfItems(data.count.no_of_items);
              // }
              if (data.count.no_of_pages === 0) {
                navigate("/sales?page=1");
                setCurrentPage(1);
              } else if (currentPage > data.count.no_of_pages) {
                setIsLoading(false);
                setCurrentPage(data.count.no_of_pages);
                navigate("/sales?page=" + data.count.no_of_pages);
              }
            }
            setIsLoading(false);
          }
        } catch (err) {
          setIsLoading(false);
          // throw new Error(err.response.data);
        }
      };
      getProducts();
    }
  }, [userId, selectedCategory, currentPage, sizeOfPages, status, searchInput, location, accessoryType, cartToggle, user?.tour?.Sales]);

  return (
    <div className={`w-100 d-flex flex-column`}>
      <SalesPageFilters selectedCategory={selectedCategory} accessoryType={accessoryType} setAccessoryType={setAccessoryType} viewMode={viewMode} setViewMode={setViewMode} search={searchInput} setSearch={setSearchInput} status={status} setStatus={setStatus} />
      <SalesPageCategoryTabs viewMode={viewMode} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} status={status} setStatus={setStatus} />
      <div className={`${style.ItemsContainer}`}>{viewMode === "tiles" ? <TilesView data={products} isLoading={isLoading} status={status} /> : <GridView data={products} isLoading={isLoading} viewMode={viewMode} />}</div>
      <div className={`d-flex justify-content-around`}>
        <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={(page) => setCurrentPage(page)} />
      </div>
    </div>
  );
}

export default SalesPageItems;
