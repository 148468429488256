import axios from "axios";
import React, { useCallback, useRef } from "react";
import { useState, useEffect } from "react";
import { Badge, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import ImageUploader from "../../Components/ImageUploader/ImageUploader";
import PopUpModal from "../../Components/Modal";
import Select from "react-select";
import FeatureditemsModal from "../../Components/Listings/FeaturedItems/FeatureditemsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes, faImage } from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/Customize.module.css";
import noImage from "../../assets/svg/no-image.svg";
import LoaderOverlay from "../../Components/loaders/loaderOverlay";
import { faArrowAltCircleUp, faCaretSquareRight } from "@fortawesome/free-regular-svg-icons";
import Cookies from "js-cookie";
import ImageUploaderForThemes from "../../Components/ImageUploader/ImageUploaderForThemes";

const ThumbnailImage = ({ srcInput }) => {
  const [imgSrc, setImgSrc] = useState(process.env.REACT_APP_URL_S3 + "/" + srcInput);
  return (
    <div>
      {/* {imgSrc} */}
      <img
        style={{ borderRadius: "6px" }}
        width="100"
        height="40"
        alt="thumbnail"
        src={imgSrc}
        className="me-2"
        // onError={() => {
        //   setImgSrc(noImage);
        // }}
      />
    </div>
  );
};

function ApplyThemeModal({ setApplyModal, applyModal, selectedTheme, userSelectedTheme, edit, setEdit, setTrigger, fields }) {
  const [validated, setValidated] = useState(false);
  const [lightboxVisible, setLightboxVisible] = useState(false);
  const [PrimaryImages, setPrimaryImages] = useState({ initialValue: [], value: [] });
  const [SecondaryImages, setSecondaryImages] = useState({ initialValue: [], value: [] });
  const [SectionOneImages, setSectionOneImages] = useState({ initialValue: [], value: [] });
  const [SectionTwoImages, setSectionTwoImages] = useState({ initialValue: [], value: [] });

  const [tertiaryImages, setTertiaryImages] = useState({ initialValue: [], value: [] });

  const [selectedRadioIndex, setSelectedRadioIndex] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [sections, setSections] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [sectionConfigs, setSectionConfigs] = useState([]);
  const [secId, setSecId] = useState();
  const [setbox, setSetbox] = useState("");
  const [setNav, setSetNav] = useState("slider");
  const [isLoading, setIsloading] = useState(false);
  const [preview, setPreview] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [previousValuesOfPrimary, setPreviousValuesOfPrimary] = useState();
  const [previousValuesOfSecondary, setPreviousValuesOfSecondary] = useState();
  const [formError, setFormError] = useState();
  const [detectChange, setDetectChange] = useState(true);
  let token = Cookies.get(process.env.REACT_APP_ACCESS_TOKEN);
  //
  function findFieldByValue(fieldsArray, valueToMatch) {
    return fieldsArray.find((obj) => obj.value === valueToMatch);
  }
  const matchedField = findFieldByValue(fields, selectedTheme?.value);

  const urlRegex = /\b(?:https?:\/\/(?:www\.)?(?:localhost:3000|staging\.techbazaar\.pk|uat\.techbazaar\.pk|techbazaar\.pk))\b/;

  function validateURL(url) {
    return urlRegex.test(url);
  }
  const sectionsList = [
    { label: "Hot Deals", value: "Hot Deals" },
    { label: "Featured Items", value: "Featured Items" },
    { label: "New Items", value: "New Items" },
    { label: "Popular Items", value: "Popular Items" },
    { label: "Top Rated Items", value: "Top Rated Items" },
    { label: "Trending Items", value: "Trending Items" },
    { label: "Best Selling Items", value: "Best Selling Items" },
    { label: "Sale Items", value: "Sale Items" },
    { label: "Latest Items", value: "Latest Items" },
    { label: "Other", value: "Other" },
  ];
  const getCategories = useCallback(async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + "/categories/get", {
        withCredentials: true,
      });
      setCategoryOptions(response.data.map(({ id, category }) => ({ label: category, value: id })));
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }, []);
  const getSectionItems = useCallback(async (data) => {
    let itemids = data.map((item) => {
      return {
        sectionName: item.sectionName,
        sectionItems: item.sectionItems.map((item) => {
          return item.listing_id;
        }),
      };
    });

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/listings/many`,
        { itemids },
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }, []);
  useEffect(() => {
    setTrigger((prev) => !prev);
    setDetectChange(true);
  }, [preview]);
  useEffect(() => {
    setSections(Array.from({ length: selectedTheme?.noOfSections }, () => ({ sectionName: "", sectionItems: [] })));
    setIsSubmitted(false);
  }, [selectedTheme]);
  useEffect(() => {
    getCategories();

    const pageLoad = async () => {
      const getSectionItems = async (data) => {
        try {
          const response = await axios.post(process.env.REACT_APP_API_URL + `/listings/many`, data, {
            withCredentials: true,
          });
          let newData = response.data.data.map((val) => {
            return {
              sectionName: val.sectionName,
              sectionItems: val.sectionItems.map((item) => {
                return { listing_id: item.listing_id, title: item.listing_title, price: item.online_price, discount: item.online_discount, image: item.img0 };
              }),
            };
          });
          setSections(newData);
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      };
      // if (edit) {
      if (selectedTheme?.value === userSelectedTheme?.final_configuration?.themeType) {
        const imagesPromiseArray = userSelectedTheme?.final_configuration?.bannerConfig.map(async (image, index) => {
          if (image !== "nil") {
            const obj = {
              preview: process.env.REACT_APP_URL_S3 + "/" + image,
              data: image,
              type: "url",
            };
            return obj;
          } else {
            return "nil";
          }
        });

        setSelectedCategories(userSelectedTheme?.final_configuration?.topCategories);
        // const imagesResolvedArray = await Promise.all(imagesPromiseArray);
        setPrimaryImages({ value: userSelectedTheme?.final_configuration?.bannerConfig[0]?.images });
        setSecondaryImages({ value: userSelectedTheme?.final_configuration?.bannerConfig[1]?.images });
        setPreviousValuesOfPrimary({ value: userSelectedTheme?.final_configuration?.bannerConfig });
        // setPreviousValuesOfSecondary({ value: userSelectedTheme?.final_configuration?.bannerConfig[1].images });

        getSectionItems(userSelectedTheme?.final_configuration?.listingSectionConfig);
        // }
      } else if (selectedTheme?.value === userSelectedTheme?.configuration?.themeType) {
        const imagesPromiseArray = userSelectedTheme?.configuration?.bannerConfig.map(async (image, index) => {
          if (image !== "nil") {
            const obj = {
              preview: process.env.REACT_APP_URL_S3 + "/" + image,
              data: image,
              type: "url",
            };
            return obj;
          } else {
            return "nil";
          }
        });

        setSelectedCategories(userSelectedTheme?.configuration?.topCategories);
        // const imagesResolvedArray = await Promise.all(imagesPromiseArray);
        setPrimaryImages({ value: userSelectedTheme?.configuration?.bannerConfig[0]?.images });
        setSecondaryImages({ value: userSelectedTheme?.configuration?.bannerConfig[1]?.images });
        setPreviousValuesOfPrimary({ value: userSelectedTheme?.configuration?.bannerConfig });
        // setPreviousValuesOfSecondary({ value: userSelectedTheme?.configuration?.bannerConfig[1].images });

        getSectionItems(userSelectedTheme?.configuration?.listingSectionConfig);
      } else {
        setSelectedCategories([]);
        // const imagesResolvedArray = await Promise.all(imagesPromiseArray);
        setPrimaryImages([]);
        setSecondaryImages([]);
        setPreviousValuesOfPrimary();
        // setPreviousValuesOfSecondary({ value: userSelectedTheme?.configuration?.bannerConfig[1].images });

        getSectionItems([]);
      }
    };

    // if (userSelectedTheme?.themeType === selectedTheme?.value) {
    pageLoad();
    // }
  }, [applyModal, getCategories]);
  const imageComponent = useRef(null);

  const handleSelectChange = (selectedOptions) => setSelectedCategories(selectedOptions.length <= 3 ? selectedOptions : selectedCategories);

  // const setImagesValue = (valueOrCallback, isPrimary) => {
  //   const setImageState = isPrimary ? setPrimaryImages : setSecondaryImages;
  //   setImageState((oldValue) => ({ ...oldValue, value: typeof valueOrCallback === "function" ? valueOrCallback(oldValue.value) : valueOrCallback }));
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let error = false;

    if (selectedTheme?.value === "Theme 3" && SecondaryImages?.value?.length !== 2) {
      setFormError("Please select two secondary images");
      error = true;
    } else {
      setFormError("");
    }

    sections.forEach((section) => {
      if (section.sectionItems.length === 0) {
        setFormError("Please add some items in each section");
        error = true;
      }
    });

    if (error) return;

    const obj = {
      PrimaryImages: selectedTheme.value === "Theme 1" || selectedTheme.value === "Theme 2" || selectedTheme.value === "Theme 4" ? PrimaryImages?.value?.length > 0 : selectedTheme.value !== "Theme 5" ? PrimaryImages?.value?.length > 0 : PrimaryImages?.value?.length === 1,
      ...(selectedTheme.bottomSlider && {
        SecondaryImages: selectedTheme.value === "Theme 3" ? SecondaryImages.value.length === 2 : SecondaryImages.value.length > 0,
      }),
      ...(selectedTheme.topCategory && !["Theme 2", "Theme 4", "Theme 5"] && { selectedCategories: selectedCategories.length > 0 }),
      section: !(selectedTheme.value === "Theme 5") ? !sections.some((obj) => Object.values(obj).some((value) => !value)) : true,
    };

    const formData = new FormData();
    setValidated(true);

    PrimaryImages?.value?.filter((item) => !item?.key).forEach((file) => formData.append(`primaryImage`, file?.data));

    if (selectedTheme?.value !== "Theme 5" && selectedTheme?.value !== "Theme 4") {
      SecondaryImages?.value?.filter((item) => !item?.key)?.map((file) => formData?.append(`secondaryImage`, file?.data));
    }

    formData.append("selectedTheme?.value", selectedTheme?.value);
    if (selectedTheme.value !== "Theme 5") {
      formData.append("selectedCategories", JSON.stringify(selectedCategories.map((category) => category.label)));
    }

    const newObj = PrimaryImages?.value
      ?.filter((item) => item.preview)
      .reduce((acc, curr) => {
        const filename = curr.data.name;
        acc[filename] = curr.link;
        return acc;
      }, {});
    const newObjTwo = SecondaryImages.value
      ?.filter((item) => item.preview)
      .reduce((acc, curr) => {
        const filename = curr.data.name;
        acc[filename] = curr.link;
        return acc;
      }, {});

    formData.append("links", JSON.stringify({ primaryImage: newObj, secondaryImage: newObjTwo }));

    if (Object.values(obj).every((value) => value === true || selectedTheme?.value === "Theme 5")) {
      setIsSubmitted(true);
      try {
        setIsloading(true);
        let imageUploadResponse;
        const filteredDataPrimary = PrimaryImages?.value?.filter((item) => !item.preview);
        const dataPre = [
          {
            bannerType: "primaryImage",
            images: filteredDataPrimary || [{
              key:null,
              originalname:null 
            }],
          },
          {
            bannerType: "secondaryImage",
            images: SecondaryImages.value?.filter((item) => !item.preview) || [{
              key:null,
              originalname:null 
            }],
          },
        ];

        imageUploadResponse = await axios.post(`${process.env.REACT_APP_URL_IMAGE_CDN}/shop/banner/${user.shop_id}`, formData, { withCredentials: true });

        if (imageUploadResponse?.data?.length > 0) {
          if (imageUploadResponse?.data[0].bannerType === "primaryImage") {
            imageUploadResponse.data[0].images.forEach((item) => {
              dataPre[0].images.push(item);
            });
          } else if (imageUploadResponse?.data[0].bannerType === "secondaryImage") {
            imageUploadResponse.data[0].images.forEach((item) => {
              dataPre[1].images.push(item);
            });
          }
          if (imageUploadResponse?.data[1]?.bannerType === "secondaryImage") {
            imageUploadResponse.data[1].images.forEach((item) => {
              dataPre[1].images.push(item);
            });
          }
        }

        const previousObject = [
          {
            bannerType: "primary",
            images: PrimaryImages?.value?.filter((item) => !item.preview),
          },
          {
            bannerType: "secondary",
            images: SecondaryImages.value?.filter((item) => !item.preview),
          },
        ];

        if (sections.every((section) => section.sectionItems.length > 0)) {
          await axios.post(`${process.env.REACT_APP_API_URL}/shops/banner/${user.shop_id}`, {
            themeType: selectedTheme?.value,
            bannerConfig: dataPre,
            topCategories: selectedCategories,
            listingSectionConfig: sections.map((item) => {
              return {
                sectionName: item.sectionName,
                sectionItems: item.sectionItems.map((item) => item.listing_id),
              };
            }),
          });
        } else {
          await axios.post(`${process.env.REACT_APP_API_URL}/shops/banner/${user.shop_id}`, {
            themeType: "Theme 5",
            bannerConfig: [],
            topCategories: [],
            listingSectionConfig: [],
          });
        }

        setIsloading(false);
        setApplyModal(false);
        setPreview(true);
        setTrigger((prev) => !prev);
        setValidated(false);
        setPrimaryImages({ value: [] });
        setSecondaryImages({ value: [] });
        setSelectedCategories([]);
      } catch (error) {
        console.error("Error sending data to server:", error);
        setIsloading(false);
      }
    }
  };

  const handleSectionNameChange = (index, value) => {
    setDetectChange(false);
    const updatedSections = [...sections];
    updatedSections[index].sectionName = value;
    setSections(updatedSections);
  };

  const setImagesValue = (valueOrCallback, isPrimary) => {
    setDetectChange(false);
    const setImageState = isPrimary ? setPrimaryImages : setSecondaryImages;
    setImageState((oldValue) => ({ ...oldValue, value: typeof valueOrCallback === "function" ? valueOrCallback(oldValue.value) : valueOrCallback }));
  };
  const setSectionsImagesValue = (valueOrCallback, isPrimary) => {
    const setImageState = isPrimary ? setSectionOneImages : setSectionTwoImages;
    setImageState((oldValue) => ({ ...oldValue, value: typeof valueOrCallback === "function" ? valueOrCallback(oldValue.value) : valueOrCallback }));
  };
  const removeItems = (index, i) => {
    setDetectChange(false);
    let values = [...sections];
    values[index].sectionItems.splice(i, 1);
    setSections(values);
  };
  const removeItemsOfBanner = (i, banner) => {
    setDetectChange(false);
    if (banner === "PrimaryImages") {
      let values = [...PrimaryImages.value];
      values.splice(i, 1);
      setPrimaryImages({ value: values });
    } else {
      let values = [...SecondaryImages.value];
      values.splice(i, 1);
      setSecondaryImages({ value: values });
    }
  };
  return (
    <div>
      <LoaderOverlay loading={isLoading} />
      <PopUpModal
        customSize={true}
        marginright="px-1"
        size={selectedTheme?.value !== "Theme 5" ? "lg" : "md"}
        title={`${selectedTheme?.value}`}
        show={applyModal}
        onHide={() => {
          setSections();
          setApplyModal(false);
          setPrimaryImages({ initialValue: [], value: [] });
          setSecondaryImages({ initialValue: [], value: [] });
          setValidated(false);
          setSetNav("slider");
          setIsSubmitted(false);
          setEdit(false);
        }}
      >
        <Form onSubmit={handleSubmit}>
          {/* {selectedTheme?.value !== "Theme 5" && ( */}
          <>
            <div className="d-flex gap-2 flex-row justify-content-end align-items-end mb-1 w-100">
              <Badge bg="success">Done</Badge>
              <Badge bg="danger">Empty</Badge>
            </div>
            <Row>
              <Col lg="9" md={8} className="border-end">
                <Row>
                  <Col lg="3" md={4}>
                    <div className={`bg-light w-100 ${styles.sidebar} p-2`}>
                      <div
                        onClick={() => {
                          setSetNav("slider");
                          setSetbox("top-slider");
                          setValidated(false);
                          setIsSubmitted(false);
                        }}
                        className={`d-flex my-2 p-2 border-bottom cursor-pointer ${setNav === "slider" && styles.selectedNav}`}
                      >
                        <FontAwesomeIcon style={{ fontSize: "24px" }} icon={faImage} />
                        <span className="fs-16 ms-2 fw-bold d-md-none d-lg-block ">Slider</span>
                      </div>

                      {!(selectedTheme?.value === "Theme 5") && (
                        <div
                          onClick={() => {
                            setSetNav("sections");
                          }}
                          className={`d-flex my-2 p-2 border-bottom cursor-pointer ${setNav === "sections" && styles.selectedNav}`}
                        >
                          <FontAwesomeIcon style={{ fontSize: "24px" }} icon={faBoxes} />
                          <span className="fs-16 ms-2 fw-bold d-md-none d-lg-block">Sections</span>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg="9" md="8" className={`${styles.formdiv}`}>
                    {setNav === "slider" && selectedTheme?.topSlider ? (
                      <div className=" ">
                        <div
                          onClick={() => {
                            setSetbox("slider-1");
                            setValidated(false);
                            setIsSubmitted(false);
                          }}
                          className=" w-100"
                        >
                          <div className="d-grid p-1">
                            <label className="mb-2">
                              Primary Banner{" "}
                              <span className=" ">
                                {" "}
                                [Upload Upto {matchedField?.PrimaryBannerLimit ? matchedField?.PrimaryBannerLimit : 1} <strong>Banner</strong>(<strong>s</strong>)]
                              </span>{" "}
                              {selectedTheme?.value !== "Theme 5" ? <span className={`text-danger`}>*</span> : null}
                            </label>
                            <small>Recommended Size: 1920 x 500 px</small>
                          </div>
                          <ImageUploaderForThemes
                            customize={true}
                            numberOfImages={matchedField?.PrimaryBannerLimit ? matchedField?.PrimaryBannerLimit : 1}
                            ref={imageComponent}
                            selectedFiles={PrimaryImages.value}
                            maxFiles={1}
                            setSelectedFiles={(value) => {
                              setImagesValue(value, true);
                            }}
                          />
                        </div>
                        {PrimaryImages?.value?.map((item, index) => (
                          <>
                            <div
                              onClick={() => {
                                setSetbox("slider-1");
                                setValidated(false);
                                setIsSubmitted(false);
                              }}
                              key={index}
                              className="d-grid p-1  w-100 my-2"
                            >
                              <div style={{ maxHeight: "200px", overflow: "auto" }}>
                                <>
                                  <div className="w-100 bg-light d-flex align-items-center justify-content-between ">
                                    <div className="d-flex">
                                      {item?.preview ? (
                                        <div>
                                          <img
                                            style={{ borderRadius: "6px" }}
                                            width="100"
                                            height="40"
                                            alt="thumbnail"
                                            src={item.preview}
                                            className="me-2"
                                            // onError={() => {
                                            //   setImgSrc(noImage);
                                            // }}
                                          />
                                        </div>
                                      ) : (
                                        <ThumbnailImage srcInput={item?.key} />
                                      )}
                                      {selectedTheme?.value !== "Theme 5" ? (
                                        <>
                                          <label className="me-2 fw-bold mt-2 ms-2 ">Link:</label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Link here"
                                            value={item.link}
                                            onChange={(e) => {
                                              if (validateURL(e.target.value)) {
                                                let values = [...PrimaryImages.value];
                                                values[index] = {
                                                  ...values[index],
                                                  link: e.target.value,
                                                };
                                                setPrimaryImages({ value: values });
                                                setDetectChange(false);
                                              } else {
                                              }
                                            }}
                                            className="my-1"
                                          />
                                        </>
                                      ) : null}
                                    </div>
                                    <span
                                      onClick={() => {
                                        removeItemsOfBanner(index, "PrimaryImages");
                                        setValidated(false);
                                        setIsSubmitted(false);
                                      }}
                                      className="mx-2 cursor-pointer"
                                    >
                                      x
                                    </span>
                                  </div>
                                </>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                    <hr />
                    {setNav === "slider" && selectedTheme?.bottomSlider ? (
                      <div className=" ">
                        <div
                          onClick={() => {
                            setSetbox("slider-2");
                            setValidated(false);
                            setIsSubmitted(false);
                          }}
                          className="w-100 "
                          style={{ display: selectedTheme?.value === "Theme 5" || selectedTheme?.value === "Theme 4" ? "none" : "block" }}
                        >
                          <div className="d-grid p-1">
                            <label className="mb-2 ">
                              Secondary Banner
                              <span className=" ">
                                {" "}
                                [Upload Upto {matchedField?.SecondaryBannerLimit} <strong>Banner</strong>(<strong>s</strong>)]
                              </span>{" "}
                              <span className="text-danger">*</span>
                            </label>
                            {selectedTheme?.value === "Theme 3" ? <small> Recommended Size: 365 x 915 px </small> : <small>Recommended Size: 1920 x 500 px</small>}
                          </div>
                          <ImageUploaderForThemes
                            customize={true}
                            ref={imageComponent}
                            selectedFiles={SecondaryImages.value}
                            numberOfImages={matchedField?.SecondaryBannerLimit}
                            setSelectedFiles={(value) => {
                              setImagesValue(value, false);
                            }}
                          />
                        </div>
                        {SecondaryImages?.value?.map((item, index) => (
                          <>
                            <div
                              onClick={() => {
                                setSetbox("slider-2");
                                setValidated(false);
                                setIsSubmitted(false);
                              }}
                              key={index}
                              className="d-grid p-1  w-100 my-2"
                            >
                              <div style={{ maxHeight: "200px", overflow: "auto" }}>
                                <>
                                  <div className="w-100 bg-light d-flex align-items-center justify-content-between ">
                                    <div className="d-flex">
                                      {item.preview ? (
                                        <div>
                                          <img
                                            style={{ borderRadius: "6px" }}
                                            width="100"
                                            height="40"
                                            alt="thumbnail"
                                            src={item.preview}
                                            className="me-2"
                                            // onError={() => {
                                            //   setImgSrc(noImage);
                                            // }}
                                          />
                                        </div>
                                      ) : (
                                        <ThumbnailImage srcInput={item.key} />
                                      )}
                                      <label className="me-2 fw-bold mt-2 ms-2 ">Link:</label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Link here"
                                        value={item.link}
                                        onChange={(e) => {
                                          if (validateURL(e.target.value)) {
                                            let values = [...SecondaryImages.value];
                                            values[index] = {
                                              ...values[index],
                                              link: e.target.value,
                                            };
                                            setSecondaryImages({ value: values });
                                            setDetectChange(false);
                                          }
                                        }}
                                        className="my-1"
                                      />
                                    </div>
                                    <span
                                      onClick={() => {
                                        removeItemsOfBanner(index, "SecondaryImages");
                                        setValidated(false);
                                        setIsSubmitted(false);
                                      }}
                                      className="mx-2 cursor-pointer"
                                    >
                                      x
                                    </span>
                                  </div>
                                </>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}

                    {selectedTheme?.topCategory && setNav === "sections" ? (
                      <div
                        onClick={() => {
                          setSetbox("top-cat");
                          setValidated(false);
                          setIsSubmitted(false);
                        }}
                        className="my-3"
                      >
                        <Form.Label htmlFor="category.label">
                          <strong>
                            Top Categories <span> (Select Upto 3 Categories)</span> <span className="text-danger">*</span>
                          </strong>
                          <br />
                          <small>Categories like tablet , mobile etc</small>
                        </Form.Label>
                        <Select
                          isMulti
                          options={categoryOptions}
                          value={selectedCategories}
                          onChange={(e) => {
                            setDetectChange(false);
                            handleSelectChange(e);
                          }}
                          isClearable
                          required
                          isSearchable={false}
                          className="my-3 w-100 "
                        />
                      </div>
                    ) : null}

                    {setNav === "sections" ? (
                      <>
                        <small>
                          Select a <strong>Section</strong> Name and Add Items through the <strong>Add Products</strong> Button{" "}
                        </small>
                        {sections?.map((section, index) => (
                          <>
                            <label className="d-flex mt-3">
                              Section {index + 1}{" "}
                              <span className=" ms-1">
                                {" "}
                                [Select Upto {selectedTheme?.MaxpProducsPerSections} <strong>Product</strong>(<strong>s</strong>)]
                              </span>{" "}
                              <span className="text-danger">*</span>
                              <div>{/* <h3>{section?.name}</h3> */}</div>
                              <Badge className="mx-2 my-0 p-0 bg-light shadow-sm text-dark "></Badge>
                            </label>
                            <div
                              onClick={() => {
                                setSetbox(`section-${index}`);
                                setValidated(false);
                                setIsSubmitted(false);
                              }}
                              key={index}
                              className="d-grid  w-100 my-1"
                            >
                              <div className="row flex-nowrap">
                                <div className="col-6">
                                  <Select
                                    options={sectionsList.filter((option) => !sections.some((section) => section.sectionName === option.value))}
                                    onChange={(e) => {
                                      handleSectionNameChange(index, e ? e.value : null);
                                      setSelectedSections(e);
                                    }}
                                    value={{ label: section.sectionName, value: section.sectionName || "" }}
                                    isClearable
                                    isSearchable={false}
                                    classNamePrefix="select"
                                    className=" w-100 "
                                    placeholder="Section Name"
                                    required
                                  />
                                  {section.name === "other" ? (
                                    <Form.Control
                                      type="text"
                                      value={section.name}
                                      onChange={(e) => {
                                        handleSectionNameChange(index, e.target.value);
                                      }}
                                      className="mt-2"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="col-6 row flex-nowrap">
                                  <Button
                                    className="btn-sm col-7 me-2"
                                    onClick={() => {
                                      setSecId(index);
                                      setShowModal(true);
                                      setValidated(false);
                                      setIsSubmitted(false);
                                    }}
                                  >
                                    Add Products
                                  </Button>
                                  <div className="col-5 btn btn-outline-secondary btn-sm disabled d-flex justify-content-center align-items-center">
                                    <span className="text-muted">Item(s): {section?.sectionItems?.length}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{ maxHeight: "200px", overflow: "auto" }}>
                              {section?.sectionItems?.map((item, i) => (
                                <>
                                  <div className="w-100 bg-light d-flex justify-content-between ">
                                    <div className="d-flex">
                                      <ThumbnailImage srcInput={item.image} />
                                      <span className="my-1 ">{item.title}</span>
                                    </div>
                                    <span
                                      onClick={() => {
                                        removeItems(index, i);
                                        setSetbox(`section-${index}`);
                                        setValidated(false);
                                        setIsSubmitted(false);
                                      }}
                                      className="mx-2 cursor-pointer"
                                    >
                                      x
                                    </span>
                                  </div>
                                </>
                              ))}
                            </div>
                          </>
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </Col>
              {/* <div className="vr" ></div> */}
              <Col lg="3" md="4">
                <div
                  style={{ opacity: "0.6", background: "repeating-linear-gradient(  45deg,  lightgray,  rgba(229,235,238,1) 13px,  rgba(229,235,238,1) 27px,  rgba(246,248,249,1) 20px)", backgroundRepeat: "repeat", height: "80px", border: "1px solid lightgray" }}
                  className={`   ${setbox === "slider-1" && styles.selected} ${validated ? (PrimaryImages.value?.length === 0 ? styles.empty : styles.complete) : ""}`}
                >
                  <span className=" h-100 w-100 d-flex justify-content-center align-items-center  ">Primary Banner</span>
                </div>
                {!(selectedTheme?.value === "Theme 5") ? (
                  <div
                    style={{ opacity: "0.6", height: "80px", border: "1px solid lightgray" }}
                    className={`mt-2 ${setbox === "section-0" && styles.selected} ${validated ? (sections[0] && Object.values(validated && sections[0]).some((value) => value === null || value === undefined || value === "") ? styles.empty : styles.complete) : ""}`}
                  >
                    <span className="  w-100 d-flex justify-content-center fs-14  ">Section 3</span>
                    <div className="d-flex mt-1  justify-content-center">
                      <div
                        className=" mx-2 bg-light"
                        style={{ opacity: "0.6", background: "repeating-repeating-linear-gradient(  45deg,  lightgray,  rgba(229,235,238,1) 13px,  rgba(229,235,238,1) 27px,  rgba(246,248,249,1) 20px)", height: "24px", width: "24px", border: "1px solid lightgray" }}
                      ></div>
                      <div
                        className=" mx-2 bg-light"
                        style={{ opacity: "0.6", background: "repeating-repeating-linear-gradient(  45deg,  lightgray,  rgba(229,235,238,1) 13px,  rgba(229,235,238,1) 27px,  rgba(246,248,249,1) 20px)", height: "24px", width: "24px", border: "1px solid lightgray" }}
                      ></div>
                      <div
                        className=" mx-2 bg-light"
                        style={{ opacity: "0.6", background: "repeating-repeating-linear-gradient(  45deg,  lightgray,  rgba(229,235,238,1) 13px,  rgba(229,235,238,1) 27px,  rgba(246,248,249,1) 20px)", height: "24px", width: "24px", border: "1px solid lightgray" }}
                      ></div>
                      <div
                        className=" mx-2 bg-light"
                        style={{ opacity: "0.6", background: "repeating-repeating-linear-gradient(  45deg,  lightgray,  rgba(229,235,238,1) 13px,  rgba(229,235,238,1) 27px,  rgba(246,248,249,1) 20px)", height: "24px", width: "24px", border: "1px solid lightgray" }}
                      ></div>
                    </div>
                  </div>
                ) : null}

                {sections?.length > 2 ? (
                  <div
                    style={{ opacity: "0.6", height: "80px", border: "1px solid lightgray" }}
                    className={`   mt-2 ${setbox === "section-2" && styles.selected}  ${validated ? (Object.values(validated && sections[2]).some((value) => value === null || value === undefined || value === "") ? styles.empty : styles.complete) : ""} `}
                  >
                    <span className="  w-100 d-flex justify-content-center fs-14  ">Section 1</span>
                    <div className="d-flex mt-1  justify-content-center">
                      <div
                        className=" mx-2 bg-light"
                        style={{ opacity: "0.6", background: "repeating-repeating-linear-gradient(  45deg,  lightgray,  rgba(229,235,238,1) 13px,  rgba(229,235,238,1) 27px,  rgba(246,248,249,1) 20px)", height: "24px", width: "24px", border: "1px solid lightgray" }}
                      ></div>
                      <div
                        className=" mx-2 bg-light"
                        style={{ opacity: "0.6", background: "repeating-repeating-linear-gradient(  45deg,  lightgray,  rgba(229,235,238,1) 13px,  rgba(229,235,238,1) 27px,  rgba(246,248,249,1) 20px)", height: "24px", width: "24px", border: "1px solid lightgray" }}
                      ></div>
                      <div
                        className=" mx-2 bg-light"
                        style={{ opacity: "0.6", background: "repeating-repeating-linear-gradient(  45deg,  lightgray,  rgba(229,235,238,1) 13px,  rgba(229,235,238,1) 27px,  rgba(246,248,249,1) 20px)", height: "24px", width: "24px", border: "1px solid lightgray" }}
                      ></div>
                      <div
                        className=" mx-2 bg-light"
                        style={{ opacity: "0.6", background: "repeating-repeating-linear-gradient(  45deg,  lightgray,  rgba(229,235,238,1) 13px,  rgba(229,235,238,1) 27px,  rgba(246,248,249,1) 20px)", height: "24px", width: "24px", border: "1px solid lightgray" }}
                      ></div>
                    </div>
                  </div>
                ) : null}
                {selectedTheme?.value === "Theme 3" && (
                  <div className="d-flex justify-content-between">
                    <div
                      style={{ opacity: "0.6", background: "repeating-linear-gradient(  45deg,  lightgray,  rgba(229,235,238,1) 13px,  rgba(229,235,238,1) 27px,  rgba(246,248,249,1) 20px)", height: "80px", border: "1px solid lightgray" }}
                      className={` text-center    ${setbox === "slider-2" && styles.selected}  ${validated ? (SecondaryImages.value?.length === 0 ? styles.empty : styles.complete) : ""} mt-2 me-2 w-100`}
                    >
                      <span className=" h-100 w-100 d-flex justify-content-center align-items-center  ">Secondary Banner</span>
                    </div>
                    <div
                      style={{ opacity: "0.6", background: "repeating-linear-gradient(  45deg,  lightgray,  rgba(229,235,238,1) 13px,  rgba(229,235,238,1) 27px,  rgba(246,248,249,1) 20px)", height: "80px", border: "1px solid lightgray" }}
                      className={` text-center  ${setbox === "slider-2" && styles.selected}  ${validated ? (SecondaryImages.value?.length < 2 || SecondaryImages.value?.length === 0 ? styles.empty : styles.complete) : ""} mt-2 w-100`}
                    >
                      <span className=" h-100 w-100 d-flex justify-content-center align-items-center  ">Secondary Banner</span>
                    </div>
                  </div>
                )}
                {selectedTheme?.topCategory ? (
                  <div
                    style={{ opacity: "0.6", height: "80px", border: "1px solid lightgray" }}
                    className={` ${setbox === "top-cat" ? styles.selected : ""} ${validated ? (selectedCategories.length === 0 ? styles.empty : styles.complete) : ""}  mt-2 d-grid justify-content-center align-items-center "`}
                  >
                    <span className="  w-100 d-flex justify-content-center fs-14  ">Categories</span>
                    <div className="d-flex justify-content-center align-items-center gap-4">
                      <div className="bg-light" style={{ opacity: "0.6", height: "40px", width: "40px", borderRadius: "50%", border: "1px solid lightgray" }}></div>
                      <div className="bg-light" style={{ opacity: "0.6", height: "40px", width: "40px", borderRadius: "50%", border: "1px solid lightgray" }}></div>
                      <div className="bg-light" style={{ opacity: "0.6", height: "40px", width: "40px", borderRadius: "50%", border: "1px solid lightgray" }}></div>
                    </div>
                  </div>
                ) : null}

                {selectedTheme?.bottomSlider && selectedTheme.value !== "Theme 3" ? (
                  <div
                    style={{ opacity: "0.6", background: "repeating-linear-gradient(  45deg,  lightgray,  rgba(229,235,238,1) 13px,  rgba(229,235,238,1) 27px,  rgba(246,248,249,1) 20px)", height: "80px", border: "1px solid lightgray" }}
                    className={`  ${setbox === "slider-2" && styles.selected}  ${validated ? (SecondaryImages?.value?.length === 0 ? styles.empty : styles.complete) : ""} mt-2`}
                  >
                    <span className=" h-100 w-100 d-flex justify-content-center align-items-center  ">Secondary Banner</span>
                  </div>
                ) : null}

                {!(selectedTheme?.value === "Theme 5") ? (
                  <div
                    style={{ opacity: "0.6", height: "80px", border: "1px solid lightgray" }}
                    className={`mt-2 ${setbox === "section-1" && styles.selected} ${validated ? (sections[1] && Object.values(validated && sections[1]).some((value) => value === null || value === undefined || value === "") ? styles.empty : styles.complete) : ""}`}
                  >
                    <span className="  w-100 d-flex justify-content-center fs-14  ">Section 2</span>
                    <div className="d-flex mt-1  justify-content-center">
                      <div
                        className=" mx-2 bg-light"
                        style={{ opacity: "0.6", background: "repeating-repeating-linear-gradient(  45deg,  lightgray,  rgba(229,235,238,1) 13px,  rgba(229,235,238,1) 27px,  rgba(246,248,249,1) 20px)", height: "24px", width: "24px", border: "1px solid lightgray" }}
                      ></div>
                      <div
                        className=" mx-2 bg-light"
                        style={{ opacity: "0.6", background: "repeating-repeating-linear-gradient(  45deg,  lightgray,  rgba(229,235,238,1) 13px,  rgba(229,235,238,1) 27px,  rgba(246,248,249,1) 20px)", height: "24px", width: "24px", border: "1px solid lightgray" }}
                      ></div>
                      <div
                        className=" mx-2 bg-light"
                        style={{ opacity: "0.6", background: "repeating-repeating-linear-gradient(  45deg,  lightgray,  rgba(229,235,238,1) 13px,  rgba(229,235,238,1) 27px,  rgba(246,248,249,1) 20px)", height: "24px", width: "24px", border: "1px solid lightgray" }}
                      ></div>
                      <div
                        className=" mx-2 bg-light"
                        style={{ opacity: "0.6", background: "repeating-repeating-linear-gradient(  45deg,  lightgray,  rgba(229,235,238,1) 13px,  rgba(229,235,238,1) 27px,  rgba(246,248,249,1) 20px)", height: "24px", width: "24px", border: "1px solid lightgray" }}
                      ></div>
                    </div>
                    <div className="d-flex mt-2"></div>
                  </div>
                ) : null}
              </Col>
            </Row>
          </>
          {/* )} */}
          {formError && <p className="text-center text-danger">{formError}</p>}
          <div className="d-flex flex-row gap-2 w-100  justify-content-end align-items-end">
            <div className="d-flex w-50 gap-2 me-4 my-2">
              {selectedTheme?.value !== "Theme 5" && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    if (setNav === "slider") {
                      setSetNav("sections");
                    } else {
                      setSetNav("slider");
                    }
                  }}
                  className="justify-content-end align-items-end w-25"
                >
                  {setNav === "sections" ? "Back" : "Next"}{" "}
                </Button>
              )}
              {isSubmitted ? (
                <a href={`${process.env.REACT_APP_MARKETPLACE_URL}/store/${user.username}/Preview?token=${token}`} target={"_blank"} size="sm" type="submit" className=" btn btn-primary justify-content-end align-items-end w-25">
                  Preview
                </a>
              ) : setNav !== "slider" || selectedTheme?.value === "Theme 5" ? (
                <Button disabled={selectedTheme?.value === "Theme 5" ? false : detectChange} size="sm" type="submit" className="justify-content-end align-items-end ">
                  Submit
                </Button>
              ) : null}
            </div>
          </div>
        </Form>
      </PopUpModal>
      <Modal
        show={preview}
        onHide={() => {
          setPreview(false);
        }}
        size={"sm"}
        centered
        dialogClassName={`${styles.customizeModal}`}
        fullscreen="lg-down"
      >
        <Modal.Body className={`p-3 d-flex justify-content-center overflow-hidden  `}>
          <a href={`${process.env.REACT_APP_MARKETPLACE_URL}/store/${user.username}/Preview?token=${token}${edit ? "&edit=true" : ""}`} target={"_blank"} size="sm" type="submit" className=" btn btn-success justify-content-end align-items-end w-50">
            Preview <FontAwesomeIcon icon={faArrowAltCircleUp} />
          </a>
        </Modal.Body>
      </Modal>
      <FeatureditemsModal matchedField={matchedField} customize={true} setDetectChange={setDetectChange} secId={secId} show={showModal} onHide={() => setShowModal(false)} sections={sections} setSections={setSections} />
    </div>
  );
}

export default ApplyThemeModal;
