import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { driver } from "driver.js";
import { updateTourGuide } from "../Features/authSlice";

const OrdersTour = ({ refreshHandle }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const ordersTour = driver({
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Orders", value: true } }));
      setTimeout(() => {
        refreshHandle();
        ordersTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Orders",
        popover: {
          title: `Orders`,
          description: "Shows and manages orders placed from the marketplace.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#orders-search",
        popover: {
          title: `Search Bar`,
          description: "Here seller can search any order by  it’s listing’s title , Item name, or order number.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#orders-date-filter",
        popover: {
          title: `Days Filter`,
          description: "Here seller can choose which days’s order he wants to see.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#orders-category",
        popover: {
          title: `Category Filter`,
          description: "From this dropdown seller can select which category’s orders he wants to see.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#orders-status-filter",
        popover: {
          title: `Status Filter`,
          description: "Seller can see orders based on status.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#orders-page-size",
        popover: {
          title: `Page Size`,
          description: "Seller can change the number of orders per page.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#orders-filter-button",
        popover: {
          title: `Reset Filters`,
          description: "Clicking on this button will remove all applied filters on the search result.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#orders-table > div:nth-child(1)",
        popover: {
          title: `Orders List`,
          description: "This table shows all orders details with actions of 'Sell' and 'Invoice'.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#action-button",
        popover: {
          title: `Sell`,
          description: `Seller can sell ordered items directly from here.`,
          side: "right",
          align: "start",
        },
      },
      {
        element: "#csv-download",
        popover: {
          title: `Download CSV`,
          description: "This button downloads all the orders information in CSV file.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Orders", value: true } }));
            setTimeout(() => {
              refreshHandle();
              ordersTour.destroy();
            }, 100);
          },
        },
      },
    ],
  });
  useEffect(() => {
    ordersTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};
export default OrdersTour;
