import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";
const JournalTour = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const journalTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Journal", value: true } }));
      journalTour.destroy();
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#journal",
        popover: {
          title: `Journal`,
          description: "This report acts as a ledger, recording all financial transactions such as purchases, expenses, and sales.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#journal-filter",
        popover: {
          title: `Filters`,
          description: "Shows journal report based on specific filters.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#journal-combined",
        popover: {
          title: `Combined`,
          description: "Shows a summary of all transactions, including purchases, sales, expenses, and taxes, filtered by day and location.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#journal-purchases",
        popover: {
          title: `Purchases`,
          description: "Shows detailed records of all purchase transactions, filtered by day and location.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#journal-sales",
        popover: {
          title: `Sales`,
          description: "Shows detailed records of all sales transactions, filtered by day and location.",
          side: "right",
          align: "start",
        },
      },

      {
        element: "#journal-trades",
        popover: {
          title: `Trades`,
          description: "The report provides a complete overview of all transactions related to trades, filtered by day and location.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#journal-expenses",
        popover: {
          title: `Expenses`,
          description: "Shows a complete overview of all transactions related to expenses.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#journal-refunds",
        popover: {
          title: `Refunds`,
          description: "The report provides a complete overview of all transactions related to refunds, filtered by day and location.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Journal", value: true } }));
            journalTour.destroy();
          },
        },
      },
    ],
  });
  useEffect(() => {
    journalTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default JournalTour;
