import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import style1 from "./../../styles/InvoiceCard.module.css";
import style from "./../../styles/Invoice.module.css";
import { Col, Row, Table, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useRef, useCallback } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import "./../../styles/printLayout.css";
import Loading from "../../Components/Loading/Loading";
import ThermalTradeInInvoice from "./ThermalTradeInInvoice";
import LoaderPage from "../../Components/loaders/loaderPage";

const TradeInInvoice = () => {
  const componentRef = useRef(null);
  const thermalcomponentRef = useRef(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const { printerType } = useSelector((state) => state.printerType);
  const logo = user.logo_path;
  const shopId = user.shop_id;
  const [items, setItems] = useState([]);
  const [createdAtDate, setCreatedAt] = useState();
  const { id } = useParams();
  const [isloading, setisLoading] = useState(true);

  useEffect(() => {
    const pageTimeout = setTimeout(() => {
      axios
        .get(process.env.REACT_APP_API_URL + `/trade-in/${id}`, {
          withCredentials: true,
        })
        .then((res) => {
          setInvoiceData(res.data?.data[0]);
          setItems(res.data?.items);
          setCreatedAt(new Date(res.data.data[0]?.created_at).toLocaleDateString());
          setisLoading(false);
        })
        .catch((err) => {
          setisLoading(false);
          throw new Error(err);
        });
    }, 1000);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [id, shopId]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
  });
  const reactToPrintThermalContent = useCallback(() => {
    return thermalcomponentRef.current;
  }, []);

  const handleThermalPrint = useReactToPrint({
    content: reactToPrintThermalContent,
  });

  return (
    <>
      {isloading ? (
        <div style={{ height: "50vh", display: "flex", justifyContent: "center" }}>
          <LoaderPage />
        </div>
      ) : items.length !== 0 ? (
        <>
          {printerType?.value === "thermal" ? (
            <div className={`my-4 d-flex justify-content-center gap-3`}>
              <Button variant="outline-primary" className="px-4" onClick={handleThermalPrint}>
                Print{" "}
              </Button>
            </div>
          ) : (
            <div className={`my-4 d-flex justify-content-center gap-3`}>
              <Button variant="outline-primary" className="px-4" onClick={handlePrint}>
                Print{" "}
              </Button>
            </div>
          )}
          {printerType?.value !== "thermal" ? (
            <div className="printComponent">
              <Card className={`border-0 ${style1.invoiceCard} ${style1.formCard} mb-5`}>
                <div className="p-5 laser-invoice" ref={componentRef}>
                  <div className={`d-flex shopInfo ${logo !== null ? "justify-content-between" : "justify-content-end"} ${style.logoadd}`}>
                    <div>{logo !== null && <img src={process.env.REACT_APP_URL_S3 + "/" + logo} alt="" className={`${style1.logoimg} logo ${style.logo}`} />}</div>
                    <div className={`d-flex flex-column text-end mb-4 address ${style.address}`}>
                      <h5 style={{ color: "#39414A", marginBottom: "0rem" }}>Address</h5>
                      <span style={{ color: "#39414A", marginBottom: "0rem" }} className={`${style.addresstxt}`}>
                        {invoiceData?.shop_address}
                      </span>
                      <span style={{ color: "#39414A", marginBottom: "0rem" }} className={`${style.addresstxt}`}>
                        Phone:{" "}
                        {invoiceData?.is_contact_no_on_invoice === 1 && invoiceData?.is_alternative_no_on_invoice === 1 ? (
                          <>
                            {invoiceData?.contact_number}
                            <br />
                            {invoiceData?.alternative_number}
                          </>
                        ) : invoiceData?.is_contact_no_on_invoice === 1 ? (
                          invoiceData?.contact_number
                        ) : invoiceData?.is_alternative_no_on_invoice === 1 ? (
                          invoiceData?.alternative_number
                        ) : (
                          invoiceData?.owner_whatsapp_number
                        )}
                      </span>
                      <span style={{ color: "#39414A" }} className={`${style.addresstxt}`}>
                        {invoiceData?.owner_email}
                      </span>
                    </div>
                  </div>
                  <h1 className={`mb-3 ${style.invoice}`}>Invoice</h1>
                  <Row>
                    <Col xs sm="5" md="5" lg="5" className="px-0 ps-3">
                      <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                        Customer details
                      </p>
                      <span
                        className="text-capitalize"
                        style={{
                          color: "#39414A",
                          marginBottom: "0rem",
                          fontSize: "18px",
                          fontWeight: "700",
                        }}
                      >
                        {invoiceData?.customer_name}
                      </span>
                      <div className={`mb-2 ${style.phn} px-0`}>
                        <p className={`${style.emailphone}`}>
                          <strong>Phone:</strong> {invoiceData?.customer_number}
                        </p>
                        <p className={`${style.emailphone}`}>
                          <strong>Email:</strong> {invoiceData?.customer_email}
                        </p>
                      </div>
                    </Col>
                    <Col xs sm="4" md="4" lg="4" className={`${style.invoicenum}`}>
                      <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                        Trade number
                      </p>
                      <h5 className={`${style.invoicetxt} fw-2`}>{invoiceData?.trade_number}</h5>
                    </Col>
                    <Col xs sm="3" md="3" lg="3" className={`${style.invoicenum}`}>
                      <p className={`${style.invoiceterms}`} style={{ textAlign: "inherit" }}>
                        Issue date
                      </p>
                      <h5 className={`${style.invoicetxt}`}>{createdAtDate}</h5>
                    </Col>
                  </Row>
                  <Table
                    style={{
                      border: "1px solid #DEDFE4",
                      tableLayout: "fixed",
                    }}
                    className={`mb-4 mt-3 ${style.invoiceTablePayments}`}
                  >
                    <tbody>
                      <tr style={{ background: "#F5F6FA" }}>
                        <th className={`${style.tabledescription}`}>Products</th>
                        <th className={`${style.tabletxt}`}>Trade-in Price</th>
                        <th className={`${style.tabletxt}`}>Total Qty</th>
                        <th className={`${style.tabletxt}`}>Total Price</th>
                      </tr>
                      {items?.map((data, index) => (
                        <tr key={index}>
                          <td className={`${style.tabledescription} d-flex flex-column`}>
                            {data?.title}
                            <span style={{ color: "#686E76", fontSize: "13px" }}>{data?.description}</span>
                          </td>
                          <td className={`${style.tabletxt}`}>{data?.trade_in_price}</td>
                          <td className={`${style.tabletxt}`}>{data?.purchase_quantity}</td>
                          <td className={`${style.tabletxt}`}>{data?.purchase_quantity * data?.trade_in_price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Row className="mt-3">
                    <Col xs="4" sm="4" md="6" lg="6"></Col>
                    <Col xs="4" sm="4" md="3" lg="3" style={{ marginBottom: "0.5rem" }}>
                      <span className={`${style.tabletxt}`}>Base Grand Total </span>
                    </Col>
                    <Col xs="4" sm="4" md="3" lg="3" className="text-center">
                      <span className={`${style.tabletxt}`}>{Number(invoiceData?.total_amount).toLocaleString("en-IN")}</span>
                    </Col>
                  </Row>
                  <p className={`${style.invoiceterms} mt-3`}>Invoice terms</p>
                  {invoiceData?.invoice_terms !== "nil" && <p className={`my-0 text-center terms ${style.terms}`}>* {invoiceData?.invoice_terms}</p>}
                  <p className={`mt-0 mb-3 text-center invoiceTerms fst-italic`}>Powered by TechBazaar POS</p>
                </div>
              </Card>
            </div>
          ) : (
            <ThermalTradeInInvoice ref={thermalcomponentRef} data={invoiceData} items={items} />
          )}
        </>
      ) : (
        <div style={{ height: "80vh" }} className="d-flex justify-content-center align-items-center">
          <h3>No data exists for this invoice</h3>
        </div>
      )}
    </>
  );
};
export default TradeInInvoice;
