import React, { useRef, useEffect } from "react";

function useOutsideAlerter(ref, setIsSandwichOpen) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (window.innerWidth < 920) {

        if (ref.current && !ref.current.contains(event.target)) {
          setIsSandwichOpen(true)
          // alert("You clicked outside of me!");
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default useOutsideAlerter;