import React, { useEffect } from "react";
// import logo from './../../../assets/images/logo.png'
import customloader from "../../../assets/images/RollingLoader.gif";
import axios from "axios";
import style from "./Invoice.module.css";
import style1 from "./../../../styles/InvoiceCard.module.css";
import { Table, Card } from "react-bootstrap";
import { useState } from "react";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import "./../../../styles/printLayout.css";
import Loading from "../../../Components/Loading/Loading";
import PrintLayout from "../../../Components/PrintLayout";

const InvoicePurchaseTemplate = forwardRef(({ id }, ref) => {
  const { user } = useSelector((state) => state.auth);
  const shopId = user.shop_id;
  const logo = user.logo_path;
  const [isloading, setisLoading] = useState(true);

  //data
  const [data, setData] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [totalPaid, setTotalPaid] = useState(0);
  // /purchase-order/payment/get-by-purchase/
  useEffect(() => {
    const getData = async () => {
      try {
        if (id !== -1) {
          const res = await axios.get(process.env.REACT_APP_API_URL + "/purchase-order/get-detail/" + id, { withCredentials: true });
          setData(res.data);
          const paymentRes = await axios.post(process.env.REACT_APP_API_URL + "/purchase-order/payment/get-by-purchase/" + id, { shopId: shopId }, { withCredentials: true });
          setPaymentData(paymentRes.data);
          let tempPayment = 0;
          paymentRes.data.map((pay) => {
            tempPayment = tempPayment + Number(pay.amount);
            return null;
          });
          setTotalPaid(tempPayment);
          setisLoading(false);
        }
      } catch (err) {
        // setIsError(true)
      }
    };
    getData();
  }, [id, shopId]);

  return (
    <>
      {isloading ? (
        <Loading />
      ) : data?.length === 0 ? (
        <div style={{ height: "80vh" }} className="d-flex justify-content-center align-items-center">
          <h3>No data exists for this invoice</h3>
        </div>
      ) : !data && !paymentData ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : (
        <PrintLayout ref={ref}>
          <Card className={`border-0 mb-5 ${style1.invoiceCard}` } style={{width:'fit-content'}}>
            <div className={`d-flex flex-column text-end p-5 `}>
              <div className="d-flex justify-content-between">
                <div className="col-md-6">{logo !== null && <img src={process.env.REACT_APP_URL_S3 + "/" + logo} alt="" className={`${style.brandLogo}`} />}</div>
                <div className="col-md-6 text-end">
                  <h5 style={{ color: "#39414A", marginBottom: "0rem" }}>Address</h5>
                  <div style={{ color: "#39414A", marginBottom: "0rem" }}>{data?.shop_address}</div>
                  {data?.shop_email !== "nil" ? <div style={{ color: "#39414A", marginBottom: "0rem" }}>{data?.shop_email}</div> : ""}
                  {data?.is_contact_no_on_invoice === 1 && data?.is_alternative_no_on_invoice === 1 ? (
                    <>
                      {data?.contact_number}
                      <br />
                      {data?.alternative_number}
                    </>
                  ) : data?.is_contact_no_on_invoice === 1 ? (
                    data?.contact_number
                  ) : data?.is_alternative_no_on_invoice === 1 ? (
                    data?.alternative_number
                  ) : (
                    data?.owner_whatsapp_number
                  )}
                  {/* {data?.shop_number !== "nil" ? (
                    <div>{data?.shop_number}</div>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
              <div className="mx-5 mt-2 border-bottom">
                <h3 className="d-flex justify-content-around" style={{ color: "#39414A" }}>
                  <span>Purchase Order#</span>
                  <span>{data?.purchase_order}</span>
                </h3>
              </div>
              <div className="mt-1 text-center">{data?.purchase_amount - totalPaid > 0 ? <h3 className="text-danger">PENDING</h3> : <h3 className="text-success">PAID</h3>}</div>
              <div className="d-flex justify-content-between px-5" style={{ color: "#39414A" }}>
                <div className="col-md-6">
                  <div>Vendor details</div>
                  <h4>{data?.vendor_name}</h4>
                  {data?.vendor_email !== "nil" ? <div>{data?.vendor_email}</div> : ""}
                  {data?.vendor_number !== "nil" ? <div>{data?.vendor_number}</div> : ""}
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div>
                      <div>Issue Date</div>
                      <h4>{new Date().toLocaleDateString()}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mx-5 mt-2">
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th colSpan={4} className="text-center">
                        <h5>Purchase Detail</h5>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={2}>
                        <strong>Title</strong>
                      </th>
                      <th
                        style={{
                          wordWrap: "break-word",
                        }}
                      >
                        <strong>Purchase Quantity</strong>
                      </th>
                      <th width="20%">
                        <strong>Cost Price</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {data.items.map((item, index) => (
                        <tr>
                          <td colSpan={2}>{item.title}</td>
                          <td>{item?.purchase_quantity}</td>
                          <td>{item.cost_price}</td>
                        </tr>
                      ))}
                    </>

                    <tr>
                      <td colSpan={4}>
                        <strong>Purchase Date:</strong> {data?.purchase_date ? new Date(data?.purchase_date).toDateString() : "Date Not Entered!"}
                      </td>
                    </tr>
                    {(data?.purchase_detail !== "nil" || "") && (
                      <tr>
                        <td colSpan={4}>
                          <strong>Memo:</strong>{" "}
                          {data?.purchase_detail !== "nil" || "" ? (
                            <p
                              style={{
                                maxWidth: "413px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxHeight: "10em",
                              }}
                            >
                              {data?.purchase_detail}
                            </p>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              {!paymentData.length > 0 ? (
                <div className="text-center">
                  <p>No Payment has been made!</p>
                </div>
              ) : (
                <div className="mx-5 my-2">
                  <Table striped bordered style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th colSpan={5} className="text-center">
                          <h5>Payments Detail</h5>
                        </th>
                      </tr>
                      <tr>
                        <th width="20%">
                          <strong>Invoice Number</strong>
                        </th>
                        <th
                          width="40%"
                          style={{
                            wordWrap: "break-word",
                          }}
                        >
                          <strong>Comments</strong>
                        </th>
                        <th width="20%">
                          <strong>Payment Date</strong>
                        </th>
                        <th width="20%">
                          <strong>Amount</strong>
                        </th>
                        <th width="20%">
                          <strong>Payment Type</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentData?.map((payment, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <strong>{payment.invoice_number}</strong>
                            </td>
                            <td>{payment.comments !== "Down Payment." ? (payment.comments !== "nil" ? payment.comments : "") : ""}</td>
                            <td>{new Date(payment.payment_date).toDateString()}</td>
                            <td>Rs. {Number(payment.amount).toLocaleString("en-IN")}</td>
                            <td>{payment.payment_type}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}

              <div className="d-flex justify-content-end mx-5 mt-2">
                <div className="col-md-6">
                  <Table>
                    <tbody>
                      <tr>
                        <td className="border-0">
                          <strong>Total Purchase Amount:</strong>
                        </td>
                        <td className="border-0">
                          <strong>Rs.</strong> {Number(data?.purchase_amount).toLocaleString("en-IN")}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Paid Amount:</strong>
                        </td>
                        <td>
                          <strong>Rs.</strong> {Number(totalPaid).toLocaleString("en-IN")}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Remaining Amount:</strong>
                        </td>
                        <td>
                          <strong>Rs.</strong> {Number(data?.purchase_amount - totalPaid).toLocaleString("en-IN")}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>

              <hr className="border-bottom" />
              {data?.invoice_terms !== "nil" && (
                <>
                  <p className="text-secondary mt-3 mb-2 text-center">Invoice Terms</p>
                  <p className={`my-0 text-center ${style.terms}`}>* {data?.vendor_invoice_terms}</p>
                </>
              )}
            </div>
          </Card>
        </PrintLayout>
      )}
    </>
  );
});

export default InvoicePurchaseTemplate;
