import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const ExpensesTour = ({ setModalAdd, setModalEdit }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const expensesTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "MyExpenses", value: true } }));
      setTimeout(() => {
        expensesTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-My-Expenses",
        popover: {
          title: "Expenses",
          description: "Overall shop expenses.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#expenses",
        popover: {
          title: `A guide to shop expenses`,
          description: "Shows and manages business-related expenses.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#expenses-search",
        popover: {
          title: `Search`,
          description: "Here seller can search any expense by it’s type or amount.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#expenses-type",
        popover: {
          title: `Expense Type`,
          description: "From this dropdown seller can select which category’s expense he wants to see.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#expenses-date-filter",
        popover: {
          title: `Days filter`,
          description: "Here seller can choose that which day’s expenses he wants to see.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#expenses-page-size",
        popover: {
          title: `Page size`,
          description: "From here seller can select the number of expenses he wants to see at a time.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#expenses-add-expense",
        popover: {
          title: `Add expense`,
          description: "Upon clicking on this button, seller can add new expense.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setModalAdd(true);
            setTimeout(() => {
              expensesTour.moveNext();
            });
          },
        },
      },
      {
        element: "#expense-add-modal > .modal-content",
        popover: {
          title: `Add New expense`,
          description: "A form to add a new expense.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setModalAdd(false);
            setTimeout(() => {
              expensesTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#expense-add-location",
        popover: {
          title: `Location`,
          description: "Here location will be auto-selected based on which location seller is present.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#expense-add-expense-type",
        popover: {
          title: `Expense Type`,
          description: "From here seller can select the expense type.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#expense-add-expense-amount",
        popover: {
          title: `Expense amount`,
          description: "Here seller can add expense amount.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#expense-add-payment-type",
        popover: {
          title: `Payment type`,
          description: "From here seller can select that by using which payment method(e.g cash, transfer, card, cheque) he wants to add expense.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#expense-add-expense-date",
        popover: {
          title: `Expense date`,
          description: "Here  date will be auto fetched based on present date.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#expense-add-comments",
        popover: {
          title: `Comments`,
          description: "Here seller can add comments related to that expense.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#expense-add-save-button",
        popover: {
          title: `Save`,
          description: "Clicking on it will save the expense information and add it to expense page.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setModalAdd(false);
            expensesTour.moveNext();
          },
        },
      },
      {
        element: "#expenses-reset-filter",
        popover: {
          title: `Reset Filters`,
          description: "Clicking on this button will remove all applied filters on the search result.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setModalAdd(true);
            setTimeout(() => {
              expensesTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#expenses-table > div:nth-child(1)",
        popover: {
          title: `Expense List`,
          description: "On this page, all added expenses will be shown.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#edit",
        popover: {
          title: `Edit`,
          description: "Upon clicking on this button, seller can edit any expense.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setModalEdit(true);
            setTimeout(() => {
              expensesTour.moveNext();
            });
          },
        },
      },
      {
        element: "#expense-edit-modal > .modal-content",
        popover: {
          title: `Edit Customer`,
          description: "A form to edit a expense.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setModalEdit(false);
            setTimeout(() => {
              expensesTour.movePrevious();
            });
          },
          onNextClick: () => {
            setModalEdit(false);
            setTimeout(() => {
              expensesTour.moveNext();
            });
          },
        },
      },
      {
        element: "#remove",
        popover: {
          title: `Delete`,
          description: "On clicking this seller will be shown a delete confirmation modal from where he can delete that expense.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setModalEdit(true);
            setTimeout(() => {
              expensesTour.movePrevious();
            });
          },
        },
      },

      {
        element: "#csv-download",
        popover: {
          title: `Download CSV`,
          description: "On clicking this, seller can download expenses information in csv file.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "MyExpenses", value: true } }));
            setTimeout(() => {
              expensesTour.destroy();
            }, 100);
          },
        },
      },
    ],
  });
  useEffect(() => {
    expensesTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default ExpensesTour;
