import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";
const SalesReportTour = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const salesReportTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "SalesReport", value: true } }));
      salesReportTour.destroy();
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#sales-report",
        popover: {
          title: `Sales Report`,
          description: "The Sales Report gives detailed insights into sales performance, showing sales volume, total revenue, and the products sold.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-report-location-filter",
        popover: {
          title: `Select Location`,
          description: "Shows the option to filter reports based on specific locations.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-report-day-filter",
        popover: {
          title: `Day Filter`,
          description: "Shows data for transactions and reports based on days.",
          side: "right",
          align: "start",
        },
      },

      {
        element: "#sales-report-reset-filters",
        popover: {
          title: `Reset Filters`,
          description: "Shows a button to reset all applied filters to their default state.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-report-sales-report",
        popover: {
          title: `Sales Report`,
          description: "Shows the total products sold, total sales value, number of sales, and total discount given in graphical form.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-report-by-product",
        popover: {
          title: `By Product`,
          description: "Shows detailed reports based on individual products, including sales volume, revenue generated, and stock levels.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-report-by-category",
        popover: {
          title: `By Category`,
          description: "Shows reports categorized by product types.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-report-by-location",
        popover: {
          title: `By Location`,
          description: "Shows reports that analyse sales data based on different geographical locations, allowing users to identify performance variations by region.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-report-by-customer",
        popover: {
          title: `By Customer`,
          description: "Shows reports that track purchases made by individual customers, helping identify key customers and their buying habits.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-report-by-salesperson",
        popover: {
          title: `By Salesperson`,
          description: "Shows reports that evaluate the performance of    each salesperson, including total sales made and customer interactions.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-report-by-bank-cash-credit",
        popover: {
          title: `By Bank/Cash/Credit`,
          description: "Shows financial reports based on payment methods, providing insights into the distribution of payments received.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-report-by-trend",
        popover: {
          title: `By Trend`,
          description: "Shows reports that analyse sales data over time, helping identify trends and patterns in sales performance.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "SalesReport", value: true } }));
            salesReportTour.destroy();
          },
        },
      },
    ],
  });
  useEffect(() => {
    salesReportTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default SalesReportTour;
