import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Card, Modal, Row,Col } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styles from "../../styles/PlansCard.module.css";
import RequestModal from "./RequestModal";
import {
    faEnvelopeCircleCheck,
    faMessage,
    faStore,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from 'react-redux';
import {authActions}from "../../Features/authSlice"
import PopUpModal from '../Modal';


const PlansModal = ({show,setShow}) => {

    const [plansData, setPlansData] = useState([]);
    const [frequency, setFrequency] = useState("monthly");
    const [modalShow, setModalShow] = useState(false);
    const [planId, setPlanId] = useState();
    const [changePlan, setChangePlan] = useState("");
    const [check, setCheck] = useState(true);
    const { user } = useSelector((state) => state.auth);
    const loc = useLocation();
    const shop_id = user.shop_id;
    const dispatch=useDispatch();
  
    useEffect(() => {
      axios
        .get(process.env.REACT_APP_API_URL + `/subscriptions/get/plans`)
        .then((res) => {
          setPlansData(
            res.data.filter((plan) => {
              if (plan.plan_type === "fixed") {
                return plan;
              }
            })
          );
        })
        .catch((err) => {
          throw new Error(err.response.data);
        });
    }, []);
    const handleFrequency = (e) => {
      setCheck(false);
      setFrequency(e.target.value);
      setTimeout(() => {
        setCheck(true);
      }, 200);
    };

    const onHide = () => {
      setShow(false);
      dispatch(authActions.throughLoginFalse());
    }
  return (
    <div style={{width: "100%"}}>
    <Modal show={show} onHide={onHide} centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title className='w-100 d-flex justify-content-center'>Renew Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
        <div className="d-flex gap-2 mt-2 justify-content-center ">
          <button
            name="plans"
            className={`"btn SubsButtons ${
              frequency === "monthly"
                ? "SubsButtonsActive"
                : "SubsButtonsinActive"
            }`}
            aria-label="radio Monthly"
            value="monthly"
            onClick={handleFrequency}
          >
            Monthly
          </button>
          <button
            name="plans"
            className={`"btn SubsButtons ${
              frequency === "quarterly"
                ? "SubsButtonsActive"
                : "SubsButtonsinActive"
            }`}
            aria-label="radio Quarterly"
            value="quarterly"
            checked={frequency === "quarterly"}
            onClick={handleFrequency}
          >
            Quarterly ( Save 10% )
          </button>
          <button
            name="plans"
            type="radio"
            className={`"btn SubsButtons ${
              frequency === "yearly"
                ? "SubsButtonsActive"
                : "SubsButtonsinActive"
            }`}
            aria-label="radio Yearly"
            value="yearly"
            checked={frequency === "yearly"}
            onClick={handleFrequency}
          >
            Yearly ( Save 20% )
          </button>
        </div>
      </Row>

      <Row
        className={`pt-5 mt-5  justify-content-center  plansRowinActive  ${
          check ? "plansRowActive" : "plansRowinActive"
        } `}
      >
        {plansData.map((i) => (
          <Col className='my-3' lg={3} md={5} sm={5} xs={12}>
            <Card
              className={`   ${styles.subsCard} ${
                i.plan_id === user.plan_id &&
                frequency === user.current_plan_billing_frequency
                  ? styles.subsCardActive
                  : styles.subsCardInActive
              }   `}
              style={{ height: "100%" }}
            >
              <Card.Body
                className={`${
                  i.plan_id === user.plan_id &&
                  frequency === user.current_plan_billing_frequency
                    ? "activeColor"
                    : "inactiveColor"
                }`}
              >
                <Card.Title className="text-center   ">
                  <h2
                    className={` ${
                      i.plan_id === user.plan_id &&
                      frequency === user.current_plan_billing_frequency
                        ? "activeColor"
                        : "inactiveColor"
                    } ${styles.subsh2}  font700  `}
                  >
                    {i.plan_name}
                  </h2>
                </Card.Title>
                <div
                  className={`${styles.subsdiv} ${
                    i.plan_id === user.plan_id &&
                    frequency === user.current_plan_billing_frequency
                      ? "activeColor"
                      : "inactiveColor"
                  } `}
                >
                  <div className="d-flex flex-column">
                    {frequency === "monthly" ? (
                      ""
                    ) : frequency === "quarterly" ? (
                      <span
                        style={{
                          textDecoration: "line-through",
                          fontSize: "20px",
                        }}
                        className=" me-5"
                      >
                        {i.monthly_grand_total * 3}
                      </span>
                    ) : (
                      <span
                        style={{
                          textDecoration: "line-through",
                          fontSize: "20px",
                        }}
                        className=" me-5"
                      >
                        {i.monthly_grand_total * 12}
                      </span>
                    )}
                    <span className="">
                      <span className={`${styles.subsspan1}`}>Rs.</span>
                      <span className={`${styles.subsspan2}`}>
                        {" "}
                        {frequency === "monthly"
                          ? i.monthly_grand_total
                          : frequency === "quarterly"
                          ? i.quarterly_grand_total
                          : frequency === "yearly"
                          ? i.yearly_grand_total
                          : ""}
                      </span>
                      <span className={`${styles.subsspan3}`}>
                        /{frequency}
                      </span>
                    </span>
                  </div>
                </div>
                <div className={`${styles.subsDetails}  `}>
                  <ul className={`${styles.subsul} `}>
                    <li className={`${styles.subsli}  font700`}>
                      <FontAwesomeIcon icon={faStore} /> MarketPlace Items{" "}
                      {frequency === "monthly"
                        ? i.marketplace_items_limit
                        : frequency === "quarterly"
                        ? Number(i.marketplace_items_limit) * 3
                        : frequency === "yearly"
                        ? i.marketplace_items_limit * 12
                        : ""}
                    </li>
                    <li className={`${styles.subsli}  font700`}>
                      <FontAwesomeIcon icon={faEnvelopeCircleCheck} /> Emails
                      Limit{" "}
                      {frequency === "monthly"
                        ? i.emails_limit
                        : frequency === "quarterly"
                        ? Number(i.emails_limit) * 3
                        : frequency === "yearly"
                        ? i.emails_limit * 12
                        : ""}
                    </li>
                    <li className={`${styles.subsli}  font700`}>
                      <FontAwesomeIcon icon={faMessage} /> SMS Limit{" "}
                      {frequency === "monthly"
                        ? i.sms_limit
                        : frequency === "quarterly"
                        ? Number(i.sms_limit) * 3
                        : frequency === "yearly"
                        ? i.sms_limit * 12
                        : ""}
                    </li>
                  </ul>
                </div>

                {user.on_payment === 1 ? (
                  i.plan_id > user.plan_id ? (
                    <button
                      className={`${styles.susbsbtnModal}`}
                      variant="primary"
                      onClick={() => {
                        setModalShow(true);
                        setPlanId(i.plan_id);
                        setChangePlan("Upgrade");
                      }}
                    >
                      Upgrade Plan
                    </button>
                  ) : i.plan_id < user.plan_id ? (
                    <button
                      className={`${styles.susbsbtnModal}`}
                      variant="primary"
                      onClick={() => {
                        setModalShow(true);
                        setPlanId(i.plan_id);
                        setChangePlan("Downgrade");
                      }}
                    >
                      Downgrade Plan
                    </button>
                  ) : i.plan_id === user.plan_id &&
                    frequency === user.current_plan_billing_frequency ? (
                    <>
                      <strong>Current Plan</strong>
                    </>
                  ) : (
                    <button
                      className={`${styles.susbsbtnModal}`}
                      variant="primary"
                      onClick={() => {
                        setModalShow(true);
                        setPlanId(i.plan_id);
                        setChangePlan("Change_Frequency");
                      }}
                    >
                      Change Frequency
                    </button>
                  )
                ) : (
                  user.on_trial === 1 && (
                    <button
                      className={`${styles.susbsbtnModal}`}
                      variant="primary"
                      onClick={() => {
                        setModalShow(true);
                        setPlanId(i.plan_id);
                        setChangePlan("");
                      }}
                    >
                      Buy Plan
                    </button>
                  )
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <PopUpModal
        title="Request"
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <RequestModal
          nextPlanDate={loc.state}
          shop_id={shop_id}
          request_status="pending"
          priority="high"
          request_type={
            user.on_trial === 1 ? "paid-plan-request" : "change-plan-request"
          }
          frequency={frequency[0].toUpperCase() + frequency.substring(1)}
          title={
            user.on_payment === 1
              ? `[${
                  frequency[0].toUpperCase() + frequency.substring(1)
                }]-${changePlan}_Plan_Request`
              : `[${
                  frequency[0].toUpperCase() + frequency.substring(1)
                }]-Paid_Plan_Request`
          }
          describe="Please Describe your request"
          placeholder="e.g. Want to move on paid plan immediately"
          planId={planId}
          close={() => setModalShow(false)}
        />
      </PopUpModal>

        
       
        </Modal.Body>
        


      </Modal> 
      </div>
  )
}

export default PlansModal