import { Button, Modal } from "react-bootstrap";
import customloader from "../../assets/images/RollingLoader.gif";
import Barcode from "react-barcode";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import style from "./barcode.module.css";
const barStyles = `
@media print {
    @page :first {
      size: 1.9in 1in;
  
    }
  }
`;
const BarcodeModal = ({ show, close, item }) => {
  const barcodeRef = useRef();
  const printBarcodeHandle = () => {
    alert("Print barcode here");
  };

  // const pageStyle = `
  // @page {
  //   size: auto;
  //   font-size: 10vm;
  // };
  // @media all {
  //   .pageBreak {
  //     display: none;
  //   };
  // };
  // @media print {
  //   .pageBreak {
  //     page-break-before: always;
  //   };
  // };
  // `;

  return (
    <>
      <style>{barStyles}</style>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="sm"
        backdrop="static"
        className="customerSalesModal"
        show={show}
        onHide={close}
        id="bar"
      >
        <Modal.Header className="" closeButton>
          <div className="d-flex align-items-center gap-4">
            <h4>Barcode</h4>
          </div>
        </Modal.Header>
        <hr className="m-0 mb-3 d-sm-none d-block" />
        {item ? (
          <div className="p-4">
            <div className={style.barcode} ref={barcodeRef}>
              <div
                className={
                  "d-flex flex-column w-100 justify-content-center text-center align-items-center bg-white"
                }
              >
                <strong className={style.barcodeTitleText}>
                  {item?.title}
                </strong>
                <Barcode
                  fontSize={15}
                  height={40}
                  value={item?.stock_no.toString()}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "auto" }}
          >
            <img
              src={customloader}
              alt="customloader"
              width="100"
              height="100"
            />
          </div>
        )}
        <Modal.Footer>
          <ReactToPrint
            trigger={() => <Button onClick={printBarcodeHandle}>Print</Button>}
            content={() => barcodeRef.current}
            // pageStyle={pageStyle}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BarcodeModal;
