import React, { useEffect, useState } from "react";
import axios from "axios";
import LoaderPage from "../../Components/loaders/loaderPage";
import { Button } from "react-bootstrap";
import Invoice from "./Invoice";
import { toast } from "react-toastify";

const DeliveryForYouOrdersInvoice = ({ close, invoiceId, refreshHandle }) => {
  const [invoiceData, setInvoiceData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (invoiceId) {
      setIsLoading(true);
      axios
        .get(process.env.REACT_APP_API_URL + `/d4u-orders/${invoiceId}/for-sales`, { withCredentials: true })
        .then((res) => {
          setInvoiceData(res?.data?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [invoiceId]);

  const handleSubmit = async () => {
    try {
      if (invoiceId) {
        const response = await axios.post(process.env.REACT_APP_API_URL + `/d4u-orders/${invoiceId}/make-sales`, {}, { withCredentials: true });
        if (response && response.data.code === 200) {
          toast.success(`${response?.data?.message}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
            pauseOnHover: false,
            className: "toastMsg",
          });
          refreshHandle();
          close();
        }
      }
      return;
    } catch (error) {
      toast.error(`${error?.response?.data?.error?.message}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500,
        pauseOnHover: false,
        className: "toastMsg",
      });
      refreshHandle();
      close();
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <LoaderPage />
        </div>
      ) : invoiceData ? (
        <>
          <Invoice invoiceData={invoiceData} />
          <div className="w-100 d-flex justify-content-center my-3 gap-3">
            <Button variant="secondary" className="w-25" onClick={close}>
              Close
            </Button>

            <Button variant="primary" className="w-25" onClick={handleSubmit}>
              Sell
            </Button>
          </div>
        </>
      ) : (
        <div style={{ height: "80vh" }} className="d-flex justify-content-center align-items-center">
          <h3>No data exists for this order</h3>
        </div>
      )}
    </>
  );
};

export default DeliveryForYouOrdersInvoice;
