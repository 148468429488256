export function generateShortDescription(category, product) {
  const requiredFields = {
    Laptop: ["brand", "model", "laptop_type", "color", "ram", "ram_type", "screen_size", "processor", "generation"],
    Mobile: ["brand", "model", "color", "ram", "storage_mobile", "is_pta_approved"],
    Tablet: ["brand", "model", "color", "ram", "storage_mobile"],
    "TV / Monitor": ["brand", "model", "type", "color", "screen_size", "refresh_rate", "ports", "speaker", "screen_type", "display_type", "resolution", "is_smart_tv"],
    "Desktop Computer": ["brand", "model", "desktop_type", "color", "ram", "ram_type", "processor", "generation"],
  };

  const requiredKeys = requiredFields[category];

  // Check if all required fields are present
  const allRequiredPresent = requiredKeys.every((field) => field in product);
  if (!allRequiredPresent) {
    throw new Error(`Missing required fields for category: ${category}`);
  }
  let conditionalCheck = true;
  if (category === "Laptop" || category === "Desktop") {
    conditionalCheck = ["storage_hdd", "storage_ssd"].some((field) => field in product);
    requiredKeys.push("storage_hdd", "storage_ssd");
  }
  // Check if additional fields are present
  const additionalFields = Object.keys(product).filter((key) => !requiredKeys.includes(key) && !key.startsWith("fk_") && key !== "description");

  let description = "";
  let additionalDetails = "Additionally, ";
  let storage = "";

  switch (category) {
    case "Laptop":
      if (product.storage_hdd && product.storage_ssd) {
        storage = `${product.storage_hdd >= 1000 ? Math.floor(product.storage_hdd / 1000) + "TB" : product.storage_hdd + "GB"} HDD and ${product.storage_ssd >= 1000 ? Math.floor(product.storage_ssd / 1000) + "TB" : product.storage_ssd + "GB"} SSD storage`;
      } else if (product.storage_hdd) {
        storage = `${product.storage_hdd >= 1000 ? Math.floor(product.storage_hdd / 1000) + "TB" : product.storage_hdd + "GB"} HDD storage`;
      } else if (product.storage_ssd) {
        storage = `${product.storage_ssd >= 1000 ? Math.floor(product.storage_ssd / 1000) + "TB" : product.storage_ssd + "GB"} SSD storage`;
      }

      description = `Meet the ${product.brand} ${product.model} ${product.laptop_type} laptop. It comes in ${product.color} and features a ${product.screen_size}-inch display, and ${product.ram}GB of ${product.ram_type} RAM ${
        product.storage_hdd || product.storage_ssd ? storage : ""
      }. Powered by a ${product.processor} ${product.generation} generation processor. `;
      if (additionalFields.length > 0) {
        // Following additional fields can be present in the product object
        // 1. battery {Intentionally left}
        // 2. camera_type {Intentionally left}
        // 3. finger_print ['Yes'/'No']
        // 4. graphic_card_memory
        // 5. graphic_card_name
        // 6. graphic_card_type
        // 7. is_backlit
        // 8. is_touch_screen
        // 9. keyboard
        // 10. refresh_rate
        // 11. resolution
        // 12. screen_type
        // 13. speaker

        if (product.screen_type) {
          // example: screen types can be IPS LCD, Retina LCD, Liquid Retina etc.
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a ${product.screen_type} screen, ` : `It features a ${product.screen_type} screen. `;
        }
        if (product.resolution && product.refresh_rate) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a ${product.resolution} resolution screen with a refresh rate of ${product.refresh_rate}Hz, ` : `It features a ${product.resolution} resolution screen with a refresh rate of ${product.refresh_rate}Hz. `;
          if (product.is_touch_screen) {
            additionalDetails += "and a touchscreen display for increased productivity. ";
          }
          if (product.refresh_rate > 60) {
            additionalDetails += "Enjoy smooth visuals with a higher refresh rate. ";
          }
        }
        if (product.graphic_card_type && product.graphic_card_name && product.graphic_card_memory) {
          additionalDetails += additionalDetails.endsWith("Additionally, ")
            ? `it comes with a ${product.graphic_card_name} having ${product.graphic_card_memory >= 1000 ? Math.floor(product.graphic_card_memory / 1000) + "GB" : product.graphic_card_memory + "MB"} of VRAM, `
            : `It features a ${product.graphic_card_name} having ${product.graphic_card_memory >= 1000 ? Math.floor(product.graphic_card_memory / 1000) + "GB" : product.graphic_card_memory + "MB"} of VRAM. `;
          if (product.graphic_card_type === "Dedicated") {
            additionalDetails += "Enjoy high-quality graphics for gaming and video editing. ";
          }
        }
        if (product.keyboard) {
          // example: numeric, standard, mechanical etc.
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a ${product.keyboard} ${product.is_backlit ? "backlit" : ""}keyboard, ` : `It features a ${product.keyboard} ${product.keyboard} ${product.is_backlit ? "backlit" : ""}keyboard. `;
        }
        if (product.ports) {
          // there can be one or more ports
          // if ports array contain more than one element then we should transform it into comma separated string
          const ports = product.ports.length > 1 ? product.ports.join(", ") : product.ports[0];
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has ${ports} ports, ` : `It comes with ${ports} ports. `;
        }
        if (product.speaker) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has ${product.speaker} speakers, ` : `It features ${product.speaker} speakers. `;
        }
        if (product.finger_print && product.finger_print === "yes") {
          // the value is either 'yes' or 'no'
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a fingerprint sensor, ` : `It features a fingerprint sensor. `;
        }
        return `<p>${description}</p><p>${additionalDetails}</p>`;
      }
      return `<p>${description}</p>`;
    case "Mobile":
      description = `Introducing the ${product.brand} ${product.model} mobile phone. Available in ${product.color}, it boasts ${product.ram}GB RAM and ${
        product.storage_mobile >= 1000 ? Math.floor(product.storage_mobile / 1000) + "TB" : product.storage_mobile + "GB"
      } of storage, The mobile phone is ${product.is_pta_approved ? "PTA approved" : "not PTA approved"}.`;
      if (additionalFields.length > 0) {
        // Following additional fields can be present in the product object
        // 1. screen_size
        // 2. port
        // 3. battery_capacity
        // 4. camera_specs (in mega pixels)
        // 5. finger_print
        // 6. screen_protection
        // 7. is_e_sim
        // 8. processor
        // 9. sim_type
        // 10. resolution
        // 11. refresh_rate
        // 12. network_band
        // 13. body_type {Left intentionally}
        // 14. screen_type
        // 15. speaker {Left intentionally}
        if (product.processor) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it is powered by a ${product.processor} processor, ` : `It is powered by a ${product.processor} processor. `;
        }
        if (product.screen_type) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a ${product.screen_type} screen, ` : `It features a ${product.screen_type} screen. `;
        }
        if (product.screen_protection && product.screen_size && product.resolution) {
          additionalDetails += additionalDetails.endsWith("Additionally, ")
            ? `it has a ${product.screen_size}-inch screen with ${product.screen_protection} protection and ${product.resolution} resolution, `
            : `It features a ${product.screen_size}-inch screen with ${product.screen_protection} protection and ${product.resolution} resolution. `;
          if (product.refresh_rate) {
            additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a refresh rate of ${product.refresh_rate}Hz, ` : `and a refresh rate of ${product.refresh_rate}Hz. `;
            if (product.refresh_rate > 60) {
              additionalDetails += "Enjoy smooth visuals with a higher refresh rate. ";
            }
          }
        }
        if (product.camera_specs) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a ${product.camera_specs}MP camera, ` : `It features a ${product.camera_specs}MP camera. `;
        }
        if (product.battery_capacity) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a ${product.battery_capacity}mAh battery, ` : `It comes with a ${product.battery_capacity}mAh battery. `;
        }
        if (product.finger_print) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a ${product.finger_print} fingerprint sensor, ` : `It features a ${product.finger_print} fingerprint sensor. `;
        }
        if (product.sim_type) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it supports ${product.sim_type} SIM, ` : `It supports ${product.sim_type} SIM. `;
        }
        if (product.network_band) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it supports ${product.network_band} bands, ` : `It supports ${product.network_band} bands. `;
        }
        if (product.is_e_sim) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it supports eSIM, ` : `It supports eSIM. `;
        }
        if (product.port) {
          // for mobile there is only one port
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a ${product.port} port, ` : `It comes with a ${product.port} port. `;
        }
        return `<p>${description}</p><p>${additionalDetails}</p>`;
      }
      return `<p>${description}</p>`;
    case "Tablet":
      description = `Discover the ${product.brand} ${product.model} tablet. This ${product.color} colored device features ${product.ram}GB RAM and ${product.storage_mobile >= 1000 ? Math.floor(product.storage_mobile / 1000) + "TB" : product.storage_mobile + "GB"} storage.`;
      if (additionalFields.length > 0) {
        // Following additional fields can be present in the product object
        // 1. screen_size
        // 2. battery_capacity
        // 3. camera_specs (in mega pixels)
        // 4. finger_print
        // 5. screen_protection
        // 6. is_e_sim
        // 7. processor
        // 8. is_sim_support
        // 9. resolution
        // 10. refresh_rate
        // 11. network_band
        // 12. body_type {left intentionally}
        // 13. is_pta_approved
        // 14. port
        // 15. screen_type
        // 16. sim_type
        // 17. speaker {left intentionally}
        if (product.processor) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it is powered by a ${product.processor} processor, ` : `It is powered by a ${product.processor} processor. `;
        }
        if (product.screen_type) {
          // it features an IPS LCD screen to boost your entertainment and productivity experience.
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it features a ${product.screen_type} screen to boost your entertainment and productivity experience, ` : `It features a ${product.screen_type} screen to boost your entertainment and productivity experience. `;
        }
        if (product.screen_protection && product.screen_size && product.resolution) {
          additionalDetails += additionalDetails.endsWith("Additionally, ")
            ? `it has a ${product.screen_size}-inch screen with ${product.screen_protection} protection and ${product.resolution} resolution, `
            : `It features a ${product.screen_size}-inch screen with ${product.screen_protection} protection and ${product.resolution} resolution. `;
          if (product.refresh_rate) {
            additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a refresh rate of ${product.refresh_rate}Hz, ` : `and a refresh rate of ${product.refresh_rate}Hz. `;
            if (product.refresh_rate > 60) {
              additionalDetails += "Enjoy smooth visuals with a higher refresh rate. ";
            }
          }
        }
        if (product.camera_specs) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a ${product.camera_specs}MP camera, ` : `It features a ${product.camera_specs}MP camera. `;
        }
        if (product.battery_capacity) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a ${product.battery_capacity}mAh battery, ` : `It comes with a ${product.battery_capacity}mAh battery. `;
        }
        if (product.finger_print) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a ${product.finger_print} fingerprint sensor, ` : `It features a ${product.finger_print} fingerprint sensor. `;
        }
        if (product.is_sim_support) {
          // in case the device is PTA Approved we should mention it in the description
          if (product.is_pta_approved) {
            additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it is PTA approved, ` : `It is PTA approved. `;
          }
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it supports ${product.is_sim_support} SIM, ` : `It supports ${product.is_sim_support} SIM. `;
          // and features a single sim / dual sim slot ..
          if (product.sim_type) {
            additionalDetails += `It features a ${product.sim_type} slot. `;
          }
        }
        if (product.network_band) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it supports ${product.network_band} bands, ` : `It supports ${product.network_band} bands. `;
        }
        if (product.is_e_sim) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it supports eSIM, ` : `It supports eSIM. `;
        }
        if (product.port) {
          // for tablet there is only one port
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a ${product.port} port, ` : `It comes with a ${product.port} port. `;
        }
        return `<p>${description}</p><p>${additionalDetails}</p>`;
      }
      return `<p>${description}</p>`;
    case "TV / Monitor":
      if (product.type === "TV") {
        // 1. display_type {bordered / borderless}

        // TV
        // Fields to include ['brand', 'model', 'type', 'color', 'screen_size', 'refresh_rate', 'ports', 'speaker', 'screen_type', 'resolution', 'is_smart_tv']
        description = `Enjoy an immersive viewing experience with the ${product.brand} ${product.model} TV. This ${product.color} TV features a ${product.screen_size}-inch ${product.display_type} ${product.screen_type} display with a resolution of ${product.resolution}. With a refresh rate of ${product.refresh_rate}Hz, enjoy smooth and lag-free viewing whether you're watching movies, sports, or playing games.`;
        if (additionalFields.length > 0) {
          if (product.ports) {
            const ports = product.ports.length > 1 ? product.ports.join(", ") : product.ports[0];
            additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has ${ports} ports, ` : `It comes with ${ports} ports. `;
          }
          if (product.speaker) {
            additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has ${product.speaker} speakers, ` : `It features ${product.speaker} speakers. `;
          }
          if (product.is_smart_tv) {
            additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it is a smart TV, ` : `It is a smart TV. `;
            if (product.operating_system) {
              additionalDetails += `It runs on ${product.operating_system} OS. `;
            }
            if (product.is_tv_certificated) {
              additionalDetails += `The TV is certified by Google. `;
            }
          }
          return `<p>${description}</p><p>${additionalDetails}</p>`;
        }
      } else {
        // Monitor
        // Fields to include ['brand', 'model', 'type', 'color', 'screen_size', 'refresh_rate', 'ports', 'speaker', 'screen_type', 'resolution', 'is_webcam']
        description = `Experience high-quality visuals with the ${product.brand} ${product.model} monitor. This ${product.color} monitor features a ${product.screen_size}-inch ${product.display_type} ${product.screen_type} display with a resolution of ${product.resolution}. With a refresh rate of ${product.refresh_rate}Hz, enjoy smooth and lag-free viewing whether you're working, watching movies, or gaming.`;
        if (additionalFields.length > 0) {
          if (product.ports) {
            const ports = product.ports.length > 1 ? product.ports.join(", ") : product.ports[0];
            additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has ${ports} ports, ` : `It comes with ${ports} ports. `;
          }
          if (product.speaker) {
            additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has ${product.speaker} speakers, ` : `It features ${product.speaker} speakers. `;
          }
          if (product.is_webcam) {
            additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a built-in webcam, ` : `It comes with a built-in webcam. `;
          }
          return `<p>${description}</p><p>${additionalDetails}</p>`;
        }
      }
      return `<p>${description}</p>`;
    case "Desktop Computer":
      if (product.storage_hdd && product.storage_ssd) {
        storage = `${product.storage_hdd >= 1000 ? Math.floor(product.storage_hdd / 1000) + "TB" : product.storage_hdd + "GB"} HDD and ${product.storage_ssd >= 1000 ? Math.floor(product.storage_ssd / 1000) + "TB" : product.storage_ssd + "GB"} SSD storage`;
      } else if (product.storage_hdd) {
        storage = `${product.storage_hdd >= 1000 ? Math.floor(product.storage_hdd / 1000) + "TB" : product.storage_hdd + "GB"} HDD storage`;
      } else if (product.storage_ssd) {
        storage = `${product.storage_ssd >= 1000 ? Math.floor(product.storage_ssd / 1000) + "TB" : product.storage_ssd + "GB"} SSD storage`;
      }

      description = `Meet the ${product.brand} ${product.model} ${product.desktop_type} desktop. It comes in ${product.color} and features a ${product.screenSize}-inch display, and ${product.ram}GB of ${product.ram_type} RAM ${product.storage_hdd || product.storage_ssd}. Powered by a ${
        product.processor
      } ${product.generation} generation processor. `;
      if (additionalFields.length > 0) {
        // Following additional fields can be present in the product object
        // 1. graphic_card_memory
        // 2. graphic_card_name
        // 3. graphic_card_type
        // 4. is_touch_screen
        // 5. resolution
        // 6. screen_size
        // 7. speaker
        // 8. refreshRate {NOT IN DATA SET}
        // 8. isBacklit {NOT IN DATA SET}

        // If product is an All in One PC then the following fields can be present
        if (product.desktop_type === "All in One") {
          if (product.isBacklit) {
            additionalDetails += additionalDetails.endsWith("Additionally, ") ? "it has a backlit keyboard, " : "It comes with a backlit keyboard.";
          }
          if (product.screen_size) {
            additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a ${product.screen_size}-inch screen, ` : `It features a ${product.screen_size}-inch screen. `;
          }
          if (product.resolution && product.refreshRate) {
            additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has a ${product.resolution} resolution screen with a refresh rate of ${product.refreshRate}Hz, ` : `It features a ${product.resolution} resolution screen with a refresh rate of ${product.refreshRate}Hz. `;
            if (product.is_touch_screen) {
              additionalDetails += "and a touchscreen display for increased productivity. ";
            }
            if (product.refreshRate > 60) {
              additionalDetails += "Enjoy smooth visuals with a higher refresh rate. ";
            }
          }
        }

        if (product.graphic_card_type && product.graphic_card_name && product.graphic_card_memory) {
          additionalDetails += additionalDetails.endsWith("Additionally, ")
            ? `it comes with a ${product.graphic_card_name} having ${product.graphic_card_memory >= 1000 ? Math.floor(product.graphic_card_memory / 1000) + "GB" : product.graphic_card_memory + "MB"} of VRAM, `
            : `It features a ${product.graphic_card_name} having ${product.graphic_card_memory >= 1000 ? Math.floor(product.graphic_card_memory / 1000) + "GB" : product.graphic_card_memory + "MB"} of VRAM. `;
          if (product.graphic_card_type === "Dedicated") {
            additionalDetails += "Enjoy high-quality graphics for gaming and video editing. ";
          }
        }
        if (product.ports) {
          // there can be one or more ports
          // if ports array contain more than one element then we should transform it into comma separated string
          const ports = product.ports.length > 1 ? product.ports.join(", ") : product.ports[0];
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has ${ports} ports, ` : `It comes with ${ports} ports. `;
        }
        if (product.speaker) {
          additionalDetails += additionalDetails.endsWith("Additionally, ") ? `it has ${product.speaker} speakers, ` : `It features ${product.speaker} speakers. `;
        }
        return `<p>${description}</p><p>${additionalDetails}</p>`;
      }
      return `<p>${description}</p>`;
    default:
      throw new Error(`Template not found for category: ${category}`);
  }
}
