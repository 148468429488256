import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import style from "./../styles/Sales.module.css";
import editpencil from "../assets/svg/editpencil.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import ReactSelect from "../Components/ReactSelect";
const ReceivePayments = ({ close, is_remaining, received_amount, updatePaymentId, emailClose, emailStatus, paymentCheck, salesNumber = null, quotationData, customerInfo }) => {
  const [is_disabled, setDisabled] = useState(true);
  const [salesComment, setSalesComment] = useState("");
  const [generateInvoice, setGenerateInvoice] = useState(false);
  let [receivePayment, setReceivePayment] = useState();
  const [paymentType, setPyamentType] = useState("Cash");
  const [validated, setValidated] = useState(false);
  const [errorPaidAmount, setPaidTotalAmount] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [success, setSuccess] = useState("");
  const [sendSms, setSendSms] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [bank, setBank] = useState(null);
  const [error, setError] = useState("");

  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const shopName = user.shop_name;
  const logo = user.logo_path;
  useEffect(() => {
    setReceivePayment(is_remaining);
  }, [is_remaining]);
  const handlePayment = (e) => {
    if (Number(e.target.value) <= Number(is_remaining) && e.target.value >= 0) {
      setReceivePayment(e.target.value);
      setPaidTotalAmount(false);
    } else {
      setReceivePayment("");
      setPaidTotalAmount(true);
    }
  };
  const loadBanksOptions = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + `/shops/banks/${shop_id}`, {
          withCredentials: true,
        })
        .then((banksRes) => {
          const banks = banksRes.data.map((bank) => ({
            value: bank,
            label: (
              <>
                <span style={{ fontSize: "12px" }}>
                  Bank Name: <strong className="fs-12">{bank?.bank_name}</strong>
                </span>
                <br />
                <span style={{ fontSize: "12px" }}>Account Title: {bank?.account_title}</span>
                <br />
                <span className="d-block" style={{ fontSize: "12px" }}>
                  Account Number:{bank?.account_number}
                </span>
              </>
            ),
            name: bank.bank_name,
          }));
          resolve(banks);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const handleChangeBank = (e) => {
    setBank({ value: e.value, label: e.name });
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `/subscriptions/subscription-details/${shop_id}`, { withCredentials: true })
      .then((res) => {
        setSubscriptionDetails(res.data?.subscription[0]);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, []);

  const handlePaymentType = (e) => {
    setPyamentType(e.target.value);
  };

  const updateReceivePayment = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);

    try {
      if (paymentType === "Cash" ? paymentCheck.isRegisterOpen : true) {
        setError("");
        if (Number(received_amount) >= 0 && (paymentType === "Card" || paymentType === "Transfer") ? (paymentType === "Card" || paymentType === "Transfer") && bank?.label?.length : true) {
          setIsClicked(true);
          let res;
          if (quotationData) {
            const sales = {
              base_grand_total: quotationData.base_grand_total,
              amount_received: receivePayment > quotationData.base_grand_total ? quotationData.base_grand_total : receivePayment,
              base_subtotal: quotationData.base_subtotal,
              base_shipping_amount: Number(quotationData.base_shipping_amount),
              shop_id: quotationData.shop_id,
              shop_name: shopName,
              user_id: quotationData.user_id,
              location_id: quotationData.location_id,
              sales_order_number: quotationData.sales_order_number,
              sales_order_comment: quotationData.sales_order_comment,
              collective_discount: quotationData.collective_discount,
              sales_person_id: quotationData.sales_person_id ? quotationData.sales_person_id : -1,
              shopLogo: user?.logo_path,
            };
            const payment = {
              customer_payments_bank_id: paymentType === "Cash" ? -1 : bank.value.bank_id,
              payment_type: paymentType,
              customer_payments_comment: quotationData.comment,
              customer_payments_amount_received: Number(receivePayment) === 0 ? 0 : receivePayment > quotationData.base_grand_total ? quotationData.base_grand_total : receivePayment,
              shop_id: quotationData.shop_id,
            };
            res = await axios.post(
              process.env.REACT_APP_API_URL + `/sales/add?quotationId=${quotationData.quotation_id}`,
              {
                miscItems: quotationData.misc_items.map((i) => ({
                  name: i.name,
                  price: i.price,
                  qty_ordered: Number(i.qty_ordered),
                  discount: i.discount,
                })),
                customer: {
                  id: quotationData.customer_id,
                  ...(customerInfo?.customer_email && customerInfo?.customer_email !== "" && customerInfo?.customer_email !== null ? { customer_email: customerInfo?.customer_email } : {}),
                  ...(customerInfo?.customer_number && customerInfo?.customer_number !== "" && customerInfo?.customer_number !== null ? { customer_number: customerInfo?.customer_number } : {}),
                },
                sales,
                payment,
                isEmail: sendEmail,
                isInvoice: generateInvoice,
                isSms: sendSms,
                salesItems: quotationData.items.map((item) => ({
                  item_id: item.item_id,
                  product_type: item.product_type,
                  price: Number(item.price),
                  qty_ordered: item.qty_ordered,
                  discount: item.discount,
                  imeis: item?.imeis?.map((imei) => imei.imeiNumber).join(","),
                })),
              },
              { withCredentials: true },
            );
            if (res) {
              setSuccess("Sale Added Successfully.");
              if (generateInvoice) {
                setTimeout(() => window.open(`/invoice/` + res.data.salesOrderId, "_blank", "noopener,noreferrer"), 1500);
              }
              close();
            }
          } else {
            res = await axios.post(
              process.env.REACT_APP_API_URL + `/sales/update-receive-payment/${updatePaymentId}`,
              {
                invoice: generateInvoice,
                customer_payments_amount_received: Number(receivePayment),
                customer_payments_comment: salesComment,
                customer_payments_bank_id: paymentType === "Cash" ? -1 : bank.value.bank_id,
                payment_type: paymentType,
                shop_id,
              },
              { withCredentials: true },
            );
            setSuccess("Payment Received Successfully.");

            // const obj = { salesId: updatePaymentId, data: res.data, countReceivedAmount: received_amount, email: sendEmail }
            let arr = [];
            const products = res.data.items.map((i) => ({
              title: i.title,
              cartQuantity: i.qty_ordered,
              price: i.sale_price,
              discount: i.discount,
            }));
            const miscProducts = res.data.miscItems.map((i) => ({
              title: i.name,
              cartQuantity: i.mis_qty,
              price: i.price,
              discount: i.discount,
              description: i.description,
            }));
            arr.push(...products);
            arr.push(...miscProducts);
            const amount = Number(res.data.data[0].base_grand_total) - (Number(res.data.data[0].customer_payments_amount_received) + Number(received_amount));

            if (sendSms) {
              axios
                .post(process.env.REACT_APP_API_URL + `/notification/sendnotify`, {
                  customerName: res.data.data[0].customer_name,
                  shopName: user.shop_name,
                  email: res.data.data[0].customer_email,
                  orderId: updatePaymentId,
                  shopsEmail: user.user_email,
                  shopkeeperName: user.user_full_name,
                  shopId: user.shop_id,
                  shopsAddress: res.data.data[0].shop_address,
                  message: salesComment ? salesComment : "",
                  number: res.data.data[0].customer_number,
                })
                .then((res) => {
                  toast.success("Sms Send Sucessfully", {
                    icon: ({ theme, type }) => <FontAwesomeIcon icon={faMessage} />,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1500,
                    // pauseOnHover: false,
                    className: "toastMsg",
                  });
                })
                .catch((err) => {
                  // console.log(err);
                });
            }
            if (sendEmail === true) {
              axios
                .post(
                  process.env.REACT_APP_API_URL + "/sales/send-receive-payment-email",
                  {
                    data: res.data.data[0],
                    items: arr,
                    remaining: amount,
                    logo: logo,
                  },
                  { withCredentials: true },
                )
                .then((emailRes) => {
                  if (emailRes.data.status === 404) {
                    close();
                    emailStatus("No Email Register for this customer. Please add Customer email to send Email.");
                    emailClose();
                  } else if (emailRes.data.status === 200) {
                    close();
                    emailStatus(emailRes.data.success.message);
                    emailClose();
                  }
                })
                .catch((err) => {
                  close();
                  emailStatus(err.response.data.error.message);
                  emailClose();
                });
            } else {
              close();
            }
            setValidated(false);
            if (generateInvoice === true && sendEmail === false) {
              window.open(`/invoice-view/` + res.data.id, "_blank", "noopener,noreferrer");
            } else if (generateInvoice === true && sendEmail === true) {
              setTimeout(() => window.open(`/invoice-view/` + res.data.id, "_blank", "noopener,noreferrer"), 1500);
            }
          }
        }
      } else {
        setError("Register is not set!");
      }
    } catch (error) {
      setSuccess("");
      setIsClicked(true);
      setIsClicked(false);
      throw new Error(error.response.data);
    }
  };
  return (
    <>
      <Form onSubmit={updateReceivePayment} noValidate validated={validated}>
        {success && <p className="text-success text-center">{success}</p>}
        <div className="text-center">{error && <span className="text-danger p-2 text-center my-2 rounded-1">*{error}</span>}</div>
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">Payment Type *</Form.Label>
          <Row>
            <Col md="3">
              <Form.Check name="paymentType" type="radio" label="Cash" value="Cash" checked={paymentType === "Cash"} onChange={handlePaymentType} />
            </Col>
            <Col md="6">
              <Form.Check name="paymentType" type="radio" label="Debit/Credit Card" value="Card" checked={paymentType === "Card"} onChange={handlePaymentType} />
            </Col>
            <Col md="3">
              <Form.Check name="paymentType" type="radio" label="Transfer" value="Transfer" checked={paymentType === "Transfer"} onChange={handlePaymentType} />
            </Col>
          </Row>
        </Form.Group>
        <hr className={`${style.borderedHr}`} />
        <Form.Group className="mb-3 mt-3">
          <Form.Label className="mb-1">Receive Amount</Form.Label>

          <div className="position-relative">
            <Form.Control
              className="p-2"
              min={1}
              required
              type="text"
              placeholder="Enter Amount"
              onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
              disabled={is_disabled}
              value={receivePayment}
              isInvalid={!(parseInt(receivePayment) > 0) && receivePayment?.length}
              onChange={(e) => handlePayment(e)}
            />
            <div className="position-absolute w-100 d-flex justify-flex-end">
              <img src={editpencil} alt="edit" className={`position-absolute`} style={{ bottom: "13px", right: "0.75rem", cursor: "pointer" }} onClick={() => setDisabled(false)} />
            </div>
            {errorPaidAmount && (
              <div className="alert alert-warning" role="alert">
                Receive Amount should be less then or equal to Remaining Amount.
              </div>
            )}
          </div>
        </Form.Group>
        {paymentType === "Cash" ? (
          ""
        ) : (
          <>
            <Form.Group className="w-100" controlId="formBasicShopName">
              <Form.Label className="mb-1">
                Select Bank <span className="text-danger">*</span>
              </Form.Label>
              <ReactSelect placeholder="Select" value={bank} options={loadBanksOptions} handleChange={handleChangeBank} searchable={false} />
              <Form.Control value={bank} onChange={() => {}} hidden required />
              <Form.Control.Feedback type="invalid">Please Select Bank.</Form.Control.Feedback>
            </Form.Group>
            <div>
              <Card className="mb-4 border-0 mt-2 bg-white">
                <Row style={{ background: "#F5F6FA" }} className="">
                  <Card.Body>
                    {bank ? (
                      <Row>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex gap-2" lg="10" xs="11">
                            <div className="d-flex flex-column">
                              <span style={{ fontSize: "12px" }}>Bank Name: {bank?.value?.bank_name}</span>
                              <span style={{ fontSize: "12px" }}>Account Title: {bank?.value?.account_title}</span>
                              <span className="d-block" style={{ fontSize: "12px" }}>
                                Account Number: {bank?.value?.account_number}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Row>
                    ) : (
                      <Row className="text-center text-secondary p-1">
                        <span className="ms-1">No Bank Added.</span>
                      </Row>
                    )}
                  </Card.Body>
                </Row>
              </Card>
            </div>
          </>
        )}
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label className="mb-1">Comments</Form.Label>
          <Form.Control placeholder="e.g. 10 days warranty" as="textarea" maxLength={"200"} rows={3} onChange={(e) => setSalesComment(e.target.value)} />
        </Form.Group>
        {(salesNumber ? !salesNumber.startsWith("D4U") : true) ? (
          <Form.Group className="mb-3">
            <Form.Check className="py-1" name="paymentType" type="checkbox" label="Generate Invoice" checked={generateInvoice} onChange={(e) => setGenerateInvoice(e.target.checked)} />
            <div className="d-flex align-items-baseline">
              <Form.Check
                className="py-1"
                name="paymentType"
                type="checkbox"
                label="Send SMS"
                checked={sendSms}
                onChange={(e) => setSendSms(e.target.checked)}
                disabled={!(customerInfo?.customer_number && customerInfo?.customer_number !== "" && customerInfo?.customer_number !== null) || (subscriptionDetails.consumed_sms >= subscriptionDetails.sms_limit ? true : false)}
              />
              {subscriptionDetails.consumed_sms >= subscriptionDetails.sms_limit ? <span className="ms-4 text-danger">Sms limit exceeded</span> : ""}
            </div>

            <div className="d-flex align-items-baseline">
              <Form.Check
                className="py-1"
                name="paymentType"
                type="checkbox"
                label="Send Email"
                checked={sendEmail}
                disabled={!(customerInfo?.customer_email && customerInfo?.customer_email !== "" && customerInfo?.customer_email !== null) || (subscriptionDetails.consumed_emails < subscriptionDetails.emails_limit ? false : true)}
                onChange={(e) => setSendEmail(e.target.checked)}
              />

              {subscriptionDetails.consumed_emails >= subscriptionDetails.emails_limit ? <span className="ms-4 text-danger">Emails limit exceeded</span> : ""}
            </div>
          </Form.Group>
        ) : (
          <></>
        )}
        <Button disabled={isClicked} className="w-100" type="submit">
          Confirm
        </Button>
      </Form>
    </>
  );
};

export default ReceivePayments;
