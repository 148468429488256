import React, { useState } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/core";
import { authActions } from "../../Features/authSlice";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
const ModalRedirections = ({ show, data }) => {
  const { user } = useSelector((state) => state.auth);
  const user_id = user.user_id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const schema = data?.json_object;
  const { type, title, properties, dependencies, required } = schema;
  const uischema = "{" + schema.split('"required":[],')[1];
  const handleFormChange = ({ formData: newFormData }) => {
    setFormData(newFormData);
  };
  const HandleSubmit = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/forced-redirections/${data?.title}`,
        { user_id, redirection_id: data?.id, formData },
        { withCredentials: true }
      );
      if (response) {
        dispatch(authActions.forcedActionCompleted());
        // navigate("/");
      }
    } catch (error) {
      throw new Error(error);
    }
  }
  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        backdrop="static"
        show={show}
      >
        <Modal.Header className="border-0 pt-3 border-bottom">
          <div className="d-flex align-items-center gap-4">
            <h4>{data?.title}</h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          {data !== "{}" ? (
            <div className="container pt-3">
              <div className="row d-flex justify-content-center">
              <div className="text-center">
                <Col>
                  <Form
                    schema={JSON.parse(schema)}
                    // uiSchema={JSON.parse(uischema)}
                    formData={formData}
                    onChange={handleFormChange}
                    showErrorList={null}
                    onSubmit={HandleSubmit}
                    validator={validator}
                  />
                </Col>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalRedirections;
