import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const CustomerTour = ({ setAddModal, setModalEdit, setModalSales }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const customerTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Customer", value: true } }));
      setTimeout(() => {
        customerTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Customer",
        popover: {
          title: "Customers",
          description: "Overall shop customers.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#customer",
        popover: {
          title: `A guide to customers`,
          description: "Shows and manages customer information.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#customer-search",
        popover: {
          title: `Search customer`,
          description: "seller can search customers by their name,email or phone number.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#customer-date-filter",
        popover: {
          title: `Days filter`,
          description: "Here seller can choose that which day’s customers he wants to see.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#customer-page-size",
        popover: {
          title: `Page size`,
          description: "Seller can choose that how many customers he wants to see at a time.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#customer-reset-filter",
        popover: {
          title: `Reset Filters`,
          description: "Clicking on this button will remove all applied filters on the search result.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#customer-table > div:nth-child(1)",
        popover: {
          title: `Customers List`,
          description: "On this page, all added customers will be shown with their information.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#edit",
        popover: {
          title: `Edit`,
          description: "Upon clicking on this button, seller can edit any customer.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setModalEdit(true);
            setTimeout(() => {
              customerTour.moveNext();
            });
          },
        },
      },
      {
        element: "#customer-edit-modal > .modal-content",
        popover: {
          title: `Edit Customer`,
          description: "A form to edit a customer.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setModalEdit(false);
            setTimeout(() => {
              customerTour.movePrevious();
            });
          },
          onNextClick: () => {
            setModalEdit(false);
            setTimeout(() => {
              customerTour.moveNext();
            });
          },
        },
      },
      {
        element: "#remove",
        popover: {
          title: `Delete`,
          description: "On clicking this button seller can delete that customer.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setModalEdit(true);
            setTimeout(() => {
              customerTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#view",
        popover: {
          title: `View`,
          description: "On clicking this, customer sales modal will appear where seller can view sales added against that specific customer.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setModalSales(true);
            setTimeout(() => {
              customerTour.moveNext();
            });
          },
        },
      },
      {
        element: "#customer-sales-modal > .modal-content",
        popover: {
          title: `View Customer Sales`,
          description: "It shows all sales added against that specific customer.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setModalSales(false);
            setTimeout(() => {
              customerTour.movePrevious();
            });
          },
          onNextClick: () => {
            setModalSales(false);
            setTimeout(() => {
              customerTour.moveNext();
            });
          },
        },
      },
      {
        element: "#csv-download",
        popover: {
          title: `Download CSV`,
          description: "On clicking this, seller can download customer record in csv file.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setModalSales(true);
            setTimeout(() => {
              customerTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#customer-add-customer",
        popover: {
          title: `Add customer`,
          description: "Upon clicking on this button, seller can add new customer.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setAddModal(true);
            setTimeout(() => {
              customerTour.moveNext();
            });
          },
        },
      },
      {
        element: "#customer-add-modal > .modal-content",
        popover: {
          title: `Add New customer`,
          description: "A form to add a new customer.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setAddModal(false);
            setTimeout(() => {
              customerTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#customer-add-customer-name",
        popover: {
          title: `Customer Full Name`,
          description: "Enter customer full name.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#customer-add-customer-type",
        popover: {
          title: `Customer Type`,
          description: " From here seller can select if the customer is of individual or business type.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#customer-add-customer-email",
        popover: {
          title: `Customer Email`,
          description: "Enter customer email.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#customer-add-customer-contact",
        popover: {
          title: `customer Contact`,
          description: "Enter customer contact number.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#customer-add-customer-comments",
        popover: {
          title: `Comments`,
          description: "Here seller can add any comments related to that customer.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#customer-add-customer-button",
        popover: {
          title: `Save`,
          description: "On clicking save button added information of customer will be saved.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setAddModal(false);
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Customer", value: true } }));
            setTimeout(() => {
              customerTour.destroy();
            }, 100);
          },
        },
      },
    ],
  });
  useEffect(() => {
    customerTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default CustomerTour;
