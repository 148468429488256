import React, { useState } from "react";
import style from "./listingAddItem.module.css";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import CustomizeTableview from "../../TableViews/CustomizeTableview";
import ListingHeader from "../ListingHeader/ListingHeader";
import NoResult from "../../NoResult";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import LoaderPage from "../../loaders/loaderPage";
import LoaderOverlay from "../../loaders/loaderOverlay";
import { toast } from "react-toastify";
import PaginationBar from "../../PaginationBar";

function ListingAddItem({ show = false, close, sendChangeSignal }) {
  const { user } = useSelector((state) => state.auth);
  const userId = user.user_id;
  const [items, setItems] = useState([]);
  const [noOfItems, setNoOfItems] = useState(0);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const changePage = (page) => {
    setCurrentPage(page);
  };
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [category, setCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [changeOccured, setChangeOccured] = useState(false);

  const refreshList = () => {
    setChangeOccured((prev) => !prev);
  };
  useEffect(() => {
    const fetchItems = async (id, search) => {
      try {
        const paramsObj = {
          page: currentPage,
          size: sizeOfPages,
        };
        if (search && search?.length) {
          paramsObj.search = search;
        }
        if (category) {
          paramsObj.category = category?.label;
        }
        const searchParams = new URLSearchParams(paramsObj).toString();
        const response = await axios.get(process.env.REACT_APP_API_URL + `/inventory/listings/${id}${searchParams?.length > 0 ? `?${searchParams}` : ""}`, {
          withCredentials: true,
        });
        setItems(response?.data?.success?.data);
        setNoOfItems(response?.data?.success?.no_of_items);
        setNoOfPages(response?.data?.success?.no_of_pages);
        setIsLoading(false);
      } catch (error) {}
    };
    let callTimeout;
    if (userId && show) {
      setIsLoading(true);
      if (searchField?.length > 0) {
        callTimeout = setTimeout(() => {
          fetchItems(userId, searchField);
        }, 300);
      } else {
        fetchItems(userId, searchField);
      }
    }

    return () => {
      if (callTimeout) {
        clearTimeout(callTimeout);
      }
    };
  }, [userId, show, searchField, currentPage, sizeOfPages, category, changeOccured]);

  const onHideHandle = (response) => {
    if (response?.listing_id) {
      close(response.listing_id);
    } else {
      close();
    }
    // close(items);
    setItems([]);
    setSelectedItems([]);
    setSearchField("");
    setNoOfItems(0);
    setCurrentPage(1);
    setCategory(null);
  };

  const handleAdd = async (e) => {
    try {
      setIsSubmitting(true);
      const listingsArray = selectedItems.map((item) => {
        return { fk_item_id: item.id, listing_title: item.title };
      });
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/listings`,
        { listings: listingsArray },
        {
          withCredentials: true,
        },
      );
      if (response) {
        onHideHandle(response.data.success);
        setIsSubmitting(false);
        sendChangeSignal();
        toast.success(`Item added successfully`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          pauseOnHover: false,
          className: "toastMsg",
        });
      }
    } catch (err) {
      refreshList();
      setIsSubmitting(false);
      if (err?.response?.data?.error?.status === 409) {
        toast.error(`Listing against this item already exists, Try another item`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          pauseOnHover: false,
          className: "toastMsg",
        });
      } else {
        toast.error(`Item not added, Try again!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          pauseOnHover: false,
          className: "toastMsg",
        });
      }
    }
  };

  return (
    <>
      <LoaderOverlay loading={isSubmitting} />

      <Modal show={show} onHide={onHideHandle} size="lg" centered dialogClassName={`${style.modalDialog}`} fullscreen="lg-down">
        <Modal.Header closeButton>
          <Modal.Title>Add Listings</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`p-0 overflow-hidden`}>
          <div className={`pt-2 p-sm-4`} style={{ borderBottom: "1px solid #DEDFE4" }}>
            <ListingHeader hideButton={true} hideTitle={true} hideShowing={true} availableItems={noOfItems} setSearchText={setSearchField} handleCategoryFilter={setCategory} />
          </div>
          <div className={`${style.tableContainer} overflow-auto`}>
            {isLoading ? (
              <LoaderPage />
            ) : noOfItems === 0 || items.length === 0 ? (
              <NoResult name="Inventory" />
            ) : (
              <CustomizeTableview
                data={items}
                columnNotShow={["id"]}
                thumbnail={{
                  title: "title",
                  img: "image",
                  description: "title",
                }}
                checkbox={{ data: selectedItems, setter: setSelectedItems }}
                actions={false}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className={`d-flex flex-column gap-1 p-4`}>
          <div className={`d-flex gap-1 align-item-end justify-content-between w-100`}>
            <span
              className=""
              style={{
                color: "#39414A",
                textAlign: "center",
                paddingTop: "14px",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "18px",
              }}
            >
              <FontAwesomeIcon icon={faCheck} className={`me-2`} />
              {selectedItems?.length} Items Selected
            </span>
            {noOfItems > 0 ? <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} /> : ""}
          </div>
          <Button className={`w-100`} onClick={handleAdd}>
            Send to the listing
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ListingAddItem;
