import { Form } from "react-bootstrap";
import inputImage from './../assets/images/Content.png';
import style from './../styles/ImageInput.module.css'

const ImageInput = ({label}) => {
    return (
        <Form.Group className="mb-3">
            <Form.Label>Upload Images</Form.Label>
            <div className={`${style.imageInput}`}>
                <label className={`${style.img}`} htmlFor="imageInput" >
                    <img src={inputImage} alt='upload'/>
                </label>
                <Form.Control style={{ display: 'none' }} type='file' id="imageInput" />
            </div>
        </Form.Group>
    );
}

export default ImageInput;