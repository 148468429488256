import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import LoaderOverlay from "../../loaders/loaderOverlay";
import style from "./completionModal.module.css";
import LoaderPage from "../../loaders/loaderPage";
import ValidationForm from "../ListingValidationForms/ValidationForm";
import form_1_active from "../../../assets/svg/form_1_active.png";
import form_2_active from "../../../assets/svg/form_2_active.png";
import form_2_pending from "../../../assets/svg/form_2_pending.png";
import form_completed from "../../../assets/svg/form_completed.png";
import resetFormIcon from "../../../assets/svg/resetFormIcon.svg";
import axios from "axios";
import ListingActivationForm from "../ListingActivationForms/ListingActivationForm";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

export default function CompletionForm({ value = null, setValue, show = false, close, itemsLimit, sendChangeSignal }) {
  const navigate = useNavigate();

  const [status, setStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mode, setMode] = useState("continue");

  const validationRef = useRef();
  const activationRef = useRef();

  async function getListing(listing) {
    try {
      setIsLoading(true);
      const getItemResponse = await axios.get(process.env.REACT_APP_API_URL + `/listings/` + listing?.id, {
        withCredentials: true,
      });
      if (getItemResponse) {
        setValue((prev) => ({
          ...prev,
          listing,
          oldValue: getItemResponse?.data?.success?.data,
        }));
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setValue((prev) => ({
          ...prev,
          listing,
        }));
      }
      setIsLoading(false);
    }
  }

  const setListing = async (id) => {
    try {
      setIsLoading(true);
      const getItemResponse = await axios.get(process.env.REACT_APP_API_URL + `/listings/get/` + id, {
        withCredentials: true,
      });
      if (getItemResponse) {
        setValue({
          listing: getItemResponse?.data?.success?.listing,
        });
        setStatus(getItemResponse?.data?.success?.listing?.status?.split(","));
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(`Open Completion Form again!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500,
        pauseOnHover: false,
        className: "toastMsg",
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (value?.listingId) {
      setListing(value.listingId);
    } else {
      if (mode === "edit") {
        setStatus((prev) => prev?.map((item) => (item === "Validated" ? "Validation Pending" : item)));
      } else {
        if (status?.length > 0) {
          if (status?.includes("Validated") && status?.includes("Active")) {
            setStatus(value?.listing?.status?.split(","));
          }
        } else {
          setStatus(value?.listing?.status?.split(","));
        }
      }
    }
  }, [value]);

  const onHideHandle = () => {
    setIsLoading(false);
    setIsSubmitting(false);
    setMode(false);
    setStatus([]);
    close();
  };
  const handleSaveAndClose = () => {
    if (status?.includes("Validation Pending") || status?.includes("Inactive")) {
      validationRef.current.handleSave(() => {
        onHideHandle();
      });
    }
  };
  const handleBackToValidation = () => {
    if (status?.includes("Inactive")) {
      getListing(value.listing);
      setMode("edit");
      setStatus((prev) => prev?.map((item) => (item === "Validated" ? "Validation Pending" : item)));
    }
  };

  const handleSaveAndContinue = () => {
    if (status?.includes("Validation Pending") || mode === "edit") {
      validationRef.current.handleSave(() => {
        setStatus((prev) => prev?.map((item) => (item === "Validation Pending" ? "Validated" : item)));
        setMode("continue");
      });
    } else if (status?.includes("Inactive")) {
      activationRef.current.handleSave(() => {
        onHideHandle();
      });
    }
  };

  const resetHandle = () => {
    if (status?.includes("Validation Pending")) {
      if (mode === "edit") {
        validationRef.current.resetEdit();
      } else {
        validationRef.current.reset();
      }
    } else if (status?.includes("Inactive")) {
      activationRef.current.reset();
    }
  };

  return (
    <>
      <LoaderOverlay loading={isSubmitting || isLoading} />
      <Modal show={show} onHide={onHideHandle} size="lg" centered dialogClassName={`${style.modalDialog}`} fullscreen="sm-down" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <div>
              <h5 className="m-0">
                Activate Listing <Badge>{value?.listing?.category}</Badge>
              </h5>
              <div className="text-muted fs-6 ">{value?.listing?.title}</div>
            </div>
            {itemsLimit ? (
              <div
                className={`alert alert-warning bg-transparent d-flex align-items-center`}
                style={{
                  fontSize: "14px",
                  padding: "5px 20px",
                }}
              >
                <p className={`m-0`}>
                  <span
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    ⚠️
                  </span>{" "}
                  Max. Number of Allowed Listings Reached!
                </p>
              </div>
            ) : (
              <></>
            )}
            <OverlayTrigger placement="left" overlay={<Tooltip>Reset</Tooltip>}>
              <img src={resetFormIcon} onClick={resetHandle} />
            </OverlayTrigger>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`p-0 overflow-hidden`}>
          <div className={`${style.tableContainer} `}>
            <div className={`row m-0 px-0 pt-1 pb-0 border-bottom w-100 overflow-auto`}>
              <ul className={`${style.stepper_progress} p-0`}>
                <li>
                  <div className={style.active}>
                    <img alt="" src={status?.includes("Validated") ? form_completed : form_1_active} height={20} width={20} />
                    <div className={``}>Validation</div>
                  </div>
                </li>
                <li>
                  <div className={status?.includes("Validated") ? style.active : ``}>
                    <img alt="" src={status?.includes("Validated") ? form_2_active : form_2_pending} height={20} width={20} />
                    <div className={``}>Activation</div>
                  </div>
                </li>
              </ul>
            </div>
            {status?.includes("Validation Pending") ? (
              <ValidationForm ref={validationRef} value={value} setValue={setValue} category={value?.listing?.category} setLoading={setIsLoading} setIsSubmitting={setIsSubmitting} sendChangeSignal={sendChangeSignal} />
            ) : status?.includes("Inactive") ? (
              <ListingActivationForm ref={activationRef} value={value} category={value?.listing?.category} setLoading={setIsLoading} setIsSubmitting={setIsSubmitting} sendChangeSignal={sendChangeSignal} />
            ) : (
              <>
                {/*<LoaderPage />*/}
                <div style={{ minHeight: "400px", height: "100%" }}></div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row className={`w-100 justify-content-end pe-2`}>
            <Col className={`d-flex  justify-content-end gap-1`}>
              <Button onClick={onHideHandle} variant="outline-primary">
                Close
              </Button>
              {status?.includes("Validation Pending") ? (
                <Button onClick={handleSaveAndClose} variant="outline-secondary">
                  Save and Close
                </Button>
              ) : (
                <Button onClick={handleBackToValidation} variant="outline-secondary">
                  Back to Validation
                </Button>
              )}

              {itemsLimit && status?.includes("Validated") ? (
                <Button
                  onClick={() => {
                    navigate("/settings/plans");
                  }}
                  variant="primary"
                >
                  Upgrade Subscription
                </Button>
              ) : (
                <Button onClick={handleSaveAndContinue} variant="primary">
                  Activate Listing
                </Button>
              )}
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}
