import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import locationService from "./locationService";

let initialValue = localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")) : null;

const initialState =
  initialValue && initialValue?.location && initialValue?.register
    ? {
        location: initialValue.location,
        register: initialValue.register,
      }
    : {
        location: {},
        register: {
          registerId: -1,
          isCashRegisterOpen: false,
          openingBalance: null,
        },
      };
export const setLocation = createAsyncThunk("/location/set-location", async ({ shop_id, location }, thunkApi) => {
  try {
    const res = await locationService.getRegisterId(shop_id, location.value);
    if (res) {
      const result = await locationService.checkOpeningBalance(res);
      if (result) {
        return {
          location: location,
          register: {
            registerId: res,
            isCashRegisterOpen: result.isCashRegisterOpen,
            openingBalance: result,
          },
        };
      } else {
        return thunkApi.rejectWithValue("Invalid register id!");
      }
      // return res;
    } else {
      return thunkApi.rejectWithValue("Invalid shop or location!");
    }
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.error.message) || error.message || error.toString();
    return thunkApi.rejectWithValue(message);
  }
});

export const locationSlice = createSlice({
  name: "locationGlobal",
  initialState,
  reducers: {
    closeLocationRegister: (state, action) => {
      state.register = {
        registerId: state.register.registerId,
        isCashRegisterOpen: false,
        openingBalance: null,
      };
    },
    onLogout: (state, action) => {
      state.location = {};
      state.register = {
        registerId: -1,
        isCashRegisterOpen: false,
        openingBalance: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setLocation.rejected, (state, action) => {
        state.register = {
          registerId: -1,
          isCashRegisterOpen: false,
          openingBalance: null,
        };
        state.location = {};
      })
      .addCase(setLocation.fulfilled, (state, action) => {
        state.register = action.payload.register;
        state.location = action.payload.location;
        localStorage.setItem("location", JSON.stringify(action.payload));
      });
  },
});
export const { closeLocationRegister, onLogout } = locationSlice.actions;
export default locationSlice.reducer;
