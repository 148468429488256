import React, { useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addMiscToCart } from "../../../Features/cart/cartSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPlusCircle, faXmark } from "@fortawesome/free-solid-svg-icons";

const MiscItems = () => {
  const [miscItemModalShow, setMiscItemModalShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  const [miscItem, setMiscItem] = useState([
    {
      title: "",
      salePrice: "",
      quantity: 1,
    },
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setValidated(true);
    dispatch(addMiscToCart(miscItem));
    setMiscItem([
      {
        title: "",
        salePrice: "",
        quantity: 1,
      },
    ]);
    setValidated(false);
    setMiscItemModalShow(false); // Close the modal after submission
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setValidated(true);
      // Check if all required fields are filled before submitting
      if (miscItem.every((item) => item.title && item.salePrice && item.quantity)) {
        handleSubmit(e); // Submit the form
      }
    }
  };

  const deleteRow = (index) => {
    let items = [...miscItem];
    items.splice(index, 1);
    setMiscItem(items);
  };
  const handleChangeProperty = (event, id, property) => {
    if (property === "quantity" || property === "salePrice") {
      if (+event.target.value > 0) {
        let items = [...miscItem];
        items[id][property] = event.target.value;
        setMiscItem(items);
      } else {
        let items = [...miscItem];
        items[id][property] = "";
        setMiscItem(items);
      }
    } else {
      if (event.target.value?.length < event.target.maxLength) {
        let items = [...miscItem];
        items[id][property] = event.target.value;
        setMiscItem(items);
      }
    }
  };

  const addRow = () => {
    const newRow = {
      id: miscItem?.length + 1,
      title: "",
      quantity: 1,
      salePrice: "",
    };
    setMiscItem([...miscItem, newRow]);
  };
  return (
    <>
      <Button variant="outline-primary" style={{ fontSize: "12px", height: "38px", marginLeft: "1em" }} onClick={() => setMiscItemModalShow(true)} className="text-nowrap" id="sales-cart-misc-items">
        Add Misc. Item <FontAwesomeIcon icon={faPlus} />
      </Button>
      <Modal centered show={miscItemModalShow} size="lg" onHide={() => setMiscItemModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Misc. Item</Modal.Title>
        </Modal.Header>
        <Form validated={validated} onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
          <Modal.Body className="m-0 p-0">
            <div className="table-container" style={{ overflowY: "auto", height: "250px", width: "100%" }}>
              <Table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>
                      <label className="text-muted fs-13">Title</label>
                    </th>
                    <th style={{ width: "120px" }}>
                      <label className="text-muted fs-13">Quantity</label>
                    </th>
                    <th style={{ width: "180px" }}>
                      <label className="text-muted fs-13">Sale Price</label>
                    </th>
                    <th style={{ width: "50px" }}>
                      <label className="text-muted fs-13">Action</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {miscItem?.length &&
                    miscItem?.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <Form.Group className=" ">
                            <Form.Control
                              placeholder="Title"
                              type="text"
                              maxLength={30}
                              value={row.title}
                              onChange={(e) => {
                                handleChangeProperty(e, index, "title");
                              }}
                              name="title"
                              required
                            />
                            <Form.Control.Feedback type="invalid">* Enter Title</Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        <td>
                          {" "}
                          <Form.Group className="">
                            <Form.Control
                              value={row.quantity}
                              maxLength={10}
                              isInvalid={row.quantity?.length > 0 && Number(row?.quantity) === 0 ? true : false}
                              onChange={(e) => {
                                handleChangeProperty(e, index, "quantity");
                              }}
                              className=""
                              type="number"
                              required
                              placeholder="Quantity"
                              onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                            />
                            <Form.Control.Feedback type="invalid">* Enter Quantity</Form.Control.Feedback>
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group className=" ">
                            <Form.Control
                              value={row.salePrice}
                              maxLength={10}
                              isInvalid={row.salePrice?.length > 0 && Number(row.salePrice) === 0 ? true : false}
                              onChange={(e) => {
                                handleChangeProperty(e, index, "salePrice");
                              }}
                              className=""
                              type="number"
                              required
                              placeholder="Sale Price"
                              onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                            />
                            <Form.Control.Feedback type="invalid">* Enter Sale Price</Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        <td>
                          <div
                            style={
                              miscItem?.length > 1
                                ? {}
                                : {
                                    pointerEvents: "none",
                                    opacity: "0.6",
                                    cursor: "pointer",
                                  }
                            }
                          >
                            <FontAwesomeIcon
                              onClick={() => deleteRow(index)}
                              className="cursor-pointer"
                              style={{
                                color: "red",
                                height: "22px",
                                marginLeft: "0.6rem",
                                marginTop: "7px",
                              }}
                              icon={faXmark}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex flex-column fs-13 text-center w-100 justify-content-center p-3">
              <FontAwesomeIcon onClick={addRow} className="cursor-pointer" icon={faPlusCircle} />
              Add More
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Add Misc. Item
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default MiscItems;
