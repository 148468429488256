import { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import AddForm from "../../Components/AddForm";
import editpencil from "../../assets/svg/editpencil.svg";
import PopUpModal from "../../Components/Modal";
import style from "../../styles/AddInventory.module.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "../../Components/ReactSelect";
import axios from "axios";
import { useSelector } from "react-redux";
import SearchableSelect from "../../Components/SearchableSelect";
import Bank from "./Bank";
import PhoneInput from "../../Components/PhoneInput/PhoneInput";
import Loading from "./../../Components/Loading/Loading";

const EditVendor = () => {
  const { user } = useSelector((state) => state.auth);
  const user_type = user.user_type;

  const shop_id = user.shop_id;
  const user_id = user.user_id;
  const [isClicked, setIsClicked] = useState(false);
  const [data, setData] = useState([]);
  const [isloading, setisLoading] = useState(true);
  const [vendorOpeningBalance, setVendorOpeningBalance] = useState("");
  const [memo, setMemo] = useState("");

  const { id } = useParams();

  const location = useLocation();
  const navigate = useNavigate();
  //fixed duplication of banks
  const ourRequest = axios.CancelToken;
  var cancel;

  //get Vendor
  useEffect(() => {
    const getVendor = setTimeout(async () => {
      try {
        const data = await axios.post(
          process.env.REACT_APP_API_URL + "/vendors/get/" + id,
          { shopId: shop_id },
          { withCredentials: true },
          {
            cancelToken: new ourRequest((c) => {
              cancel = c;
            }),
          },
        );
        if (data) {
          cancel();
          setData(data.data);
          setVendorName(data.data.vendor_name);
          setEmail(data.data.business_email.length > 0 ? data.data.business_email : undefined);
          setPhone(data.data.business_phone.length > 0 ? data.data.business_phone : undefined);
          setContactName(data.data.contact_person_name === "nil" ? undefined : data.data.contact_person_name);
          setContactPhone(data.data.contact_number.length > 0 ? (data.data.contact_number === "nil" ? undefined : data.data.contact_number) : undefined);
          setProductType(data.data.product_types.split(",").map((type) => ({ value: type, label: type })));
          setVendorOpeningBalance(data.data.vendor_opening_balance > 0 ? data.data.vendor_opening_balance : undefined);
          setMemo(data.data.memo === "nil" ? undefined : data.data.memo);
          setTerms(data.data.terms === "nil" ? undefined : data.data.terms);
          const locationsRes = await axios.post(process.env.REACT_APP_API_URL + "/location/get-location-by-user/" + user_id, { shop_id: shop_id, user_type: user_type }, { withCredentials: true });
          if (locationsRes) {
            const locations = locationsRes.data
              .map(({ location_id, location }) => ({
                value: location_id,
                label: location,
              }))
              .filter(({ value, label }) => {
                if (Number(value) === Number(data.data.location)) {
                  return { value, label };
                }
              });
            setVendorLocation(locations[0]);
          }
          const bankVar = await axios.get(process.env.REACT_APP_API_URL + "/vendors/bank/get-by-vendor/" + id, { withCredentials: true });
          if (bankVar) {
            const banks = bankVar.data.map((bank) => {
              const obj = {};
              obj["bankId"] = bank.vendor_bank_id;
              obj["accountName"] = bank.account_holder_name;
              obj["accountNumber"] = bank.account_number;
              obj["bankName"] = bank.bank_name;
              obj["accountEmail"] = bank.email;
              return obj;
            });
            setOldBanks(banks);
            setisLoading(false);
          }
        }
      } catch (error) {
        setisLoading(false);
        // throw error;
      }
    }, 1000);
    return () => {
      clearTimeout(getVendor);
    };
  }, [id]);

  //form validation
  const [validatedVendor, setValidatedVendor] = useState(false);

  //vendors
  const [vendorName, setVendorName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [vendorLocation, setVendorLocation] = useState("");
  const [productType, setProductType] = useState([]);
  const [banks, setBanks] = useState([]);
  const [terms, setTerms] = useState("");
  const [error, setError] = useState("");

  const loadLocatinOptions = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.REACT_APP_API_URL + "/location/get-location-by-user/" + user_id, { shop_id: shop_id, user_type: user_type }, { withCredentials: true })
        .then((locationsRes) => {
          const locations = locationsRes.data.map(({ location_id, location, active_till }) => ({
            value: location_id,
            label: location,
            isDisabled: new Date().toISOString() >= active_till ? true : false,
          }));
          resolve(locations);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleVendorNameChange = (e) => {
    setVendorName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handleContactNameChange = (e) => {
    setContactName(e.target.value);
  };
  const handleContactPhoneChange = (e) => {
    setContactPhone(e.target.value);
  };

  const handleChangeLocation = (e) => {
    setVendorLocation({ value: e.value, label: e.label });
  };

  const handleTermsChange = (e) => {
    setTerms(e.target.value);
  };

  //bank
  const [bankData, setBankData] = useState(null);

  const [oldBanks, setOldBanks] = useState([]);
  const [newBanks, setNewBanks] = useState([]);
  const [deletedBanks, setDeletedBanks] = useState([]);

  useEffect(() => {
    setBanks([...oldBanks, ...newBanks]);
  }, [oldBanks, newBanks]);

  const handleBankRemove = (index) => {
    if (index.bankId) {
      setDeletedBanks((prev) => [...prev, index.bankId]);
    }
    setOldBanks(oldBanks.filter((bank) => bank !== index));
    setNewBanks(newBanks.filter((bank) => bank !== index));
    // setBanks(banks.filter(bank => bank !== index));
  };

  const handleBankEdit = (bank) => {
    setBankData(bank);
    setEditBankModalShow(true);
  };

  // submit form

  const handleVendorSubmit = async (e) => {
    try {
      e.preventDefault();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
      }
      setValidatedVendor(true);
      const emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      let emailPass = true;
      if (email?.length > 0 && !emailFormat.test(email)) {
        emailPass = false;
      }
      if (vendorName.length > 0 && productType.length > 0 && phone.length > 0 && emailPass) {
        const vendors = {
          vendor_name: vendorName,
          business_email: email,
          business_phone: phone,
          contact_person_name: contactName,
          contact_number: contactPhone,
          product_types: productType.map((obj) => obj.value).join(","),
          terms: terms,
        };
        const vendorOldBankDetail = oldBanks.map((bank) => ({
          vendor_bank_id: bank.bankId,
          account_holder_name: bank.accountName,
          account_number: bank.accountNumber,
          bank_name: bank.bankName,
          email: bank.accountEmail,
        }));
        const vendorNewBankDetail = newBanks.map((bank) => ({
          account_holder_name: bank.accountName,
          account_number: bank.accountNumber,
          bank_name: bank.bankName,
          email: bank.accountEmail,
        }));
        setIsClicked(true);

        const data = await axios.patch(
          process.env.REACT_APP_API_URL + "/vendors/update/" + id,
          {
            vendors: vendors,
            oldBanks: vendorOldBankDetail,
            newBanks: vendorNewBankDetail,
          },
          { withCredentials: true },
        );
        if (data) {
          deletedBanks.map((bank) => {
            axios.delete(process.env.REACT_APP_API_URL + "/vendors/bank/delete/" + bank, { withCredentials: true });
            return null;
          });
          navigate(location.state.redirect);
          setError("");
        }
      }
    } catch (err) {
      setIsClicked(false);
      setError(err.response?.data.error.message);
    }
  };

  const [addBankModalShow, setAddBankModalShow] = useState(false);
  const [editBankModalShow, setEditBankModalShow] = useState(false);

  return (
    <>
      {isloading ? (
        <Loading />
      ) : data.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
          <h1>No Record exists against the provided ID.</h1>
        </div>
      ) : (
        <AddForm>
          <div className="d-flex justify-content-between align-items-center px-5">
            <h5>Edit Supplier</h5>
          </div>
          <hr />

          <div className={`${style.formMain}`}>
            <Form noValidate validated={validatedVendor} onSubmit={handleVendorSubmit}>
              <hr className={`${style.borderedHr}`} />

              <Row className="">
                {error && <p className="text-danger text-center">{error}</p>}
                <Col className="py-3" md={6}>
                  <Form.Group className="w-100" controlId="formBasicShopName">
                    <label>
                      Supplier Name<span className="text-danger">*</span>
                    </label>
                    <Form.Control value={vendorName} className="p-2" onChange={handleVendorNameChange} name="vendor_name" type="text" placeholder="e.g. XYZ " required />
                    <Form.Control.Feedback type="invalid">Please Enter Supplier Name.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="py-3" md={6}>
                  <Form.Group className="w-100" controlId="formBasicShopName">
                    <label>Business Email </label>
                    <Form.Control value={email} onChange={handleEmailChange} className="p-2" name="business_email" type="email" placeholder="e.g. example@mail.com" />
                  </Form.Group>
                </Col>

                <Col className="py-3" md={6}>
                  <Form.Group className="w-100" controlId="formBasicShopName">
                    <label>
                      Business Phone<span className="text-danger">*</span>{" "}
                    </label>
                    <PhoneInput value={phone} onChange={handlePhoneChange} className="p-2" name="business_phone" placeholder="+92-3XX-XXXXXXX" />
                  </Form.Group>
                </Col>
                <Col className="py-3" md={6}>
                  <Form.Group>
                    <SearchableSelect
                      multiselect
                      value={productType}
                      placeholder="Select"
                      label="Product Type"
                      important
                      searchable={false}
                      options={[
                        { value: "laptops", label: "Laptops" },
                        { value: "mobiles", label: "Mobiles" },
                        { value: "tabs", label: "Tabs" },
                        {
                          value: "desktop computers",
                          label: "Desktop Computers",
                        },
                        { value: "leds", label: "LEDs" },
                        { value: "accessories", label: "Accessories" },
                      ]}
                      closeMenuOnSelect={false}
                      handleChange={(e) => setProductType(e)}
                    />
                    <Form.Control value={productType} onChange={() => {}} hidden required />
                    <Form.Control.Feedback type="invalid">Please select Product Type.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="py-3" md={6}>
                  <Form.Group className="w-100" controlId="formBasicShopName">
                    <label>Contact Person Name </label>
                    <Form.Control value={contactName} onChange={handleContactNameChange} className="p-2" name="contact_person_name" type="text" placeholder="Secondary contact person" />
                  </Form.Group>{" "}
                </Col>
                <Col className="py-3" md={6}>
                  <Form.Group className="w-100" controlId="formBasicShopName">
                    <label>Contact Number </label>
                    {/* <Form.Control value={contactPhone} onChange={handleContactPhoneChange} className="p-2" name='business_email' type="text" placeholder="e.g. example@mail.com" /> */}
                    <PhoneInput value={contactPhone} onChange={handleContactPhoneChange} className="p-2" name="business_email" placeholder="+92-3XX-XXXXXXX" />
                  </Form.Group>
                </Col>
                {vendorOpeningBalance > 0 ? (
                  <>
                    <Col className="py-3" sm="6" md={6}>
                      <Form.Group className="mb-3" controlId="price">
                        <Form.Label className="mb-0">Supplier Opening Balance</Form.Label>
                        <Form.Control min={0} type="number" placeholder="e.g. 500" value={vendorOpeningBalance ? vendorOpeningBalance : ""} disabled={true} name="vendor_opening_balance" />
                        <Form.Control.Feedback type="invalid">Invalid Supplier Opening Balance </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col className="py-3" sm="6" md={6}>
                      <Form.Group className="mb-3" controlId="price">
                        <Form.Label className="mb-0">
                          Location <span className="text-danger">*</span>
                        </Form.Label>
                        <ReactSelect placeholder="Select" value={vendorLocation} options={loadLocatinOptions} handleChange={handleChangeLocation} searchable={false} disabled={true} />
                        <Form.Control required value={vendorLocation} onChange={() => {}} hidden />
                        <Form.Control.Feedback type="invalid">Please Select Location.</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="price">
                        <Form.Label className="mb-0">Memo</Form.Label>
                        <Form.Control
                          type="text"
                          as="textarea"
                          rows={3}
                          placeholder="Write Memo"
                          value={memo}
                          onChange={(e) => {
                            setMemo(e.target.value);
                          }}
                          disabled={true}
                          name="memo"
                          pattern="^([A-Za-z ]|[0-9])+$"
                        />
                        <Form.Control.Feedback type="invalid">Please fill Memo </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col className="py-3 d-flex align-items-end" md={12}>
                  <Form.Group className="w-100 ">
                    <Button className="w-100 " variant="outline-primary" onClick={() => setAddBankModalShow(true)}>
                      Add bank details
                    </Button>
                    {/* <Form.Control value={banks} onChange={()=>{}} hidden required/>
                                    <Form.Control.Feedback type="invalid">Please Add atleast one ban</Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
                {banks.length > 0 && (
                  <>
                    <h4 style={{ color: "#39414A" }}>Banks</h4>
                    <Col className="py-3 row justify-content-around gap-4" md={12}>
                      {banks.map((bank, index) => (
                        <div className="p-4 bg-light shadow col-md-5" key={index} style={{ borderRadius: "20px" }}>
                          <div className="d-flex flex-column">
                            <div className="d-flex justify-content-between">
                              <h5
                                style={{
                                  color: "#39414A",
                                  marginBottom: "0rem",
                                }}
                              >
                                {bank.accountName}
                              </h5>
                              <div>
                                <div>
                                  <img
                                    src={editpencil}
                                    alt="edit icon"
                                    onClick={() => {
                                      handleBankEdit(bank);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <FontAwesomeIcon
                                    className="mx-3"
                                    onClick={() => {
                                      handleBankRemove(bank);
                                    }}
                                    icon={faClose}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <span style={{ color: "#39414A", marginBottom: "0rem" }}>Account number: {bank.accountNumber}</span>
                            <span style={{ color: "#39414A", marginBottom: "0rem" }}>Bank:{bank.bankName}</span>

                            <p style={{ color: "#39414A" }}>{bank.accountEmail === "nil" ? "" : bank.accountEmail}</p>
                          </div>
                        </div>
                      ))}
                    </Col>
                  </>
                )}
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Terms</Form.Label>
                    <Form.Control value={terms} onChange={handleTermsChange} placeholder="Terms and Condition" as="textarea" maxLength={300} rows={3} />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="py-3">
                <Col>
                  <Button className="w-100" onClick={handleVendorSubmit} disabled={isClicked}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </AddForm>
      )}

      <PopUpModal title="Add Vendor bank info" show={addBankModalShow} onHide={() => setAddBankModalShow(false)}>
        <Bank setBanks={setNewBanks} close={setAddBankModalShow} />
      </PopUpModal>

      <PopUpModal title="Edit Vendor bank info" show={editBankModalShow} onHide={() => setEditBankModalShow(false)}>
        <Bank setBanks={setOldBanks} close={setEditBankModalShow} data={bankData} setData={setBankData} />
      </PopUpModal>
    </>
  );
};

export default EditVendor;
